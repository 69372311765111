import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { colors, type } from 'core/components';

import ArrowSVG from 'assets/arrow-up-down.svg?react';

import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@reach/accordion';
import { ColorProfileKey } from 'components/StepsAccordion/AccordionStep';

const ColorProfiles: { [key in ColorProfileKey]: string } = {
  Red: `
    background: ${colors.red};
    color: ${colors.white};
  `,
  Yellow: `
    background: ${colors.yellow70};
    color: ${colors.black90};
  `,
  Green: `
    background: ${colors.green70};
    color: ${colors.white};
  `,
  Gray: `
    background: ${colors.black30};
    color: ${colors.text60};
  `,
};

export const ColoredDot = styled.div<{
  colorProfileKey?: ColorProfileKey;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.2;
  border-radius: 50%;
  background: ${colors.blue50};
  color: ${colors.white};
  padding-top: 3px;

  ${({ colorProfileKey }) =>
    colorProfileKey &&
    ColorProfiles[colorProfileKey] &&
    css`
      ${ColorProfiles[colorProfileKey]}
    `};
`;

export const IconContainer = styled.div`
  margin-right: 0.5rem;
`;

export const StatusBadge = styled.div`
  border-radius: 1rem;
  color: ${colors.text60};
  font-size: ${type.size.small};
  padding: 0.3rem 0.6rem 0.1rem 0.6rem;
  position: absolute;
  right: 3rem;
  background: ${colors.black30};
`;

export const Item = styled(AccordionItem)<{
  $isComplete: boolean;
  $hasError: boolean;
  $colorProfileKey?: ColorProfileKey;
  $isExpanded: boolean;
  $isLoading: boolean;
}>`
  margin-bottom: 0.75rem;
  border: 1px solid ${colors.black20};
  border-radius: 8px;

  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      box-shadow:
        0 2px 5px rgba(0, 0, 0, 0.1),
        0 4px 4px rgba(0, 0, 0, 0.05);
    `};

  ${({ $isComplete }) =>
    $isComplete &&
    css`
      ${StatusBadge} {
        background: ${colors.green70};
        color: ${colors.white};
      }
    `};

  ${({ $hasError }) =>
    $hasError &&
    css`
      ${StatusBadge} {
        background: ${colors.error};
        color: ${colors.white};
      }
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${colors.black05};
      color: ${colors.black50};

      ${ColoredDot} {
        background: ${colors.black50};
      }
    `};

  ${({ $colorProfileKey, $isLoading }) =>
    !$isLoading &&
    $colorProfileKey &&
    ColorProfiles[$colorProfileKey] &&
    css`
      ${StatusBadge} {
        ${ColorProfiles[$colorProfileKey]}
      }
    `};
`;

export const Button = styled(AccordionButton)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1.5rem 3rem 1.5rem 1.5rem;
`;

export const Title = styled(type.H4)`
  margin-bottom: -0.25rem;
`;

export const Panel = styled(AccordionPanel)`
  padding: 0 1.5rem 1.5rem;
`;

export const ArrowIcon = styled(ArrowSVG)<{ $isExpanded: boolean }>`
  position: absolute;
  right: 1.5rem;
  width: 12px;
  height: 7px;
  fill: currentColor;
  transform: ${({ $isExpanded }) => $isExpanded && 'rotateX(180deg)'};
`;
