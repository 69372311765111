import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit';
import systemReducer from 'state/system/slice';

import orthoReducer from 'pages/OrthoPrism/orthoSlice';
import prismReducer from 'pages/Prism/prismSlice';

import ActionItemsReducer from 'pages/ActionItems/actionItemsSlice';
import PatientReducer from 'pages/Patient/patientSlice';
import PromotionsReducer from 'pages/Promotion/promotionsSlice';
import PracticeManagmentReducer from 'pages/PracticeManagement/shared/slice';

import systemMiddleware from 'state/system/middleware';
import api from 'state/api/api';
import listenerMiddleware from 'state/store/listener';

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    system: systemReducer,
    ortho: orthoReducer,
    prism: prismReducer,
    actionItems: ActionItemsReducer,
    patient: PatientReducer,
    practiceManagement: PracticeManagmentReducer,
    promotions: PromotionsReducer,
  },
  middleware: (getDefault) =>
    getDefault({
      // After the `@apollo/client` update, the client itself isn't serializable anymore
      // (or it never was and failed silently, not sure)
      // Disabling the serializable check for now, until we find a better / permanent solution
      serializableCheck: false,
    })
      .prepend(listenerMiddleware.middleware)
      .concat(api.middleware)
      .concat(systemMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppDispatch = typeof store.dispatch;

export default store;
