import styled from 'styled-components/macro';

export const FormContainer = styled.form`
  max-width: 40rem;
  padding: 1.25rem;
  text-align: center;
`;

export const Reasons = styled.div`
  margin-bottom: 1.25rem;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-align: left;
`;

export const Label = styled.label`
  display: block;
  padding: 0.5rem 0;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

export const Checkbox = styled.input`
  margin-right: 0.5rem;
  cursor: pointer;
  appearance: checkbox;
`;
