import React, { useState } from 'react';

import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import * as TreatmentPlans from 'pages/OrthoPrism/treatmentPlans';
import {
  FormRow,
  NoteHeading,
  SmallHeading,
  SmallSubHeading,
  TextArea,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/TreatmentPlanTab/ProReviewForm.css';

type Props = {
  tpState: TreatmentPlans.ReviewOption | undefined;
  isProCase?: boolean;
  isProClinician?: boolean;
  onChange: (notes?: string) => void;
};

type TextChange = React.ChangeEvent<HTMLTextAreaElement>;

const Notes = ({ tpState, isProCase, isProClinician, onChange }: Props) => {
  const isRejected = TreatmentPlans.isRejectionOption(tpState);
  const isMissingInfo =
    tpState === TreatmentPlans.ReviewOption.MissingInformation;

  const [note, setNote] = useState('');

  const onChangeNotes = (e: TextChange) => {
    onChange(e.target.value);
  };

  const isOnePartForm =
    (!isRejected && !isProCase) || isRejected || isProClinician;
  const isTwoPartForm = !isRejected && isProCase;

  if (isOnePartForm && isRejected) {
    return (
      <FormRow>
        <SmallHeading>Notes {isRejected && '(required)'}</SmallHeading>
        <TextArea
          maxLength={5000}
          onChange={onChangeNotes}
          placeholder={'Your notes or instructions'}
        />
      </FormRow>
    );
  }

  if (isTwoPartForm) {
    return (
      <FormRow>
        <NoteHeading>Notes</NoteHeading>
        {!isMissingInfo && (
          <SmallSubHeading>
            Leave detailed notes to let the provider know how we are treating
            this patient
          </SmallSubHeading>
        )}
        <RichTextEditor
          onChange={(s: string) => {
            setNote(s);
            onChange(s);
          }}
          content={note}
        />
      </FormRow>
    );
  }
  return null;
};

export default Notes;
