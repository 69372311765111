import {
  RespondToClarificationRequestMutation,
  RespondToClarificationRequestMutationVariables,
  RespondToClarificationRequestDocument,
} from 'generated/core/graphql';
import { useGQLMutation } from 'hooks/useGQL';
import { useDispatch } from 'react-redux';
import {
  setClarificationRequest,
  setPostSubmitPrismClarificationInfo,
  NeedsClarificationInfo,
} from 'pages/Patient/patientSlice';
import moment from 'moment';
import { useSubmitMaterialAggregateClarificationMutation } from 'generated/legacy/graphql';

const useSubmitClarificationResponse = () => {
  const [respondToClarification] = useGQLMutation<
    RespondToClarificationRequestMutation,
    RespondToClarificationRequestMutationVariables
  >(RespondToClarificationRequestDocument, true);
  const [submitLegacyClarification] =
    useSubmitMaterialAggregateClarificationMutation({
      errorPolicy: 'all',
    });
  const dispatch = useDispatch();
  const submitNeedsClarificationResponse = (
    clarificationResponse: string,
    userEmail: string | null | undefined,
    clarificationInfo: NeedsClarificationInfo,
    caseRef?: string
  ) => {
    const promises = [];

    const dispatchSetClarificationRequestHelper =
      (materialClassification: 'scan' | 'xray') =>
      (result: RespondToClarificationRequestMutation | undefined) => {
        const clarificationRequest =
          result?.respondToClarificationRequest?.clarificationRequest;
        if (clarificationRequest) {
          dispatch(
            setClarificationRequest({
              clarificationRequest,
              materialClassification,
              caseRef,
            })
          );
        }
      };

    const dispatchPrismClarificationInfoHelper = () => {
      const updatedPrismClarificationInfo = {
        ...clarificationInfo,
        responseInfo: {
          response: clarificationResponse,
          respondedAt: moment().format(),
          respondedByEmail: userEmail,
        },
      };
      dispatch(
        setPostSubmitPrismClarificationInfo(updatedPrismClarificationInfo)
      );
    };

    if (clarificationInfo?.prismSubmissionRef) {
      promises.push(
        submitLegacyClarification({
          variables: {
            clarificationInput: {
              evaluationNotes: clarificationResponse,
              prismSubmissionRef: clarificationInfo.prismSubmissionRef,
            },
          },
        }).then(dispatchPrismClarificationInfoHelper)
      );
    }

    if (clarificationInfo?.scanClarificationRequestId) {
      promises.push(
        respondToClarification({
          response: clarificationResponse,
          clarificationRequestId: clarificationInfo.scanClarificationRequestId,
        }).then(dispatchSetClarificationRequestHelper('scan'))
      );
    }

    if (clarificationInfo?.xrayClarificationRequestId) {
      promises.push(
        respondToClarification({
          response: clarificationResponse,
          clarificationRequestId: clarificationInfo.xrayClarificationRequestId,
        }).then(dispatchSetClarificationRequestHelper('xray'))
      );
    }

    return promises;
  };

  return { submitNeedsClarificationResponse };
};

export default useSubmitClarificationResponse;
