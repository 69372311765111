import React from 'react';
import { useSelector } from 'react-redux';
import { Loading } from 'core/components';
import moment from 'moment';

import { formatIntakeSections } from 'utils/intake';
import VersionPills, { useVersionPills } from 'components/StyledVersionPills';
import { determineIntakeName } from 'utils/intake';

import {
  selectCompletedIntakeForms,
  selectSelectedCase,
  selectPatientDoctorPreferences,
} from 'pages/Patient/patientSlice';
import { DentalNotationOptions } from 'generated/legacy/graphql';

import { usePatientLoadingStates } from 'pages/Patient/utils';
import SurveySection from 'components/Intake/SurveySection';
import {
  TgWrapper,
  TgDetailHeader,
  TgHeaderContainer,
  TgSubmissionInformation,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/DiagnosticMaterials.css';

const TreatmentGoalsTab = () => {
  const intakeForms = useSelector(selectCompletedIntakeForms);
  const doctorPreferences = useSelector(selectPatientDoctorPreferences);
  const { isFetchingTGs } = usePatientLoadingStates();
  const selectedCase = useSelector(selectSelectedCase);
  const vpProps = useVersionPills(intakeForms ?? [], true);
  const intakeForm = intakeForms?.[vpProps.currentIndex];

  if (!intakeForm) {
    return null;
  }

  if (isFetchingTGs) {
    return <Loading />;
  }

  const intakeName = determineIntakeName(selectedCase?.isGen2);

  const formattedIntakeSections = formatIntakeSections(
    intakeForm.data.sections
  );

  const intakeSumissionDate = moment(intakeForm?.signedDate!).format(
    'MMM Do YYYY'
  );
  const intakeSubmissionTime = moment(intakeForm?.signedDate!).format('h:mm A');
  const submissionSubtext = `Submitted ${intakeSumissionDate} at ${intakeSubmissionTime}`;

  return (
    <TgWrapper>
      {!selectedCase?.isGen2 && <VersionPills {...vpProps} />}
      <TgHeaderContainer>
        <div>
          <TgDetailHeader>{intakeName}</TgDetailHeader>
          <TgSubmissionInformation>{submissionSubtext}</TgSubmissionInformation>
        </div>
      </TgHeaderContainer>

      {formattedIntakeSections.map((section, index) => {
        return (
          <SurveySection
            section={section}
            displayDivider={index < formattedIntakeSections.length - 1}
            key={index}
            dentalNotation={
              doctorPreferences?.dentalNotation as DentalNotationOptions
            }
          />
        );
      })}
    </TgWrapper>
  );
};

export default TreatmentGoalsTab;
