import React from 'react';
import { AppcuesFlows } from 'constants/appcues';

import {
  Banner,
  BannerText,
  AlertContainer,
  TourLink,
  DismissLink,
} from 'pages/ActionItems/TourBanner.css';

//Object for tracking when the user has snoozed the action items banner
type SnoozeData = {
  snoozeExpiration: string | null; //When the snooze expires.
  timesSnoozed: number; // number of times snoozed
};

type Props = {
  setTourBannerSnoozed: React.Dispatch<React.SetStateAction<boolean>>;
  tourBannerSnoozed: boolean;
};

const TourBanner = ({ setTourBannerSnoozed, tourBannerSnoozed }: Props) => {
  const snoozeKey = 'actionItemsSnoozeData';

  //Read the snooze data from local storage, and parse it
  const getSnoozeData = (): SnoozeData => {
    const snoozeData = localStorage.getItem(snoozeKey) || '';
    if (!snoozeData.length) {
      return {
        timesSnoozed: 0,
        snoozeExpiration: null,
      };
    }
    return JSON.parse(snoozeData);
  };

  //Update the snooze data with the new expiration, and hide the banner
  const updateTourSnoozeData = () => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    localStorage.setItem(
      snoozeKey,
      JSON.stringify({
        timesSnoozed: snoozeData.timesSnoozed + 1,
        snoozeExpiration: tomorrow.toString(),
      })
    );

    //The snooze expiration is  only done on page load, set manually set it false here.
    setTourBannerSnoozed(true);
  };

  //Banner is snoozed if the number of times snoozed exceeds 3, or if the snooze has expired
  const isExpired = (): boolean => {
    return (
      snoozeData.timesSnoozed > 3 ||
      (!!snoozeData.snoozeExpiration &&
        new Date(snoozeData.snoozeExpiration!) > new Date())
    );
  };

  const snoozeData = getSnoozeData();

  if (tourBannerSnoozed) {
    return null;
  } else {
    setTourBannerSnoozed(isExpired());
  }

  return (
    <AlertContainer type="default">
      <Banner>
        <BannerText>👋</BannerText>
        <BannerText>
          Learn the ins-and-out's of the Action Items page:
        </BannerText>
        <TourLink
          onClick={() => window.Appcues.show(AppcuesFlows.ACTION_ITEMS_TOUR)}
        >
          Take a tour
        </TourLink>
        <DismissLink onClick={() => updateTourSnoozeData()}>
          {snoozeData.timesSnoozed === 3 ? 'Dismiss forever' : 'Show me later'}
        </DismissLink>
      </Banner>
    </AlertContainer>
  );
};

export default TourBanner;
