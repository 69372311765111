export const CANADA_COUNTRY_CODE = 'CA';
export const US_COUNTRY_CODE = 'US';

//ISO standard for Country Codes https://www.iban.com/country-codes
export const COUNTRY_CODE_MAPPING_ALPHA_2_TO_ALPHA_3 = {
  US: 'USA',
  CA: 'CAN',
  PR: 'PRI',
  VI: 'VIR',
  AS: 'ASM',
  GU: 'GUM',
  MP: 'MNP',
};
