import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import * as propTypes from 'utils/propTypes';
import NotFound from 'pages/NotFound';

import TpQualityControlSearch from 'pages/TpQualityControl/TpQualityControlSearch';
import OrthoPrismView from 'pages/OrthoPrism/OrthoPrismView';

const TpQualityControl = ({ match }: RouteComponentProps) => (
  <Switch>
    <Route
      component={TpQualityControlSearch}
      exact
      path={`${match.path}/:id?`}
    />
    <Route exact path={`${match.path}/:id/cases/:caseRef`}>
      <OrthoPrismView isQc={true} />
    </Route>
    <Route component={NotFound} />
  </Switch>
);

TpQualityControl.propTypes = {
  match: propTypes.routerMatch(),
};

export default TpQualityControl;
