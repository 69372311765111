import styled from 'styled-components/macro';
import { colors, mediaQueries, type } from 'core/components';
import DeleteIconPath from 'assets/delete.svg';

export const Heading = styled(type.H3)`
  margin-bottom: 1rem;
`;

export const CheckboxContainer = styled.form`
  display: block;
  padding: 1.5rem;
  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};
  border-radius: 0.1875rem;
`;

export const FormContent = styled.div`
  max-width: 37.375rem;
  margin: 0 auto;
`;

export const Label = styled.label`
  display: block;
  padding: 0.5rem 0;
  cursor: pointer;
  padding-bottom: 1rem;
  font-size: ${type.size.t2};
`;

export const Checkbox = styled.input`
  margin-right: 0.5rem;
  cursor: pointer;
  appearance: checkbox;
`;

export const UploadContainer = styled.div`
  background: ${colors.black05};
  height: 480px;
`;

export const FileTypesText = styled.div`
  margin-top: 1rem;
  color: ${colors.black70};
  text-align: center;
`;

export const SelectedPhotos = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 4rem);
  grid-auto-rows: 4rem;
  grid-gap: 2rem;
  width: 100%;
  margin-bottom: 0rem;

  @media ${mediaQueries.tabletAndAbove} {
    grid-template-columns: repeat(auto-fill, 6rem);
    grid-auto-rows: 6rem;
  }
`;

export const DeleteButton = styled.button`
  position: absolute;
  top: -8px;
  right: -8px;
  background: url(${DeleteIconPath}) no-repeat center;
  background-size: cover;
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

export const FileName = styled.div`
  position: absolute;
  top: 100%;
  right: 0px;
  width: 100%;
  height: 35px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  overflow: hidden;
`;

export const PhotoTile = styled.div`
  position: relative;
  background: ${({ photo }) => photo && `url(${photo}) no-repeat center`};
  background-size: cover;
  margin-bottom: 1rem;
`;

export const UploadTile = styled.div`
  position: relative;
  background: ${colors.black05};
`;

export const AlertContainer = styled.div`
  margin-top: 1rem;
`;
