import React, { ChangeEvent } from 'react';
import { FilterProps } from 'react-table';
import { TextInput } from 'core/components';

export const InputColumnFilter = <T extends object>({
  column,
}: FilterProps<T>) => {
  /**
   * An input filter
   */

  const { filterValue, setFilter } = column;
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value || undefined);
  };

  return (
    <TextInput
      onChange={handleChange}
      value={filterValue || ''}
      placeholder="Search..."
    />
  );
};
