import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { type } from 'core/components';
import { useIsLoading } from 'state/system';
import {
  fetchPrismAggregates,
  fetchXrays,
  selectXrayMaterialState,
  fetchScans,
  selectScanMaterialState,
  selectPrismState,
  selectClarificationRequestInfo,
  selectArePhotosReadyForSubmit,
  selectAreScansReadyToSubmit,
  selectAreXraysReadyToSubmit,
  selectIsSubmitted,
  uploadScan,
  uploadXrays,
  selectSkipCaseSummary,
  selectSectionsCanCollect,
  selectActiveCase,
  selectIsRetainerCaseWithOrderInfo,
  selectPendingClarificationResponse,
} from 'pages/Patient/patientSlice';
import StepsAccordion, { AccordionStep } from 'components/StepsAccordion';
import NeedsClarificationBanner from 'pages/Patient/CaseCreator/NeedsClarificationBanner';

import Scans from 'pages/Patient/CaseCreator/DiagnosticMaterials/Scans';
import Photos from 'pages/Patient/CaseCreator/DiagnosticMaterials/Photos';
import Xrays from 'pages/Patient/CaseCreator/DiagnosticMaterials/Xrays';

import { PageWrapper, BackButton, LeftArrow } from 'pages/Patient/styles.css';
import CameraSVG from 'assets/camera.svg?react';
import ScansSVG from 'assets/scans.svg?react';
import XraySVG from 'assets/xray.svg?react';
import { RouteParams } from 'pages/Patient/CaseCreator/types';
import CaseCreatorFooter, { Steps } from 'pages/Patient/Footer';
import {
  getCoreMaterialBadgeInfo,
  getPrismBadgeInfo,
  materialNeedsClinicianAction,
} from 'pages/Patient/utils';
import { SkeletonCaseCreator } from 'pages/Patient/CaseCreator/Skeletons';
import useSubmitCase from 'pages/Patient/CaseCreator/useSubmitCase';
import api from 'state/api';

const NO_MATERIAL_STEP = -1;

const DiagnosticMaterials = () => {
  const match = useRouteMatch<RouteParams>();
  const { push } = useHistory();

  const [stepIndex, setStepIndex] = useState<number>(NO_MATERIAL_STEP);
  const isSubmitted = useSelector(selectIsSubmitted);
  const photoState = useSelector(selectPrismState);
  const scanState = useSelector(selectScanMaterialState);
  const xrayState = useSelector(selectXrayMaterialState);
  const photosReadyForSubmit = useSelector(selectArePhotosReadyForSubmit);
  const scansReadyToSubmit = useSelector(selectAreScansReadyToSubmit);
  const xraysReadyToSubmit = useSelector(selectAreXraysReadyToSubmit);
  const sectionsCanCollect = useSelector(selectSectionsCanCollect);
  const skipCaseSummary = useSelector(selectSkipCaseSummary);
  const photosLoading = useIsLoading(fetchPrismAggregates.typePrefix);
  const scansLoading = useIsLoading(fetchScans.typePrefix);
  const xraysLoading = useIsLoading(fetchXrays.typePrefix);
  const isUploadingScans = useIsLoading(uploadScan.typePrefix);
  const isUploadingXrays = useIsLoading(uploadXrays.typePrefix);
  const activeCase = useSelector(selectActiveCase);
  const isRetainerCaseWithOrderInfo = useSelector(
    selectIsRetainerCaseWithOrderInfo
  );
  const { data: { edges: ordersForCase = [] } = {} } = api.useGetOrdersQuery({
    caseRef: activeCase?.caseRef,
  });

  const hasSubmittedBefore =
    isRetainerCaseWithOrderInfo ||
    (activeCase?.caseType.name === 'retainer' && ordersForCase.length > 0);
  const { canSubmitCase, submitCase, isSubmittingCase } = useSubmitCase();
  const isMaterialsLoading = photosLoading || scansLoading || xraysLoading;

  const photoBadgeInfo = getPrismBadgeInfo(photoState);
  const xrayBadgeInfo = getCoreMaterialBadgeInfo(xrayState, xraysReadyToSubmit);
  const scanBadgeInfo = getCoreMaterialBadgeInfo(scanState, scansReadyToSubmit);
  const diagnosticMaterialSections = ['photos', 'scans', 'xrays'];
  const clarificationRequestInfo = useSelector(selectClarificationRequestInfo);
  const pendingClarificationResponse = useSelector(
    selectPendingClarificationResponse
  );
  const waitingForClarification =
    clarificationRequestInfo?.question &&
    !pendingClarificationResponse?.responseInfo;

  const footerDisabled =
    isUploadingScans || isUploadingXrays || waitingForClarification;
  const footerLoading = isUploadingScans || isUploadingXrays;
  const stepViewModels = sectionsCanCollect
    .filter((section) => diagnosticMaterialSections.includes(section))
    .map((section) => {
      if (section === 'photos') {
        return {
          key: 'photos',
          title: 'Photos',
          badgeInfo: photoBadgeInfo,
          isLoading: photosLoading,
          Icon: CameraSVG,
          ViewComponent: Photos,
        };
      } else if (section === 'scans') {
        return {
          key: 'scans',
          title: 'Scans',
          badgeInfo: scanBadgeInfo,
          isLoading: scansLoading,
          Icon: ScansSVG,
          ViewComponent: Scans,
        };
      } else if (section === 'xrays') {
        return {
          key: 'xrays',
          title: 'X-rays',
          badgeInfo: xrayBadgeInfo,
          isLoading: xraysLoading,
          Icon: XraySVG,
          ViewComponent: Xrays,
        };
      }
      throw new Error(`Section, '${section}' not in diagnostic materials`);
    });

  useEffect(() => {
    const getNextStep = () => {
      /* Do not change the current step if the user has a step already open.
       * For example when a step becomes complete
       * When the user changes steps manually, the current index will go to -1, then the choosen step
       */
      if (stepIndex >= 0 || isMaterialsLoading) {
        return stepIndex;
      }
      for (let i = 0; i < stepViewModels.length; i++) {
        const stepViewModel = stepViewModels[i];
        const key = stepViewModel.key;
        const isPhotosStep = key === 'photos' && !photosReadyForSubmit;
        const isScansStep =
          key === 'scans' &&
          materialNeedsClinicianAction(scanState) &&
          !scansReadyToSubmit;
        const isXraysStep =
          key === 'xrays' &&
          materialNeedsClinicianAction(xrayState) &&
          !xraysReadyToSubmit;
        if (isPhotosStep || isScansStep || isXraysStep) {
          return i;
        }
      }
      return NO_MATERIAL_STEP;
    };
    const nextStep = getNextStep();
    setStepIndex(nextStep);
  }, [photoState, scanState, xrayState, isMaterialsLoading]);

  const accordionOnChange = (index: number) => {
    if (stepIndex === index) {
      setStepIndex(NO_MATERIAL_STEP);
    } else {
      setStepIndex(index);
    }
  };

  const onBackClick = () => {
    push(`${match.url.split('/').slice(0, -1).join('/')}`);
  };
  if (isMaterialsLoading) {
    return <SkeletonCaseCreator />;
  }

  // update the footer to handle submitting the retainer case instead of going to checkout
  // or going to another page in the scenario of rejections or clarification where they don't
  // need to go to checkout again
  let retainerPostSubmitFooterInfo = {};
  if (hasSubmittedBefore && skipCaseSummary) {
    retainerPostSubmitFooterInfo = {
      onSubmit: async () => {
        await submitCase({ isCore: true });
        return true;
      },
      isCtaLoading: footerLoading || isSubmittingCase,
      disabled: footerDisabled || !canSubmitCase,
    };
  }

  return (
    <PageWrapper isNarrow>
      {!skipCaseSummary && (
        <BackButton onClick={onBackClick}>
          <LeftArrow /> Back to all case tasks
        </BackButton>
      )}
      <type.H4>Diagnostic materials</type.H4>
      <type.Body>
        Our team review these diagnostic materials and checks for any issues
        that could complicate treatment.
      </type.Body>
      {!!clarificationRequestInfo && (
        <NeedsClarificationBanner
          clarificationInfo={clarificationRequestInfo}
          isEditable={!isSubmitted}
          caseRef={activeCase?.caseRef}
          caseSource={activeCase?.source}
        />
      )}
      <StepsAccordion index={stepIndex} onChange={accordionOnChange}>
        {stepViewModels.map((stepViewModel, i) => {
          const nextIndex = i + 1 < stepViewModels.length ? i + 1 : undefined;
          return (
            <AccordionStep
              key={`accordion-step${stepViewModel.key}`}
              testId={`accordion-step${stepViewModel.key}`}
              number={`${i}`}
              isOpen={stepIndex === i}
              title={stepViewModel.title}
              statusBadgeText={stepViewModel.badgeInfo.text}
              badgeColorOverride={stepViewModel.badgeInfo.color}
              isLoading={stepViewModel.isLoading}
              Icon={stepViewModel.Icon}
            >
              <stepViewModel.ViewComponent
                onClickNext={
                  nextIndex ? () => setStepIndex(nextIndex!) : undefined
                }
              />
            </AccordionStep>
          );
        })}
      </StepsAccordion>
      <CaseCreatorFooter
        currentStep={Steps.DiagnosticMaterials}
        disabled={!!footerDisabled}
        rightMessage={
          waitingForClarification ? 'Write a response to re-submit' : ''
        }
        {...retainerPostSubmitFooterInfo}
      />
    </PageWrapper>
  );
};

export default DiagnosticMaterials;
