import { createContext } from 'react';

import { Queries } from 'generated/core/graphql';
interface ContextProps {
  formsData?: Queries['getForms'];
  refreshData: (returnError?: boolean) => Promise<void>;
}

export const IntakeFormsContext = createContext({} as ContextProps);
export const IntakeFormsProvider = IntakeFormsContext.Provider;
