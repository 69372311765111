import React, { useCallback, useEffect, useState } from 'react';
import api from 'state/api';
import { VirtualizedTable } from 'components/VirtualizedTable';
import { Column } from 'react-virtualized';
import { GetAllOrdersQuery } from 'generated/core/graphql';
import moment from 'moment';
import { Loading, Skeleton } from 'core/components';
import { Nullable } from 'utils/types';

type OrderListType = (GetAllOrdersQuery['orders']['edges'][0]['node'] & {
  practiceName?: string;
  patientId?: string;
})[];

const {
  useLazyGetAllOrdersQuery,
  useLazyGetPracticeQuery,
  useLazyGetCaseQuery,
} = api;

const HEADER_HEIGHT = 50;
const ROW_HEIGHT = 50;

const OrdersAdmin = () => {
  const [orders, setOrders] = useState<OrderListType>([]);
  const [
    getOrders,
    { data: orderData, currentData: currentOrderData, isLoading, isError },
  ] = useLazyGetAllOrdersQuery();
  const [getPractice] = useLazyGetPracticeQuery();
  const [practiceNames, setPracticeNames] = useState<{ [key: number]: string }>(
    {}
  );
  const [getCase] = useLazyGetCaseQuery();
  const [patientIds, setPatientIds] = useState<{ [key: string]: string }>({});

  const checkForPracticeName = useCallback(
    async (practiceId: number) => {
      if (!practiceNames[practiceId]) {
        const practice = await getPractice({
          id: practiceId.toString(),
        }).unwrap();
        setPracticeNames((prev) => ({
          ...prev,
          [practiceId]: practice.name,
        }));
      }
    },
    [practiceNames, setPracticeNames, getPractice]
  );

  const checkForPatientId = useCallback(
    async (caseRef: Nullable<string>) => {
      if (!caseRef) {
        return;
      }
      if (!patientIds[caseRef]) {
        try {
          const caseData = await getCase({ caseRef }).unwrap();
          setPatientIds((prev) => ({
            ...prev,
            [caseRef]: caseData.patientId.toString(),
          }));
        } catch (e) {
          console.log(caseRef, e);
          setPatientIds((prev) => ({
            ...prev,
            [caseRef]: '—',
          }));
        }
      }
    },
    [patientIds, setPatientIds, getCase]
  );

  useEffect(() => {
    getOrders({ first: 30 });
  }, []);

  useEffect(() => {
    const newOrders = currentOrderData
      ? [...orders, ...currentOrderData.edges.map((edge) => edge.node)]
      : orders;
    if (currentOrderData) {
      setOrders(newOrders);
      currentOrderData.edges.forEach((edge) => {
        checkForPracticeName(edge.node.practiceId);
        checkForPatientId(edge.node.caseRef);
      });
    }
  }, [currentOrderData]);

  return orderData ? (
    <VirtualizedTable
      rowCount={orders.length}
      rows={orders}
      headerHeight={HEADER_HEIGHT}
      rowHeight={ROW_HEIGHT}
      loadMoreRows={() => {
        currentOrderData?.pageInfo.hasNextPage &&
          getOrders({
            first: 30,
            after: currentOrderData?.pageInfo.endCursor,
          });
      }}
    >
      <Column
        label="Order ref"
        dataKey="shortOrderRef"
        flexGrow={0.1}
        flexShrink={1}
        width={1}
      />
      <Column
        label="Patient ID"
        dataKey="patientId"
        flexGrow={0.1}
        flexShrink={1}
        width={1}
        cellRenderer={({ rowData }) =>
          rowData.caseRef
            ? patientIds[rowData.caseRef] || <Skeleton width={'50%'} />
            : '-'
        }
      />
      <Column
        label="Practice"
        dataKey="practiceName"
        flexGrow={0.25}
        flexShrink={1}
        width={1}
        cellRenderer={({ rowData }) =>
          practiceNames[rowData.practiceId] || <Skeleton width={'100%'} />
        }
      />
      <Column
        label="Status"
        dataKey="status"
        flexGrow={0.1}
        flexShrink={1}
        width={1}
      />
      <Column
        label="Created at"
        dataKey="createdAt"
        flexGrow={0.15}
        flexShrink={1}
        width={1}
        cellRenderer={({ cellData }) =>
          moment(cellData).format('MM/DD/YYYY, h:mm:ss a')
        }
      />
    </VirtualizedTable>
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {isLoading && (
        <>
          <p>Loading</p>
          <Loading />
        </>
      )}
      {isError && (
        <>
          <p>We encountered an error while fetching orders.</p>
        </>
      )}
    </div>
  );
};

export default OrdersAdmin;
