import React, { useContext, useRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { FormikProps } from 'formik';

import { Modal, Button } from 'core/components';
import {
  StyledModal,
  Header,
  StyledDivider,
  Body,
  Footer,
  CancelButton,
  ModalTitle,
} from 'components/Modals/OrderRetainersModal/OrderRetainersModal.css';
import CreateOrUpdateCouponForm from 'components/Modals/CreateCouponsModal/CreateOrUpdateCouponForm';
import { useSelector } from 'react-redux';
import {
  selectPromotions,
  createCoupon,
  updateCoupon,
} from 'pages/Promotion/promotionsSlice';
import { NotificationContext } from 'core/components';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useIsLoading } from 'state/system';
import { AppDispatch } from 'state/store';

export type CreateOrUpdateCouponModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isEditing?: boolean;
  coupon?: CreateOrUpdateCouponFormValues;
};

export type CreateOrUpdateCouponFormValues = {
  promotionName: string;
  couponCode: string;
  redemptionLimit: string;
  startsAt: string | null;
  endsAt: string | null;
  updatedReason: string | null;
  practices: string | null;
  patients: string | null;
};

const CreateOrUpdateCouponModal = ({
  isOpen,
  onClose,
  isEditing,
  coupon,
}: CreateOrUpdateCouponModalProps) => {
  const promotions = useSelector(selectPromotions);
  const dispatch = useDispatch<AppDispatch>();
  const { showNotification } = useContext(NotificationContext);
  const isUpdatingCoupon = useIsLoading('promotions/updateCoupon');
  const isCreatingCoupon = useIsLoading('promotions/createCoupon');
  const isLoading = isUpdatingCoupon || isCreatingCoupon;
  const formRef = useRef<FormikProps<CreateOrUpdateCouponFormValues>>(null);
  const [initialValues, setInitialValues] =
    useState<CreateOrUpdateCouponFormValues>({
      promotionName: '',
      couponCode: '',
      redemptionLimit: '1',
      startsAt: moment().toISOString(),
      endsAt: null,
      updatedReason: '',
      practices: null,
      patients: null,
    });

  const onCancel = () => {
    onClose();
  };

  const setCouponExistingValues = (coupon: CreateOrUpdateCouponFormValues) => {
    setInitialValues({
      promotionName: coupon.promotionName,
      couponCode: coupon.couponCode || '',
      redemptionLimit: coupon.redemptionLimit.toString(),
      startsAt: coupon.startsAt,
      endsAt: coupon.endsAt || null,
      updatedReason: '',
      practices: coupon.practices,
      patients: coupon.patients,
    });
  };

  useEffect(() => {
    if (isEditing && coupon) {
      setCouponExistingValues(coupon);
    } else {
      // Reset the form if we are not editing
      setInitialValues({
        promotionName: '',
        couponCode: '',
        redemptionLimit: '1',
        startsAt: moment().toISOString(),
        endsAt: null,
        updatedReason: '',
        practices: null,
        patients: null,
      });
    }
  }, [isEditing, coupon]);

  const onSubmit = async (values: CreateOrUpdateCouponFormValues) => {
    try {
      if (isEditing) {
        await dispatch(
          updateCoupon({
            input: {
              redemptionLimit: parseInt(values.redemptionLimit),
              startsAt: moment(values.startsAt!).toISOString(),
              endsAt: values.endsAt
                ? moment(values.endsAt!).toISOString()
                : null,
              code: values.couponCode,
              metadataJson: {
                updatedReason: values.updatedReason,
              },
            },
          })
        ).unwrap();
      } else {
        const isSinglePatient = values.patients?.split(',').length === 1;
        await dispatch(
          createCoupon({
            input: {
              promotionName: values.promotionName,
              redemptionLimit: parseInt(values.redemptionLimit),
              startsAt: moment(values.startsAt!).toISOString(),
              endsAt: values.endsAt
                ? moment(values.endsAt!).toISOString()
                : null,
              code: values.couponCode || undefined,
              criteria: {
                patientId: isSinglePatient
                  ? values.patients?.trim()
                  : undefined,
                patientIds: !isSinglePatient
                  ? values.patients?.split(',')
                  : undefined,
                practiceIds: values.practices
                  ? values.practices?.trim().split(',')
                  : undefined,
              },
            },
          })
        ).unwrap();
      }

      showNotification(
        `Coupon ${isEditing ? 'editted' : 'created'} successfully`,
        'success'
      );
      onClose();
    } catch (error: any) {
      showNotification(
        `Failed to ${isEditing ? 'edit' : 'create'} coupon ${error?.message}`,
        'error'
      );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} hideCloseButton>
      <StyledModal>
        <Header>
          <ModalTitle>{isEditing ? 'Edit' : 'Create'} coupon</ModalTitle>
        </Header>
        <StyledDivider />
        <Body>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            innerRef={formRef}
            enableReinitialize={true}
          >
            <CreateOrUpdateCouponForm
              promotions={promotions}
              disabled={isLoading}
              isEditing={isEditing}
            />
          </Formik>
        </Body>
        <StyledDivider />
        <Footer>
          <CancelButton buttonType="text" onClick={onCancel}>
            Cancel
          </CancelButton>
          <Button
            onClick={() => formRef.current?.submitForm()}
            buttonType="secondary"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Submit
          </Button>
        </Footer>
      </StyledModal>
    </Modal>
  );
};

export default CreateOrUpdateCouponModal;
