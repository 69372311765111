import React, { useState } from 'react';
import { AlertCard, type, colors, Grid, Avatar } from 'core/components';
import PhotoModal from 'components/Modals/PhotoModal';
import { LegacyMaterialStates } from 'constants/Material';
import { FileInfo } from 'utils/materials';
import { getDoctorInitials } from 'utils/string';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import moment from 'moment';

import {
  selectCustomer,
  selectLatestXrayClarification,
  selectLatestScanClarification,
  selectIsInClarificationStep,
  selectLastSubmission,
  selectClarificationRequestInfo,
  selectXrayMaterialState,
  selectScanMaterialState,
} from 'pages/OrthoPrism/orthoSlice';
import { MaterialStates } from 'generated/core/graphql';

const Header = styled.div`
  font-weight: ${type.weight.bold};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const ResponseDate = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.tiny};
  color: ${colors.black80};
`;

const AlertContainer = styled.div`
  margin: 1rem 0;
`;

const EvaluationNotes = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  padding-bottom: 0.5rem;

  span {
    padding-left: 1.5rem;
  }
`;

const StyledLink = styled.a`
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.blue50};
  line-height: 160%;
  font-size: ${({ theme }) => theme.fontSizes.tiny};
`;

type ClarificationAttachment = {
  filename?: string | null | undefined;
  url?: string | null | undefined;
};

const getAnnotatedAttachments = (
  clarificationAttachments?: ClarificationAttachment[]
) => {
  const files: FileInfo[] = [];

  clarificationAttachments?.forEach(({ url, filename }) =>
    files.push({
      filename: filename!,
      url: url!,
    })
  );

  return files;
};

const NeedsClarificationAlert = () => {
  const [currentAnnotatedFile, setCurrentAnnotatedFile] = useState<FileInfo>();
  const customer = useSelector(selectCustomer);
  const clinicianName = customer?.referringDentist?.fullName;
  const latestScanClarification = useSelector(selectLatestScanClarification);
  const latestXrayClarification = useSelector(selectLatestXrayClarification);
  const mostRecentClarificationInfo = useSelector(
    selectClarificationRequestInfo
  );
  const lastSubmission = useSelector(selectLastSubmission);
  const isInClarificationStep = useSelector(selectIsInClarificationStep);
  const scanMaterialState = useSelector(selectScanMaterialState);
  const xrayMaterialState = useSelector(selectXrayMaterialState);

  const getNeedsClarificationInfo = () => {
    if (
      latestScanClarification &&
      latestScanClarification?.question ===
        mostRecentClarificationInfo?.question &&
      scanMaterialState?.state !== MaterialStates.Completed
    ) {
      const { question, response, respondedAt, attachments } =
        latestScanClarification;
      const responseDate = moment(respondedAt!);
      return {
        clarificationRequest: question,
        clarificationResponse: response,
        responseDate: responseDate.format('MMM Do, YYYY'),
        files: attachments ? getAnnotatedAttachments(attachments!) : [],
      };
    }

    if (
      latestXrayClarification &&
      latestXrayClarification?.question ===
        mostRecentClarificationInfo?.question &&
      xrayMaterialState?.state !== MaterialStates.Completed
    ) {
      const { question, response, respondedAt } = latestXrayClarification;
      const responseDate = moment(respondedAt!);
      return {
        clarificationRequest: question,
        clarificationResponse: response,
        responseDate: responseDate.format('MMM Do, YYYY'),
      };
    }

    const prismSubmissionHistory = lastSubmission?.stateData?.history;

    if (
      prismSubmissionHistory &&
      prismSubmissionHistory.length > 1 &&
      prismSubmissionHistory[prismSubmissionHistory.length - 2]?.data ===
        LegacyMaterialStates.NEEDS_CLARIFICATION
    ) {
      const clarificationResponse =
        prismSubmissionHistory[prismSubmissionHistory.length - 1];

      const clarificationResponseText = clarificationResponse?.extra
        ? clarificationResponse?.extra['rejectionNotes']
        : '';

      const responseDate = moment(clarificationResponse?.created);

      const clarificationRequestData =
        prismSubmissionHistory[prismSubmissionHistory.length - 2];

      const clarificationRequestText = clarificationRequestData?.extra
        ? clarificationRequestData?.extra['rejectionNotes']
        : '';

      if (clarificationRequestText === mostRecentClarificationInfo?.question) {
        return {
          clarificationRequest: clarificationRequestText,
          clarificationResponse: clarificationResponseText,
          responseDate: responseDate.format('MMM Do, YYYY'),
        };
      }
    }
  };

  const clarificationInfo = getNeedsClarificationInfo();

  // if the case is currently in a needs clarification state,
  // do not display alert card with previous communication information
  if (!clarificationInfo || isInClarificationStep) {
    return null;
  }

  return (
    <AlertContainer>
      <AlertCard
        type="warning"
        displayCloseButton={true}
        header="Clarification needed"
      >
        <EvaluationNotes>
          {clarificationInfo?.clarificationRequest}
        </EvaluationNotes>

        {!!clarificationInfo?.files?.length && (
          <>
            <Header> Attachments</Header>
            <Grid container direction="column" alignItems="baseline">
              {clarificationInfo.files.map((attachment: any) => {
                return (
                  <StyledLink
                    onClick={() => {
                      setCurrentAnnotatedFile(attachment);
                    }}
                  >
                    {attachment.filename}
                  </StyledLink>
                );
              })}
              {currentAnnotatedFile && (
                <Grid item>
                  <PhotoModal
                    isOpen={!!currentAnnotatedFile}
                    fileName={currentAnnotatedFile.filename}
                    url={currentAnnotatedFile.url}
                    onClose={() => {
                      setCurrentAnnotatedFile(undefined);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
        <Grid
          container
          direction="row"
          style={{ paddingTop: '1rem', paddingBottom: '.5rem' }}
        >
          <Grid item style={{ paddingLeft: '1.5rem', paddingRight: '.75rem' }}>
            <Avatar
              style={{ backgroundColor: colors.blue50, paddingTop: '.25rem' }}
            >
              {getDoctorInitials(clinicianName)}
            </Avatar>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Header>{clinicianName}</Header>
              </Grid>
              <Grid item>
                <ResponseDate>
                  Responded on {clarificationInfo?.responseDate}
                </ResponseDate>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <EvaluationNotes>
          <span>{clarificationInfo?.clarificationResponse}</span>
        </EvaluationNotes>
      </AlertCard>
    </AlertContainer>
  );
};

export default NeedsClarificationAlert;
