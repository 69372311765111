import React from 'react';
import styled from 'styled-components/macro';

import SparkleIcon from 'assets/sparkle.svg?react';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black70};

  svg {
    padding-bottom: 4px;
  }
`;
const DefaultAnswersSectionFooter = () => (
  <Wrapper>
    <SparkleIcon />= Your default answer from your treatment preferences
  </Wrapper>
);

export default DefaultAnswersSectionFooter;
