import { fetchApi } from 'utils/api';
import { RestMethod } from 'utils/api-types';

export const fetchProductCatalog = async () => {
  try {
    const res = await fetchApi(`/api/v1/catalog/`, RestMethod.GET);

    return res;
  } catch (err) {
    throw new Error('Could not fetch product catalog');
  }
};
