import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import LoyaltyProviderView from 'pages/LoyaltyProviderView/LoyaltyProviderView';

const LoyaltyProviderPage = ({ match }: RouteComponentProps) => (
  <Switch>
    <Route path={match.path} exact>
      <LoyaltyProviderView />
    </Route>
  </Switch>
);

export default LoyaltyProviderPage;
