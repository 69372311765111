import { CatalogItemType } from 'types/checkout';
import Dinero from 'dinero.js';
import React, { useEffect } from 'react';
import { Skeleton } from 'core/components';
import {
  CartItemContainer,
  CartItemCostContainer,
  Description,
  ItemBody,
  Question,
  StrikethroughText,
  Title,
} from 'components/CatalogItem/Catalog.css';

const CatalogItem = ({
  item,
  onChange,
  displayPrices,
  isLoading,
}: {
  item: CatalogItemType;
  onChange?: (quantity: number) => void;
  displayPrices: boolean;
  isLoading?: boolean;
}) => {
  const costDisplay = (quantity: number, priceInCents: Dinero.Dinero) => {
    return quantity + ' for ' + priceInCents.toFormat();
  };

  // Reset the quantity to 0 on mount
  useEffect(() => {
    onChange && onChange(item.quantity);
  }, []);

  const isFree = item.totalPriceAfterDiscounts.getAmount() === 0;

  const StrikethroughPrice = (
    <CartItemCostContainer>
      {'Cost: '}
      {!item.totalPriceBeforeDiscounts.equalsTo(
        item.totalPriceAfterLoyalty
      ) && (
        <StrikethroughText>
          {costDisplay(item.quantity, item.totalPriceBeforeDiscounts)}
        </StrikethroughText>
      )}
      {costDisplay(item.quantity, item.totalPriceAfterLoyalty)}
    </CartItemCostContainer>
  );
  const UnitPrice = (
    <CartItemCostContainer>{`Cost: ${item.totalPriceBeforeDiscounts.toFormat()}`}</CartItemCostContainer>
  );
  return (
    <div style={{ width: '100%' }}>
      {item.question && <Question>{item.question}</Question>}
      <CartItemContainer>
        <img
          style={{
            width: '180px',
            height: '140px',
            objectFit: 'contain',
            marginRight: '1rem',
          }}
          src={item.imageSrc}
          alt="item"
        />
        <ItemBody>
          <Title>{item.title}</Title>
          <Description>{item.description}</Description>
          {displayPrices ? (
            <>
              {isLoading ? (
                <Skeleton animation="wave" variant="text" />
              ) : item.totalPriceAfterDiscounts.getAmount() <
                item.totalPriceBeforeDiscounts.getAmount() ? (
                StrikethroughPrice
              ) : (
                UnitPrice
              )}
            </>
          ) : isLoading ? (
            <Skeleton animation="wave" variant="text" />
          ) : (
            <CartItemCostContainer>
              {isFree ? `Cost: Free` : `Cost will be added to your invoice`}
            </CartItemCostContainer>
          )}
        </ItemBody>
      </CartItemContainer>
    </div>
  );
};
export default CatalogItem;
