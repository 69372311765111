import React, { useCallback, useState } from 'react';
import {
  FormContainer,
  CardContainer,
  Card,
  CopyIconWrapper,
  SectionTitle,
  BoldedErrorHeader,
  StyledAlertText,
} from 'pages/AccountCreation/AccountCreation.css';
import { Formik } from 'formik';
import * as Sentry from '@sentry/react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';

import {
  CreatePracticeAccountMutation,
  CreatePracticeAccountMutationVariables,
  CreatePracticeAccountDocument,
  OrganizationTypes,
  DoctorSpecialtyTypes,
  OrganizationAccessLevelTypes,
  UserManagedLocationTypes,
  CreateUserDoctorAccountFields,
  CreateGenericAddressFields,
} from 'generated/legacy/graphql';
import { debounce } from 'lodash';
import { useLazyMutation } from 'utils/lazyQuery';
import { NewAccountFormV2 } from 'pages/AccountCreation/AccountCreationFormSections';
import { AlertCard } from 'core/components';
import { apolloErrorToString } from 'utils/apollo';
import { ApolloError } from '@apollo/client';
import { useGQLQuery } from 'hooks/useGQL';
import {
  ValidateAddressDocument,
  ValidateAddressQuery,
  ValidateAddressQueryVariables,
} from 'generated/core/graphql';
import { REFACTOR_ANY } from '@Types/refactor';

interface ICopyableFields {
  practice_name: string | undefined;
  practice_id: string | undefined;
  address_line_1: string | undefined;
  address_line_2: string | undefined | null;
  city: string | undefined;
  state: string | undefined;
  country: string | undefined | null;
  zip: string | undefined;
  phone: string | undefined | null;
  user_email: string | undefined;
  user_password: string | undefined;
  partner_org_external_id: string | undefined;
  partner_org_name: string | undefined;
}

interface ICopyProps {
  value: string | undefined | null;
}

const CopyIcon = ({ value }: ICopyProps) => {
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const handleCopy = () => {
    navigator.clipboard?.writeText(value || '');
    setIsClicked(true);
    setInterval(() => {
      setIsClicked(false);
    }, 1500);
  };

  return (
    <CopyIconWrapper onClick={() => handleCopy()}>
      {isClicked ? <CheckIcon /> : <FileCopyIcon />}
    </CopyIconWrapper>
  );
};

const ResponseCard = ({ fields }: { fields: ICopyableFields }) => {
  return (
    <CardContainer>
      <SectionTitle>Successfully created!</SectionTitle>
      {Object.keys(fields).map((fld) => {
        return (
          fields[fld as keyof ICopyableFields] && (
            <Card key={fld}>
              <div>{fld}:</div>
              <div>{fields[fld as keyof ICopyableFields]}</div>
              <CopyIcon value={fields[fld as keyof ICopyableFields]} />
            </Card>
          )
        );
      })}
    </CardContainer>
  );
};

enum UserGroups {
  candidPro = 'candid-pro',
}

export const AccountCreationForm = () => {
  const [response, setResponse] = useState<ICopyableFields>();
  const [generalErrorMessage, setGeneralErrorMessage] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const submitButtonRef = React.useRef<HTMLButtonElement>(null);
  const createPracticeAccountMutation = useLazyMutation<
    CreatePracticeAccountMutation,
    CreatePracticeAccountMutationVariables
  >(CreatePracticeAccountDocument);

  const [validateAddress] = useGQLQuery<
    ValidateAddressQuery,
    ValidateAddressQueryVariables
  >(ValidateAddressDocument);

  const buildValidationError = (rsp: ValidateAddressQuery) => {
    if (rsp?.validateAddress?.isValid === false) {
      const errors = {
        practice: { address: { zip: '', stateCode: '', countryCode: '' } },
      };
      rsp?.validateAddress?.problems?.forEach((problem) => {
        const errorMessage = problem?.message.substring(
          problem?.message.indexOf(':') + 1
        );
        if (problem?.message.includes('postal_code')) {
          errors['practice']['address'].zip = errorMessage || '';
        } else if (problem?.message.includes('admin_region')) {
          errors['practice']['address'].stateCode = errorMessage || '';
        } else if (problem?.message.includes('country:')) {
          errors['practice']['address'].countryCode = errorMessage || '';
        } else {
          setGeneralErrorMessage(errorMessage || '');
        }
      });

      return errors;
    }
  };

  const validateAddressForm = async (address: CreateGenericAddressFields) => {
    if (
      address.addressLine1 &&
      address.city &&
      address.stateCode &&
      address.zip &&
      address.countryCode &&
      address.name
    ) {
      const addressLines = [address.addressLine1];
      if (address.addressLine2) {
        addressLines.push(address.addressLine2);
      }
      const rsp = await validateAddress({
        address: {
          addressLines,
          city: address.city,
          adminRegion: address.stateCode,
          postalCode: address.zip,
          country: address.countryCode,
          name: address.name,
        },
        validateDeliverability: true,
      });
      return buildValidationError(rsp!);
    }
  };

  const validateForm = async (
    values: CreatePracticeAccountMutationVariables
  ) => {
    if (values.practice?.address) {
      setGeneralErrorMessage('');
      return await validateAddressForm(values.practice?.address);
    }
  };
  const validateFormDebounced = useCallback(debounce(validateForm, 800), []);

  const submitForm = (formData: CreatePracticeAccountMutationVariables) => {
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
    } else {
      handleCreateAccount(formData);
    }
  };
  const handleCreateAccount = (
    formData: CreatePracticeAccountMutationVariables
  ) => {
    const createAccount = async () => {
      try {
        setIsSubmitting(true);
        let arg: CreatePracticeAccountMutationVariables = {
          practice: {
            ...formData.practice,
          },
        };
        if (
          formData.practice.organizationType ===
            OrganizationTypes.OfficeLocation &&
          formData.userDoctors
        ) {
          arg = {
            ...arg,
            userDoctors: (
              formData.userDoctors as REFACTOR_ANY
            ) /* TODO: Casting is a quickfix */
              .map(
                (
                  user_doctor: REFACTOR_ANY /* TODO: Casting is a quickfix */
                ): REFACTOR_ANY => ({
                  ...(user_doctor as CreateUserDoctorAccountFields),
                  groups: [UserGroups.candidPro],
                  orgAccessLevel: OrganizationAccessLevelTypes.Viewer,
                  // To simplify the endpoint, we only send the access level for this user's practice
                  // The Access Level values are shared between the org level and the user managed level
                  userManagedLocations: UserManagedLocationTypes.Viewer,
                  specialty: DoctorSpecialtyTypes.Dentist,
                })
              ),
          };
        }
        const rsp = await createPracticeAccountMutation(arg);
        const rspPractice = rsp?.data?.createPracticeAccount?.practice;
        const copyableFields: ICopyableFields = {
          practice_name: rspPractice?.name,
          practice_id: rspPractice?.id,
          address_line_1: rspPractice?.address.addressLine1,
          address_line_2: rspPractice?.address?.addressLine2,
          city: rspPractice?.address.city,
          state: rspPractice?.address.stateCode,
          country: rspPractice?.address.countryCode,
          zip: rspPractice?.address.zip,
          phone: rspPractice?.address.phone,
          user_email: rspPractice?.managingLocationUsers
            .map((usr) => usr.email)
            .join(', '),
          user_password: formData?.userDoctors
            ? (
                formData.userDoctors as REFACTOR_ANY
              ) /* TODO: Casting is a quickfix */[0]?.password
            : undefined,
          partner_org_name: rspPractice?.partnerOrgAccount?.partnerOrg?.name,
          partner_org_external_id: rspPractice?.partnerOrgAccount?.externalId,
        };
        setResponse(copyableFields);
        setGeneralErrorMessage('');
      } catch (e) {
        if (e instanceof ApolloError) {
          Sentry.captureException(e);
          const apolloError = apolloErrorToString(e);
          setGeneralErrorMessage(apolloError);
        }
      } finally {
        setIsSubmitting(false);
      }
    };
    createAccount();
  };

  const initialValue = {
    practice: {
      name: '',
      defaultStripeUserId: `${getNRandomCharacters(8)}.dummy`,
      organizationType: OrganizationTypes.ManagingOrganization,
    },
  };
  return (
    <>
      {generalErrorMessage && (
        <AlertCard type="critical">
          <BoldedErrorHeader>There has been an error</BoldedErrorHeader>
          <StyledAlertText>{generalErrorMessage}</StyledAlertText>
        </AlertCard>
      )}
      {!response ? (
        <Formik
          onSubmit={submitForm}
          initialValues={initialValue}
          validate={validateFormDebounced}
        >
          <FormContainer>
            <NewAccountFormV2
              submitButtonRef={submitButtonRef}
              submitForm={handleCreateAccount}
              isSubmitting={isSubmitting}
            />
          </FormContainer>
        </Formik>
      ) : (
        <ResponseCard fields={response} />
      )}
    </>
  );
};

const getNRandomCharacters = (n: number) => {
  const s = 'abcdefghijklmnopqrstuvwxyz0123456789';
  return Array(n)
    .join()
    .split(',')
    .map(function () {
      return s.charAt(Math.floor(Math.random() * s.length));
    })
    .join('');
};
