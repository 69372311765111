import styled from 'styled-components/macro';
import { Button, Grid } from 'core/components';

export const FormContainer = styled.div<{ isConfirmView?: boolean }>`
  ${({ theme, isConfirmView }) => `
  width: ${isConfirmView ? '23rem' : '38rem'};
  @media ${theme.mediaQueries.mobile} {
    width: 100%;
  };


`}
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 1rem;
  justify-content: space-between;
  padding: 24px; 32px;

`;

export const StyledButton = styled(Button)`
  padding-right: 1.25rem;
  padding-left: 1.25rem;
`;

export const StyledCancelButton = styled(StyledButton)`
  color: ${({ theme }) => theme.colors.black70};
`;

export const ModalBody = styled(Grid)`
  padding: 32px;
  max-height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Header = styled.div`
  padding: 24px; 32px;
  h4 {
    margin-bottom: 0rem;
  }
`;
