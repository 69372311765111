import React from 'react';
import { BrandChipProps } from 'components/BrandChip/types';
import { StyledChip } from 'components/BrandChip/BrandChip.css';
import { CANDID_BRAND_NAME, convertToBrand, getBrandTheme } from 'utils/brands';

const BrandChip = ({ brandInfo }: BrandChipProps) => {
  const brandName = convertToBrand(brandInfo?.name, CANDID_BRAND_NAME);
  const {
    brandChip: { props },
  } = getBrandTheme(brandName);

  return <StyledChip {...props} />;
};

export default BrandChip;
