import React from 'react';
import { Skeleton as MUISkeleton, Grid } from 'core/components';

export const Skeleton = () => {
  return (
    <Grid container data-testid="skeleton" style={{ width: '840px' }}>
      <MUISkeleton variant="text" height={40} width={'100%'} />
      <MUISkeleton variant="text" height={25} width={'80%'} />
      <MUISkeleton variant="text" height={25} width={'80%'} />
      <MUISkeleton variant="text" height={25} width={'80%'} />
    </Grid>
  );
};

export const OrderDetailSkeleton = () => {
  return (
    <Grid container data-testid="skeleton" style={{ width: '100%' }}>
      <MUISkeleton variant="text" height={25} width={'80%'} />
      <MUISkeleton variant="text" height={25} width={'80%'} />
      <MUISkeleton variant="text" height={25} width={'80%'} />
      <MUISkeleton variant="text" height={25} width={'80%'} />
    </Grid>
  );
};
