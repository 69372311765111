import styled from 'styled-components/macro';
import { theme, type } from 'core/components';

import ArrowSvg from 'assets/arrow-right-line.svg?react';

export const ResourceContainers = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const PageSection = styled.section`
  width: 100%;
  max-width: 680px;
  margin-right: auto;
  margin-left: auto;
  padding: 2.5rem 1.25rem;
`;

export const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
`;

export const Title = styled(type.H2)`
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.text90};
  line-height: 32px;
  font-size: 32px;
`;

export const StyledArrow = styled(ArrowSvg)`
  fill: ${theme.colors.white};
`;
