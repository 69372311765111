import React from 'react';
import { useSelector } from 'react-redux';
import { Loading, AlertCard } from 'core/components';
import moment from 'moment';

import { useIsLoading } from 'state/system';
import { formatIntakeSections } from 'utils/intake';
import VersionPills, { useVersionPills } from 'components/StyledVersionPills';
import { determineIntakeName } from 'utils/intake';
import { DentalNotationOptions } from 'generated/legacy/graphql';

import {
  selectCompletedIntakeForms,
  fetchIntakeForms,
  selectSelectedCase,
  selectPatientDoctorPreferences,
} from 'pages/OrthoPrism/orthoSlice';
import SurveySection from 'components/Intake/SurveySection';
import * as S from 'pages/OrthoPrism/Intake/Intake.css';
import { useHistory } from 'react-router-dom';
import { LinkItem } from 'pages/OrthoPrism/OrthoPrism.css';
import { PROVIDER_FACING_STATUSES } from 'constants/caseStatus';

const Intake = () => {
  const intakeForms = useSelector(selectCompletedIntakeForms);
  const doctorPreferences = useSelector(selectPatientDoctorPreferences);
  const isLoading = useIsLoading(fetchIntakeForms.typePrefix);
  const selectedCase = useSelector(selectSelectedCase);
  const vpProps = useVersionPills(intakeForms ?? [], true);
  const intakeForm = intakeForms?.[vpProps.currentIndex];
  const { push } = useHistory();

  if (isLoading) {
    return <Loading />;
  }

  const intakeName = determineIntakeName(selectedCase?.isGen2);
  const alertHeaderText = selectedCase?.isGen2
    ? `${intakeName} not yet submitted`
    : '';
  const alertBodyText = selectedCase?.isGen2
    ? 'Continue submission'
    : `${intakeName} not yet submitted`;

  if (
    !intakeForm ||
    selectedCase?.caseState?.internal ===
      PROVIDER_FACING_STATUSES.INCOMPLETE_SUBMISSION
  ) {
    return (
      <AlertCard type="warning" header={alertHeaderText} displayIcon={true}>
        {selectedCase?.isGen2 ? (
          <LinkItem
            onClick={() =>
              push(`/patient/${selectedCase?.patientId}/case-creator`)
            }
          >
            Continue submission
          </LinkItem>
        ) : (
          <>{alertBodyText}</>
        )}
      </AlertCard>
    );
  }

  const formattedIntakeSections = formatIntakeSections(
    intakeForm.data.sections
  );

  const intakeSumissionDate = moment(intakeForm?.signedDate!).format(
    'MMM Do YYYY'
  );
  const intakeSubmissionTime = moment(intakeForm?.signedDate!).format('h:mm A');
  const submissionSubtext = `Submitted by ${intakeForm?.createdByEmail}, ${intakeSumissionDate} at ${intakeSubmissionTime}`;

  return (
    <S.Wrapper>
      {!selectedCase?.isGen2 && <VersionPills {...vpProps} />}
      <S.HeaderContainer>
        <div>
          <S.DetailHeader>{intakeName}</S.DetailHeader>
          <S.SubmissionInformation>{submissionSubtext}</S.SubmissionInformation>
        </div>
      </S.HeaderContainer>

      {formattedIntakeSections.map((section, index) => {
        return (
          <SurveySection
            section={section}
            displayDivider={index < formattedIntakeSections.length - 1}
            key={index}
            dentalNotation={
              doctorPreferences?.dentalNotation as DentalNotationOptions
            }
          />
        );
      })}
    </S.Wrapper>
  );
};

export default Intake;
