import React from 'react';
import { Loading } from 'core/components';

import PreferenceSection from 'pages/AccountPreferencesPage/PreferenceSection';

import { FormikRadioGroup } from 'components/FormikForms';
import { ScanPreferenceOptions } from 'generated/legacy/graphql';

type ScanPreferencesProps = {
  isLoading: boolean;
  supportPvsScan: boolean;
  id?: string;
};

const ScanPreferences = ({
  isLoading,
  supportPvsScan,
  id,
}: ScanPreferencesProps) => {
  const options = [
    {
      displayValue: 'Upload STL files',
      value: ScanPreferenceOptions.UploadStl,
    },
    ...(supportPvsScan
      ? [
          {
            displayValue: 'Mail PVS impressions',
            value: ScanPreferenceOptions.PvsImpressions,
          },
        ]
      : []),
    {
      displayValue: 'Sync your scanner',
      value: ScanPreferenceOptions.SyncWithScanner,
    },
  ];

  return (
    <PreferenceSection
      title="Scan preferences"
      description="Save time by choosing which scan method you want selected by default when submitting cases."
      id={id}
    >
      {isLoading ? (
        <Loading isCentered />
      ) : (
        <FormikRadioGroup
          type="radio"
          options={options}
          name={'scanSubmissionPreference'}
        />
      )}
    </PreferenceSection>
  );
};

export default ScanPreferences;
