import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Definitions } from 'pages/ActionItems/OverviewModules/definitions';
import { useSelector } from 'react-redux';
import { ExtractReturnType } from 'state/api';
import api from 'state/api';
import { SkipQuery } from 'state/api/utils';
import { RootState } from 'state/store';
import { memoizeOne } from 'utils/memoizer';
import { isNotNil } from 'utils/typeCheck';
import { Nullable } from 'utils/types';

type ActionItemOverviewQueryResult = NonNullable<
  ExtractReturnType<typeof api.useGetActionItemsOverviewQuery>
>;
export type ActionItemTask = NonNullable<
  ActionItemOverviewQueryResult['incompleteSubmission'][0]
>;

const convertOverview = memoizeOne((data: ActionItemOverviewQueryResult) => ({
  incompleteSubmissionTasks: data.incompleteSubmission.filter(isNotNil),
  materialsRejectedTasks: data.materialsRejected.filter(isNotNil),
  needsClarificationTasks: data.needsClarification.filter(isNotNil),
  tpReadyForReviewTasks: data.tpReadyForReview.filter(isNotNil),
  count: [
    {
      state: Definitions.InTreatment,
      count: data.inTreatmentCount,
    },
    {
      state: Definitions.Rejected,
      count: data.materialsRejected.length,
    },
    {
      state: Definitions.NeedsClarification,
      count: data.needsClarification.length,
    },
    {
      state: Definitions.Incomplete,
      count: data.incompleteSubmission.length,
    },
    {
      state: Definitions.TpReview,
      count: data.tpReadyForReview.length,
    },
    {
      state: Definitions.PreTreatment,
      count: data.preTreatmentCount,
    },
    {
      state: Definitions.TotalCases,
      count: data.totalCount,
    },
  ],
}));

export const useGetOverview = () => {
  const referringDentistId = useSelector(
    (state: RootState) => state.actionItems.referringDentistId
  );

  return api.useGetActionItemsOverviewQuery(
    referringDentistId ? { referringDentistId } : SkipQuery,
    {
      skip: !referringDentistId,
      selectFromResult: ({ data, ...rest }) => ({
        data: data && convertOverview(data),
        ...rest,
      }),
    }
  );
};

export const useGetMultipleStatusesTasks = () => {
  const { data, ...rest } = useGetOverview();

  if (!data) {
    return { data, ...rest };
  }

  const incompleteCustomerIds = data.incompleteSubmissionTasks.map((task) =>
    task.customerId.toString()
  );
  const rejectedCustomerIds = data.materialsRejectedTasks.map((task) =>
    task.customerId.toString()
  );
  const needsClarificationCustomerIds = data.needsClarificationTasks.map(
    (task) => task.customerId.toString()
  );

  return {
    data: [
      ...new Set([
        ...incompleteCustomerIds.filter((i) => rejectedCustomerIds.includes(i)),
        ...incompleteCustomerIds.filter((i) =>
          needsClarificationCustomerIds.includes(i)
        ),
        ...rejectedCustomerIds.filter((i) =>
          needsClarificationCustomerIds.includes(i)
        ),
      ]),
    ],
    ...rest,
  };
};

type ActionItemsState = Partial<{
  referringDentistId: Nullable<number>;
}>;

const initialState: ActionItemsState = {};

const actionItemsSlice = createSlice({
  name: 'actionItems',
  initialState,
  reducers: {
    setReferringDentistId: (state, action: PayloadAction<number>) => {
      state.referringDentistId = action.payload;
    },
  },
});

export default actionItemsSlice.reducer;

export const { setReferringDentistId } = actionItemsSlice.actions;
