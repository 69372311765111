import React, { FunctionComponent } from 'react';
import { ScanPreferenceOptions } from 'generated/legacy/graphql';

import { ValuesOf } from 'utils/types';

export const TabIds = {
  UploadStls: ScanPreferenceOptions.UploadStl,
  PvsImpressions: ScanPreferenceOptions.PvsImpressions,
  SyncWithScanner: ScanPreferenceOptions.SyncWithScanner,
} as const;
export type TabId = ValuesOf<typeof TabIds>;

export interface StlTab {
  id: TabId;
  name: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  Component: FunctionComponent;
}
