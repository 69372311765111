import Cropper from 'cropperjs';
import moment from 'moment';
import { SubmissionState } from 'pages/OrthoPrism/types';
import {
  CropMetadataInput,
  PhotoTypeType,
  StateDataType,
  SubmissionType,
} from 'generated/legacy/graphql';
import { NavDirection, SubmissionPayload } from 'utils/types';
import { PRISM_AGGREGATE_STATES, PRISM_HISTORY_EVENTS } from 'constants/index';

type CropperData = Omit<Cropper.Data, 'scaleX' | 'scaleY'>;
export type StateDataTypeExtra = StateDataType & {
  initialNote?: string;
};

const getLastIndexOfEvent = (events: StateDataTypeExtra[], eventName: string) =>
  events.map((value) => value.data).lastIndexOf(eventName);

export const countSubmissionAndRejectionEvents = (
  events: StateDataTypeExtra[]
) => {
  return events
    .filter(
      (event) =>
        event.data.includes(PRISM_HISTORY_EVENTS.GENERIC_REJECTED) ||
        event.data.includes(PRISM_AGGREGATE_STATES.SUBMISSION)
    )
    .map((event) =>
      event.data.includes(PRISM_HISTORY_EVENTS.GENERIC_REJECTED)
        ? PRISM_HISTORY_EVENTS.GENERIC_REJECTED
        : PRISM_AGGREGATE_STATES.SUBMISSION
    )
    .reduce<Record<string, number>>((eventCounts, event) => {
      eventCounts[event] ??= 0;
      eventCounts[event] += 1;

      return eventCounts;
    }, {});
};

export const getNavigatedView = (
  photoTypes: PhotoTypeType[],
  currentView: string,
  dir: NavDirection
): string => {
  const currentIndex = photoTypes.findIndex((v) => v.name === currentView);
  const lastViewIndex = photoTypes.length - 1;

  // If moving forward from the last view, jump back to the first view
  if (currentIndex === lastViewIndex && dir === NavDirection.right) {
    return photoTypes[0].name;
  }

  // If moving back from the first view, jump to the last view
  if (currentIndex === 0 && dir === NavDirection.left) {
    return photoTypes[lastViewIndex].name;
  }

  return dir === NavDirection.right
    ? photoTypes[currentIndex + 1].name
    : photoTypes[currentIndex - 1].name;
};

type StringOrDate = string | Date;
// Sorts items into descending order (newest/latest first)
export const sortByDate = (a: StringOrDate, b: StringOrDate) =>
  moment(b).toDate().getTime() - moment(a).toDate().getTime();

const isLessThanTimeInterval = (
  a: StringOrDate,
  b: StringOrDate,
  hours: number
) => Math.abs(moment(a).diff(moment(b), 'hours')) < hours;

interface HasLabel {
  label: string;
}

interface HasDateCreated {
  created: StringOrDate;
}
interface HasDateCreatedAt {
  createdAt: StringOrDate;
}
interface HasDateSubmissionDate {
  submissionDate: StringOrDate;
}
interface HasIteration {
  iteration: number;
}

type HasCreated = HasDateCreated | HasDateCreatedAt | HasDateSubmissionDate;

const toDate = (hc: HasCreated): string | Date | never => {
  if ('created' in hc) {
    return hc.created;
  }
  if ('createdAt' in hc) {
    return hc.createdAt;
  }
  if ('submissionDate' in hc) {
    return hc.submissionDate;
  }

  throw new Error(`${JSON.stringify(hc)} is missing date property`);
};

export enum Sort {
  Desc,
  Asc,
}
export const sortByIteration =
  (sort: Sort) => (a: HasIteration, b: HasIteration) => {
    if (a.iteration === b.iteration) {
      return 0;
    }

    if (sort === Sort.Desc) {
      return a.iteration > b.iteration ? -1 : 1;
    }

    return a.iteration < b.iteration ? -1 : 1;
  };
export const sortByCreated = (sort: Sort) => (a: HasCreated, b: HasCreated) => {
  const d1 = toDate(a);
  const d2 = toDate(b);

  if (sort === Sort.Desc) {
    return sortByDate(d1, d2);
  }

  return sortByDate(d2, d1);
};

export const sortByLabel = (sort: Sort) => (a: HasLabel, b: HasLabel) => {
  if (a.label === b.label) {
    return 0;
  }

  if (sort === Sort.Desc) {
    return a.label < b.label ? -1 : 1;
  }

  return a.label > b.label ? -1 : 1;
};

const roundToDecimalLength = (num: number, len: number) => {
  return Number(Math.round(Number(num + `e${len}`)) + `e-${len}`).toString();
};

// Formats CropperJS data values into CropMetadataInput type
export const formatCropMetadata = (obj: CropperData) => {
  const result: { [key: string]: number | string } = {};

  for (const key of Object.keys(obj)) {
    // Don't round/convert "rotate" to string, as backend expects a number
    result[key] =
      key === 'rotate'
        ? obj[key]
        : roundToDecimalLength(obj[key as keyof CropperData], 5);
  }

  return result as CropMetadataInput;
};

// Flattens history from all submission items into a single list
export const formatSubmissionHistory = (
  isPhotoHistory: boolean,
  photoSubmissionHistory: StateDataType[] | undefined,
  submissionSet: SubmissionType[],
  primaryHistory?: StateDataType[]
) => {
  const submissionHistory = !isPhotoHistory
    ? submissionSet.flatMap(
        (submission) => submission?.stateData?.history ?? []
      )
    : photoSubmissionHistory === undefined
      ? []
      : photoSubmissionHistory;

  const history = submissionHistory
    .map(({ data, ...rest }) => ({
      ...rest,
      data: `ortho-${data}`,
    }))
    .concat(
      (primaryHistory ?? []).filter(
        ({ data }) => !['completed', 'could_not_treat'].includes(data)
      )
    )
    // Sort history items into descending order
    .sort((a, b) => sortByDate(a.created, b.created));
  // Keep the most recent (by date) "evaluation" event and *remove the rest* (to log "All photos uploaded")
  const firstEvaluationIndex = getLastIndexOfEvent(history, 'evaluation');

  // Get the submission initial notes
  const initialNotes = submissionSet.map((submission: SubmissionType) => ({
    initialNote: submission.initialNote,
    timestamp: submission.submissionDate,
  }));

  let typedFilteredHistory = history.filter(
    (value, i) =>
      !(value.data === 'evaluation' && i < (firstEvaluationIndex ?? 0))
  ) as StateDataTypeExtra[];

  // Logic to map initial note to history, this will match correctly 99% of the time
  if (initialNotes.length) {
    typedFilteredHistory = typedFilteredHistory.map((item) => ({
      ...item,
      initialNote:
        initialNotes.length &&
        item.data === PRISM_AGGREGATE_STATES.SUBMISSION && // Check for submission type
        isLessThanTimeInterval(
          item.created,
          initialNotes[initialNotes.length - 1].timestamp,
          1
        ) // Check for the date and hour
          ? initialNotes.pop()?.initialNote || ''
          : '',
    }));
  }
  // Remove the most recent (by date) "collection" event and *keep the rest* (to log "Redos uploaded")
  const firstCollectionIndex = getLastIndexOfEvent(
    typedFilteredHistory,
    'collection'
  );
  !isPhotoHistory &&
    firstCollectionIndex > -1 &&
    typedFilteredHistory.splice(firstCollectionIndex, 1);
  return typedFilteredHistory;
};

// Converts standardized Submission status string into human-friendly message
export const toStatusLabel = (submission: SubmissionPayload): string => {
  const state = submission.stateData.data;
  const category = submission.stateData.data
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
  const rejectionLabel = submission.rejectionReasons?.[0]?.label;

  if (state === SubmissionState.Approved) {
    return 'Submission approved';
  }

  if (!rejectionLabel) {
    return category;
  }

  //@TODO: fetch these from the backend, when the backend starts to provide them
  switch (state) {
    case SubmissionState.RejectedPhotos: {
      const photoRejectionSubtype =
        submission.rejectionReasons?.[0]?.name?.split('_')[1]!;
      return `Submission rejected because of photo ${photoRejectionSubtype}`;
    }
    case SubmissionState.RejectedCustomer: {
      return `Submission rejected - ${rejectionLabel}`;
    }
    case SubmissionState.RejectedMissingInfo: {
      return 'Submission rejected because of missing information';
    }
    default: {
      return category;
    }
  }
};

/**
   Splits a list into a left right pair based on a predicate function. e.g.

     const list = ['left', 'right', 'left', 'right'];
     const [left, right] = splitList(list, s => s === 'right');

     console.log(left, right) // =>  ['left', 'left'], ['right', 'right']
 */
type LeftRightTuple = [string[], string[]];
export const splitList = (
  list: string[],
  isRight: (s: string) => boolean
): LeftRightTuple =>
  list.reduce(
    ([left, right], item) =>
      isRight(item)
        ? [left, right.concat([item])]
        : [left.concat([item]), right],
    [[], []] as LeftRightTuple
  );

export const toAggregateLabel = (label?: string): string =>
  label?.replace(/(-\s+)?Aligners/gi, '') ?? '';
