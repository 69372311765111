import React from 'react';
import { Chip } from 'core/components';
import {
  SelectionBox,
  OptionTitle,
  OptionDescription,
} from 'components/OptionsBox.css';

const OptionsBox = ({
  Icon,
  title,
  description,
  onClick,
  selected,
  disabled,
  chipText,
}: {
  Icon: any;
  title: string;
  description: string;
  onClick: () => void;
  selected: boolean;
  disabled?: boolean;
  chipText?: string;
}) => {
  return (
    <SelectionBox
      onClick={!disabled ? onClick : undefined}
      selected={selected}
      disabled={disabled}
      role="button"
    >
      <div>
        <Icon />
      </div>
      <div>
        <OptionTitle>
          {title}
          {chipText && <Chip variant="outlined" label={chipText} size="tiny" />}
        </OptionTitle>
        <OptionDescription>{description}</OptionDescription>
      </div>
    </SelectionBox>
  );
};

export default OptionsBox;
