import styled from 'styled-components/macro';
import { colors, mediaQueries, type } from 'core/components';
import { PermissionedInput } from 'components/PermissionedInput';
import CloseIcon from 'assets/close.svg';

export const Form = styled.form`
  display: block;
  padding: 2rem;
  margin-top: 2rem;
  background: white;
  border: 1px solid ${colors.border};
  border-radius: 0.1875rem;
`;

export const SubForm = styled.form`
  display: block;
  background: white;
`;

export const NotApplicableContainer = styled.div`
  display: block;
  padding: 2rem;
  margin-top: 2rem;
  background: white;
  border: 1px solid ${colors.border};
  border-radius: 0.1875rem;

  p {
    margin-bottom: 0;
  }
`;

export const ReviewRadiosRow = styled.div`
  margin-bottom: 1.5rem;

  p {
    margin-bottom: 0;
  }
`;

export const FormRow = styled.div`
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 2.5rem;
  }

  p {
    margin-bottom: 0;
  }
`;

export const RadioGroup = styled.div`
  margin-top: 1.25rem;
  @media ${mediaQueries.tabletAndAbove} {
    width: 50%;
  }
`;

export const Label = styled.label<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? colors.black50 : colors.black90)};
  display: block;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

export const Radio = styled(PermissionedInput)`
  margin-right: 0.75rem;
  vertical-align: text-top;
  cursor: pointer;
  appearance: radio;
`;

export const Checkbox = styled.input`
  margin-top: 0.125rem;
  margin-right: 0.75rem;
  vertical-align: text-top;
  cursor: pointer;
  appearance: checkbox;
`;

export const QuestionHeading = styled(type.H5)`
  margin-bottom: 1rem;
`;

export const SmallHeading = styled.h4`
  font-size: ${type.size.small};
  font-weight: ${type.weight.bold};
`;

export const TextAreaFullWidth = styled.textarea`
  width: 100%;
  min-height: 6.5rem;
  padding: 0.625rem;
  line-height: 1.4;
  border: 1px solid ${colors.black20};
  border-radius: 0.125rem;
  transition: border-color 0.2s ease-in-out;

  &:hover,
  &:focus {
    border-color: ${colors.black40};
  }

  &::placeholder {
    color: ${colors.black60};
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  button :first-child {
    margin-right: 25px;
  }
`;

export const UploadContainer = styled.div<{ isSmall?: boolean }>`
  height: ${({ isSmall }) => (isSmall ? '3.5rem' : '10rem')};
  width: 50%;
  margin-bottom: 1rem;
  background: ${colors.black05};
`;

export const AttachmentRow = styled.div`
  display: grid;
  grid-template-columns: 20fr 1fr;
  grid-gap: 1.5rem;
  border: 1px solid ${colors.black20};
  width: 50%;
  height: 3.5rem;
  margin-bottom: 0.5rem;
`;

export const FileName = styled.div<{ src?: string }>`
  overflow: hidden;
  color: ${colors.blue50};
  text-overflow: ellipsis;
  margin-left: 0.625rem;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
  height: 50%;

  a {
    font-weight: ${type.weight.default};
    text-decoration: underline;
  }
`;

export const DeleteButton = styled.button`
  width: 1rem;
  height: 1rem;
  margin-right: 0.625rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  vertical-align: top;
  background: url(${CloseIcon}) no-repeat;
`;
