import { DefaultProtocolOptions } from 'generated/legacy/graphql';
import { FormQuestion, FormSection } from 'generated/core/graphql';
import { capitalizeWords } from 'utils/string';

export const getDefaultQuestions = (questions: FormSection[] = []) =>
  questions
    .flatMap((section) => section.questions)
    .filter((question) => question.hasDefaultPreferenceOption);

export const getQuestionKey = (
  question: FormQuestion,
  protocol: DefaultProtocolOptions
) => {
  const prefixMap = {
    [DefaultProtocolOptions.Candidairway]: 'candidAirway',
    [DefaultProtocolOptions.Candidrx]: 'candidRx',
  };

  const formatted = capitalizeWords(question.questionKey || '').replace(
    /\s+/g,
    ''
  );

  return `${prefixMap[protocol]}${formatted}`;
};
