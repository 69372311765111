import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import CaseSection from 'pages/Patient/CaseCreator/CaseSection';
import { Heading, NotificationContext } from 'core/components';
import { SkeletonCaseSection } from 'pages/Patient/CaseCreator/Skeletons';
import {
  fetchPatient,
  fetchPrismAggregates,
  fetchScans,
  fetchTreatmentGoalQuestions,
  fetchXrays,
  resetPatientState,
  selectAreDiagnosticMaterialsReadyForSubmit,
  selectAreTreatmentGoalsReadyForSubmit,
  selectIsSubmitted,
  selectPatient,
  selectPatientName,
  selectPrismState,
  selectScanMaterialState,
  selectSectionsCanCollect,
  selectTreatmentGoalsState,
  selectXrayMaterialState,
  selectActiveCase,
  selectCanCollectTreatmentGoals,
  selectIsRetainerCaseWithOrderInfo,
} from 'pages/Patient/patientSlice';
import { useIsLoading } from 'state/system';

import UserIcon from 'assets/ic_user.svg?react';
import FolderIcon from 'assets/fi_folder-plus.svg?react';
import SlidersIcon from 'assets/ic_sliders.svg?react';
import { StyledButton } from 'pages/Patient/CaseCreator/CaseCreator.css';
import { AppDispatch } from 'state/store';
import { ActionType } from 'pages/Patient/CaseCreator/types';
import { PageWrapper } from 'pages/Patient/styles.css';
import useSubmitCase from 'pages/Patient/CaseCreator/useSubmitCase';
import { LegacyMaterialStates } from 'constants/Material';
import {
  CaseSource,
  MaterialStates,
  StateTransitions,
} from 'generated/core/graphql';
import { CaseTypeNames } from 'constants/Case';
import api from 'state/api';

const BasicInfoSection = () => {
  const patient = useSelector(selectPatient);
  const isLoading = useIsLoading(fetchPatient.type);
  if (isLoading) {
    return <SkeletonCaseSection />;
  }

  return (
    <CaseSection
      title="Basic info"
      Icon={UserIcon}
      actionType={ActionType.NOT_STARTED}
      description="Enter details about this patient before continuing in the flow."
      path="basic-info"
      isComplete={!!patient}
    />
  );
};
interface ActionMap {
  [key: string]: ActionType | undefined;
}
const ACTION_TYPE_MAP: ActionMap = {
  [MaterialStates.NotStarted]: ActionType.NOT_STARTED,
  [MaterialStates.NeedsClarification]: ActionType.NEEDS_CLARIFICATION,
  [StateTransitions.Reject]: ActionType.REJECTED,
  [LegacyMaterialStates.NOT_STARTED]: ActionType.NOT_STARTED,
  [LegacyMaterialStates.NEEDS_CLARIFICATION]: ActionType.NEEDS_CLARIFICATION,
  [LegacyMaterialStates.REJECTED]: ActionType.REJECTED,
};

const DiagnosticSection = () => {
  const patient = useSelector(selectPatient);
  const isComplete = useSelector(selectAreDiagnosticMaterialsReadyForSubmit);
  const isXraysLoading = useIsLoading(fetchXrays.typePrefix);
  const isPhotosLoading = useIsLoading(fetchPrismAggregates.typePrefix);
  const isScansLoading = useIsLoading(fetchScans.typePrefix);
  const photosState = useSelector(selectPrismState);
  const scanState = useSelector(selectScanMaterialState);
  const xrayState = useSelector(selectXrayMaterialState);
  const sectionsCanCollect = useSelector(selectSectionsCanCollect);
  const isLoading = isXraysLoading || isPhotosLoading || isScansLoading;

  const getActionType = () => {
    let result = ActionType.NOT_STARTED;
    for (const section of sectionsCanCollect) {
      let actionType: ActionType | undefined | null;
      if (section === 'photos') {
        actionType = ACTION_TYPE_MAP[photosState];
      } else if (section === 'scans') {
        actionType =
          ACTION_TYPE_MAP[scanState?.transition!] ||
          ACTION_TYPE_MAP[scanState?.state!];
      } else if (section === 'xrays') {
        actionType =
          ACTION_TYPE_MAP[xrayState?.transition!] ||
          ACTION_TYPE_MAP[xrayState?.state!];
      } else {
        continue; // section not in diagnostic materials
      }
      if (
        actionType &&
        [ActionType.REJECTED, ActionType.NEEDS_CLARIFICATION].includes(
          actionType
        )
      ) {
        // early  return the rejected or needs clarification state
        return actionType;
      }
      if (actionType !== ActionType.NOT_STARTED) {
        // override the result from not started state. we're guaranteed to be
        // started here since because the state doesn't exist in the map
        result = ActionType.STARTED;
      }
    }
    return result;
  };

  if (isLoading) {
    return <SkeletonCaseSection />;
  }

  const actionType = getActionType();

  return (
    <CaseSection
      title="Diagnostic materials"
      Icon={FolderIcon}
      description="Our team will review these diagnostic materials and check for any issues that could complicate treatment."
      path="diagnostic-materials"
      actionType={actionType}
      isDisabled={!patient}
      isComplete={isComplete}
    />
  );
};

const TreatmentGoalsSection = () => {
  const patient = useSelector(selectPatient);
  const activeCase = useSelector(selectActiveCase);

  //If we don't have an active case, we don't have a workflow to check if treatment goals are needed
  //so selectAreTreatmentGoalsReadyForSubmit will be true. If there is no case, we can assume we're
  //creating an aligner case, and TG are in fact needed
  const tgCompleteOrNotNeeded = useSelector(
    selectAreTreatmentGoalsReadyForSubmit
  );
  const isComplete = tgCompleteOrNotNeeded && !!activeCase;
  const state = useSelector(selectTreatmentGoalsState);
  const isTreatmentGoalsLoading = useIsLoading(
    fetchTreatmentGoalQuestions.typePrefix
  );
  const actionType =
    state === LegacyMaterialStates.NOT_STARTED
      ? ActionType.NOT_STARTED
      : ActionType.STARTED;

  if (isTreatmentGoalsLoading) {
    return <SkeletonCaseSection />;
  }

  return (
    <CaseSection
      title="Treatment goals"
      Icon={SlidersIcon}
      description="Choose which clinical features you'd like to use for this case and specify any restrictions or concerns for specific teeth. We strongly recommend a doctor complete this task."
      path="treatment-goals"
      actionType={actionType}
      isDisabled={!patient}
      isComplete={isComplete}
    />
  );
};

const CaseCreator = () => {
  const { push } = useHistory();
  const { canSubmitCase, isSubmittingCase, submitCase } = useSubmitCase();
  const location = useLocation<{ fromCustomerCreator?: boolean }>();
  const patient = useSelector(selectPatient);
  const patientName = useSelector(selectPatientName);
  const isSubmitted = useSelector(selectIsSubmitted);
  const hasTreatmentGoals = useSelector(selectCanCollectTreatmentGoals);
  const activeCase = useSelector(selectActiveCase);
  const isRetainerCaseWithOrderInfo = useSelector(
    selectIsRetainerCaseWithOrderInfo
  );
  const { data: { edges: ordersForCase = [] } = {} } = api.useGetOrdersQuery({
    caseRef: activeCase?.caseRef,
  });

  const hasSubmittedBefore =
    isRetainerCaseWithOrderInfo ||
    (activeCase?.caseType.name === 'retainer' && ordersForCase.length > 0);
  const dispatch = useDispatch<AppDispatch>();
  const { showNotification } = useContext(NotificationContext);

  const patientId = Number(patient?.id);

  const goToCheckout =
    activeCase?.caseType.name === CaseTypeNames.RETAINER && !hasSubmittedBefore;

  const onSubmitClick = () => {
    if (goToCheckout) {
      push(`/patient/${patientId}/case-creator/checkout?new_scans=true`);
    } else {
      submitCase({
        onSuccess: () => {
          dispatch(resetPatientState()); // reset patient state to avoid confustion between selected case and new case
          push({
            pathname: `/patient/${patientId}`,
          });

          showNotification('Case submitted', 'success');
        },
        onError: () => showNotification('Failed to submit case', 'error'),
        isCore: activeCase?.source === CaseSource.Core,
      });
    }
  };

  const header = !patientName ? 'New patient' : patientName;

  useEffect(() => {
    if (location.pathname.includes('/patient/new')) {
      dispatch(resetPatientState());
    }
  }, [location.pathname, dispatch]);

  return (
    <PageWrapper isNarrow>
      <Heading variant={'h3'} style={{ marginBottom: '1.5rem' }}>
        {header}
      </Heading>
      <BasicInfoSection />
      <DiagnosticSection />
      {hasTreatmentGoals && <TreatmentGoalsSection />}
      {!isSubmitted && (
        <StyledButton
          buttonType="secondary"
          buttonSize="large"
          disabled={!canSubmitCase}
          isLoading={isSubmittingCase}
          onClick={onSubmitClick}
        >
          {goToCheckout ? 'Continue to checkout' : 'Submit case'}
        </StyledButton>
      )}
    </PageWrapper>
  );
};

export default CaseCreator;
