import React from 'react';
import { RouteComponentProps } from 'react-router';
import CaseSearchPage from 'components/SearchPages/CaseSearchPage';
import { MatchParams } from 'pages/TpCentralEnhanced/types';

const TpUploadSearch = ({
  history,
  match,
}: RouteComponentProps<MatchParams>) => {
  const { id } = match.params;

  const handleSubmit = ({ caseRef }: { caseRef: string }) => {
    if (!caseRef) {
      return;
    }

    history.push(`${match.url}/${caseRef}`);
  };

  return (
    <CaseSearchPage
      patientId={id}
      onSelectCase={handleSubmit}
      pageTitle="Treatment Plan Central"
    />
  );
};

export default TpUploadSearch;
