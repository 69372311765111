/* eslint eslint-comments/no-use: off */
/* eslint-disable @typescript-eslint/no-explicit-any */
type ResultFn<TArgs extends any[], TEntry> = (...args: TArgs) => TEntry;

function isEqualArgs(args1: any[] | null, args2: any[]): boolean {
  if (!args1) {
    return false;
  }

  const _args1_ = args1.length;
  if (_args1_ !== args2.length) {
    return false;
  }

  for (let i = 0; i < _args1_; ++i) {
    if (args1[i] !== args2[i]) {
      return false;
    }
  }

  return true;
}

/**
 * Memoizes a function  caches the result of the last call.
 *
 * @param fn Memoized function
 * @returns
 */
export function memoizeOne<TArgs extends any[], TEntry>(
  fn: ResultFn<TArgs, TEntry>
): ResultFn<TArgs, TEntry> {
  let lastArgs: any[] | null = null;
  let lastResult: any;

  return (...args: TArgs): TEntry =>
    isEqualArgs(lastArgs, args)
      ? lastResult
      : (lastArgs = args) && (lastResult = fn(...args));
}
/* eslint-enable */
