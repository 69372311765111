import { AnyAction, Middleware } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import { ApolloClientType, client as legacyClient } from 'gql/GraphQLProvider';
import { DocumentNode, gql } from '@apollo/client';
import { documentToString } from 'state/system/util';

interface GraphQLMutationPayload {
  mutation: DocumentNode;
  variables?: unknown;
  origin: string;
  client?: ApolloClientType;
}

interface GraphQLQueryPayload {
  query: DocumentNode;
  variables?: unknown;
  origin: string;
  client?: ApolloClientType;
}

/**
   Usage:

       import { CleverMutation } from 'generated/legacy/graphql';

       ...

       store.dispatch(
         graphql.mutate({
           namespace: 'clever/mutation',
           mutation: CleverMutation,
           variables: { arg: 'Immanentize the Eschaton!' },
         })
      );
 */
export const mutate = createAction(
  'graphql/mutate',
  (payload: GraphQLMutationPayload, meta?: unknown) => ({
    payload: {
      ...payload,
      mutation: documentToString(payload.mutation),
      client: payload.client || legacyClient,
    },
    meta,
  })
);

/**
   Usage:

       import { CleverQuery } from 'generated/legacy/graphql';

       ...

       store.dispatch(
         graphql.mutate({
           namespace: 'clever/query',
           mutation: CleverQuery,
           variables: { immanentized: true },
         })
      );
 */
export const query = createAction(
  'graphql/query',
  (payload: GraphQLQueryPayload, meta?: unknown) => ({
    payload: {
      ...payload,
      query: documentToString(payload.query),
      client: payload.client || legacyClient,
    },
    meta,
  })
);

/** exported for tests, do not use */
const pending = (origin: string, meta?: unknown) => ({
  type: asPending(origin),
  meta,
});

/** exported for tests, do not use */
export const fulfilled = (
  origin: string,
  payload: unknown,
  meta?: unknown
) => ({
  type: asFulfilled(origin),
  payload,
  meta,
});

/** exported for tests, do not use */
const rejected = (origin: string, error: unknown, meta?: unknown) => ({
  type: asRejected(origin),
  error,
  meta,
});

/**
   Adds `graphql/pending` suffix to action type
*/
const asPending = (action: AnyAction | string) =>
  `${typeof action === 'string' ? action : action.type}/graphql/pending`;

/**
   Adds `graphql/fulfilled` suffix to action type
*/
export const asFulfilled = (action: AnyAction | string) =>
  `${typeof action === 'string' ? action : action.type}/graphql/fulfilled`;

/**
   Adds `graphql/rejected` suffix to action type
*/
const asRejected = (action: AnyAction | string) =>
  `${typeof action === 'string' ? action : action.type}/graphql/rejected`;

type GraphQLAction = ReturnType<typeof mutate> | ReturnType<typeof query>;

const isGraphQLAction = (a: any): a is GraphQLAction =>
  mutate.match(a) || query.match(a);
/*
  TODO: GraphQL requests should be cancellable. Apollo makes this a
  pain in the ass, see: https://github.com/apollographql/apollo-client/issues/4150

  It does seem that we could subscribe to the query or mutation, then
  in a finally block (called if this request is happening in a
  coroutine and `clearTimeout` aborts the process) where we
  unsub/cancel the request.
 */
const graphql: Middleware = (store) => (next) => async (action) => {
  next(action);

  if (!isGraphQLAction(action)) {
    return;
  }
  const dispatch = store.dispatch;
  const { origin, variables, client } = action.payload;

  next(pending(origin));

  try {
    let result;
    if (mutate.match(action)) {
      const { data } = await client.mutate({
        fetchPolicy: 'no-cache',
        variables: variables ?? {},
        mutation: gql(action.payload.mutation),
      });

      result = data;
    }

    if (query.match(action)) {
      const { data } = await client.query({
        fetchPolicy: 'no-cache',
        variables: variables ?? {},
        query: gql(action.payload.query),
      });

      result = data;
    }

    if (!result) {
      throw new Error(
        `[runtime type error]: unrecognized action, not mutation or query: ${JSON.stringify(
          action
        )}`
      );
    }

    dispatch(fulfilled(origin, result, action.meta));
  } catch (error) {
    dispatch(rejected(origin, { error }));
  }
};

export default graphql;
