import { useCallback, useRef } from 'react';

type Callback = (...args: any[]) => any;

function useCallbackQueue<T extends Callback>() {
  const callbackQueue = useRef<T[]>([]);

  /**
   * Add a callback to the queue
   */
  const addToQueue = useCallback((callback: T) => {
    callbackQueue.current.push(callback);
  }, []);

  /**
   * Empty the queue while executing the callbacks in the order they were added
   */
  const executeQueue = useCallback(async (...args: Parameters<T>) => {
    while (callbackQueue.current.length) {
      const callback = callbackQueue.current.shift();
      if (callback) {
        await callback(...args);
      }
    }
  }, []);

  return { queue: callbackQueue.current, addToQueue, executeQueue };
}

export default useCallbackQueue;
