import { LegacyMaterialStates } from 'constants/Material';
import SubmittedSVG from 'assets/submitted.svg?react';
import RejectedSVG from 'assets/thumbs-down-filled.svg?react';
import ApprovedSVG from 'assets/thumbs-up-filled.svg?react';
import NeedRepairSVG from 'assets/ic_alert-triangle.svg?react';
import QuestionSVG from 'assets/question-icon.svg?react';

export const titleMapping: {
  [key in string]: { Icon: typeof SubmittedSVG; text: string };
} = {
  [LegacyMaterialStates.UPLOADED]: {
    Icon: SubmittedSVG,
    text: 'Material uploaded',
  },
  [LegacyMaterialStates.APPROVED]: {
    Icon: ApprovedSVG,
    text: 'Approved by',
  },
  [LegacyMaterialStates.REJECTED]: {
    Icon: RejectedSVG,
    text: 'Rejected by',
  },
  [LegacyMaterialStates.NEEDS_REPAIR]: {
    Icon: NeedRepairSVG,
    text: 'Scans sent for repair',
  },
  [LegacyMaterialStates.NEEDS_CLARIFICATION]: {
    Icon: QuestionSVG,
    text: 'Clarification needed',
  },
  [LegacyMaterialStates.CLARIFICATION_PROVIDED]: {
    Icon: QuestionSVG,
    text: 'Clarification provided',
  },
  [LegacyMaterialStates.PUSHED_TO_PRODUCTION]: {
    Icon: SubmittedSVG,
    text: 'Pushed to production',
  },
};

export const evaluationTypeMapping: {
  [key in string]: string;
} = {
  quality_control_evaluation: 'quality control',
  orthodontic_evaluation: 'clinical reviewer',
  pro_clinician_evaluation: 'treating provider',
};
