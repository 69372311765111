import React from 'react';

import {
  RowContainer,
  StyledCheckCircle,
  StyledCirleOutline,
  Text,
  VerticalContainer,
} from 'pages/ActionItems/MissingMaterialsTooltip.css';

type Props = {
  materials: string[]; //All of the missing materials.
  isCaseGen2: boolean;
};

const MissingMaterialsTooltip = ({ materials, isCaseGen2 }: Props) => {
  const materialMapping = {
    basic_info: 'Basic info',
    photos: 'Photos',
    scans: 'Scans',
    xrays: 'X-rays',
    treatment_goals: isCaseGen2 ? 'Treatment goals' : 'Treatment details',
    case_submission: 'Case submission',
  };

  const icons = Object.entries(materialMapping).map(([k, v]) => {
    if (!isCaseGen2 && k === 'case_submission') {
      return null;
    }

    //If any diagnostic material is missing, case submission is not returned. Make sure to still mark it incomplete
    const isIncomplete =
      materials.includes(k) ||
      (k === 'case_submission' && materials.length >= 0);

    return (
      <RowContainer>
        {isIncomplete ? <StyledCirleOutline /> : <StyledCheckCircle />}
        <Text>{v}</Text>
      </RowContainer>
    );
  });
  return <VerticalContainer>{icons}</VerticalContainer>;
};

export default MissingMaterialsTooltip;
