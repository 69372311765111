import React, { useState } from 'react';
import {
  CouponContainer,
  CouponTextInput,
  CouponError,
} from 'pages/Patient/Checkout/Checkout.css';
import { Button, Chip } from 'core/components';
import { IneligiblePromotion } from 'generated/core/graphql';

interface CouponCodeInputBoxProps {
  couponCode: string;
  appliedCouponCode: string | null;
  couponError: IneligiblePromotion | null | undefined;
  setCouponCode: (value: string) => void;
}

const CouponCodeInputBox: React.FC<CouponCodeInputBoxProps> = ({
  couponCode,
  appliedCouponCode,
  setCouponCode,
  couponError,
}) => {
  const [couponCodeInput, setCouponCodeInput] = useState('');
  const resetCouponCode = () => {
    setCouponCode('');
    setCouponCodeInput('');
  };
  const showChip = couponCode === appliedCouponCode;
  return (
    <div>
      <CouponContainer>
        <span style={{ marginRight: 2 }}>Promo code:</span>
        {showChip && (
          <Chip onDelete={() => resetCouponCode()} label={couponCode} />
        )}
      </CouponContainer>
      <CouponContainer>
        {!showChip && (
          <CouponContainer>
            <CouponTextInput
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCouponCodeInput(e.target.value);
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  setCouponCode(couponCodeInput.trim());
                }
              }}
              value={couponCodeInput}
            />
            <Button
              buttonType="secondary"
              onClick={() => setCouponCode(couponCodeInput)}
            >
              Apply
            </Button>
          </CouponContainer>
        )}
      </CouponContainer>
      {couponError?.message && (
        <CouponError>{couponError?.message}</CouponError>
      )}
    </div>
  );
};

export default CouponCodeInputBox;
