import styled from 'styled-components/macro';

export const Container = styled.div`
  padding: 32px;
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.colors.text20};
  border-radius: 12px;
  scroll-margin-top: 200px;
`;

export const Title = styled.h4`
  ${({ theme }) => theme.text.body.default};
`;

export const Description = styled.p`
  ${({ theme }) => theme.text.body.default};
  color: ${({ theme }) => theme.colors.black70};
`;

export const MenuContainer = styled.div`
  padding: 32px;
`;
