import React from 'react';

type TreatmentProtocolsLinkProps = {
  copy: string;
  link: string;
};

const TreatmentProtocolsLink = ({
  copy,
  link,
}: TreatmentProtocolsLinkProps) => (
  <a target="_blank" rel="noopener noreferrer" href={link}>
    {copy}
  </a>
);

export default TreatmentProtocolsLink;
