import styled from 'styled-components/macro';

export const ListContainer = styled.ul`
  margin-left: 1.5rem;
  margin-bottom: 0;
`;

export const ListItem = styled.li`
  list-style-type: disc;
`;

export const ListItemText = styled.div`
  display: inline;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CheckboxContainer = styled.div<{
  isReadonly?: boolean;
}>`
  color: ${({ theme, isReadonly }) =>
    isReadonly ? theme.colors.text70 : theme.colors.text90};
  display: flex;
  flex-direction: row;
  gap: 16px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;

  > input {
    appearance: checkbox;
    align-self: start;
  }
`;
