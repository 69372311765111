import React, { useEffect } from 'react';
import { SearchTablePage } from 'components/SearchTablePage';
import { useDispatch } from 'react-redux';
import { resetPatientState } from 'pages/Patient/patientSlice';

const PatientSearch = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetPatientState()); // Reset patient state to clear out any data from previous patient
  }, []);
  return <SearchTablePage pageTitle="Patient search" />;
};

export default PatientSearch;
