import { useEffect, useState } from 'react';

type Params = {
  src: string;
  id?: string;
};

let cachedScripts: string[] = [];

const useScript = ({ src, id }: Params): [boolean, boolean] => {
  const [state, setState] = useState({
    isLoaded: false,
    hasError: false,
  });

  useEffect(() => {
    // If 'cachedScripts' already includes the script src, then it was already
    // loaded by another instance of this hook
    if (cachedScripts.includes(src)) {
      setState({
        isLoaded: true,
        hasError: false,
      });
    } else {
      cachedScripts.push(src);

      // Create script and add load/error listeners
      const script = document.createElement('script');
      script.async = true;
      if (id) {
        script.id = id;
      }
      script.src = src;

      const onScriptLoad = () => {
        setState({
          isLoaded: true,
          hasError: false,
        });
      };

      const onScriptError = () => {
        cachedScripts = cachedScripts.filter((currSrc) => currSrc !== src);
        script.remove();

        setState({
          isLoaded: true,
          hasError: true,
        });
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      // Add script to document body
      document.body.appendChild(script);

      // Remove listeners on cleanup
      return () => {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
      };
    }
  }, [src]);

  return [state.isLoaded, state.hasError];
};

export default useScript;
