import React from 'react';
import styled from 'styled-components/macro';
import { colors } from 'core/components';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';

enum TooltipTheme {
  Pink = 'pink',
}

enum TooltipPlacement {
  left = 'left',
  right = 'right',
  bottom = 'bottom',
  top = 'top',
}

type IconContainerProps = {
  color: string;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  margin-top: 0;
`;

const Label = styled.label`
  width: 100%;
`;

const IconWrapper = styled.div<IconContainerProps>`
  cursor: pointer;
  color: ${({ color }) => color};
  display: inline-block;
  vertical-align: middle;
  svg {
    transform: scale(0.8);
  }
`;

type TooltipProps = {
  tooltipTheme?: TooltipTheme;
  iconColor?: string;
  placement?: TooltipPlacement;
  content: React.ReactChild | React.ReactChild[];
  label?: string;
};

export const InfoTooltip = ({
  tooltipTheme = TooltipTheme.Pink,
  placement = TooltipPlacement.bottom,
  content,
  iconColor = colors.black60,
  label,
}: TooltipProps) => (
  <Wrapper>
    {!!label && <Label>{label}</Label>}
    <Tippy
      placement={placement}
      hideOnClick={false}
      content={content}
      theme={tooltipTheme}
    >
      <IconWrapper color={iconColor}>
        <InfoIcon />
      </IconWrapper>
    </Tippy>
  </Wrapper>
);
