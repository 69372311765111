import styled from 'styled-components';

export const Wrap = styled.div`
  width: 100%;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const StyledTextInput = styled.input<{ showError?: boolean }>`
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 1px solid
    ${({ showError, theme }) =>
      showError ? theme.palette.error.main : theme.palette.border.main};
  border-radius: 2px;
  transition: border 200ms ease-in-out;

  &:focus {
    border-color: ${({ theme }) => theme.colors.black30};
  }

  &:hover {
    border-color: ${({ showError, theme }) =>
      showError ? theme.palette.error.main : theme.palette.border.hover};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.black60};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px white inset; /* stylelint-disable-line property-no-vendor-prefix */
  }

  &:not(:placeholder-shown) + label {
    z-index: 1;
    background: white;
    opacity: 1;
    transform: translateY(-0.8125rem);
  }
`;

export const FloatingLabel = styled.label`
  position: absolute;
  top: calc(50% - ${({ theme }) => theme.fontSizes.small});
  left: 0.625rem;
  z-index: -1;
  padding: 0 0.25rem;
  margin-bottom: 0.2rem;
  font-size: ${({ theme }) => theme.fontSizes.tiny};
  color: ${({ theme }) => theme.colors.black60};
  opacity: 0;
  transition: all 0.3s ease;
`;
