import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import * as propTypes from 'utils/propTypes';
import NotFound from 'pages/NotFound';

import XrayUploadOverview from 'pages/XrayUpload/XrayUploadOverview';
import XrayUploadProvider from 'pages/XrayUpload/XrayUploadProvider';
import XrayUploadSearch from 'pages/XrayUpload/XrayUploadSearch';

const XrayUpload = ({ match }: RouteComponentProps) => (
  <Switch>
    <Route component={XrayUploadSearch} exact path={`${match.path}/:id?`} />
    <XrayUploadProvider>
      <Route
        component={XrayUploadOverview}
        exact
        path={`${match.path}/:id/:caseRef`}
      />
    </XrayUploadProvider>
    <Route component={NotFound} />
  </Switch>
);

XrayUpload.propTypes = {
  match: propTypes.routerMatch(),
};

export default XrayUpload;
