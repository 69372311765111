import styled from 'styled-components/macro';
import { colors } from 'core/components';

export const OrderItemsContainer = styled.section<{
  empty?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: ${({ empty }) => (empty ? '0' : '1 1 0')};
  opacity: ${({ empty }) => (empty ? '0' : '1')};
  flex-wrap: wrap;
  gap: 12px;
  transition: all 0.5s ease-in-out;
`;
export const OrderItemContainer = styled.section<{
  selected?: boolean;
  disabled?: boolean;
}>`
  background: ${({ selected, disabled }) =>
    disabled ? colors.black10 : selected ? colors.blue05 : colors.white};
  position: relative;
  display: flex;
  width: 100%;
  height: 144px;
  border: 2px solid #0452c7;
  padding: 24px;
  border-radius: 8px;
  gap: 24px;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
`;

export const Radio = styled.input`
  margin-right: 0.5rem;
  cursor: pointer;
  appearance: radio;
  height: 1rem;
  width: 1rem;
  vertical-align: middle;
`;

export const StyledImage = styled.img`
  width: 140px;
  height: 96px;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

export const Title = styled.div`
  font-family: Modern Era;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
`;

export const Description = styled.div<{
  gray?: boolean;
  key?: number;
}>`
  color: ${({ gray }) => (gray ? colors.black70 : colors.black90)};
  font-family: Modern Era;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
`;

export const TextContainer = styled.div<{
  flexDirection?: string;
}>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 4px;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : 'row'};
`;
