import { useEffect } from 'react';

const confirmChanges = (e) => {
  // Ensure beforeunload prompt shows in all browsers (https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#Example)
  e.preventDefault();
  e.returnValue = '';
};

const useConfirmChanges = (hasChanges) => {
  useEffect(() => {
    if (hasChanges) {
      window.addEventListener('beforeunload', confirmChanges);

      return () => {
        window.removeEventListener('beforeunload', confirmChanges);
      };
    }
  }, [hasChanges]);
};

export default useConfirmChanges;
