import * as d3 from 'd3';
import React, { useState, useEffect, useRef } from 'react';
import { Divider, Popover, Grid } from 'core/components';
import { definitionsMap } from 'pages/ActionItems/OverviewModules/definitions';
import { NewPatientButton } from 'pages/ActionItems/ActionItems';
import { SkeletonDonutChart, SkeletonTask } from 'pages/ActionItems/Skeletons';

import {
  TaskTitle,
  ColoredBullet,
  TaskNumber,
  PopoverContainer,
  OverviewContainer,
  TasksContainer,
  TaskContainer,
  TaskTitleContainer,
  ButtonContainer,
} from 'pages/ActionItems/OverviewModules/Overview.css';

import { DefinitionsPopover } from 'pages/ActionItems/OverviewModules/DefinitionsPopover/Definitions';
import {
  DonutChartDataType,
  DefinitionsMapType,
  OverviewModuleProps,
} from 'pages/ActionItems/types';
import useIsTouchDevice from 'hooks/useIsTouchDevice';

const initialDonutChartData: DonutChartDataType = {
  displayBorder: false,
  data: [],
};

// This is exported because we use this function in a test
export const generateDonutChartData = (taskCountData: any[]) => {
  let categoryCount = 0;
  const data = taskCountData.map((task: any) => {
    if (task.count) {
      categoryCount++;
    }

    return {
      key: task.state,
      value: task.count,
      color: definitionsMap[task.state as keyof DefinitionsMapType].color,
    };
  });

  const displayBorder = categoryCount > 1;
  return { displayBorder, data };
};

const filterProviderTasks = (
  providerTasks: any[],
  displayDefinitionsMap: DefinitionsMapType
) => {
  return Object.keys(displayDefinitionsMap).reduce<any[]>(
    (result, definition) => {
      const task = providerTasks.find((t) => t.state === definition);
      if (task && displayDefinitionsMap[definition].display) {
        result.push(task);
      }
      return result;
    },
    []
  );
};

const renderTasks = (
  providerTasks: any[],
  displayDefinitionsMap: DefinitionsMapType
) => {
  const isTouchDevice = useIsTouchDevice();
  const filteredProviderTasks = filterProviderTasks(
    providerTasks,
    displayDefinitionsMap
  );

  return filteredProviderTasks.map((task) => {
    const taskDefinition =
      definitionsMap[task.state as keyof DefinitionsMapType];
    const color = taskDefinition.color;

    return (
      <>
        <Divider />
        <TaskContainer
          container
          direction="row"
          onClick={() => {
            if (isTouchDevice && task.state) {
              location.hash = task.state;
            }
          }}
        >
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            wrap="nowrap"
            xs={10}
          >
            <Grid item>
              <ColoredBullet color={color} data-testid="colored-bullet" />
            </Grid>
            <TaskTitleContainer item>
              <TaskTitle>{task.state}</TaskTitle>
            </TaskTitleContainer>
          </Grid>
          <Grid item>
            <TaskNumber>{task.count}</TaskNumber>
          </Grid>
        </TaskContainer>
      </>
    );
  });
};

export const OverviewModule = ({
  providerTaskCounts,
  isFetchingProviderTasks,
  showSkeleton,
  onNewPatientClick,
}: OverviewModuleProps) => {
  const [donutChartData, setDonutChartData] = useState<DonutChartDataType>(
    initialDonutChartData
  );

  useEffect(() => {
    if (providerTaskCounts) {
      const filteredTasks = filterProviderTasks(
        providerTaskCounts,
        definitionsMap
      );

      setDonutChartData(generateDonutChartData(filteredTasks));
    }
  }, [providerTaskCounts]);

  const donutContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (donutContainerRef.current && donutChartData.data.length > 0) {
      renderDonutChart({
        data: donutChartData.data.map((d) => ({
          name: d.key ?? '',
          value: d.value ?? 0,
          color: d.color,
        })),
        element: donutContainerRef.current,
      });
    }
  }, [donutChartData, donutContainerRef]);

  return (
    <OverviewContainer
      container
      direction="column"
      data-testid="action-item-overview"
    >
      <Grid container justifyContent="flex-end">
        <span data-testid="definitions-popover">
          <PopoverContainer>
            <Popover on={'hover'} direction={'right'}>
              <DefinitionsPopover definitionsMap={definitionsMap} />
            </Popover>
          </PopoverContainer>
        </span>
      </Grid>
      <div
        data-testid="action-item-donut-chart"
        style={{
          width: '170px',
          height: '170px',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '30px',
        }}
        ref={donutContainerRef}
      >
        {showSkeleton(isFetchingProviderTasks, providerTaskCounts) && (
          <SkeletonDonutChart />
        )}
      </div>

      <TasksContainer item>
        {showSkeleton(isFetchingProviderTasks, providerTaskCounts) ? (
          <SkeletonTask />
        ) : (
          renderTasks(providerTaskCounts ?? [], definitionsMap)
        )}
      </TasksContainer>
      <ButtonContainer item>
        <NewPatientButton onClick={onNewPatientClick} />
      </ButtonContainer>
    </OverviewContainer>
  );
};

const renderDonutChart = ({
  data,
  element,
}: {
  data: { name: string; value: number; color: string }[];
  element: HTMLDivElement;
}) => {
  const { width } = element.getBoundingClientRect();
  const margin = 0;
  const radius = width / 2 - margin;

  d3.select(element).select('svg').remove();

  const svg = d3
    .select(element)
    .append('svg')
    .attr('width', '100%')
    .attr('height', '100%')
    .attr('viewBox', '0 0 ' + width + ' ' + width)
    .attr('preserveAspectRatio', 'xMinYMin')
    .append('g')
    .attr(
      'transform',
      'translate(' + Math.min(width) / 2 + ',' + Math.min(width) / 2 + ')'
    );

  const pie = d3
    .pie()
    .sort(null)
    .value((d) => d.valueOf());

  const arc: any = d3
    .arc()
    .innerRadius(radius * 0.85)
    .outerRadius(radius * 1);

  const pieData = pie(data.map((d) => d.value));
  const count = data.reduce((acc, d) => acc + d.value, 0);

  svg
    .selectAll('allSlices')
    .data(pieData)
    .enter()
    .append('path')
    .attr('d', arc)
    .attr('fill', (d) => data[d.index].color)
    .attr('stroke', 'white')
    .attr('transform', 'rotate(90)')
    .style('stroke-width', '2px');

  svg
    .append('text')
    .text(count)
    .attr('text-anchor', 'middle')
    .attr('font-size', '2rem')
    .attr('y', 0);

  svg
    .append('text')
    .text('Patients')
    .attr('text-anchor', 'middle')
    .attr('font-size', '1rem')
    .attr('font-weight', 'bold')
    .attr('y', 22.5);

  svg.selectAll('text').attr('fill', '#222');
};
