import styled from 'styled-components/macro';
import { type } from 'core/components';

export const SectionHeading = styled(type.H5)`
  margin-bottom: 0.25rem;
`;

export const PatientInfo = styled(type.H5)`
  margin-bottom: 0.25rem;
  margin-top: 1rem;
`;
