import React from 'react';
import { Button } from 'core/components';

import CropIcon from 'assets/ic_crop.svg?react';
import FlipIcon from 'assets/ic_mirror-horiz.svg?react';
import RotateLeftIcon from 'assets/ic_rotate-ccw.svg?react';
import RotateRightIcon from 'assets/ic_rotate-cw.svg?react';

import {
  ConfirmButtons,
  Container,
  RotationSlider,
  ToolBar,
  ToolButton,
} from 'components/PrismPhotoReview/PhotoTools/PhotoTools.css';

type Props = {
  currentRotation: number | string;
  isEditing?: boolean;
  isSaving?: boolean;
  isVisible?: boolean;
  onChangeRotation: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickFlip: () => void;
  onClickCrop: () => void;
  onClickRotateLeft: () => void;
  onClickRotateRight: () => void;
  onDiscardEdits: () => void;
  onSaveEdits: () => void;
};

const PhotoTools = ({
  currentRotation,
  isEditing,
  isSaving,
  isVisible,
  onChangeRotation,
  onClickFlip,
  onClickCrop,
  onClickRotateLeft,
  onClickRotateRight,
  onDiscardEdits,
  onSaveEdits,
}: Props) => (
  <Container
    aria-hidden={!isVisible}
    isVisible={isVisible}
    data-testid="PrismPhotoReview-PhotoTools-ToolContainer"
  >
    <ToolBar
      isSaving={isSaving}
      data-testid="PrismPhotoReview-PhotoTools-ToolBar"
    >
      <ToolButton
        onClick={onClickFlip}
        title="Flip"
        type="button"
        data-testid="PrismPhotoReview-PhotoTools-Flip"
      >
        <FlipIcon role="img" />
      </ToolButton>
      <ToolButton
        onClick={onClickCrop}
        title="Crop"
        type="button"
        data-testid="PrismPhotoReview-PhotoTools-Crop"
      >
        <CropIcon role="img" />
      </ToolButton>
      <ToolButton
        onClick={onClickRotateLeft}
        title="Rotate left"
        type="button"
        data-testid="PrismPhotoReview-PhotoTools-RotateLeft"
      >
        <RotateLeftIcon role="img" />
      </ToolButton>
      <ToolButton
        onClick={onClickRotateRight}
        title="Rotate right"
        type="button"
        data-testid="PrismPhotoReview-PhotoTools-RotateRight"
      >
        <RotateRightIcon role="img" />
      </ToolButton>
      <RotationSlider
        max="180"
        min="-180"
        onChange={onChangeRotation}
        title="Rotate"
        type="range"
        value={currentRotation}
        data-testid="PrismPhotoReview-PhotoTools-RotationSlider"
      />
    </ToolBar>
    <ConfirmButtons aria-hidden={!isEditing} isVisible={isEditing}>
      <Button
        buttonType="secondary-outline"
        disabled={isSaving}
        onClick={onDiscardEdits}
        data-testid="PrismPhotoReview-PhotoTools-Discard"
      >
        Discard
      </Button>
      <Button
        buttonType="secondary"
        disabled={isSaving}
        isLoading={isSaving}
        onClick={onSaveEdits}
        data-testid="PrismPhotoReview-PhotoTools-Save"
      >
        Save
      </Button>
    </ConfirmButtons>
  </Container>
);

export default PhotoTools;
