import React from 'react';
import PopoverClick from 'core/components/Popover/PopoverClick';
import PopoverHover from 'core/components/Popover/PopoverHover';
import useTheme from 'core/hooks/useTheme';

import { BasePopoverProps } from 'core/components/Popover/Popover.css';

type Hoverable = {
  on: 'hover';
};

type Clickable = {
  on: 'click';
};

export type PopoverProps = BasePopoverProps & (Clickable | Hoverable);

const isHover = (props: PopoverProps) => props.on === 'hover';
const isClick = (props: PopoverProps) => props.on === 'click';

const getAnchorOrigin = (
  direction: BasePopoverProps['direction']
): BasePopoverProps['anchorOrigin'] => {
  switch (direction) {
    case 'right':
      return {
        vertical: 'top',
        horizontal: 'right',
      };
    case 'bottom':
      return {
        vertical: 'bottom',
        horizontal: 'center',
      };
    case 'left':
      return {
        vertical: 'top',
        horizontal: 'left',
      };
    case 'top':
      return {
        vertical: 'top',
        horizontal: 'center',
      };
  }
};

const getTransformOrigin = (
  direction: BasePopoverProps['direction']
): BasePopoverProps['anchorOrigin'] => {
  switch (direction) {
    case 'right':
      return {
        vertical: 'top',
        horizontal: 'left',
      };
    case 'bottom':
      return {
        vertical: 'top',
        horizontal: 'center',
      };
    case 'left':
      return {
        vertical: 'top',
        horizontal: 'right',
      };
    case 'top':
      return {
        vertical: 'bottom',
        horizontal: 'center',
      };
  }
};

const getPopoverMargin = (direction: BasePopoverProps['direction']) => {
  const theme = useTheme();
  switch (direction) {
    case 'right':
      return {
        marginLeft: theme.space.md,
      };
    case 'bottom':
      return {
        marginTop: theme.space.md,
      };
    // Left and top have negative margins to set because they're not actually based off
    // the transformation origin so we have to base this margin based on the right point of the anchor
    case 'left':
      return {
        marginLeft: `-${theme.space.md}`,
      };
    case 'top':
      return {
        marginTop: `-${theme.space.md}`,
      };
  }
};

//
// NOTE: This is different from ToolTip!!!
// This allows you to put ANY content in a popup not just text.
//
const Popover = (props: PopoverProps) => {
  const { PaperProps, direction, ...rest } = props;
  const { style: paperStyle, ...restPaper } = PaperProps || {};

  const anchorOrigin = getAnchorOrigin(direction);
  const transformOrigin = getTransformOrigin(direction);
  const popoverMargin = getPopoverMargin(direction);
  const overridePaperProps = {
    style: {
      ...popoverMargin,
      ...paperStyle,
    },
    ...restPaper,
  };

  const popoverProps = {
    anchorOrigin,
    transformOrigin,
    PaperProps: { ...overridePaperProps },
    direction,
    ...rest,
  };

  if (isHover(props)) {
    return <PopoverHover {...popoverProps} />;
  } else if (isClick(props)) {
    return <PopoverClick {...popoverProps} />;
  }
  return null;
};

export default Popover;
