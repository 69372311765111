import React from 'react';
import { Grid, SelectInput } from 'core/components';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from 'react-i18next';
import { ReactSelectOption } from 'utils/types';

export const LanguageToggle = () => {
  const englishOption = { value: 'en', label: 'English' };
  const spanishOption = { value: 'es', label: 'Español' };
  const langaugeOptions = [englishOption, spanishOption];
  const selectedLanguages: { [key: string]: ReactSelectOption } = {
    en: englishOption,
    es: spanishOption,
  };
  const { i18n } = useTranslation();
  const value = selectedLanguages[i18n?.resolvedLanguage || ''];

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      style={{ gap: '0.5rem' }}
      justifyContent="center"
    >
      Select a language
      <SelectInput
        options={langaugeOptions}
        value={value}
        onChange={(option) => {
          const { value } = option as ReactSelectOption;
          i18n.changeLanguage(value);
        }}
      />
    </Grid>
  );
};
