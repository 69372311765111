import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NotFound from 'pages/NotFound';
import BasicInfo from 'pages/Patient/CaseCreator/BasicInfo';
import DiagnosticMaterials from 'pages/Patient/CaseCreator/DiagnosticMaterials';
import TreatmentGoals from 'pages/Patient/CaseCreator/TreatmentGoals';
import CaseSubmitted from 'pages/Patient/CaseCreator/CaseSubmitted';
import CaseCreator from 'pages/Patient/CaseCreator';
import PatientDetail from 'pages/Patient/PatientDetail';
import PatientSearch from 'pages/Patient/PatientSearch';
import { SkeletonCaseCreator } from 'pages/Patient/CaseCreator/Skeletons';
import DocumentTitle from 'components/DocumentTitle';
import {
  Route,
  RouteComponentProps,
  Switch,
  useParams,
} from 'react-router-dom';

import { PageBody } from 'pages/Patient/Patient.css';
import {
  selectPatient,
  fetchPatient,
  fetchCases,
  selectActiveCase,
  selectPatientName,
  fetchPatientLastStepEligible,
  setSelectedCaseRef,
  fetchMaterials,
  selectedTreatmentGoalFormsFromWorkflow,
} from 'pages/Patient/patientSlice';
import { useIsLoading } from 'state/system/slice';
import CaseCreatorHeader from 'pages/Patient/Header';
import { RouteParams } from 'pages/Patient/CaseCreator/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import RetainerCheckout from 'pages/Patient/Checkout/RetainerCheckout';

const CaseCreatorPage = ({ match }: RouteComponentProps) => {
  const { 'beta-treatment-goals': fetchBetaForms } = useFlags();
  const { id } = useParams<RouteParams>();
  const dispatch = useDispatch();
  const patient = useSelector(selectPatient);
  const activeCase = useSelector(selectActiveCase);
  const patientName = useSelector(selectPatientName);
  const formsToFetch = useSelector(selectedTreatmentGoalFormsFromWorkflow);
  const isPatientLoading = useIsLoading(fetchPatient.type);
  const isCasesLoading = useIsLoading(fetchCases.typePrefix);
  const caseRef = activeCase?.caseRef;

  const isNewPatient = !id;

  useEffect(() => {
    if (id && parseInt(id)) {
      dispatch(
        fetchPatient({
          customerId: id,
        })
      );
      dispatch(fetchCases({ patientIds: [Number(id)] }));
    }
  }, [id]);

  useEffect(() => {
    if (caseRef) {
      dispatch(setSelectedCaseRef(caseRef));
      dispatch(
        fetchMaterials({ caseRef: caseRef, fetchBetaForms, formsToFetch })
      );
      dispatch(fetchPatientLastStepEligible());
    }
  }, [caseRef]);

  const title = !patientName ? 'New patient' : patientName;

  const loadingData = isPatientLoading || isCasesLoading;
  const waitingOnPatientData = !patient && !isNewPatient;
  const showLoading = waitingOnPatientData || loadingData;
  if (showLoading) {
    return (
      <DocumentTitle data-private title={title}>
        <CaseCreatorHeader />
        <PageBody>
          <SkeletonCaseCreator />
        </PageBody>
      </DocumentTitle>
    );
  }

  return (
    <DocumentTitle data-private title={title}>
      <CaseCreatorHeader />
      <PageBody>
        <Switch>
          <Route component={CaseCreator} exact path={match.path} />
          <Route
            path={`${match.path}/basic-info`}
            exact
            component={BasicInfo}
          />
          <Route
            path={`${match.path}/diagnostic-materials`}
            exact
            component={DiagnosticMaterials}
          />
          <Route
            path={`${match.path}/treatment-goals`}
            exact
            component={TreatmentGoals}
          />
          <Route
            path={`${match.path}/case-submitted`}
            exact
            component={CaseSubmitted}
          />
          <Route
            path={`${match.path}/checkout`}
            exact
            component={RetainerCheckout}
          />
          <Route component={NotFound} />
        </Switch>
      </PageBody>
    </DocumentTitle>
  );
};

const PatientDetailRoutes = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route component={PatientDetail} exact path={match.path} />
      <Route component={CaseCreatorPage} path={`${match.path}/case-creator`} />
    </Switch>
  );
};

const PatientRoutes = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route component={PatientSearch} exact path={match.path} />
      <Route
        component={PatientDetailRoutes}
        exact
        path={[
          `${match.path}/:id(\\d+)`,
          `${match.path}/:id(\\d+)/cases/:caseRef`,
        ]}
      />
      <Route
        component={CaseCreatorPage}
        path={[`${match.path}/new`, `${match.path}/:id(\\d+|new)/case-creator`]}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default PatientRoutes;
