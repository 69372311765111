import React from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';

import { arrayContainer, itiArrayContainer, arrayItem } from 'styles/array.css';
import { evaluationIndicator } from 'styles/imageThumb.css';

import { PhotoMap } from 'pages/OrthoPrism/types';

const PhotoTypeGrid = styled.div<{ iti?: boolean }>`
  ${({ iti }) => (iti ? itiArrayContainer : arrayContainer)};
  grid-gap: 3vw;
  width: 100%;

  @media ${({ theme }) => theme.mediaQueries.tabletAndAbove} {
    grid-gap: 1.25rem;
  }
`;

const PhotoTypeItem = styled.button<{
  isRejected?: boolean;
  photo?: string;
}>`
  ${arrayItem};
  ${evaluationIndicator};
  padding-top: 73.34%;
  background: ${({ theme }) => theme.colors.black10} no-repeat center;
  background-size: contain;

  ${({ photo }) =>
    photo &&
    css`
      background-image: url(${photo});
    `};
`;

const ITIContainer = styled.div`
  margin-top: 1rem;
`;

type Props = {
  onReviewPhoto: (view: string) => void;
  photoMap: PhotoMap;
  photoViewNames: {
    treatmentViewNames: string[];
    itiViewNames: string[];
  };
};

const PhotoOverview = ({ onReviewPhoto, photoMap, photoViewNames }: Props) => {
  const renderItem = (name: string) => {
    const { photo, state } = photoMap[name] ?? {};
    return (
      <PhotoTypeItem
        key={name}
        isRejected={state?.isRejected}
        onClick={() => onReviewPhoto(name)}
        photo={photo?.photoUrl!}
        title={photo?.photoType.label}
        data-private
        data-testid={'OrthoPrism-Photo-' + name}
      />
    );
  };
  const { treatmentViewNames, itiViewNames } = photoViewNames;

  return (
    <>
      <PhotoTypeGrid>{treatmentViewNames.map(renderItem)}</PhotoTypeGrid>
      {!!itiViewNames.length && (
        <ITIContainer>
          <PhotoTypeGrid iti>{itiViewNames.map(renderItem)}</PhotoTypeGrid>
        </ITIContainer>
      )}
    </>
  );
};

export default PhotoOverview;
