import React from 'react';

import {
  SmallHeading,
  Label,
  Checkbox,
} from 'pages/OrthoPrism/Plan/TreatmentReviewForm/TreatmentReviewForm.css';

import { RejectionReason } from 'pages/OrthoPrism/types';

type Props = {
  category: RejectionReason;
  selections: Record<string, boolean>;
  onChange: (reason: RejectionReason, checked: boolean) => void;
};

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

const Reasons = ({ category, selections, onChange }: Props) => {
  return (
    <>
      <SmallHeading>{category.label}</SmallHeading>
      {category.reasons && (
        <ul>
          {category.reasons.map((reason) => (
            <li key={reason.name}>
              <Label>
                <Checkbox
                  type="checkbox"
                  name={reason.name}
                  checked={!!selections[reason.name]}
                  onChange={(e: ChangeEvent) =>
                    onChange(reason, e.target.checked)
                  }
                />
                {reason.label}
              </Label>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default Reasons;
