import { GetProductVariantsQuery, GetCasesQuery } from 'generated/core/graphql';
import { CandidProCustomerQuery } from 'generated/legacy/graphql';

export type ProductVariantType = GetProductVariantsQuery['productVariants'][0];

export type SelectOption = {
  value: string;
  label: string;
};

export type PatientType = CandidProCustomerQuery['customer'];
export type CaseType = GetCasesQuery['getCases'][0];

export enum FormType {
  practice,
  patient,
}
