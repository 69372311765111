import { changePassword } from 'api/login';
import { Button } from 'core/components';
import DocumentTitle from 'components/DocumentTitle';
import { FormikInputWrapper } from 'components/FormikForms';
import { passwordValidator } from 'components/FormikForms/utils';
import { Formik } from 'formik';
import {
  SplitContainer,
  LoginContainer,
  LoginForm,
  InputFields,
  Logo,
} from 'pages/Login/Login.css';
import React from 'react';
import { getBrandSettings, getBrandFromDomain } from 'utils/brands';
import withNotifications from 'hocs/withNotifications';

type FormProps = {
  new_password: string;
  confirm_new_password: string;
};

type PasswordResetConfirmationProps = {
  showNotification: (message: string, variant: string) => void;
};

const extractToken = (url: string) => {
  // Url will be in form of /account/reset/confirm/ENCODED_USER_ID/TOKEN
  const VALID_URL_LENGTH = 5;
  const urlParts = url.split('/');
  if (urlParts.length < VALID_URL_LENGTH) {
    return {
      uid: '',
      token: '',
    };
  }
  return {
    uid: urlParts[urlParts.length - 2],
    token: urlParts[urlParts.length - 1],
  };
};

const PasswordResetConfirmation = ({
  showNotification,
}: PasswordResetConfirmationProps) => {
  const initialValues: FormProps = {
    new_password: '',
    confirm_new_password: '',
  };

  const { label, logoSrc } = getBrandSettings(getBrandFromDomain());

  const handleSubmit = async ({
    new_password,
    confirm_new_password,
  }: FormProps) => {
    if (new_password !== confirm_new_password) {
      showNotification('Passwords do not match', 'error');
      return;
    }

    const { uid, token } = extractToken(window.location.pathname);

    if (!uid || !token) {
      showNotification('Invalid reset link', 'error');
      return;
    }

    try {
      await changePassword(new_password, token, uid);
    } catch (err: unknown) {
      if (err instanceof Error) {
        showNotification(err.message || 'Failed to change password', 'error');
      } else {
        showNotification('Failed to change password', 'error');
      }
      return;
    }

    showNotification('Password changed successfully', 'success');
    window.location.href = '/login';
  };
  return (
    <DocumentTitle title="Password reset">
      <SplitContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <LoginContainer>
              <LoginForm>
                {logoSrc && (
                  <Logo alt={label as string} src={logoSrc as string} />
                )}
                <InputFields>
                  <FormikInputWrapper
                    testId="new-password-input"
                    autoComplete="username"
                    name="new_password"
                    type="password"
                    placeholder="New password"
                    validate={passwordValidator}
                  />
                </InputFields>
                <InputFields>
                  <FormikInputWrapper
                    testId="confirm-new-password-input"
                    autoComplete="username"
                    name="confirm_new_password"
                    type="password"
                    placeholder="Confirm new password"
                    validate={passwordValidator}
                  />
                </InputFields>
                <Button
                  testId="login-btn"
                  buttonType="secondary"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type="submit"
                >
                  Reset password
                </Button>
              </LoginForm>
            </LoginContainer>
          )}
        </Formik>
      </SplitContainer>
    </DocumentTitle>
  );
};

export default withNotifications(PasswordResetConfirmation);
