import styled from 'styled-components/macro';
import { Switch } from '@material-ui/core';
import SparkleIcon from 'assets/sparkle.svg?react';

export const Note = styled.div`
  ${({ theme }) => theme.text.body.default};
  padding-bottom: 24px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.black70};
  border-bottom: 1px solid ${({ theme }) => theme.colors.text20};
`;

export const ProtocolToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProtocolSubCopy = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.black70};
`;

export const StyledSparkleIcon = styled(SparkleIcon)``;

// TODO update this to match figma
export const StyleToggle = styled(Switch)`
  & .MuiIconButton-root {
    color: ${({ theme }) => theme.colors.black50};
    opacity: 1;
    &:hover {
      background-color: transparent;
    }
  }
  & .MuiSwitch-thumb {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  & .MuiSwitch-track {
    background-color: ${({ theme }) => theme.colors.text20};
  }

  & .MuiSwitch-switchBase.Mui-checked {
    color: ${({ theme }) => theme.colors.blue50};

    &:hover {
      background-color: transparent;
    }
  }

  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.blue30};
  }
`;
