import React from 'react';
import { LinearProgressProps as Props } from '@material-ui/core/LinearProgress';
import { StyledProgressBar } from 'core/components/ProgressBar/ProgressBar.css';

export interface ProgressBarProps extends Props {
  currentValue: number;
  totalValue: number;
}

const ProgressBar = ({
  currentValue,
  totalValue,
  ...rest
}: ProgressBarProps) => {
  const percentCompleted = Math.floor((currentValue * 100) / totalValue);
  const color = percentCompleted === 100 ? 'primary' : 'secondary';

  return (
    <StyledProgressBar
      value={percentCompleted}
      color={color}
      variant="determinate"
      {...rest}
    />
  );
};

export default ProgressBar;
