import { FormikProps, FormikValues } from 'formik';
import { Form, GetFormQuery } from 'generated/core/graphql';
import { TreatmentGoalForm } from 'pages/Patient/CaseCreator/TreatmentGoals/TreatmentGoalForm';
import {
  selectAreTreatmentGoalsReadyForSubmit,
  selectTreatmentGoalsInitialValues,
  selectDefaultTreatmentGoalValues,
  selectPatientDoctorPreferences,
  selectCanEditSubmission,
} from 'pages/Patient/patientSlice';
import { DentalNotationOptions } from 'generated/legacy/graphql';
import React from 'react';
import { useSelector } from 'react-redux';

type GenericTreatmentGoalContainerProps = {
  treatmentGoalForms: GetFormQuery['getForm'][];
  formRef: React.RefObject<FormikProps<FormikValues>>;
  setSelectedForm: (form: Form) => void;
  onValuesChanged: () => void;
  onSubmit: (values: FormikValues) => void;
};

export const GenericTreatmentGoalContainer = ({
  treatmentGoalForms,
  formRef,
  setSelectedForm,
  onValuesChanged,
  onSubmit,
}: GenericTreatmentGoalContainerProps) => {
  const isComplete = useSelector(selectAreTreatmentGoalsReadyForSubmit);
  const initialValues = useSelector(selectTreatmentGoalsInitialValues);
  const doctorPreferences = useSelector(selectPatientDoctorPreferences);
  const defaultTreatmentGoalValues = useSelector(
    selectDefaultTreatmentGoalValues
  );
  const canEditSubmission = useSelector(selectCanEditSubmission);
  if (!treatmentGoalForms || treatmentGoalForms.length !== 1) {
    return null;
  }

  const selectedForm = treatmentGoalForms[0];

  setSelectedForm(selectedForm as Form);

  return (
    <TreatmentGoalForm
      formToComplete={selectedForm?.formSchema!}
      skipValidation={!isComplete}
      initialValues={initialValues}
      defaultValues={defaultTreatmentGoalValues}
      stepTitle="Treatment goals"
      formRef={formRef}
      onSubmit={onSubmit}
      onValuesChanged={onValuesChanged}
      dentalNotation={
        doctorPreferences?.dentalNotation as DentalNotationOptions
      }
      disabled={!canEditSubmission}
    />
  );
};
