import React from 'react';
import { AlertCard, Grid } from 'core/components';
import {
  AlertTitle,
  AlertContent,
  RejectedIcon,
  ActionNeededIcon,
  ActionLink,
  Container,
} from 'pages/Patient/PatientDetail/CaseTrackerAlert.css';
import { useHistory } from 'react-router-dom';
import { AlertTypeEnum } from 'pages/Patient/types';
type AlertType = AlertTypeEnum.Critical | AlertTypeEnum.Warning;

type RejectedAlertProps = {
  type: AlertType;
  actionLink?: string;
};

const config = {
  critical: {
    title: 'Material rejected',
    icon: RejectedIcon,
    defaultContent: `Our team found an issue with your materials. Please review and resubmit ASAP.`,
    actionLabel: 'Resubmit',
  },
  warning: {
    title: 'Clarification needed',
    icon: ActionNeededIcon,
    defaultContent: `Our orthodontic review team is unsure about one or actions of your materials, and needs to ask some clarifying questions. You’ll need to review their note and respond promptly to figure out the next step.`,
    actionLabel: 'Review and respond',
  },
};

const CaseTrackerAlert = ({
  type,
  actionLink = '/patient',
}: RejectedAlertProps) => {
  const { title, icon: Icon, defaultContent, actionLabel } = config[type];
  const { push } = useHistory();

  return (
    <Container>
      <AlertCard type={type}>
        <Grid container direction="row">
          <Grid item>
            <Icon />
          </Grid>
          <Grid item>
            <Grid item container direction="column">
              <Grid item>
                <AlertTitle>{title}</AlertTitle>
              </Grid>
              <Grid item>
                <AlertContent>{defaultContent}</AlertContent>
              </Grid>
              <Grid item>
                <ActionLink onClick={() => push(actionLink)}>
                  {actionLabel}
                </ActionLink>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AlertCard>
    </Container>
  );
};

export default CaseTrackerAlert;
