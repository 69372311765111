import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { captureException } from '@sentry/browser';

import { Modal, Button, NotificationContext } from 'core/components';
import {
  StyledModal,
  Header,
  StyledDivider,
  Body,
  Footer,
  CancelButton,
  OptionQuestion,
  ModalTitle,
  Disclaimer,
  ContactSupport,
} from 'components/Modals/OrderRetainersModal/OrderRetainersModal.css';
import NewScanIcon from 'assets/ic-file-plus.svg?react';
import ReuseLastStepIcon from 'assets/fi_refresh-cw.svg?react';
import {
  selectIsLastStepEligible,
  selectPatient,
} from 'pages/Patient/patientSlice';
import IneligibleRetainerAlertCard from 'components/AlertCards/IneligibleRetainerAlertCard';
import OptionsBox from 'components/OptionsBox';
import { useCreateRetainerCase } from 'pages/Patient/utils';
import { getBrandSettings, getBrandFromDomain } from 'utils/brands';

export type OrderRetainersModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

enum Options {
  NEW_SCAN = 'NEW_SCAN',
  REUSE_LAST_STEP = 'REUSE_LAST_STEP',
}

const OrderRetainerModal = ({ isOpen, onClose }: OrderRetainersModalProps) => {
  const { push } = useHistory();
  const { showNotification } = useContext(NotificationContext);
  const isLastStepEligible = useSelector(selectIsLastStepEligible);
  const patient = useSelector(selectPatient);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Options | null>(null);
  const createRetainerCase = useCreateRetainerCase();
  const {
    caseSupport: { email: supportEmail },
  } = getBrandSettings(getBrandFromDomain());

  useEffect(() => {
    if (selectedOption) {
      return;
    }
    setSelectedOption(Options.NEW_SCAN);
  }, [isLastStepEligible]);

  if (!patient) {
    return null;
  }

  const onSubmit = async (option: Options) => {
    try {
      setIsSubmitting(true);
      if (option === Options.REUSE_LAST_STEP) {
        push(`/patient/${patient?.id}/case-creator/checkout?last_step=true`);
      } else if (option === Options.NEW_SCAN) {
        await createRetainerCase({ patient });
      }
    } catch (err) {
      captureException(err);
      showNotification(
        'Sorry there was an issue, please try again or reach out to support',
        'error'
      );
    } finally {
      setIsSubmitting(false);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} hideCloseButton>
      <StyledModal>
        <Header>
          <ModalTitle>Order Retainers</ModalTitle>
        </Header>
        <StyledDivider />
        <Body>
          <OptionQuestion>
            Choose how you want to create this patient's retainers.
          </OptionQuestion>
          <OptionsBox
            Icon={NewScanIcon}
            title="Use new scans"
            description="For optimal results, send us new scans to create retainers based on the patient's exact anatomy after aligners."
            onClick={() => setSelectedOption(Options.NEW_SCAN)}
            selected={selectedOption === Options.NEW_SCAN}
            chipText="PREFERRED"
          />
          <OptionsBox
            Icon={ReuseLastStepIcon}
            title="Re-use the last step"
            description="If the patient's anatomy matches the final stage of the current aligners plan, you can create a retainer out of the last step."
            onClick={() => setSelectedOption(Options.REUSE_LAST_STEP)}
            selected={selectedOption === Options.REUSE_LAST_STEP}
            disabled={!isLastStepEligible}
          />
          {!isLastStepEligible && <IneligibleRetainerAlertCard />}
          {isLastStepEligible && (
            <Disclaimer>
              If new scans are needed, please{' '}
              <ContactSupport
                href={`mailto:${supportEmail}?subject=[REQUEST] Set up a retainer case base on new scan. Patient's name: ${patient?.firstName} ${patient?.lastName}, patient's id: ${patient?.id}`}
              >
                contact support
              </ContactSupport>{' '}
              to set up a retainer case based on new scans.
            </Disclaimer>
          )}
        </Body>
        <StyledDivider />
        <Footer>
          <CancelButton buttonType="text" onClick={onClose}>
            Cancel
          </CancelButton>
          <Button
            onClick={() => onSubmit(selectedOption!)}
            buttonType="secondary"
            disabled={!selectedOption}
            isLoading={isSubmitting}
          >
            Continue
          </Button>
        </Footer>
      </StyledModal>
    </Modal>
  );
};

export default OrderRetainerModal;
