import React from 'react';
import { Loading } from 'core/components';

import PreferenceSection from 'pages/AccountPreferencesPage/PreferenceSection';
import { ScanIntervalDaysOptions } from 'generated/legacy/graphql';

import { FormikRadioGroup } from 'components/FormikForms';

type ScanIntervalDaysProps = {
  isLoading: boolean;
  id?: string;
};

const ScanIntervalDays = ({ isLoading, id }: ScanIntervalDaysProps) => {
  const options = [
    {
      displayValue: '7-day wear schedule',
      value: ScanIntervalDaysOptions.Seven,
    },
    {
      displayValue: '14-day wear schedule',
      value: ScanIntervalDaysOptions.Fourteen,
    },
  ];

  return (
    <PreferenceSection
      title="Aligner wear schedule"
      description="Choose what schedule you want to set for your patients. Notifications in ProMonitoring will be sent accordingly."
      id={id}
    >
      {isLoading ? (
        <Loading isCentered />
      ) : (
        <FormikRadioGroup
          type="radio"
          options={options}
          name={'scanIntervalDays'}
        />
      )}
    </PreferenceSection>
  );
};

export default ScanIntervalDays;
