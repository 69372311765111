import * as Sentry from '@sentry/react';

export const openVisionSoftware = async (customerRef: string, tpId: any) => {
  const sessionId = await fetch(
    `${import.meta.env.VITE_REACT_APP_API_LEGACY}/api/v1/tokens/login/external`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: import.meta.env.VITE_REACT_APP_SOFTSMILE_USERNAME,
        password: import.meta.env.VITE_REACT_APP_SOFTSMILE_PASSWORD,
      }),
    }
  );

  await sessionId
    .json()
    .then((result) =>
      window.open(
        `vision://launch%20treatmentPlanId=${customerRef}%20sessionId=${result['sessionId']}` +
          (tpId !== null && tpId !== undefined
            ? `%20treatmentPlanVersionId=${tpId}`
            : '')
      )
    )
    .catch((error) => {
      const humanReadableError =
        "Error authenticating, so we can't open the Treatment Planning software. Please submit a bug report.";
      alert(humanReadableError);
      Sentry.captureException(error);
      console.error(error);
    });
};
