import styled from 'styled-components/macro';
import { Button } from 'core/components';
import { PageSection } from 'styles/layout.css';

export const Section = styled(PageSection)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const EmptyColumn = styled.div``;

export const UpdateJourneyContainer = styled.div`
  margin-bottom: 4rem;
`;

export const UpdateJourneyButton = styled(Button)`
  align-self: center;
`;
