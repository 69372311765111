import styled from 'styled-components/macro';
import { Box } from 'core/components';

export const Flex = styled(Box)<{
  direction?: string;
  grow?: number;
  gap?: string;
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  flex-grow: ${({ grow }) => grow};
  gap: ${({ gap }) => gap};
  .flexie-wrapper {
    flex-grow: 1;
  }
`;
