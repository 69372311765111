import styled from 'styled-components/macro';

export const SelectionBox = styled.div<{
  selected?: boolean;
  disabled?: boolean;
}>`
  ${({ theme, selected, disabled }) => `
  width: 100%;
  display: flex;
  margin: 8px 0 ;
  padding: 24px;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
  border-radius: 12px;
  border: 2px solid ${theme.colors.black40};
  user-select: none;
  svg {
    flex: none;
    margin-right: 30px;
    path {
      stroke: ${theme.colors.black40};
    }
  };
  ${
    selected &&
    `
    border: 2px solid ${theme.colors.blue50};
    svg {
      path {
        stroke: ${theme.colors.blue50};
      }
    };
  `
  }
  ${
    disabled &&
    `
    pointer-events: none;
    opacity: 50%;
  `
  }
`}
`;

export const OptionTitle = styled.div`
  color: ${({ theme }) => theme.colors.black90};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const OptionDescription = styled.div`
  color: ${({ theme }) => theme.colors.black90};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
`;
