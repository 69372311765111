import moment from 'moment';
import { CustomerType } from 'generated/legacy/graphql';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { AddressFormType } from 'components/AddressForm/types';
import { Nullable } from 'utils/types';

/**
 * Format customer name for display and for generating file names
 */
export const formatCustomerName = (
  customer: Pick<CustomerType, 'firstName' | 'lastName'>
) => {
  const { firstName, lastName } = customer;

  const [firstInitial] = firstName.split('');
  const formattedFullName = `${firstInitial}. ${lastName}`;
  const formattedLastName = lastName.replace(/\s/g, '');

  return {
    firstInitial,
    formattedFullName,
    formattedLastName,
  };
};

export const formatPhoneNumber = (phoneNumber: Nullable<string>) => {
  let valid = false;
  let parsedNumber;
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    parsedNumber = phoneUtil.parse(phoneNumber, 'US');
    valid = phoneUtil.isValidNumber(parsedNumber);
    return {
      valid: valid,
      formatedPhoneNumber: phoneUtil.format(
        parsedNumber,
        PhoneNumberFormat.NATIONAL
      ),
    };
  } catch (e) {
    valid = false;
    return {
      valid: valid,
      formatedPhoneNumber: phoneNumber,
    };
  }
};

//Compares two addresses
export const addressMatches = (
  validatedAddresses: AddressFormType[],
  input: AddressFormType
) => {
  if (validatedAddresses.length !== 1) {
    return false;
  }

  const validatedAddress: AddressFormType = validatedAddresses[0];

  if (
    input?.addressLine1?.toUpperCase() !==
    validatedAddress?.addressLine1?.toUpperCase()
  ) {
    return false;
  }

  if (
    input?.addressLine2 &&
    input.addressLine2.toUpperCase() !==
      validatedAddress?.addressLine2?.toUpperCase()
  ) {
    return false;
  }

  if (input.city?.toUpperCase() !== validatedAddress?.city?.toUpperCase()) {
    return false;
  }

  if (
    input.stateCode?.toUpperCase() !==
    validatedAddress?.stateCode?.toUpperCase()
  ) {
    return false;
  }

  if (input.zip !== validatedAddress?.zip) {
    return false;
  }

  return true;
};

export const formatCustomerInfo = (customerData: any) => {
  const {
    date_of_birth: dob,
    first_name: firstName,
    id,
    last_name: lastName,
  } = customerData;
  const [firstInitial] = firstName;
  const name = `${firstInitial}. ${lastName}`;

  return {
    id: id.toString(),
    dob,
    name,
  };
};

export const formatBirthday = (birthday: string) => {
  const age = moment().diff(birthday, 'years', false);

  return `${birthday} (${age} y/o)`;
};
