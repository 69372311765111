import { AnyAction, Middleware } from 'redux';

declare global {
  export interface Meta {
    debounce?: number;
  }
}

export const withDebounce = (debounce: number) => (action: AnyAction) => ({
  ...action,
  meta: {
    ...(action.meta || {}),
    debounce,
  },
});

const pending: Record<string, NodeJS.Timeout> = {};

const debounce: Middleware = (_store) => (next) => (action: AnyAction) => {
  if (!action.meta?.debounce) {
    next(action);
    return;
  }

  if (pending[action.type]) {
    clearTimeout(pending[action.type]);
  }

  pending[action.type] = setTimeout(() => {
    delete pending[action.type];

    next(action);
  }, action.meta.debounce);
};

export default debounce;
