import React from 'react';
import styled from 'styled-components';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import {
  NotificationVariant,
  VARIANTS,
} from 'core/components/NotificationProvider/constants';
import CloseSVG from 'core/assets/icons/close-alt.svg?react';

import {
  CloseButton,
  contentStyles,
} from 'core/components/NotificationProvider/NotificationContent/NotificationContent.css';

type Props = {
  message: string;
  onClose: any;
  variant?: NotificationVariant | null;
};

const ContentWrapper = ({ onClose, variant, ...otherProps }: Props) => (
  <SnackbarContent
    action={
      <CloseButton onClick={onClose} type="button">
        <CloseSVG role="img" />
      </CloseButton>
    }
    classes={{ message: 'message' }}
    {...otherProps}
  />
);

const NotificationContent = styled(ContentWrapper)<Props>`
  ${contentStyles};
`;

NotificationContent.defaultProps = {
  variant: VARIANTS.INFO,
};

export default NotificationContent;
