import React, { useContext } from 'react';
import { Route, RouteComponentProps, Switch, Redirect } from 'react-router-dom';

import NotFound from 'pages/NotFound';

import OrthoPrismSearch from 'pages/OrthoPrism/OrthoPrismSearch';
import OrthoPrismView from 'pages/OrthoPrism/OrthoPrismView';
import { AuthContext } from 'components/AuthProvider';
import { ACCESS_GROUPS } from 'constants/index';

const OrthoPrism = ({ match, location }: RouteComponentProps) => {
  const { checkHasAccess } = useContext(AuthContext);

  // We used to allow both ortho and candid pro access to ortho prism
  // that's not the case anymore with the release of PDP. However,
  // some users may still have ortho-prism bookmarked so we should redirect
  // until a better solution is found where we can remove this check
  if (checkHasAccess([...ACCESS_GROUPS.STAFF, ...ACCESS_GROUPS.ORTHO])) {
    return (
      <Switch>
        <Route path={match.path} exact>
          <OrthoPrismSearch />
        </Route>
        <Route
          exact
          path={[
            `${match.path}/:id`,
            `${match.path}/:id/cases`,
            `${match.path}/:id/cases/:caseRef`,
          ]}
        >
          <OrthoPrismView isQc={false} />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    );
  }

  // we can assume that the user has candid pro access
  // since we only allow ortho or candid pro access in pages.tsx
  const path = location.pathname.replace('ortho-prism', 'patient');
  return <Redirect to={path} />;
};

export default OrthoPrism;
