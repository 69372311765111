import CandidLogo from 'assets/candid-pro-no-circle-no-tm-red.svg';
import GlidewellLogo from 'assets/glidewell-logo.svg';
import OlivLogo from 'assets/oliv-logo.svg';
import LaunchNotesBackground from 'assets/launch-notes-background.png';
import GlidewellLoginRightPanel from 'assets/GlidewellLoginRightPanel.jpg';
import OlivLoginRightPanel from 'assets/OlivLoginRightPanel.jpg';
import GlidewellFavicon from 'assets/favicon-glidewell.ico';
import CandidFavicon from 'assets/favicon-candid.ico';
import OlivFavicon from 'assets/favicon-oliv.ico';
import refinementImgSrc from 'assets/orderItems/refinement.png';
import alignerCandidProImgSrc from 'assets/orderItems/aligner-candidpro.png';
import alignerPromonitoringImgSrc from 'assets/orderItems/aligner-promonitoring.png';
import retainerImgSrc from 'assets/orderItems/retainer.png';
import RetainerCandidPng from 'assets/products/candid/retainers.png';
import RetainerGlidewellPng from 'assets/products/glidewell/retainers.png';
import { theme } from 'core/components';
import { Nullable } from 'utils/types';

// Eventually these will be deprecated and replaced when the Brand Service API is live (pushed to version 2 of the white labellling project)
// For now this will serve as a temporary solution to have Brand information available pre-authentication and should be kept in sync the Brands table
export type SupportedBrand = 'candid_pro' | 'glidewell' | 'oliv';

export const CANDID_BRAND_NAME: SupportedBrand = 'candid_pro';
export const GLIDEWELL_BRAND_NAME: SupportedBrand = 'glidewell';
export const OLIV_BRAND_NAME: SupportedBrand = 'oliv';

// REFACTOR
export const ProductBrand = {
  glidewell: 'PRO_MONITORING',
  candid_pro: 'CANDID',
  generic: 'GENERIC',
};

const BRAND_ORTHO_BIOS: Record<SupportedBrand, Record<string, string>> = {
  candid_pro: {
    'ed@candidco.com':
      'https://candidpro.zendesk.com/hc/en-us/articles/7548443118871-Treatment-Plans-Notes-Revisions-Scheduling-a-Call-with-an-Orthodontist#h_01H43T9FNCXTXK51G15APSSQVW',
    'dr.michael.kabot@candidco.com':
      'https://candidpro.zendesk.com/hc/en-us/articles/7548443118871-Treatment-Plans-Notes-Revisions-Scheduling-a-Call-with-an-Orthodontist#h_01H43T9N28F1PC6XA1PTBPQXV3',
    'dr.perry.stevenson@candidco.com':
      'https://candidpro.zendesk.com/hc/en-us/articles/7548443118871-Treatment-Plans-Notes-Revisions-Scheduling-a-Call-with-an-Orthodontist#h_01H43T9WQN3SY4VZ6ZZC7TVY4K',
    'dr.ammar.siddiqi@candidco.com':
      'https://candidpro.zendesk.com/hc/en-us/articles/7548443118871-Treatment-Plans-Notes-Revisions-Scheduling-a-Call-with-an-Orthodontist#h_01H7JWA8JQ9DKN0GYKM6KFTGBM',
  },
  glidewell: {
    'ed@candidco.com':
      'https://promonitoring.zendesk.com/hc/en-us/articles/23185054845719-Orthodontist-Biographies#h_01HWTCZBVYDH75G2H9H7C6XD59',
    'dr.michael.kabot@candidco.com':
      'https://promonitoring.zendesk.com/hc/en-us/articles/23185054845719-Orthodontist-Biographies#h_01HWTCZKT10XP53NKD3TW52JBQ',
    'dr.perry.stevenson@candidco.com':
      'https://promonitoring.zendesk.com/hc/en-us/articles/23185054845719-Orthodontist-Biographies#h_01HWTD02E3962NHX5JZN8RG47G',
    'dr.ammar.siddiqi@candidco.com':
      'https://promonitoring.zendesk.com/hc/en-us/articles/23185054845719-Orthodontist-Biographies#h_01HWTD065AM95XQJ320SQD13J7',
  },
  oliv: {},
};

type Support = {
  email: string;
  phoneNumbers: {
    phoneNumber: string;
    subtext: string;
  }[];
};

type BrandInformation = {
  billingSupport: Nullable<Support>;
  brandId: number;
  caseSupport: Support;
  favicon: string;
  helpCenterUrl: string;
  label: string;
  loginBackgroundImgSrc: string;
  logoSrc: string;
  marketingEmail: string;
  monitoringLabel: string;
  name: SupportedBrand;
  showLaunchNotes: boolean;
  showLoyaltyInfo: boolean;
  showZendesk: boolean;
  subheader: Nullable<string>;
  url: string;
};

type BrandSupportedFeatures = {
  PvsScanFlow: boolean;
  DisplayPricesToProviders: boolean;
};

//////////////////////////
// Brand Domains Pages Settings
// These settings are purely aesthetic and are used to customize the look and feel of the pages based on the brand
//////////////////////////
const BRAND_DOMAIN_SETTINGS: Record<SupportedBrand, BrandInformation> = {
  candid_pro: {
    billingSupport: null,
    brandId: 1,
    caseSupport: {
      email: 'doctor@candidco.com',
      phoneNumbers: [
        {
          phoneNumber: '(855) 640-8606',
          subtext: 'call or text',
        },
      ],
    },
    favicon: CandidFavicon,
    helpCenterUrl: 'https://candidpro.zendesk.com/hc/en-us',
    label: 'CandidPro',
    loginBackgroundImgSrc: LaunchNotesBackground,
    logoSrc: CandidLogo,
    marketingEmail: 'marketing@candidco.com',
    monitoringLabel: 'CandidMonitoring',
    name: CANDID_BRAND_NAME,
    showLaunchNotes: true,
    showLoyaltyInfo: true,
    showZendesk: true,
    subheader: null,
    url: 'review.candidco.com',
  },
  glidewell: {
    billingSupport: {
      email: 'mail@glidewelldental.com',
      phoneNumbers: [
        {
          phoneNumber: '(800) 854-7256',
          subtext: 'USA',
        },
        {
          phoneNumber: '(888) 278-0414',
          subtext: 'Canada',
        },
      ],
    },
    brandId: 2,
    caseSupport: {
      email: 'doctor@promonitoring.ai',
      phoneNumbers: [
        {
          phoneNumber: '+1 (888) 335-4696',
          subtext: 'call or text',
        },
      ],
    },
    favicon: GlidewellFavicon,
    helpCenterUrl: 'https://promonitoring.zendesk.com/hc/en-us',
    label: 'Glidewell',
    loginBackgroundImgSrc: GlidewellLoginRightPanel,
    logoSrc: GlidewellLogo,
    marketingEmail: 'marketing@promonitoring.ai',
    monitoringLabel: 'ProMonitoring',
    name: GLIDEWELL_BRAND_NAME,
    showLaunchNotes: false,
    showLoyaltyInfo: false,
    showZendesk: false,
    subheader: 'Log into the provider portal',
    url: 'glidewell.promonitoring.ai',
  },
  oliv: {
    billingSupport: null, // TODO: Oliv brand stub
    brandId: 3,
    caseSupport: {
      email: 'support@olivortho.com',
      phoneNumbers: [], // TODO: Oliv brand stub
    },
    favicon: OlivFavicon,
    helpCenterUrl: 'https://oliv.zendesk.com/hc/en-us',
    label: 'Oliv',
    loginBackgroundImgSrc: OlivLoginRightPanel,
    logoSrc: OlivLogo,
    marketingEmail: 'marketing@olivortho.com',
    monitoringLabel: 'DentalMonitoring',
    name: OLIV_BRAND_NAME,
    showLaunchNotes: false,
    showLoyaltyInfo: false,
    showZendesk: true,
    subheader: null,
    url: 'portal.olivortho.com',
  },
} as const;

//////////////////////////
// Brand Supported Features
// These are the functional feature that are supported by the brand of the user or viewing customer
//////////////////////////
const BRAND_SUPPORTED_FEATURES: Record<SupportedBrand, BrandSupportedFeatures> =
  {
    candid_pro: {
      PvsScanFlow: true,
      DisplayPricesToProviders: true,
    },
    glidewell: {
      PvsScanFlow: false,
      DisplayPricesToProviders: false,
    },
    oliv: {
      // these are likely mostly wrong; copied wholesale from the `candid_pro` brand
      PvsScanFlow: true,
      DisplayPricesToProviders: true,
    },
  };

type BrandTheme = {
  brandChip: {
    props: {
      background: string;
      foreground: string;
      label: string;
    };
  };
};

const BRAND_THEME: Record<SupportedBrand, BrandTheme> = {
  glidewell: {
    brandChip: {
      props: {
        background: theme.colors.text70,
        foreground: 'white',
        label: 'ProMonitoring',
      },
    },
  },
  candid_pro: {
    brandChip: {
      props: {
        background: theme.colors.red50,
        foreground: 'white',
        label: 'CandidPro',
      },
    },
  },
  oliv: {
    brandChip: {
      props: {
        background: theme.colors.green50, // TODO: Oliv brand stub
        foreground: 'white', // TODO: Oliv brand stub
        label: 'Oliv', // TODO: Oliv brand stub
      },
    },
  },
};

export const DEFAULT_LINKS = {
  PHOTO_EXPECTATIONS:
    'https://candidpro.zendesk.com/hc/en-us/articles/7440183329687-CandidPro-Diagnostic-Photo-and-Scan-Guide',
  SCANS_EXPORT_STL:
    'https://candidpro.zendesk.com/hc/en-us/articles/9191905370647-Top-Scanner-Instructions-Resources',
  SCANS_UNZIP_EXPORTED_SCANS:
    'https://candidpro.zendesk.com/hc/en-us/articles/10732444716439',
  SCANS_LAB_REQUIREMENTS:
    'https://candidpro.zendesk.com/hc/en-us/articles/7542528616471-CandidPro-Diagnostic-Impression-Guide',
  TREATMENT_GOALS_PROTOCOLS:
    'https://candidpro.zendesk.com/hc/en-us/articles/7542873494295',
};

//////////////////////////
// Brand Checkout SKUs
// These are the SKUs used to create the products in the checkout flow, these SKU will live here until the Order Revamp project is complete
//////////////////////////

type CheckoutSkusInfo = {
  sku: string;
  description: string;
  price: number;
  imageSrc: string;
  providerFacingProductName: string;
};

type CheckoutSkus = {
  lastStepAligner: CheckoutSkusInfo;
  lastStepRetainer: CheckoutSkusInfo;
  retainer: CheckoutSkusInfo;
  refinement: CheckoutSkusInfo;
  refinementGood: CheckoutSkusInfo;
  aligner: CheckoutSkusInfo;
  alignerGood: CheckoutSkusInfo;
  replacement: CheckoutSkusInfo;
};

const SKUs: Record<SupportedBrand, CheckoutSkus> = {
  candid_pro: {
    lastStepAligner: {
      sku: 'RT00001',
      description: 'Last Step Aligner',
      price: 9900,
      imageSrc: retainerImgSrc,
      providerFacingProductName: 'Last step aligner',
    },
    lastStepRetainer: {
      sku: 'RET-REORDER',
      description: 'Last Step Retainer',
      price: 9900,
      imageSrc: retainerImgSrc,
      providerFacingProductName: 'Last step retainer',
    },
    retainer: {
      sku: 'RET-NEW',
      description: 'Retainer',
      price: 9900,
      imageSrc: RetainerCandidPng,
      providerFacingProductName: 'Retainer',
    },
    refinement: {
      sku: 'REFINE0003',
      description:
        'Start another round of aligners to ensure a perfectly aligned smile. Our team will determine how many more trays are needed.',
      price: 40000,
      imageSrc: refinementImgSrc,
      providerFacingProductName: 'Refinements',
    },
    refinementGood: {
      sku: 'ADDITIONAL-ALIGNER-GOOD',
      description: 'The refinement good for manufacturing',
      price: 0,
      imageSrc: refinementImgSrc,
      providerFacingProductName: 'Refinements',
    },
    aligner: {
      sku: 'AK00007',
      description:
        'CandidPro’s proprietary down-to-the-micron manufacturing process creates some of the best clear aligners in the industry.',
      price: 190000,
      imageSrc: alignerCandidProImgSrc,
      providerFacingProductName: 'Comprehensive aligners',
    },
    alignerGood: {
      sku: 'AKG00001',
      description: 'The aligner good for manufacturing',
      price: 0,
      imageSrc: alignerCandidProImgSrc,
      providerFacingProductName: 'Comprehensive aligners',
    },
    replacement: {
      sku: 'AKREPL0001',
      description:
        'If a patient misplaced one or more aligner trays, you can easily order replacements.',
      price: 4950,
      imageSrc: '', //Not used, and needs to be refactored to usue common photo
      providerFacingProductName: 'Replacement aligners',
    },
  },
  glidewell: {
    lastStepAligner: {
      sku: 'PERMAFORM',
      description: 'Last Step Aligner',
      price: 9900,
      imageSrc: '',
      providerFacingProductName: 'Last step aligner',
    },
    lastStepRetainer: {
      sku: 'PERMAFORM-REORDER',
      description: 'Last Step Retainer',
      price: 9900,
      imageSrc: retainerImgSrc,
      providerFacingProductName: 'Last step retainer',
    },
    retainer: {
      sku: 'PERMAFORM-NEW',
      description: 'Retainer',
      price: 9900,
      imageSrc: RetainerGlidewellPng,
      providerFacingProductName: 'Retainer',
    },
    refinement: {
      sku: 'GL-REFINE02',
      description:
        'Start another round of aligners to ensure a perfectly aligned smile. Our team will determine how many more trays are needed.',
      price: 40000,
      imageSrc: refinementImgSrc,
      providerFacingProductName: 'Refinements',
    },
    refinementGood: {
      sku: 'GL-ADDITIONAL-ALIGNER-GOOD',
      description: 'The refinement good for manufacturing',
      price: 0,
      imageSrc: refinementImgSrc,
      providerFacingProductName: 'Refinements',
    },
    aligner: {
      sku: 'GL-ALIGNER-KIT',
      description:
        'Promonitoring’s proprietary down-to-the-micron manufacturing process creates some of the best clear aligners in the industry.',
      price: 99900,
      imageSrc: alignerPromonitoringImgSrc,
      providerFacingProductName: 'Comprehensive aligners',
    },
    alignerGood: {
      sku: 'GL-AKG00001',
      description: 'The aligner good for manufacturing.',
      price: 0,
      imageSrc: alignerPromonitoringImgSrc,
      providerFacingProductName: 'Comprehensive aligners',
    },
    replacement: {
      sku: 'GL-AKREPL0001',
      description:
        'If a patient misplaced one or more aligner trays, you can easily order replacements.',
      price: 4950,
      imageSrc: '', //Not used, and needs to be refactored to usue common photo
      providerFacingProductName: 'Replacement aligners',
    },
  },
  oliv: {
    // these are likely mostly wrong; copied wholesale from the `candid_pro` brand
    lastStepAligner: {
      sku: 'RT00001',
      description: 'Last Step Aligner',
      price: 9900,
      imageSrc: retainerImgSrc,
      providerFacingProductName: 'Last step aligner',
    },
    lastStepRetainer: {
      sku: 'RET-REORDER',
      description: 'Last Step Retainer',
      price: 9900,
      imageSrc: retainerImgSrc,
      providerFacingProductName: 'Last step retainer',
    },
    retainer: {
      sku: 'RET-NEW',
      description: 'Retainer',
      price: 9900,
      imageSrc: RetainerCandidPng,
      providerFacingProductName: 'Retainer',
    },
    refinement: {
      sku: 'REFINE0003',
      description:
        'Start another round of aligners to ensure a perfectly aligned smile. Our team will determine how many more trays are needed.',
      price: 40000,
      imageSrc: refinementImgSrc,
      providerFacingProductName: 'Refinements',
    },
    refinementGood: {
      sku: 'ADDITIONAL-ALIGNER-GOOD',
      description: 'The refinement good for manufacturing',
      price: 0,
      imageSrc: refinementImgSrc,
      providerFacingProductName: 'Refinements',
    },
    aligner: {
      sku: 'AK00007',
      description:
        'CandidPro’s proprietary down-to-the-micron manufacturing process creates some of the best clear aligners in the industry.',
      price: 190000,
      imageSrc: alignerCandidProImgSrc,
      providerFacingProductName: 'Comprehensive aligners',
    },
    alignerGood: {
      sku: 'AKG00001',
      description: 'The aligner good for manufacturing',
      price: 0,
      imageSrc: alignerCandidProImgSrc,
      providerFacingProductName: 'Comprehensive aligners',
    },
    replacement: {
      sku: 'AKREPL0001',
      description:
        'If a patient misplaced one or more aligner trays, you can easily order replacements.',
      price: 4950,
      imageSrc: '', //Not used, and needs to be refactored to usue common photo
      providerFacingProductName: 'Replacement aligners',
    },
  },
};

function convertToBrand(brand: string): SupportedBrand;
function convertToBrand(
  brand: Nullable<string>,
  defaultTo: SupportedBrand
): SupportedBrand;
function convertToBrand(brand: Nullable<string>, defaultTo?: SupportedBrand) {
  return brand ? (brand as SupportedBrand) : defaultTo;
}

export { convertToBrand };

export const getBrandFromDomain = (): SupportedBrand =>
  (Object.entries(BRAND_DOMAIN_SETTINGS).find(([, value]) =>
    window.location.hostname.includes(value.url)
  )?.[0] as SupportedBrand) ?? CANDID_BRAND_NAME;

export const getBrandOrthoBios = (brandName: SupportedBrand) =>
  BRAND_ORTHO_BIOS[brandName];

export const getBrandSettings = (brandName: SupportedBrand) =>
  BRAND_DOMAIN_SETTINGS[brandName];

export const getBrandSKUs = (brandName: SupportedBrand) => SKUs[brandName];

export const getBrandTheme = (brandName: SupportedBrand) =>
  BRAND_THEME[brandName];

export const getBrandSupportedFeatures = (brandName: SupportedBrand) => {
  return BRAND_SUPPORTED_FEATURES[brandName];
};
