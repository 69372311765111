import { AuthContext } from 'components/AuthProvider';
import { useContext } from 'react';
import { PartnerOrgType } from 'generated/legacy/graphql';

export function usePartnerOrgs(): {
  partnerOrgs: Pick<PartnerOrgType, 'name' | 'logoUrl'>[];
} {
  const { userInfo } = useContext(AuthContext);

  const partnerOrgs =
    userInfo?.doctor?.practices
      ?.map((practice) => practice?.partnerOrgAccount?.partnerOrg)
      .filter((partnerOrg): partnerOrg is PartnerOrgType => !!partnerOrg) || [];

  return {
    partnerOrgs,
  };
}
