import { fetchApi } from 'utils/api';
import { RestMethod } from 'utils/api-types';
import { CaseTypes } from 'generated/legacy/graphql';

type TestUserParams = {
  first_name?: string;
  last_name?: string;
  email?: string;
  case_type?: CaseTypes;
  doctor_id?: string;
  treatment_goal_type?: string;
  upload_photos?: boolean;
  upload_scans?: boolean;
  upload_xrays?: boolean;
  submit_to_ortho?: boolean;
  submit_case?: boolean;
  is_gen2?: boolean;
  require_ortho_review?: boolean;
};

export const createTestUser = async (params: TestUserParams) => {
  try {
    const res = await fetchApi(
      '/api/v1/test-user-creator',
      RestMethod.POST,
      params
    );
    return res;
  } catch (err: any) {
    // logging for now, will show more helpful errors when reproducing error
    console.error(err);
    throw new Error(
      err.error ? String(err.error) : 'Could not create test user'
    );
  }
};
