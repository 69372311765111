import React, { useState, useEffect } from 'react';
import { captureException } from '@sentry/browser';

import DEFAULT_TOOTH_ICON from 'assets/universalToothChartIcons/1.svg?react';
import { ToothWrapper } from 'components/ToothChart/DynamicTooth.css';

import { DentalNotationOptions } from 'generated/legacy/graphql';
import {
  convertFromPalmerToUniversal,
  convertFromFDIToUniversal,
  ToothCheckboxProps,
} from 'components/ToothChart/ToothChart';

const getToothIconAsset = async ({
  quadrant,
  index,
  notation = DentalNotationOptions.Universal,
}: {
  quadrant: number;
  index: number;
  notation?: DentalNotationOptions;
}) => {
  let universalIndex = index;
  if (notation === DentalNotationOptions.Palmer) {
    universalIndex = convertFromPalmerToUniversal(quadrant, index);
  } else if (notation === DentalNotationOptions.Fdi) {
    universalIndex = convertFromFDIToUniversal(quadrant, index);
  }
  // Dynamic import limitations:
  // https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#imports-must-start-with--or-
  const path = `../../assets/universalToothChartIcons/${universalIndex}.svg?react`;

  try {
    let asset = DEFAULT_TOOTH_ICON;

    asset = await import(path).then((module) => module.default);

    return asset;
  } catch (error) {
    captureException(`Missing tooth icon asset. Path: ${path}`);

    return DEFAULT_TOOTH_ICON;
  }
};

const DynamicTooth = ({
  checked,
  quadrant,
  index,
  notation = DentalNotationOptions.Universal,
  readOnly,
  onChange,
}: ToothCheckboxProps) => {
  const [ToothIcon, setToothIcon] = useState<React.ComponentType>(
    React.Fragment
  );

  useEffect(() => {
    const initializeToothIcon = async () => {
      const toothIcon = await getToothIconAsset({
        quadrant,
        index: Number(index),
        notation,
      });

      setToothIcon(() => toothIcon);
    };

    initializeToothIcon();
  }, []);

  if (!ToothIcon) {
    return null;
  }

  return (
    <ToothWrapper
      onClick={() => {
        onChange(quadrant, parseInt(index), !checked);
      }}
      isSelected={checked}
      readOnly={readOnly}
    >
      {<ToothIcon />}
    </ToothWrapper>
  );
};

export default DynamicTooth;
