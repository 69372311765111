import useZendesk from 'hooks/useZendesk';
import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
`;

type ContactSupportProps = {
  text: string;
};
const ContactSupport = ({ text }: ContactSupportProps) => {
  const {
    showZendesk,
    openSupportPage,
    isWidgetOpen,
    handleCloseWidget,
    handleOpenWidget,
  } = useZendesk();

  return (
    <StyledSpan
      onClick={() => {
        if (!showZendesk) {
          openSupportPage();
        }
        return isWidgetOpen ? handleCloseWidget() : handleOpenWidget();
      }}
    >
      {text}
    </StyledSpan>
  );
};

export default ContactSupport;
