import { ACCESS_GROUPS } from 'constants/index';
import { AuthContext } from 'components/AuthProvider';
import { useContext } from 'react';

/*
 * function that checks if the user is a candid ortho user.
 * @returns whether the user is in ortho
 */
export function useIsOrtho(): boolean {
  const { checkHasAccess } = useContext(AuthContext);

  return checkHasAccess && checkHasAccess(ACCESS_GROUPS.ORTHO);
}
