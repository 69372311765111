import moment from 'moment';
import React from 'react';
import {
  Skeleton,
  Table,
  Text,
  ProgressBar,
  mediaQueries,
} from 'core/components';
import {
  TaskTableProps,
  ActionButtonProps,
  MaterialTypeMap,
} from 'pages/ActionItems/types';
import {
  ActionStyledButton,
  ActionLink,
  MaterialIconRowContainer,
  MaterialIconContainer,
  GreyTooltip,
  ToolTipWithArrow,
} from 'pages/ActionItems/ActionItems.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CameraSVG from 'assets/camera.svg?react';
import ScansSVG from 'assets/scans.svg?react';
import XraySVG from 'assets/xray.svg?react';
import { useGetMultipleStatusesTasks } from 'pages/ActionItems/actionItemsSlice';
import MissingMaterialsTooltip from 'pages/ActionItems/MissingMaterialsTooltip';
import { getCaseTypeLabel } from 'utils/case';
import { MUIDataTableOptions } from 'mui-datatables';

const DATE_FORMAT = 'll'; // "MMM. d, YYYY"

const materialNameMap: MaterialTypeMap = {
  //Materials from core
  scans: {
    title: 'Scans',
    icon: <ScansSVG style={{ marginRight: '0.5rem' }} aria-hidden />,
  },
  xrays: {
    title: 'X-rays',
    icon: <XraySVG style={{ marginRight: '0.5rem' }} aria-hidden />,
  },
  //Materials from candid-app: TODO: remove
  studio_scan: {
    title: 'Scans',
    icon: <ScansSVG style={{ marginRight: '0.5rem' }} aria-hidden />,
  },
  x_ray_set: {
    title: 'X-rays',
    icon: <XraySVG style={{ marginRight: '0.5rem' }} aria-hidden />,
  },
  photos: {
    title: 'Photos',
    icon: <CameraSVG style={{ marginRight: '0.5rem' }} aria-hidden />,
  },
};

type SortObj = {
  data: string[];
};

const ActionButton = ({ name, path }: ActionButtonProps) => {
  const isMobile = useMediaQuery(mediaQueries.mobile);

  return (
    <ActionLink data-testid="action-link" to={path}>
      <ActionStyledButton
        isShort={!isMobile}
        isFullWidth={isMobile}
        buttonType="secondary-outline"
      >
        {name}
      </ActionStyledButton>
    </ActionLink>
  );
};

const skeletonLoadingCell = {
  name: '',
  label: '',
  options: {
    customBodyRender: () => {
      return <Skeleton width="80%" />;
    },
    customHeadLabelRender: () => {
      return <Skeleton width={50} />; // Width should be % but there have been a problem with rendering so stick with absolute number
    },
  },
};

const renderIconsForMaterials = (
  materials: (string | null)[],
  tabletSize: boolean
) => {
  if (materials.length === 3) {
    return (
      <MaterialIconContainer>
        <ScansSVG style={{ marginRight: '1rem' }} aria-hidden />
        <CameraSVG style={{ marginRight: '1rem' }} aria-hidden />
        <XraySVG style={{ marginRight: '1rem' }} aria-hidden />
        {tabletSize && 'All'}
      </MaterialIconContainer>
    );
  }

  return materials.map((material: string | null) => (
    <MaterialIconContainer>
      {material && materialNameMap[material].icon}
      {tabletSize && material && materialNameMap[material].title}
    </MaterialIconContainer>
  ));
};

const TaskTable = ({ journeyStatus, tasks, isFetching }: TaskTableProps) => {
  const { data } = useGetMultipleStatusesTasks();
  const multipleStatusesTasks = data ?? [];

  const desktopSize = useMediaQuery('(min-width:960px)');
  const tabletSize = useMediaQuery('(min-width:1360px)');
  const mobileSize = useMediaQuery('(max-width:600px)');

  const journeyStatusMapping = {
    tp_ready_for_review: {
      extraColumn: [
        {
          name: 'created',
          label: 'Started on',
          options: {
            setCellProps: () => {
              return (
                desktopSize && {
                  // Fix header width for desktop size
                  style: {
                    width: '18%',
                  },
                }
              );
            },
            customBodyRender: (value: string) =>
              value ? moment(value).format(DATE_FORMAT) : null,
          },
        },
        {
          name: 'lastUpdated',
          label: 'Plan sent on',
          options: {
            setCellProps: () => {
              return (
                desktopSize && {
                  style: {
                    width: '17%',
                  },
                }
              );
            },
            customBodyRender: (value: string) =>
              value ? moment(value).format(DATE_FORMAT) : null,
          },
        },
        {
          name: 'actionNeeded',
          label: !mobileSize ? 'Action needed' : ' ',
          options: {
            sort: false,
            setCellProps: () => {
              return (
                desktopSize && {
                  style: {
                    width: '15%',
                  },
                }
              );
            },
            customBodyRender: (_value: string, tableMeta: any) => {
              const patient_id = tableMeta.rowData[0];
              const case_ref = tableMeta.rowData[1];
              const path = `patient/${patient_id}/cases/${case_ref}`;
              return <ActionButton name={'Review'} path={path} />;
            },
          },
        },
      ],
    },
    incomplete_submission: {
      extraColumn: [
        {
          name: 'isGen2',
          label: 'Is Gen2',
          options: {
            display: false,
          },
        },
        {
          name: 'materials',
          label: 'Progress',
          options: {
            setCellProps: () => {
              return (
                desktopSize && {
                  style: {
                    width: '18%',
                  },
                }
              );
            },
            sortCompare:
              (order: string) =>
              (l1: SortObj, l2: SortObj): number =>
                (l1.data.length - l2.data.length) * (order === 'asc' ? 1 : -1),
            customBodyRender: (value: string[], tableMeta: any) => {
              const isCaseGen2 = tableMeta.rowData[6];
              const totalMaterialCount = isCaseGen2 ? 6 : 5;
              return (
                <ToolTipWithArrow
                  title={
                    <MissingMaterialsTooltip
                      materials={value}
                      isCaseGen2={isCaseGen2}
                    />
                  }
                  placement="top"
                  arrow
                >
                  <div>
                    <ProgressBar
                      style={{ height: '6px' }}
                      totalValue={totalMaterialCount}
                      currentValue={totalMaterialCount - value.length}
                    />
                  </div>
                </ToolTipWithArrow>
              );
            },
          },
        },
        {
          name: 'lastUpdated',
          label: 'Last updated',
          options: {
            setCellProps: () => {
              return (
                desktopSize && {
                  style: {
                    width: '17%',
                  },
                }
              );
            },
            customBodyRender: (value: string) => {
              return value ? moment(value).format(DATE_FORMAT) : null;
            },
          },
        },
        {
          name: 'actionNeeded',
          label: !mobileSize ? 'Action needed' : ' ',
          options: {
            sort: false,
            setCellProps: () => {
              return (
                desktopSize && {
                  style: {
                    width: '15%',
                  },
                }
              );
            },
            customBodyRender: (_value: any, tableMeta: any) => {
              const patient_id = tableMeta.rowData[0];

              return (
                <ActionButton
                  name={'Continue'}
                  path={`/patient/${patient_id}/case-creator`}
                />
              );
            },
          },
        },
      ],
    },
    materials_rejected: {
      extraColumn: [
        {
          name: 'isGen2',
          label: 'Is Gen2',
          options: {
            display: false,
          },
        },
        {
          name: 'materials',
          label: 'Materials rejected',
          options: {
            sort: false,
            setCellProps: () => {
              return (
                desktopSize && {
                  style: {
                    width: '18%',
                  },
                }
              );
            },
            customBodyRender: (value: string[]) => (
              <MaterialIconRowContainer>
                {renderIconsForMaterials(value, tabletSize)}
              </MaterialIconRowContainer>
            ),
          },
        },
        {
          name: 'lastUpdated',
          label: 'Last updated',
          options: {
            setCellProps: () => {
              return (
                desktopSize && {
                  style: {
                    width: '17%',
                  },
                }
              );
            },
            customBodyRender: (value: string) => {
              return value ? moment(value).format(DATE_FORMAT) : null;
            },
          },
        },
        {
          name: 'actionNeeded',
          label: !mobileSize ? 'Action needed' : ' ',
          options: {
            sort: false,
            setCellProps: () => {
              return (
                desktopSize && {
                  style: {
                    width: '15%',
                  },
                }
              );
            },
            customBodyRender: (_value: any, tableMeta: any) => {
              const patient_id = tableMeta.rowData[0];

              return (
                <ActionButton
                  name={'Resubmit'}
                  path={`/patient/${patient_id}/case-creator`}
                />
              );
            },
          },
        },
      ],
    },
    needs_clarification: {
      extraColumn: [
        {
          name: 'isGen2',
          label: 'Is Gen2',
          options: {
            display: false,
          },
        },
        {
          name: 'materials',
          label: 'Materials involved',
          options: {
            sort: false,
            setCellProps: () => {
              return (
                desktopSize && {
                  style: {
                    width: '18%',
                  },
                }
              );
            },
            customBodyRender: (value: string[]) => (
              <MaterialIconRowContainer>
                {renderIconsForMaterials(value, tabletSize)}
              </MaterialIconRowContainer>
            ),
          },
        },
        {
          name: 'lastUpdated',
          label: 'Last updated',
          options: {
            setCellProps: () => {
              return (
                desktopSize && {
                  style: {
                    width: '17%',
                  },
                }
              );
            },
            customBodyRender: (value: string) => {
              return value ? moment(value).format(DATE_FORMAT) : null;
            },
          },
        },
        {
          name: 'actionNeeded',
          label: !mobileSize ? 'Action needed' : ' ',
          options: {
            sort: false,
            setCellProps: () => {
              return (
                desktopSize && {
                  style: {
                    width: '15%',
                  },
                }
              );
            },
            customBodyRender: (_value: any, tableMeta: any) => {
              const patient_id = tableMeta.rowData[0];
              return (
                <ActionButton
                  name={'Clarify'}
                  path={`/patient/${patient_id}/case-creator`}
                />
              );
            },
          },
        },
      ],
    },
  };

  const baseDefaultColumns = [
    {
      name: 'customerId',
      label: 'ID',
      options: {
        setCellProps: () => {
          return (
            desktopSize && {
              style: {
                width: '10%',
              },
            }
          );
        },
        customBodyRender: (value: string, tableMeta: any) => {
          const caseRef = tableMeta.rowData[1];
          const patientId = tableMeta.rowData[0];
          let idLink;
          if (journeyStatus === 'tp_ready_for_review') {
            idLink = `patient/${patientId}/cases/${caseRef}`;
          } else {
            idLink = `/patient/${patientId}/case-creator`;
          }

          return (
            <>
              <ActionLink data-testid="action-link" to={idLink}>
                {value}
              </ActionLink>{' '}
              {multipleStatusesTasks.includes(value.toString()) && (
                <GreyTooltip
                  title={
                    <Text variant={'small'}>
                      This patient is in multiple statuses
                    </Text>
                  }
                  placement="top"
                  data-testid="action-item-table"
                />
              )}
            </>
          );
        },
      },
    },
    {
      name: 'caseRef',
      label: 'Case Ref',
      options: {
        display: false, // We do not display this but we want this data to populate the review action url
      },
    },
  ];
  const additionalDefaultColumns = [
    {
      name: 'patient',
      label: 'Patient',
      options: {
        setCellProps: () => {
          return (
            desktopSize && {
              style: {
                width: '20%',
              },
            }
          );
        },
        customBodyRender: (_value: any, tableMeta: any) => {
          const firstName = tableMeta['rowData'][3];
          const lastName = tableMeta['rowData'][4];
          return `${firstName} ${lastName}`;
        },
        sortCompare:
          (order: string) =>
          (a: any, b: any): number => {
            const ascending = a?.rowData[3] > b?.rowData[3] ? 1 : -1;
            return order === 'asc' ? ascending : -1 * ascending;
          },
      },
    },
    {
      name: 'firstName',
      label: 'First name',
      options: {
        display: false, // We do not display this but we want this data to populate the Patient name
      },
    },
    {
      name: 'lastName',
      label: 'Last name',
      options: {
        display: false, // We do not display this but we want this data to populate the Patient name
      },
    },
    {
      name: 'caseType',
      label: 'Case Type',
      options: {
        setCellProps: () => {
          return (
            desktopSize && {
              style: {
                width: '20%',
              },
            }
          );
        },
        customBodyRender: (value: string) => {
          return getCaseTypeLabel(value ?? '');
        },
      },
    },
  ];

  const defaultColumns = [...baseDefaultColumns, ...additionalDefaultColumns];

  const journeyMapping = journeyStatusMapping[journeyStatus];
  const SKELETON_LOADING_ROWS = 4;
  const columns = isFetching
    ? Array(SKELETON_LOADING_ROWS).fill(skeletonLoadingCell)
    : [...defaultColumns, ...journeyMapping.extraColumn];

  const options: MUIDataTableOptions = {
    selectableRows: 'none',
    fixedHeader: false,
    pagination: !isFetching,
    sortOrder: {
      name: 'lastUpdated',
      direction: 'asc',
    },
    responsive: mobileSize ? 'simple' : isFetching ? 'simple' : 'vertical',
  };
  return (
    <Table
      data={isFetching ? Array(SKELETON_LOADING_ROWS).fill({}) : tasks}
      columns={columns}
      options={options}
      card={mobileSize}
    />
  );
};

export default TaskTable;
