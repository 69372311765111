import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { colors } from 'core/components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Container = styled.label<{
  alignContent?: string;
  helperImageSrc?: string;
  helperImgBackgroundSize?: string;
  isHorizontal?: boolean;
  isDisabled?: boolean;
  isDropping?: boolean;
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.black70};
  cursor: pointer;
  background: inherit;
  transition:
    color 0.15s,
    background-color 0.15s;

  &:hover {
    background-color: ${colors.blue10};
  }

  svg {
    flex: none;
    stroke: ${colors.blue50};
  }

  ${({ helperImageSrc, helperImgBackgroundSize }) =>
    helperImageSrc &&
    css`
      background-image: url(${helperImageSrc});
      ${helperImgBackgroundSize &&
      `background-size: ${helperImgBackgroundSize};`}
      background-repeat: no-repeat;
      background-position: center;

      svg {
        position: absolute;
        top: 1rem;
        left: 1rem;
      }
    `};

  ${({ isHorizontal }) =>
    isHorizontal &&
    css`
      flex-direction: row;
      justify-content: center;
      padding-left: 1rem;
    `};

  ${({ isDropping }) =>
    isDropping
      ? css`
          color: ${colors.black90};
          background-color: ${colors.blue10};
        `
      : css`
          &:hover {
            color: ${colors.black90};
          }
        `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${colors.black50};
      pointer-events: none;

      svg {
        stroke: ${colors.black50};
      }
    `};
`;

export const EmptyText = styled.p<{ isHorizontal: boolean }>`
  margin: ${({ isHorizontal }) =>
    isHorizontal ? '0.125rem 0 0 0.5rem' : '0.5rem 0 0'};
`;

export const ImageTextWrap = styled.div`
  position: absolute;
  text-align: center;

  ${EmptyText} {
    color: ${({ theme }) => theme.colors.text90};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;
