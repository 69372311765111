import styled from 'styled-components';

export const Label = styled.div`
  margin-bottom: 0.2rem;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const ErrorText = styled.div`
  margin-top: 0.3rem;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.palette.error.main};
`;
