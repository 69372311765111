import { AlertCard, type } from 'core/components';
import React from 'react';
import moment from 'moment';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  text-decoration: underline;
  font-weight: ${type.weight.bold};
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.black90};
`;

type Props = {
  evaluationPeriodEnd: string;
  caseCount: number;
  interval: number;
  currentTierName: string;
  currentTierEntryRule: number;
};

const DowngradeLoyaltyTierAlert = ({
  evaluationPeriodEnd,
  caseCount,
  interval,
  currentTierName,
  currentTierEntryRule,
}: Props) => {
  const formattedEvaluationPeriodEnd =
    moment(evaluationPeriodEnd).format('MMMM D');
  const casesNeededToMaintainTier = currentTierEntryRule - caseCount;
  const intervalText = interval > 1 ? `${interval} months` : `month`;

  return (
    <AlertCard
      type="warning"
      header={`Submit ${casesNeededToMaintainTier} cases by ${formattedEvaluationPeriodEnd} to stay in your current loyalty tier`}
      body={`As per our loyalty rules, you must submit ${currentTierEntryRule} cases every ${intervalText} to stay in the ${currentTierName} tier.`}
      displayIcon={true}
      displayCloseButton={true}
    >
      <StyledLink to="/loyalty">Learn more</StyledLink>
    </AlertCard>
  );
};

export default DowngradeLoyaltyTierAlert;
