import { theme, type } from 'core/components';
import styled from 'styled-components/macro';

export const Modal = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${theme.palette.white};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px 32px;
  box-shadow: 0px -1px 0px 0px ${({ theme }) => theme.colors.black20} inset;
  align-items: center;
`;

export const Content = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  overflow: auto;
  align-items: center;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 16px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content space-between;
  align-items: center;
  padding: 14px; 32px;
  box-shadow: 0px 1px 0px 0px ${({ theme }) => theme.colors.black20} inset;
`;

export const HeaderButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  media ${({ theme }) => theme.mediaQueries.tabletAndAbove} {
    min-width: 280px;
  }
`;

export const HeaderTitle = styled(type.H4)`
  margin: 0.25rem 0 0;
`;
