import styled from 'styled-components/macro';

import CheckSvg from 'assets/check.svg?react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const Row = styled.div<{
  center?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
`;

export const TierName = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

export const TierImg = styled.img`
  height: 0.75rem;
  width: 0.75rem;
`;

export const NameText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.default};
`;

export const SubText = styled.div`
  margin-left: 1.25rem;
  color: ${({ theme }) => theme.colors.text70};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: 150%;
`;

export const NumCases = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.text70};
  font-size: ${({ theme }) => theme.fontSizes.default};
`;

export const StyledCheck = styled(CheckSvg)`
  > path {
    stroke: ${({ theme }) => theme.colors.green70};
  }
`;
