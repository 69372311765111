import styled from 'styled-components/macro';

const TRANSITION_SPEED = 500;
const SIDEBAR_WIDTH = '300px';

type Props = {
  isOpen: boolean;
};

export const SidebarBody = styled.div<Props>`
  margin-left: ${({ isOpen }) => (isOpen ? SIDEBAR_WIDTH : '0px')};
  transition: margin-left ${TRANSITION_SPEED}ms;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100%;
    margin-left: 0;
  }
`;
