import React from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router-dom';

import { DecodedCase } from 'utils/case';
import * as propTypes from 'utils/propTypes';
import CaseSearchPage from 'components/SearchPages/CaseSearchPage';

type MatchParams = {
  id?: string;
};

const TpQualityControlSearch = ({
  history,
  match,
}: RouteComponentProps<MatchParams>) => {
  const { id } = match.params;

  const handleSubmit = ({ caseRef }: DecodedCase) => {
    if (!caseRef) {
      return;
    }

    history.push(`${match.url}/cases/${caseRef}`);
  };

  return (
    <CaseSearchPage
      patientId={id}
      onSelectCase={handleSubmit}
      pageTitle="Treatment Planning tools"
    />
  );
};

TpQualityControlSearch.propTypes = {
  history: propTypes.routerHistory.isRequired,
  match: propTypes.routerMatch({
    id: PropTypes.string,
  }).isRequired,
};

export default TpQualityControlSearch;
