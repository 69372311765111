import { fetchApi } from 'utils/api';

/**
 * Fetch order item details based on a list of order item refs (uuid)
 * @param {Array<UUID>} orderItems
 * in core, the case no longer knows about the order items so we need to pass in the case ref
 * to fetch the order items appropriately
 * @param {UUID} caseRef
 *
 * @returns
 interface OrderItemsWithShipment {
  @param {string} order_item_ref
  @param {number} order_id
  @param {number} quantity
  @param {string} product_sku
  @param {string} product_name
  @param {string} product_type
  @param {Shipment} shipment
}
interface Shipment {
  @param {string} carrier
  @param {number} id
  @param {(ItemsEntity)[] | null} items?
  @param {(null)[] | null} registrations?
  @param {null} delivered_at?
  @param {string} created
  @param {string} shipment_direction
  @param {null} tracking_id?
  @param {string} status_datetime
  @param {string} logistics_provider
  @param {string} customer_ref
  @param {null} notified_3pl?
  @param {string} status
  @param {null} shipped_at?
  @param {string} shipment_ref
  @param {null} last_updated?
}
interface ItemsEntity {
  @param {string} created
  @param {number} id
  @param {number} shipment_id
  @param {string} item_ref
  @param {null} last_updated?
}
 */
export const fetchOrderItemsShipping = async (
  orderItems,
  caseRef = undefined
) => {
  try {
    const res = await fetchApi(
      '/api/v1/order-items/',
      'GET',
      {},
      {
        params: {
          order_item_refs: orderItems,
          case_ref: caseRef,
        },
      }
    );

    return res;
  } catch (err) {
    throw new Error('Could not fetch order items');
  }
};

export const fetchOrder = async (orderId) => {
  try {
    const res = await fetchApi(`/api/v1/order/${orderId}/`, 'GET');

    return res;
  } catch (err) {
    throw new Error('Could not fetch case order');
  }
};
