import FullPageModal from 'components/FullPageModal/FullPageModal';
import { CaseTypeNames } from 'constants/Case';
import moment from 'moment';
import {
  selectActiveCase,
  selectActiveCaseHasApprovedTP,
} from 'pages/Patient/patientSlice';
import CancelCaseInProgressModal, {
  CaseCloseType,
} from 'components/Modals/CaseInProgressModal';
import ProductSelection from 'pages/Patient/ProductSelectionModal/ProductSelection';
import RetainerSelection from 'pages/Patient/ProductSelectionModal/RetainerSelection';
import { RetainerTypes } from 'pages/Patient/ProductSelectionModal/types';
import {
  createCase,
  selectIsLastStepEligible,
  selectPatient,
  selectSelectedProductType,
  setIsRefinementModalOpen,
  setSelectedProduct,
} from 'pages/Patient/patientSlice';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppDispatch } from 'state/store';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProductTypes } from 'types/checkout';

type ProductSelectionModalProps = {
  patientName: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ProductSelectionModal = ({
  isOpen,
  onClose,
  patientName,
}: ProductSelectionModalProps) => {
  const { 'bypass-ortho-review-process': bypassOrthoReviewProcess } =
    useFlags();
  const selectedProduct = useSelector(selectSelectedProductType);
  const isLastStepEligible = useSelector(selectIsLastStepEligible);
  const [showRetainerOptions, setShowRetainerOptions] = useState(false);
  const [selectedRetainerType, setSelectedRetainerType] =
    useState<RetainerTypes | null>(null);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const patient = useSelector(selectPatient);

  const activeCase = useSelector(selectActiveCase);
  const activeCaseHasApprovedTp = useSelector(selectActiveCaseHasApprovedTP);
  const dispatch = useDispatch<AppDispatch>();
  const { push } = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const retainerFormLink = useMemo(
    () =>
      `https://formfacade.com/public/113606064810223952308/all/form/1FAIpQLSfdhC6cWGKFuy1-qPVfh4TbC0qC0yokiwH3pJxm_pbKqnB4oQ?emailAddress=${
        patient?.referringDentist?.email
      }&entry.1081703492=${patient?.practice?.name}&entry.433797451=${
        patient?.referringDentist?.fullName
      }&entry.402285835=${patient?.firstName} ${
        patient?.lastName
      }&entry.790415107=${moment(patient?.birthday?.toString()).format(
        'YYYY-MM-DD'
      )}`,
    [patient]
  );

  const internalOnClose = () => {
    //Some cleanup to clear state in case the user reopens the modal
    dispatch(setSelectedProduct(null));
    setShowRetainerOptions(false);
    setSelectedRetainerType(null);
    onClose();
  };

  const createCaseInternal = async (caseType: CaseTypeNames) => {
    if (selectedProduct == null || !patient) {
      return;
    }

    setIsSubmitting(true);
    await dispatch(
      createCase({
        caseCreationInput: {
          caseOptions: {
            caseType: caseType,
            patientId: Number(patient.id),
            practiceId: Number(patient.practice?.id),
            precedingCaseRef:
              caseType === CaseTypeNames.REFINEMENTS
                ? activeCase?.caseRef
                : null,
          },
          workflowOptions: {
            bypassOrthoReview: bypassOrthoReviewProcess,
          },
        },
      })
    );

    push(`/patient/${patient?.id}/case-creator/diagnostic-materials`);

    setIsSubmitting(false);
    internalOnClose();
  };
  //TODO: Ideally these page specific actions would  be in their respective folder, but there's not enough
  // content to justify that.
  //In general there is way too much product specific logic in this file
  //No ticket for this, this will probably change over time
  const handleProductSelectionOnContinue = async (
    selectedProduct: ProductTypes | null,
    showConfirmationModal: boolean = false
  ) => {
    if (selectedProduct == null || !patient) {
      return;
    }

    switch (selectedProduct) {
      case ProductTypes.Aligner:
        //Create the aligner case and go to diagnostic materials

        if (!showConfirmationModal) {
          createCaseInternal(CaseTypeNames.ALIGNER);
        } else {
          setShowConfirmationModal(true);
        }
        break;
      case ProductTypes.Retainer:
        if (!isLastStepEligible) {
          if (!showConfirmationModal) {
            createCaseInternal(CaseTypeNames.RETAINER);
          } else {
            setShowConfirmationModal(true);
          }
        } else {
          setShowRetainerOptions(true);
        }
        break;
      case ProductTypes.Refinement:
        dispatch(setIsRefinementModalOpen(true));
        internalOnClose();
        break;
      case ProductTypes.Replacement:
        //Open the google forms
        onClose();
        window.open(retainerFormLink, '_blank');
        break;
    }
  };

  const handleRetainerSelectionOnContinue = async (
    showConfirmationModal: boolean = false
  ) => {
    if (selectedRetainerType == null || !patient) {
      return;
    }

    switch (selectedRetainerType) {
      case RetainerTypes.NewScans:
        if (showConfirmationModal) {
          //Create the retainer case and go to diagnostic materials
          createCaseInternal(CaseTypeNames.RETAINER);
        } else {
          setShowConfirmationModal(true);
        }
        break;
      case RetainerTypes.Reorder:
        push(`/patient/${patient?.id}/case-creator/checkout?reorder=true`);
        break;
      case RetainerTypes.LastStep:
        push(`/patient/${patient?.id}/case-creator/checkout?last_step=true`);
        break;
    }
  };

  const onSubmit = (userConfirmed: boolean = false) => {
    const showConfirmationModal = !!activeCase && !userConfirmed;
    if (showRetainerOptions) {
      handleRetainerSelectionOnContinue(showConfirmationModal);
    } else {
      handleProductSelectionOnContinue(selectedProduct, showConfirmationModal);
    }
  };

  return (
    <FullPageModal
      continueDisabled={
        selectedProduct == null ||
        (showRetainerOptions && selectedRetainerType == null)
      }
      isOpen={isOpen}
      onClose={internalOnClose}
      onContinue={() => onSubmit(false)}
      patientName={patientName}
      titleText="Place order"
      isLoading={isSubmitting}
    >
      <CancelCaseInProgressModal
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={() => {
          setShowConfirmationModal(false);
          onSubmit(true);
        }}
        completionType={
          activeCaseHasApprovedTp
            ? CaseCloseType.Complete
            : CaseCloseType.Cancel
        }
      />
      {!showRetainerOptions && <ProductSelection />}
      {showRetainerOptions && (
        <RetainerSelection
          selectedRetainer={selectedRetainerType}
          updateSelectedRetainer={(retainerType) =>
            setSelectedRetainerType(retainerType)
          }
        />
      )}
    </FullPageModal>
  );
};
