import {
  Box,
  Radio,
  Checkbox,
  Button,
  CircularProgress,
} from '@material-ui/core';
import {
  ModelTypes,
  useAssociateAddressToOrderItemMutation,
  useUpdateAddressMutation,
} from 'generated/legacy/graphql';
import {
  selectHomeAddress,
  selectPracticeAddress,
} from 'pages/OrthoPrism/orthoSlice';
import React from 'react';
import { useSelector } from 'react-redux';

const AddressAssociation = ({ orderItem }: any) => {
  const [isProcessing, setProcessing] = React.useState<boolean>(false);
  const [progress, setProgress] = React.useState<number>(0);
  const [forceValidation, setForceValidation] = React.useState<boolean>(false);
  const [selectedAddressType, setSelectedAddressType] = React.useState<
    'practice' | 'patient'
  >('patient');
  const [
    updateAddress,
    { error: addressUpdateError, reset: resetUpdateAddress },
  ] = useUpdateAddressMutation();
  const [
    associateAddressToOrderItem,
    {
      error: associationError,
      data: addressAssociationData,
      reset: resetAddressAssociation,
    },
  ] = useAssociateAddressToOrderItemMutation();
  const homeAddress = useSelector(selectHomeAddress);
  const practiceAddress = useSelector(selectPracticeAddress);

  const getAddress = (addressType: 'practice' | 'patient') => {
    if (addressType === 'practice') {
      return practiceAddress;
    } else if (addressType === 'patient') {
      return homeAddress;
    } else {
      throw new Error('Invalid Address Type');
    }
  };

  const handleAssociateAddress = async () => {
    setProcessing(true);
    const address = getAddress(selectedAddressType);
    setProgress(10);
    if (forceValidation) {
      setProgress(20);
      setProgress(30);
      await updateAddress({
        variables: {
          shippingAddressInput: {},
          shippingAddressID: address.id,
          addressType:
            selectedAddressType === 'patient'
              ? ModelTypes.Patient
              : ModelTypes.Practice,
          forceValidation: true,
        },
      });
    }
    setProgress(50);
    await associateAddressToOrderItem({
      variables: {
        orderItemRefs: [orderItem.order_item_ref],
        addressType:
          selectedAddressType === 'patient'
            ? ModelTypes.Patient
            : ModelTypes.Practice,
        addressId: address.id,
      },
    });
    setProgress(100);
    setProcessing(false);
  };

  const cleanup = () => {
    setProgress(0);
    setForceValidation(false);
    resetUpdateAddress();
    resetAddressAssociation();
    window.location.reload();
  };

  if (addressUpdateError) {
    return <div>Address Update Error: {addressUpdateError.message}</div>;
  }
  if (associationError) {
    return <div>Address Association Error: {associationError.message}</div>;
  }
  if (isProcessing) {
    return <CircularProgress variant="determinate" value={progress} />;
  }

  if (addressAssociationData) {
    const associatedOrderItem =
      addressAssociationData.associateAddressToOrderItems?.orderItems?.[0];
    return (
      <>
        <div>Address Associated</div>
        <div>Order Item Ref: {associatedOrderItem?.orderItemRef}</div>
        <div>Address Type: {selectedAddressType}</div>
        <div>Address ID: {getAddress(selectedAddressType)?.id}</div>
        <div>
          <strong>Shipping To:</strong>
        </div>
        <Box display="flex" flexDirection="column">
          <span>{associatedOrderItem?.standardizedShippingAddress?.name}</span>
          {associatedOrderItem?.standardizedShippingAddress?.addressLines?.map(
            (line, index) => <span key={index}>{line}</span>
          )}
          <span>
            {associatedOrderItem?.standardizedShippingAddress?.city},{' '}
            {associatedOrderItem?.standardizedShippingAddress?.adminRegion}{' '}
            {associatedOrderItem?.standardizedShippingAddress?.postalCode}
          </span>
          <span>
            {associatedOrderItem?.standardizedShippingAddress?.country}
          </span>
        </Box>
        <Button onClick={cleanup}>Reset</Button>
      </>
    );
  }

  return (
    <>
      <div>Address Association</div>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row">
            <Radio
              checked={selectedAddressType === 'patient'}
              onChange={() => setSelectedAddressType('patient')}
              value="patient"
              name="patient"
              inputProps={{ 'aria-label': 'patient' }}
            />
            <Box display="flex" flexDirection="column">
              <span>
                <strong>Patient</strong>
              </span>
              {homeAddress.firstName && (
                <span>
                  {homeAddress.firstName} {homeAddress.lastName}
                </span>
              )}
              {homeAddress.businessName && (
                <span>{homeAddress.businessName}</span>
              )}
              <span>{homeAddress.addressLine1}</span>
              {homeAddress.addressLine2 && (
                <span>{homeAddress.addressLine2}</span>
              )}
              <span>
                {homeAddress.city}, {homeAddress.stateCode} {homeAddress.zip}
              </span>
              <span>{homeAddress.countryCode}</span>
              <Box display="flex" flexDirection="column">
                <small>app_address ID: {homeAddress.id}</small>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Radio
              checked={selectedAddressType === 'practice'}
              onChange={() => setSelectedAddressType('practice')}
              value="practice"
              name="practice"
              inputProps={{ 'aria-label': 'practice' }}
            />
            <Box display="flex" flexDirection="column">
              <span>
                <strong>Practice</strong>
              </span>
              <span>{practiceAddress.name}</span>
              <span>{practiceAddress.addressLine1}</span>
              {practiceAddress.addressLine2 && (
                <span>{practiceAddress.addressLine2}</span>
              )}
              <span>
                {practiceAddress.city}, {practiceAddress.stateCode}{' '}
                {practiceAddress.zip}
              </span>
              <span>{practiceAddress.countryCode}</span>
              <Box display="flex" flexDirection="column">
                <small>app_genericaddress ID: {practiceAddress.id}</small>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Checkbox
            checked={forceValidation}
            onChange={() => setForceValidation(!forceValidation)}
            inputProps={{ 'aria-label': 'forceValidation' }}
          />
          <span>Force Validation</span>
        </Box>
      </Box>
      <Button onClick={handleAssociateAddress}>Associate Address</Button>
    </>
  );
};

export default AddressAssociation;
