import {
  FormMetaChoices,
  FormQuestion,
  QuestionTypes,
} from 'generated/core/graphql';

export enum AnswerType {
  YES = 'yes',
  NO = 'no',
}

export type QuestionFormikProps = {
  'question-type': QuestionTypes;
  'question-text': string;
  'question-key': string;
  'answer-type-condition': string;
  'consent-type-condition': string;
  'require-explanation': boolean;
  'explanation-label': string;
  'cnt-reason-text': string;
  'question-tooltip'?: string;
  'date-format'?: string;
  choices?: FormMetaChoices[];
  'is-follow-up-question'?: boolean;
  'is-required'?: boolean;
  'has-default-preference-option': boolean;
};

//When the user is editing questions, we need to keep track if the original
//question key was blank, and the user has modified it, and should be able to continue to modify it
//or if the original question key was already set, and the user should never be able to modify it
export type EditableFormQuestion = FormQuestion & {
  originalQuestionKey: string;
};
