import { AlertCard } from 'core/components';
import React from 'react';
import styled from 'styled-components/macro';
import ContactSupport from 'components/ContactSupport';

const Header = styled.div`
  padding-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.black90};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
`;

const Container = styled.div`
  margin-top: 18px;
`;

const IneligibleRetainerAlertCard = () => {
  return (
    <Container>
      <AlertCard displayIcon={false} type="default">
        <Header>
          This treatment plan is not eligible for last-step retainers due to the
          presence of attachments, bite ramps or cut-outs.
        </Header>
        <ContactSupport text="Contact support to proceed" />{' '}
      </AlertCard>
    </Container>
  );
};

export default IneligibleRetainerAlertCard;
