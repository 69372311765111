import styled from 'styled-components/macro';
import { CSSObject } from 'styled-components';
import { mediaQueries } from 'core/components';

export const Wrapper = styled.div`
  position: relative;
  padding-top: 2rem;
`;

export const PlanViewer = styled.div`
  position: relative;
  width: 90%;
  margin: auto;
  margin-bottom: 1rem;
  height: 75vh;
  background: ${({ theme }) => theme.colors.black05};

  @media ${mediaQueries.mobile} {
    padding-top: 150%;
  }

  @media ${mediaQueries.tabletAndBelow} {
    width: 100%;
  }
`;

export const PlanViewerContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

export const InfoBlocks = styled.ul`
  li {
    font-size: ${({ theme }) => theme.fontSizes[2]};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-align: center;
    display: inline-block;

    &:not(:first-child) {
      margin-left: 2rem;
    }

    :last-child {
      margin-left: 0;
      margin-top: 1rem;
      display: block;
    }

    @media ${mediaQueries.tabletAndAbove} {
      :last-child {
        margin-left: 2rem;
        margin-top: 0;
        float: right;
      }
    }
  }
`;

export const Overline = styled.h6`
  ${({ theme }) => theme.text.overline as CSSObject};
  margin-bottom: 0.25rem;
`;

export const Notes = styled.section`
  margin-bottom: 1.5rem;

  p {
    margin-bottom: 0;
    font-style: italic;
    color: ${({ theme }) => theme.colors.text70};
  }
`;

export const BorderedDiv = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  padding: 32px;
`;

export const CenterText = styled.div`
  text-align: center;
  padding-top: 1rem;
`;
