import React, { useContext } from 'react';
import DocumentTitle from 'components/DocumentTitle';
import { ArrayHelpers, FieldArray, Formik, useFormikContext } from 'formik';
import {
  FormikCheckboxWrapper,
  FormikOption,
  FormikSelectWrapper,
} from 'components/FormikForms';
import { AddressFormType } from 'components/AddressForm/types';
import AddressForm from 'components/AddressForm/AddressForm';
import { Button, NotificationContext, Table } from 'core/components';
import { GridContainer } from 'styles/layout.css';
import { Flex } from 'pages/Orders/style.css';
import {
  AddressInput,
  CreateOrderMutationVariables,
  OrderItemInputType,
} from 'generated/core/graphql';
import { getAddressInputFromAddressForm } from 'components/AddressForm/utils';
import PlusIcon from 'assets/plus-circle-outline.svg?react';
import MinusIcon from 'assets/minus-circle-outline.svg?react';
import styled from 'styled-components/macro';
import { ApolloError } from '@apollo/client';
import { MUIDataTableOptions } from 'mui-datatables';
import api from 'state/api';
import { OrganizationTypes } from 'generated/legacy/graphql';

type CreateOrderFormType = {
  orderItems: Array<OrderItemInputType>;
  orderItemsSelection: {
    productVariantSku: string;
    quantity: number;
  };
  autoActivate: CreateOrderMutationVariables['autoActivate'];
  practiceId: CreateOrderMutationVariables['practiceId'];
  shippingAddress: AddressFormType;
};

const initialState: CreateOrderFormType = {
  orderItems: [],
  orderItemsSelection: {
    productVariantSku: '',
    quantity: 1,
  },
  practiceId: 0,
  autoActivate: false,
  shippingAddress: {
    addressLine1: '',
    city: '',
    stateCode: '',
    zip: '',
    countryCode: 'US',
  },
};

const StyledTable = styled(Table)<{ loading: boolean }>`
  tr {
    cursor: pointer;
  }
  width: 100%;
  opacity: ${({ loading }) => (loading ? 0.3 : 1)};
`;

const CreateOrderForm = () => {
  const submitButtonRef = React.useRef<HTMLButtonElement>(null);
  const [createOrder] = api.useCreateOrderMutation();

  const { showNotification } = useContext(NotificationContext);

  const handleSubmit = async (
    values: CreateOrderFormType,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: () => void
  ) => {
    setSubmitting(true);
    const addressInput = getAddressInputFromAddressForm(values.shippingAddress);
    try {
      const response = await createOrder({
        orderItems: values.orderItems,
        autoActivate: values.autoActivate,
        practiceId: values.practiceId,
        shippingAddress: addressInput as AddressInput,
        couponCodes: [],
      }).unwrap();
      showNotification(
        `Order created successfully with Order Ref: ${response?.order?.id}`,
        'success'
      );
      resetForm();
    } catch (e) {
      showNotification(
        `Failed to create order: ${(e as ApolloError)?.message}`,
        'error'
      );
    }
    setSubmitting(false);
  };
  const onSubmit = async () => {
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  };

  const validateForm = (values: CreateOrderFormType) => {
    const errors: Partial<{ [K in keyof CreateOrderFormType]: string }> = {};
    if (values.practiceId === 0) {
      errors.practiceId = 'Please select a practice';
    }
    if (values.orderItems.length === 0) {
      errors.orderItems = 'Please select at least one product';
    }

    return errors;
  };

  return (
    <DocumentTitle title={'Create order'}>
      <Formik
        initialValues={initialState}
        onSubmit={onSubmit}
        validate={validateForm}
      >
        <OrderForm buttonRef={submitButtonRef} submitForm={handleSubmit} />
      </Formik>
    </DocumentTitle>
  );
};

const OrderItemsForm = ({
  push,
  remove,
}: {
  push: ArrayHelpers['push'];
  remove: ArrayHelpers['remove'];
}) => {
  const { values, setFieldValue } = useFormikContext<CreateOrderFormType>();

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const { data: productVariants } = api.useGetProductVariantsQuery(
    {},
    {
      selectFromResult: ({ data, ...rest }) => ({
        data: data ?? [],
        ...rest,
      }),
    }
  );

  const productVariantOptions: FormikOption[] = productVariants.map(
    (productVariant) => ({
      value: productVariant.sku,
      displayValue: `${productVariant.name} - ${USDollar.format(productVariant.defaultPriceInCents / 100)}`,
    })
  );
  const productQuantities: FormikOption<number>[] = [
    { value: 1, displayValue: '1' },
    { value: 2, displayValue: '2' },
    { value: 3, displayValue: '3' },
    { value: 4, displayValue: '4' },
  ];
  const remainingProductVariantOptions = productVariantOptions.filter(
    (productVariantOption) =>
      !values.orderItems.find(
        (orderItem) =>
          orderItem.productVariantSku === productVariantOption.value
      )
  );

  const mappedOrderItems = values.orderItems.map((orderItem) => {
    const productVariant = productVariants.find(
      (productVariant) => productVariant.sku === orderItem.productVariantSku
    );
    return {
      productVariantSku: `${productVariant?.name} - ${orderItem.productVariantSku}`,
      quantity: orderItem.quantity,
      price: USDollar.format(
        ((productVariant?.defaultPriceInCents ?? 0) * orderItem.quantity) / 100
      ),
    };
  });
  const columns = [
    { label: 'Product name and SKU', name: 'productVariantSku' },
    { label: 'Quantity', name: 'quantity' },
    { label: 'Total Price', name: 'price' },
    {
      label: 'Remove',
      name: 'remove',
      options: {
        customBodyRenderLite: (dataIndex: number) => {
          return <MinusIcon onClick={() => remove(dataIndex)} />;
        },
      },
    },
  ];
  const options: MUIDataTableOptions = {
    search: false,
    pagination: false,
    sort: false,
    selectableRows: 'none',
  };

  return (
    <>
      <Flex direction="column" gap={'1rem'}>
        <b>Create an Order</b>
        <Flex direction="column">
          <FormikSelectWrapper
            name={`orderItemsSelection.productVariantSku`}
            label="Select a product"
            type={'text'}
            options={remainingProductVariantOptions}
            className="flexie-wrapper"
          />
          <FormikSelectWrapper
            name={`orderItemsSelection.quantity`}
            label="Select a quantity"
            type={'number'}
            options={productQuantities}
            className="flexie-wrapper"
          />
        </Flex>
        <Flex>
          <Button
            buttonType="secondary"
            disabled={
              !values.orderItemsSelection.productVariantSku ||
              !values.orderItemsSelection.quantity
            }
            onClick={() => {
              push({
                productVariantSku: values.orderItemsSelection.productVariantSku,
                quantity: values.orderItemsSelection.quantity,
              });
              setFieldValue('orderItemsSelection.productVariantSku', '');
            }}
          >
            Add product to order <PlusIcon />
          </Button>
        </Flex>
      </Flex>
      <Flex direction="column">
        <h3>Order summary</h3>
        <StyledTable
          title="Order Items"
          data={mappedOrderItems}
          loading={false}
          columns={columns}
          options={options}
        />
      </Flex>
    </>
  );
};

const OrderForm = ({
  buttonRef: submitButtonRef,
  submitForm,
}: {
  buttonRef: React.RefObject<HTMLButtonElement>;
  submitForm: (
    values: CreateOrderFormType,
    setSubmitting: (submitting: boolean) => void,
    resetForm: () => void
  ) => void;
}) => {
  const {
    handleSubmit,
    isValid,
    isSubmitting,
    values,
    dirty,
    setSubmitting,
    resetForm,
  } = useFormikContext<CreateOrderFormType>();
  const { data: practices } = api.useGetFilteredPracticesQuery(
    {
      organizationType: OrganizationTypes.OfficeLocation,
    },
    {
      selectFromResult: ({ data, ...rest }) => ({
        data: data ?? [],
        ...rest,
      }),
    }
  );

  const practiceOptions: FormikOption<number>[] = practices.map((practice) => ({
    value: parseInt(practice.id),
    displayValue: practice.name,
  }));

  return (
    <GridContainer numColumns={2}>
      <FieldArray
        name={'orderItems'}
        render={(arrayHelpers) => (
          <OrderItemsForm
            push={arrayHelpers.push}
            remove={arrayHelpers.remove}
          />
        )}
      />
      <Flex direction="column">
        <Flex grow={1}>
          <FormikSelectWrapper
            name={'practiceId'}
            label="Select a practice"
            type={'text'}
            options={practiceOptions}
            className="flexie-wrapper"
          />
        </Flex>
        <Flex grow={1}>
          <FormikCheckboxWrapper
            label="Auto activate order?"
            name="autoActivate"
            type="checkbox"
            style={{ alignItems: 'center' }}
          />
        </Flex>

        <AddressForm
          addressPath="shippingAddress."
          getAddressData={(values: CreateOrderFormType) =>
            values.shippingAddress
          }
          submitForm={(values) => submitForm(values, setSubmitting, resetForm)}
          updateAddress={(
            value: AddressFormType,
            originalValues: CreateOrderFormType
          ) => {
            return {
              ...originalValues,
              shippingAddress: {
                addressLine1: value.addressLine1 ?? '',
                addressLine2: value.addressLine2 ?? '',
                city: value.city ?? '',
                stateCode: value.stateCode ?? '',
                zip: value.zip ?? '',
                //Smarty street doesn't return country, so use the original.
                countryCode: originalValues.shippingAddress.countryCode,
                businessName: originalValues.shippingAddress.businessName,
              },
            };
          }}
          submitButtonRef={submitButtonRef}
        />
      </Flex>
      <Flex direction="column">
        <Flex>
          <h3>Selected Practice: </h3>
          <p>
            {
              practices.find(
                (practice) => parseInt(practice.id) === values.practiceId
              )?.name
            }
          </p>
        </Flex>
        <Flex>
          <h3>Auto activate order: </h3>
          <p>{values.autoActivate ? 'Yes' : 'No'}</p>
        </Flex>
        <Flex>
          <h3>Shipping Address: </h3>
          <p>
            {isValid && dirty && (
              <>
                {values.shippingAddress.addressLine1},{' '}
                {values.shippingAddress.addressLine2},{' '}
                {values.shippingAddress.city},{' '}
                {values.shippingAddress.stateCode}, {values.shippingAddress.zip}
              </>
            )}
          </p>
        </Flex>
        <Button
          onClick={() => handleSubmit()}
          disabled={!isValid || isSubmitting || !dirty}
        >
          Submit order
        </Button>
      </Flex>
    </GridContainer>
  );
};
export default CreateOrderForm;
