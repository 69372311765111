import styled from 'styled-components/macro';
import { createGlobalStyle } from 'styled-components';
import { Chip, colors, type } from 'core/components';

import HamburgerMenuIcon from 'assets/hamburger-menu-icon.svg?react';
import CloseSVG from 'assets/close.svg?react';
import { Link } from 'react-router-dom';
import { Drawer } from '@material-ui/core';

export const StyledLinkContainer = styled.div<{
  currentPage?: boolean;
}>`
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;

  @media ${({ theme }) => theme.mediaQueries.tabletAndAbove} {
    ${({ currentPage }) =>
      currentPage &&
      `
      border-bottom: 4px solid ${colors.blue50};
      padding-top: 1.4rem;
      padding-bottom: 1.15rem;

      a {
        color: ${colors.blue50} !important;
      }
    `};
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    ${({ currentPage }) =>
      currentPage &&
      `
      a {
        color: ${colors.blue50} !important;
      }
    `};
  }
`;

export const StyledLink = styled(Link)`
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 1rem 0;
  }
`;

export const GlobalStyles = createGlobalStyle`
  .app-root {
  }
`;

export const Wrap = styled.header`
  position: sticky;
  top: 0;
  z-index: 50;
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  button {
    height: 2.25rem;
    padding: 0.4375rem 1.5rem 0.375rem;
    border-radius: 1.125rem;
  }
`;

export const MobileParent = styled.div`
  display: none;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: flex;
    padding: 0 1.25rem;
    height: 3.75rem;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
`;

export const VerticalDeskptopParent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`;

export const DesktopParent = styled.div`
  display: flex;
  padding: 0 1.25rem;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`;

export const HamburgerIcon = styled(HamburgerMenuIcon)`
  display: none;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 3rem;
  }
`;
export const CloseIcon = styled(CloseSVG)`
  display: none;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: flex;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`;

export const MobileNavContainer = styled.div`
  display: none;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: flex;
    justify-content: baseline;
    align-items: space-between;
    cursor: pointer;
  }
`;

export const DesktopNavContainer = styled.div`
  display: flex;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`;

export const HeaderLinksMobile = styled.div`
  display: none;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  a {
    color: ${colors.black70};
    font-weight: bold;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

export const HeaderLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  white-space: nowrap;

  a {
    color: ${colors.black70};
    font-weight: bold;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

export const Logo = styled.img<{ isClickable?: boolean }>`
  flex: none;
  max-height: 20px;
  ${({ isClickable }) => isClickable && 'cursor: pointer;'}
`;

export const LogoContainer = styled.div<{ isCentered?: boolean }>`
  min-width: 280px;

  ${({ isCentered }) =>
    isCentered &&
    `
      display: flex;
      justify-content: center;
    `}
`;

export const MobileDrawer = styled(Drawer)`
  display: none;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: flex;
  }
`;

export const LoyaltyChip = styled(Chip).attrs({
  'data-testid': 'loyalty-chip',
})<{
  background: string;
  border: string;
}>`
  ${({ theme, background, border }) => `
            &.MuiChip-root {
                background-color: ${background};
                border-color: ${border};
                font-size: 14px;
                font-weight: ${theme.fontWeights.default};
            }
  
            margin-left: 0.25rem !important;
            padding-left: 4px !important;
            padding-bottom: 0px !important;
  
            img {
                margin-bottom: 2px;
                height: 12px;
                width: 12px;
            }
        `}
`;

export const LaunchNotesNotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  gap: 8px;
  margin-right: 16px;
  cursor: pointer;
  > svg {
    align-self: center;
    width: 24px;
    height: 24px;
  }
`;

export const LaunchNotesEmbedContainer = styled.div`
  .launchnotes-embed {
    font-family: ${type.family.modernEra};
  }

  .powered-by-launchnotes > :nth-child(2) {
    display: none;
  }
`;

export const MenuButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-width: 280px;
  align-items: center;
  padding: 10.5px 0;
`;

export const ZendeskWidgetContainer = styled.div`
  margin-right: 16px;
  align-items: center;
  display: flex;
  height: 20px;
`;

export const HelpCircleContainer = styled.div`
  align-self: center;
  margin-right: 1rem;
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`;
