import { AlertCard, Grid, type, colors } from 'core/components';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import moment from 'moment';
import UpgradeIcon from 'assets/upgrade-loyalty-tier-icon.svg?react';

import { GetPracticeLoyaltyQuotesQuery } from 'generated/core/graphql';

import { ALIGNER_SKU } from 'constants/loyalty';

const Header = styled(type.H5)`
  font-size: ${({ theme }) => theme.fontSizes.default};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-bottom: 0.25rem;
`;

const { BodySmall } = type;

const BodyText = styled(BodySmall)`
  margin: 0 0 0.5rem 0;
`;

const Price = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const ViewTierLink = styled(Link)`
  color: ${colors.blue50};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-decoration: underline;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const AlertContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

type Props = {
  upcomingTierName: string;
  casesNeededToEnterNextTier: number;
  getPracticeLoyaltyQuotesResult: GetPracticeLoyaltyQuotesQuery | undefined;
  evaluationPeriodEnd: string;
  evaluationPeriodInterval: number;
};

const UpgradeLoyaltyTierAlert = ({
  upcomingTierName,
  casesNeededToEnterNextTier,
  getPracticeLoyaltyQuotesResult,
  evaluationPeriodEnd,
  evaluationPeriodInterval,
}: Props) => {
  const loyaltyQuotes =
    getPracticeLoyaltyQuotesResult?.getPracticeLoyaltyQuotes;

  const contractRate = loyaltyQuotes?.find(
    (quote) => quote.tierName === 'Contract Rate'
  );

  const upcomingQuote = loyaltyQuotes?.find(
    (quote) => quote.tierName === upcomingTierName
  );
  const contractRateAlignerPrice = contractRate?.products?.find(
    (product) => product?.sku === ALIGNER_SKU
  );
  const upcomingTierAlignerPrice = upcomingQuote?.products?.find(
    (product) => product?.sku === ALIGNER_SKU
  );

  if (!contractRateAlignerPrice?.quote || !upcomingTierAlignerPrice?.quote) {
    return null;
  }

  const discountInCents =
    contractRateAlignerPrice?.quote - upcomingTierAlignerPrice?.quote;

  const discount =
    discountInCents &&
    (discountInCents / 100).toLocaleString('en-us', {
      style: 'currency',
      currency: 'USD',
    });

  const formattedNextEvaluationPeriodEndDate = moment(evaluationPeriodEnd)
    .add(evaluationPeriodInterval, 'month')
    .format('MMM Do YYYY');

  return (
    <AlertCard
      type="info"
      displayCloseButton={true}
      testId="loyalty-tier-alert-banner"
    >
      <AlertContainer>
        <UpgradeIcon style={{ width: '8rem', height: '5rem' }} />

        <Grid container>
          <Grid item xs={11}>
            <Grid container direction="column">
              <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
                <Header>
                  {`You're ${casesNeededToEnterNextTier} cases away from unlocking ${upcomingTierName} tier!`}
                </Header>
              </Grid>
              <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
                <BodyText>
                  {`Approve ${casesNeededToEnterNextTier} more cases and get `}
                  <Price>{discount}</Price>{' '}
                  {`off each case you submit from now through ${formattedNextEvaluationPeriodEndDate}.`}
                </BodyText>
              </Grid>
              <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
                <ViewTierLink to="/loyalty">View my tier</ViewTierLink>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AlertContainer>
    </AlertCard>
  );
};

export default UpgradeLoyaltyTierAlert;
