import styled from 'styled-components/macro';
import { colors, type } from 'core/components';

export const AnnotatedAttachment = styled.div`
  color: ${colors.red70};
  text-decoration: underline;
  font-weight: ${type.weight.bold};
  font-size: ${({ theme }) => theme.fontSizes.small};
  cursor: pointer;
`;

export const AttachmentHeader = styled.div`
  padding: 0.5rem 0;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const StatusBadge = styled.div`
  border-radius: 1rem;
  color: ${colors.red70};
  font-size: ${type.size.small};
  right: 3rem;
  width: fit-content;
  padding: 0.05rem 0.5rem;
  border: 1px solid ${colors.red70};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justifycontent: flex-start;
  min-width: 0;
  padding-top: 0.5rem;
  flex-wrap: wrap;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const LinkContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export const LinkItem = styled.a`
  color: ${colors.black90};
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin-right: 0.5rem;
  &:hover {
    color: ${colors.black90};
    text-decoration: underline;
  }
`;

export const LinkTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${type.weight.bold};
  display: block;
  white-space: nowrap;
  margin-right: 0.5rem;
`;

export const EvaluationNoteText = styled.div`
  color: ${colors.black90};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-style: italic;
  padding-top: 0.75rem;
`;

export const IconContainer = styled.div`
  min-width: 1rem;
  min-height: 1rem;
  margin-right: 0.5rem;
`;

export const AttachmentContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
`;

export const AttachmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
