import styled from 'styled-components';
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  ResponsiveValue,
  space,
  SpaceProps,
  variant,
  VariantArgs,
} from 'styled-system';

export type TextVariants = 'default' | 'large' | 'small' | 'micro';

export type TextProps = ColorProps &
  LayoutProps &
  SpaceProps &
  VariantArgs & {
    variant?: ResponsiveValue<TextVariants>;
  };

const Text = styled.div<TextProps>`
  ${color}
  ${layout}
  ${space}
  ${variant({
    scale: 'text.body',
  })}
`;

export default Text;
