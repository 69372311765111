import React, {
  createContext,
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';

import {
  FullCaseFieldsFragment,
  CustomerWithFullCasesQuery,
  useCustomerWithFullCasesLazyQuery,
} from 'generated/legacy/graphql';

type Customer = CustomerWithFullCasesQuery['customer'];

type ValidCase = FullCaseFieldsFragment & {
  caseRef: string;
};

type CustomerWithCases = Customer & {
  validCases: ValidCase[];
};

const caseIsValid = (
  caseInstance: Maybe<FullCaseFieldsFragment>
): caseInstance is ValidCase => {
  return !!caseInstance?.caseRef;
};

export const CustomerCasesContext = createContext({} as CaseContextProps);

interface CaseContextProps {
  getCustomerWithCasesData: (customerId: string) => void;
  customerWithCases: Maybe<CustomerWithCases>;
  loadingCustomerWithCases: boolean;
  customerWithCasesError: Maybe<string>;
}

const CustomerCasesProvider: FunctionComponent = ({ children }) => {
  const [customerWithValidCases, setCustomerWithValidCases] =
    useState<CustomerWithCases | null>(null);
  const [
    getCustomerWithFullCases,
    {
      data: customerWithCasesData,
      loading: loadingCustomerWithCases,
      error: customerWithCasesError,
    },
  ] = useCustomerWithFullCasesLazyQuery();

  useEffect(() => {
    const { customer } = customerWithCasesData || {};

    if (!customer) {
      return;
    }

    const customerWithValidCases = {
      ...customer,
      validCases: customer?.cases?.filter(caseIsValid) || [],
    };

    setCustomerWithValidCases(customerWithValidCases);
  }, [customerWithCasesData]);

  const getCustomerWithCasesData = useCallback(
    (customerId: string) => {
      getCustomerWithFullCases({
        variables: {
          customerId,
        },
      });
    },
    [getCustomerWithFullCases]
  );

  return (
    <CustomerCasesContext.Provider
      value={{
        customerWithCases: customerWithValidCases,
        getCustomerWithCasesData,
        loadingCustomerWithCases,
        customerWithCasesError: customerWithCasesError?.message,
      }}
    >
      {children}
    </CustomerCasesContext.Provider>
  );
};

export default CustomerCasesProvider;
