import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'components/Tag/Tag.css';

const COLORS = ['blue', 'light-blue', 'pink'];

const Tag = ({
  children,
  disabled = false,
  className = '',
  color = 'blue',
  onClick,
  ...otherProps
}) => (
  <Container
    className={className}
    color={color}
    disabled={disabled}
    onClick={onClick}
    type="button"
    {...otherProps}
  >
    {children}
  </Container>
);

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(COLORS),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Tag;
