import React from 'react';
import { Skeleton } from 'core/components';
import { CompletedContainer } from 'pages/Patient/CaseCreator/CaseSection.css';
import { PageWrapper } from 'pages/Patient/styles.css';

export const SkeletonCaseSection = () => (
  <CompletedContainer>
    <Skeleton animation="wave" variant="circle" width={40} height={40} />
    <Skeleton animation="wave" height={50} width="80%" />
  </CompletedContainer>
);

export const SkeletonCaseCreator = () => (
  <PageWrapper isNarrow>
    <Skeleton
      animation="wave"
      height={50}
      width="60%"
      style={{ marginBottom: 20 }}
    />
    <SkeletonCaseSection />
    <SkeletonCaseSection />
    <SkeletonCaseSection />
  </PageWrapper>
);
