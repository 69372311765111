import React from 'react';
import * as S from 'pages/OrthoPrism/Photos/PhotoReviewForm/PhotoReviewForm.css';

type Props = {
  label: string;
  indicator: boolean;
  onChange: () => void;
  onYes: () => void;
  onNo: () => void;
  dataTestId: string;
};

const FormRadioGroup = ({
  label,
  indicator,
  onChange,
  onYes,
  onNo,
  dataTestId,
}: Props) => (
  <S.FormRow>
    <p>{label}</p>
    <S.RadioGroup onChange={onChange} role="radiogroup">
      <S.Label>
        <S.Radio
          type="radio"
          checked={indicator}
          onChange={onYes}
          data-testid={dataTestId + '-Yes'}
        />
        Yes
      </S.Label>
      <S.Label>
        <S.Radio
          type="radio"
          checked={!indicator}
          onChange={onNo}
          data-testid={dataTestId + '-No'}
        />
        No
      </S.Label>
    </S.RadioGroup>
  </S.FormRow>
);

export default FormRadioGroup;
