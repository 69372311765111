import { AlertCard } from 'core/components';
import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  width: 100%;
  padding-bottom: 10px;
`;

const CheckoutAlertCard = ({ message }: { message: string }) => {
  return (
    <Container>
      <AlertCard displayIcon={false} type="warning">
        {message}
      </AlertCard>
    </Container>
  );
};

export default CheckoutAlertCard;
