import React from 'react';

import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { Grid, colors } from 'core/components';

import LeftArrowIcon from 'assets/arrow-left-nav.svg?react';

export const StyledBackLink = () => {
  const history = useHistory();

  return (
    <BackLinkWrapper
      container
      spacing={1}
      alignItems="center"
      onClick={history.goBack}
    >
      <Grid item>
        <LeftArrowIcon />
      </Grid>
      <Grid item>Back</Grid>
    </BackLinkWrapper>
  );
};

const BackLinkWrapper = styled(Grid)`
  font-weight: 700;
  color: ${colors.blue50};
  cursor: pointer;

  svg path {
    fill: ${colors.blue50};
  }
`;

export const PageContainer = styled(Grid)`
  margin-top: 36px;
`;
