import { ItiPaymentCategory } from 'constants/in-treatment-issues';

export enum StepName {
  TreatmentPlanning = 'treatment_planning',
  Manufacturing = 'manufacturing',
  ManufacturingWhitening = 'manufacturing_whitening',
  TreatmentPlanningSoftware = 'treatment_planning_software',
}

export enum Supplier {
  ClearCorrect = 'clear_correct',
  Kraken = 'kraken',
  SureCure = 'sure_cure',
  Vision = 'vision',
  DentalMonitoring = 'dental_monitoring',
}

export type SupplierServices = {
  [stepName in StepName]: {
    supplier: Supplier;
    external_ref: string;
    external_case_created: string;
    supplier_label: string;
    url: string;
    previous_suppliers?: { redirected_at?: string; name?: string }[];
  };
};

export enum JourneyTransition {
  ForceCaseActive = 'force_case_active',
  ForceCaseExpired = 'force_case_expired',
  ForceCaseCanceled = 'force_case_canceled',
  ForceCouldNotTreat = 'force_could_not_treat',
  ForceNeedsDentalWork = 'force_needs_dental_work',
  ForceCaseCompleted = 'force_case_completed',
  ForceInTreatmentIssue = 'force_in_treatment_issue',
  ForceInTreatment = 'force_in_treatment',
  ForceOrthoReview = 'force_ortho_review',
  ForceQcReview = 'force_qc_review',
  ForceRefinementsNeeded = 'force_additional_aligners_requested',
  ForceAlignerKitDelivered = 'force_aligner_kit_delivered',
  ForceInitialFitIssue = 'force_case_closed:_initial_fit_issue',
  ForceReadyForTreatmentPlanning = 'force_ready_for_treatment_planning',
  Complete = 'complete',
}

export type CaseData = {
  iti_payment_category?: ItiPaymentCategory;
  iti_type?: string;
  days_between_patient_dm_scans?: number;
  days_on_current_step?: number;
  current_step?: number;
  original_treatment_start_date?: string;
  notes?: string;
  has_dental_work_been_done?: boolean;
};
