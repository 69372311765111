import { fetchApi } from 'utils/api';

export const pinLogIn = async (loginPin) => {
  try {
    const res = await fetchApi('/api/v1/auth/login/pin/', 'POST', {
      login_pin: loginPin,
    });

    return res;
  } catch (err) {
    const { non_field_errors: nonFieldErrors = null } =
      err?.response?.data ?? {};
    throw new Error(nonFieldErrors || 'Unable to log in');
  }
};
