import { theme, type } from 'core/components';
import styled from 'styled-components/macro';

export const Author = styled.div`
  color: ${({ theme }) => theme.colors.text70};
  display: inline-block;
  margin-left: 0.5rem;
`;

export const DateTime = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.text70};
`;

export const HistoryList = styled.ul`
  border-left: solid 0.25rem ${theme.colors.black10};
  > li {
    position: relative;
    padding-left: 3.5rem;
    margin-bottom: 1.5rem;
  }

  svg {
    position: absolute;
    top: 0.25rem;
    left: 1rem;
    width: 2rem;
    height: 1.5rem;
  }
`;

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.text70};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-top: 0.5rem;
`;

export const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const UnderlineLink = styled.a`
  text-decoration: underline;
`;

export const LinkRow = styled.p`
  margin-bottom: 0.25rem;
`;

export const VersionTitle = styled(type.H4)`
  margin-bottom: 16px;
`;
