import styled from 'styled-components/macro';

import CircleOutline from 'assets/circle-outlined.svg?react';
import CheckedCircle from 'assets/checked-circle.svg?react';

export const RowContainer = styled.div`
  display: flex;
  flex-diretion: row;
  gap: 0.5rem;
`;

export const StyledCheckCircle = styled(CheckedCircle)`
  width: 0.875rem;
  height: 0.875rem;
`;

export const StyledCirleOutline = styled(CircleOutline)`
  width: 0.875rem;
  height: 0.875rem;
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.25rem 0;
`;

export const Text = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.default};
  font-size: ${({ theme }) => theme.fontSizes.tiny};
  line-height: 13px;
`;
