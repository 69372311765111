import styled from 'styled-components';
import { Divider } from '@material-ui/core';
import { Button, TextInput, Table, theme } from 'core/components';
import { Search } from '@material-ui/icons';

export const StyledTable = styled(Table)`
  width: 100%;
  z-index: 0;
  & td {
    padding: 16px;
    margin: 0;
  }
  & th {
    padding: 4px 16px;
  }
`;

export const RateWrapper = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 0;
    margin-left: 10px;
  }
`;

export const RateInput = styled(TextInput)<{ $touched: boolean }>(
  ({ $touched }) => `
  width: max-content;
  & input {
    padding: 0 24px;
    width: 180px !important;
    ${$touched ? `border: 2px solid ${theme.colors.blue50} !important;` : ''};
    border-radius: 4px;
    transition: border 0.1s linear, margin 0.1s linear;
  }
  position: absolute;
`
);

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: min(400px, 100%);
`;

export const SearchInput = styled(TextInput)`
  input {
    max-width: 100%;
    border: 1px solid ${theme.colors.border};
    border-radius: 4px;
  }
`;

export const SearchIcon = styled(Search)`
  margin-left: -20%;
  margin-right: 2%;
  color: ${theme.colors.black50};
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  margin: 0.5rem 0;
  color: ${theme.colors.border};
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  margin: 0;
  min-width: max-content;
`;
