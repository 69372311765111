import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import AggregateSearchPage from 'components/SearchPages/AggregateSearchPage';

type RouteParams = {
  id?: string;
};

const PrismSearch = () => {
  const match = useRouteMatch<RouteParams>();
  const { id } = match.params;

  return (
    <AggregateSearchPage
      customerId={id}
      selectFor="prism"
      pageTitle="Prism (photo review)"
    />
  );
};

export default PrismSearch;
