import React from 'react';
import { Loading } from 'core/components';

import ThumbsDownIcon from 'assets/thumbs-down.svg?react';
import ThumbsUpIcon from 'assets/thumbs-up.svg?react';

import {
  Approve,
  Container,
  Reject,
} from 'components/PrismPhotoReview/EvaluationButtons/EvaluationButtons.css';

type Props = {
  isApproving?: boolean;
  isApproved?: boolean;
  isRejected?: boolean;
  enableRejection?: boolean;
  onClickApprove: () => void;
  onClickReject: () => void;
};

const EvaluationButtons = ({
  isApproving,
  isApproved,
  isRejected,
  onClickApprove,
  onClickReject,
  enableRejection,
}: Props) => (
  <Container>
    <Approve
      data-testid="PrismPhotoReview-Approve"
      disabled={isApproving || isApproved}
      isFaded={isRejected}
      isSelected={isApproved}
      onClick={onClickApprove}
      title="Approve"
    >
      {isApproving ? <Loading color="white" /> : <ThumbsUpIcon role="img" />}
    </Approve>
    {enableRejection && (
      <Reject
        data-testid="PrismPhotoReview-Reject"
        disabled={isRejected}
        isFaded={isApproved}
        isSelected={isRejected}
        onClick={onClickReject}
        title="Reject"
      >
        <ThumbsDownIcon role="img" />
      </Reject>
    )}
  </Container>
);

export default EvaluationButtons;
