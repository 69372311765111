import { FormikOption } from 'components/FormikForms/index';
import {
  JourneyComponentsQuery,
  Practice,
  UsCanadaAdminRegions,
} from 'generated/legacy/graphql';
import { Promotion } from 'pages/Promotion/promotionsSlice';
import { stateToFullMap } from 'constants/states';
import { capitalizeWords, isValidSfAccountId } from 'utils/string';
import { formatPhoneNumber } from 'utils/customer';
import moment from 'moment';

type JourneyComponents = JourneyComponentsQuery['journeyComponents'];

type Enum<E> = Record<keyof E, number | string> & { [k: number]: string };

const MIN_AGE = 0;
const MINOR_AGE = 18;
const MAX_AGE = 100;

export const convertEnumToFormikOptions = <T extends Enum<T>>(
  targetEnum: T,
  displayValueTransformer: (value: string) => string = (v) => v
): FormikOption[] => {
  return Object.keys(targetEnum).map((key) => {
    const castedKey = key as keyof typeof targetEnum;
    return {
      value: targetEnum[castedKey] as unknown as string,
      displayValue: displayValueTransformer(String(targetEnum[castedKey])),
    };
  });
};

export const convertJourneyComponentsToFormikOptions = (
  journeyComponents: JourneyComponents
): FormikOption[] => {
  return journeyComponents.flatMap((journeyComponent) => {
    const valueComponent = journeyComponent.component!;
    const valueComponentLabel = formatJourneyComponentLabel(valueComponent);
    const componentStates = journeyComponent.states!;
    return componentStates?.map((stateData) => {
      const labelData = {
        value: `${valueComponent} - ${stateData?.state}`,
        displayValue: `${valueComponentLabel} - ${stateData?.state}`,
      };
      return labelData;
    });
  });
};

export const convertPracticesToFormikOptions = (
  practices?: Array<Pick<Practice, 'name' | 'id'>>
): FormikOption[] => {
  if (!practices) {
    return [];
  }
  return practices.map((practice) => {
    return {
      value: practice.id,
      displayValue: practice.name,
    };
  });
};

export const convertPromotionsToFormikOptions = (
  promotions?: Array<Promotion>
): FormikOption[] => {
  if (!promotions) {
    return [];
  }
  return promotions.map((p) => {
    return {
      value: p.name,
      displayValue: p.name,
    };
  });
};

export const textInCommaSeparatedFormatValidator = (value: string) => {
  const pattern = /^[a-zA-Z0-9, ]+$/;
  let error;

  if (value && !value.match(pattern)) {
    error = 'Alphanumeric characters and commas only';
  }
  return error;
};

const formatJourneyComponentLabel = (component: string) => {
  const [leg] = component.split('_');
  return leg.charAt(0).toUpperCase() + leg.slice(1);
};

export const convertJourneyFormInputToBeCustomerSearchCompatible = (
  journey: string
) => {
  const [component, state] = journey.split(' - ');
  const journeyStateAndComponentObject = {
    component,
    state,
  };
  return journeyStateAndComponentObject;
};

/**
 *
 * Shared Options
 *
 */

export const usStateOptions = convertEnumToFormikOptions(
  UsCanadaAdminRegions,
  (state) => stateToFullMap[state]
);

export enum YesNoEnum {
  yes = 'yes',
  no = 'no',
}
export const yesNoOptions = convertEnumToFormikOptions(
  YesNoEnum,
  capitalizeWords
);

/**
 *
 * Validators
 *
 */

export const requiredValidator = (value: any) => {
  let error;

  if (!value) {
    error = 'Required';
  }
  return error;
};

export const validSfAccountId = (value: any) => {
  let error;
  error = requiredValidator(value);

  if (error || !isValidSfAccountId(value)) {
    error = 'This is not a valid Salesforce Account ID';
  }

  return error;
};

export const validateDateOfBirth = (value: string) => {
  let error;
  if (!value) {
    error = 'Required';
  } else if (
    moment(value).isValid() &&
    moment().diff(value, 'years') < MIN_AGE
  ) {
    error = 'Please enter a valid birthday';
  } else if (
    moment(value).isValid() &&
    moment().diff(value, 'years') > MAX_AGE
  ) {
    error = 'This patient is too old to receive treatment';
  }
  return error;
};

export const validateGuardianDateOfBirth = (value: any) => {
  if (!value) {
    return 'Required';
  } else if (
    moment(value).isValid() &&
    value &&
    moment().diff(value, 'years') < MINOR_AGE
  ) {
    return 'Must be 18 or older to be a guardian';
  }
};

export const optionalValidator = (_: any) => undefined;

export const alphaNumericUnderscoreValidator = (
  value: string,
  allowSpaces = false
) => {
  const pattern = allowSpaces ? /^[a-z0-9_ ]+$/i : /^[a-z0-9_]+$/i;
  let error;

  if (!value.match(pattern)) {
    error = `Alphanumeric characters ${allowSpaces && `and spaces `} only`;
  }
  return error;
};

export const emailValidator = (value: string) => {
  const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  let error;

  if (!value) {
    error = 'Required';
  } else if (!value.match(pattern)) {
    error = 'Invalid email address';
  }
  return error;
};

export const notRequiredEmailValidator = (value: string) => {
  const pattern = /^$|^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  let error;

  if (!value.match(pattern)) {
    error = 'Invalid email address';
  }
  return error;
};

export const phoneValidator = (value: string = '') => {
  const onlyNums = value.replace(/\D/g, '');
  let error;

  if (!value) {
    error = 'Required';
  } else if (onlyNums.length !== 10) {
    error = 'Invalid phone number';
  }
  return error;
};

export const zipCodeValidator = (value: string) => {
  const zipRegex =
    /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z](\s)?[0-9][A-Z][0-9]$|^(\d{11}|\d{9})|(\d{5}(-)?(\d{6}|\d{4})?((-)\d{2})?)$/i;
  let error;
  if (!value) {
    error = 'Required';
  } else if (!value.match(zipRegex)) {
    error = 'Invalid zip';
  }
  return error;
};

export const passwordValidator = (value: string) => {
  // require password to have uppercase, lowercase, number, and special character
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9\s])/;
  let error;

  if (!value) {
    error = 'Required';
  } else if (value.length < 8) {
    error = 'Password must be at least 8 characters';
  } else if (!pattern.test(value)) {
    error =
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character';
  }
  return error;
};

export const afterStartDateValidator = (startDate: string, endDate: string) => {
  let error;
  if (endDate) {
    if (moment(endDate).isBefore(startDate)) {
      error = 'End date must be after start date';
    }
  }
  return error;
};

/**
 *
 * NORMALIZE INPUT VALUES
 *
 */

export const normalizePhoneNumber = (
  field: string,
  value: string,
  setFunction: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void,
  shouldValidate: boolean = true
) => {
  setFunction(
    field,
    formatPhoneNumber(value).formatedPhoneNumber,
    shouldValidate
  );
};

const formatSectionLabel = (sectionLabel: string) =>
  sectionLabel.replace(/[\W]+/g, '');
export const getAnswerFieldName = (sectionLabel: string, key: string) =>
  `answer_${formatSectionLabel(sectionLabel)}_${key}`;
export const getExplanationFieldName = (sectionLabel: string, key: string) =>
  `explanation_${formatSectionLabel(sectionLabel)}_${key}`;
export const getListAnswerFieldName = (sectionLabel: string, key: string) =>
  `list_${formatSectionLabel(sectionLabel)}_${key}`;
