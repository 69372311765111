import styled from 'styled-components';
import { Button, DatePicker, SelectInput } from 'core/components';

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Container = styled(Flex)`
  width: 50vw;
  max-width: 450px;
  margin: 0;
  > :not(:last-child) {
    margin-bottom: 1rem;
  }
  align-self: baseline;
`;

export const StyledButton = styled(Button)`
  width: max-content;
`;

export const LoyaltySelect = styled(SelectInput)<{
  hasError?: boolean;
  width?: number;
}>`
  min-width: 200px;
  height: 2.5rem;
  background: ${({ theme }) => theme.colors.white};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

export const StyledSmall = styled.small`
  margin-top: 0.75rem;
  font-size: 0.85rem;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;
