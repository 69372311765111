import React from 'react';
import LeftArrowSVG from 'assets/arrow-left.svg?react';
import { useHistory } from 'react-router-dom';

import { BackButton } from 'styles/layout.css';
import { Box, colors, Grid, Heading, Table } from 'core/components';
import styled from 'styled-components/macro';
import { Chip } from '@material-ui/core';

export const PageHeader = ({ title }: { title: string }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      style={{ padding: '0 1rem' }}
    >
      <Grid item>
        <BackLink />
      </Grid>
      <Grid item>
        <Heading variant={'h4'}>{title}</Heading>
      </Grid>
    </Grid>
  );
};

export const BackLink = () => {
  const history = useHistory();

  return (
    <BackButton onClick={history.goBack}>
      <LeftArrowSVG aria-hidden />
      Back
    </BackButton>
  );
};

export const Flex = styled(Box)<{
  direction?: string;
  grow?: number;
  gap?: string;
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  flex-grow: ${({ grow }) => grow};
  gap: ${({ gap }) => gap};
`;

export const StyledBalanceBox = styled(Flex)`
  border: 1px solid #e0e0e0;
  padding: 1rem;
  border-radius: 5px;
  flex-grow: 1;
  flex-basis: 0;
`;

export const StyledTable = styled(Table)<{ loading: boolean }>`
  tr {
    cursor: pointer;
  }
  width: 100%;
  opacity: ${({ loading }) => (loading ? 0.3 : 1)};
  &.MuiTableHead-root {
    background-color: ${colors.red50};
  }
`;

export const StyledChip = styled(Chip)`
  &.MuiChip-root {
    background-color: ${colors.white};
    gap: 0;
    border-radius: 16px;
    border: 1px solid ${colors.red50};
  }

  .MuiChip-label {
    color: ${colors.red50};
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    text-align: left;
  }
`;

export const LightText = styled.p`
  color: ${colors.black70};
`;
