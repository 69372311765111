import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  ActionItemsOverviewDocument,
  ActionItemsOverviewQuery,
  ActionItemsOverviewQueryVariables,
  AddPartDocument,
  AddPartMutation,
  AddPartMutationVariables,
  AddTreatmentPlanStagingDocument,
  AddTreatmentPlanStagingMutation,
  AddTreatmentPlanStagingMutationVariables,
  AddTreatmentPlanToolingDocument,
  AddTreatmentPlanToolingMutation,
  AddTreatmentPlanToolingMutationVariables,
  CreateOrderDocument,
  CreateOrderMutation,
  CreateOrderMutationVariables,
  GenerateTemplatedTreatmentPlanToolingFileDocument,
  GenerateTemplatedTreatmentPlanToolingFileMutation,
  GenerateTemplatedTreatmentPlanToolingFileMutationVariables,
  GetCasesForSearchDocument,
  GetCasesForSearchQuery,
  GetCasesForSearchQueryVariables,
  GetMostRecentlyApprovedTpStagingForPatientDocument,
  GetMostRecentlyApprovedTpStagingForPatientQuery,
  GetMostRecentlyApprovedTpStagingForPatientQueryVariables,
  GetOrdersDocument,
  GetOrdersQuery,
  GetOrdersQueryVariables,
  GetPracticePaymentStatusDocument,
  GetPracticePaymentStatusQuery,
  GetPracticePaymentStatusQueryVariables,
  GetProductVariantsDocument,
  GetProductVariantsQuery,
  GetProductVariantsQueryVariables,
  GetTreatmentPlanStagingsByPatientIdDocument,
  GetTreatmentPlanStagingsByPatientIdQuery,
  GetTreatmentPlanStagingsByPatientIdQueryVariables,
  GetInvoicesForCaseDocument,
  GetInvoicesForCaseQuery,
  GetInvoicesForCaseQueryVariables,
  GetAllOrdersDocument,
  GetAllOrdersQuery,
  GetAllOrdersQueryVariables,
  GetCaseQuery,
  GetCaseDocument,
  GetCaseQueryVariables,
  SaveManufacturingFilesDocument,
  SaveManufacturingFilesMutation,
  SaveManufacturingFilesMutationVariables,
} from 'generated/core/graphql';

import {
  CustomerSearchDocument,
  CustomerSearchQuery,
  CustomerSearchQueryVariables,
  GetAllPracticesDocument,
  GetAllPracticesQuery,
  GetAllPracticesQueryVariables,
  GetConsolidatedOrderItemsByCaseRefDocument,
  GetConsolidatedOrderItemsByCaseRefQuery,
  GetConsolidatedOrderItemsByCaseRefQueryVariables,
  GetPracticesDocument,
  GetPracticesQuery,
  GetPracticesQueryVariables,
  GetConsolidatedOrderItemsDocument,
  GetConsolidatedOrderItemsQuery,
  GetConsolidatedOrderItemsQueryVariables,
  GetPracticeDocument,
  GetPracticeQuery,
  GetPracticeQueryVariables,
  CandidProCustomerDocument,
  CandidProCustomerQuery,
  CandidProCustomerQueryVariables,
  SetAccountPreferencesDocument,
  SetAccountPreferencesMutation,
  SetAccountPreferencesMutationVariables,
  MeDocument,
  MeQuery,
  MeQueryVariables,
} from 'generated/legacy/graphql';

import { coreClient, client as legacyClient } from 'gql/GraphQLProvider';
import { mutateDecorator, queryDecorator } from 'state/api/utils';

const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: 'invalid://unknown/use_queryFn_instead',
  }),
  tagTypes: [
    'ActionItem',
    'Case',
    'Customer',
    'Invoice',
    'Order',
    'Part',
    'Patient',
    'OrderItem',
    'Practice',
    'PracticePaymentStatus',
    'ProductVariant',
    'TreatmentPlanStaging',
    'TreatmentPlanTooling',
    'User',
  ],
  keepUnusedDataFor: 60 /* Default is 60; in seconds */,
  endpoints: (builder) => ({
    /* action item */
    getActionItemsOverview: builder.query({
      providesTags: ['ActionItem'],
      queryFn: queryDecorator(
        coreClient.query<
          ActionItemsOverviewQuery,
          ActionItemsOverviewQueryVariables
        >,
        'actionItemsOverview',
        ActionItemsOverviewDocument
      ),
    }),
    /* user */
    getMe: builder.query({
      providesTags: ['User'],
      queryFn: queryDecorator(
        legacyClient.query<MeQuery, MeQueryVariables>,
        'me',
        MeDocument
      ),
    }),

    /* cases */
    getCasesForPatients: builder.query({
      providesTags: ['Case'],
      queryFn: queryDecorator(
        coreClient.query<
          GetCasesForSearchQuery,
          GetCasesForSearchQueryVariables
        >,
        'getCases',
        GetCasesForSearchDocument
      ),
    }),
    getCase: builder.query({
      providesTags: ['Case'],
      queryFn: queryDecorator(
        coreClient.query<GetCaseQuery, GetCaseQueryVariables>,
        'getCase',
        GetCaseDocument
      ),
    }),

    /* customers */
    getCustomers: builder.query({
      providesTags: ['Customer'],
      queryFn: queryDecorator(
        legacyClient.query<CustomerSearchQuery, CustomerSearchQueryVariables>,
        'customerSearch',
        CustomerSearchDocument
      ),
    }),

    /* order */
    createOrder: builder.mutation({
      invalidatesTags: ['Order'],
      queryFn: mutateDecorator(
        coreClient.mutate<CreateOrderMutation, CreateOrderMutationVariables>,
        'createOrder',
        CreateOrderDocument
      ),
    }),
    getConsolidatedOrderItems: builder.query({
      providesTags: ['Order'],
      queryFn: queryDecorator(
        legacyClient.query<
          GetConsolidatedOrderItemsByCaseRefQuery,
          GetConsolidatedOrderItemsByCaseRefQueryVariables
        >,
        'consolidatedOrderItems',
        GetConsolidatedOrderItemsByCaseRefDocument
      ),
    }),
    getOrders: builder.query({
      providesTags: ['Order'],
      queryFn: queryDecorator(
        coreClient.query<GetOrdersQuery, GetOrdersQueryVariables>,
        'orders',
        GetOrdersDocument
      ),
    }),
    getAllOrders: builder.query({
      providesTags: ['Order'],
      queryFn: queryDecorator(
        coreClient.query<GetAllOrdersQuery, GetAllOrdersQueryVariables>,
        'orders',
        GetAllOrdersDocument
      ),
    }),
    getOrderItems: builder.query({
      providesTags: ['OrderItem'],
      queryFn: queryDecorator(
        legacyClient.query<
          GetConsolidatedOrderItemsQuery,
          GetConsolidatedOrderItemsQueryVariables
        >,
        'consolidatedOrderItems',
        GetConsolidatedOrderItemsDocument
      ),
    }),
    getInvoicesForCase: builder.query({
      providesTags: ['Invoice'],
      queryFn: queryDecorator(
        coreClient.query<
          GetInvoicesForCaseQuery,
          GetInvoicesForCaseQueryVariables
        >,
        'getInvoicesForCase',
        GetInvoicesForCaseDocument
      ),
    }),
    /* part */
    addPart: builder.mutation({
      invalidatesTags: ['Part'],
      queryFn: mutateDecorator(
        coreClient.mutate<AddPartMutation, AddPartMutationVariables>,
        'addPart',
        AddPartDocument
      ),
    }),

    /* patient */
    getPatient: builder.query({
      providesTags: ['Patient'],
      queryFn: queryDecorator(
        legacyClient.query<
          CandidProCustomerQuery,
          CandidProCustomerQueryVariables
        >,
        'customer',
        CandidProCustomerDocument
      ),
    }),

    /* practice */
    getPractices: builder.query({
      providesTags: ['Practice'],
      queryFn: queryDecorator(
        legacyClient.query<GetAllPracticesQuery, GetAllPracticesQueryVariables>,
        'practices',
        GetAllPracticesDocument
      ),
    }),
    getFilteredPractices: builder.query({
      providesTags: ['Practice'],
      queryFn: queryDecorator(
        legacyClient.query<GetPracticesQuery, GetPracticesQueryVariables>,
        'practices',
        GetPracticesDocument
      ),
    }),
    getPractice: builder.query({
      providesTags: ['Practice'],
      queryFn: queryDecorator(
        legacyClient.query<GetPracticeQuery, GetPracticeQueryVariables>,
        'practice',
        GetPracticeDocument
      ),
    }),
    setAccountPreferences: builder.mutation({
      invalidatesTags: ['User'],
      queryFn: mutateDecorator(
        legacyClient.mutate<
          SetAccountPreferencesMutation,
          SetAccountPreferencesMutationVariables
        >,
        'setAccountPreferences',
        SetAccountPreferencesDocument
      ),
    }),

    /* practice payment status */
    getPracticePaymentStatus: builder.query({
      providesTags: ['PracticePaymentStatus'],
      queryFn: queryDecorator(
        coreClient.query<
          GetPracticePaymentStatusQuery,
          GetPracticePaymentStatusQueryVariables
        >,
        'practicePaymentStatus',
        GetPracticePaymentStatusDocument
      ),
    }),

    /* product variants */
    getProductVariants: builder.query({
      providesTags: ['ProductVariant'],
      queryFn: queryDecorator(
        coreClient.query<
          GetProductVariantsQuery,
          GetProductVariantsQueryVariables
        >,
        'productVariants',
        GetProductVariantsDocument
      ),
    }),

    /* treatment plan staging */
    addTreatmentPlanStaging: builder.mutation({
      invalidatesTags: ['TreatmentPlanStaging'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          AddTreatmentPlanStagingMutation,
          AddTreatmentPlanStagingMutationVariables
        >,
        'addTreatmentPlanStaging',
        AddTreatmentPlanStagingDocument
      ),
    }),

    getTreatmentPlanStagingForPatient: builder.query({
      providesTags: ['TreatmentPlanStaging'],
      queryFn: queryDecorator(
        coreClient.query<
          GetTreatmentPlanStagingsByPatientIdQuery,
          GetTreatmentPlanStagingsByPatientIdQueryVariables
        >,
        'getTreatmentPlanStagingMaterials',
        GetTreatmentPlanStagingsByPatientIdDocument
      ),
    }),
    getMostRecentlyApprovedTpStagingForPatient: builder.query({
      providesTags: ['TreatmentPlanStaging'],
      queryFn: queryDecorator(
        coreClient.query<
          GetMostRecentlyApprovedTpStagingForPatientQuery,
          GetMostRecentlyApprovedTpStagingForPatientQueryVariables
        >,
        'getMostRecentlyApprovedTpStagingForPatient',
        GetMostRecentlyApprovedTpStagingForPatientDocument
      ),
    }),

    /* treatment plan tooling */
    addTreatmentPlanTooling: builder.mutation({
      invalidatesTags: ['TreatmentPlanTooling'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          AddTreatmentPlanToolingMutation,
          AddTreatmentPlanToolingMutationVariables
        >,
        'addTreatmentPlanTooling',
        AddTreatmentPlanToolingDocument
      ),
    }),
    generateTemplatedTreatmentPlanToolingFile: builder.mutation({
      invalidatesTags: ['TreatmentPlanTooling'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          GenerateTemplatedTreatmentPlanToolingFileMutation,
          GenerateTemplatedTreatmentPlanToolingFileMutationVariables
        >,
        'generateTemplatedTreatmentPlanToolingFile',
        GenerateTemplatedTreatmentPlanToolingFileDocument
      ),
    }),

    /* workflows */
    saveManufacturingFiles: builder.mutation({
      invalidatesTags: ['Part', 'TreatmentPlanTooling'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          SaveManufacturingFilesMutation,
          SaveManufacturingFilesMutationVariables
        >,
        'saveManufacturingFiles',
        SaveManufacturingFilesDocument
      ),
    }),
  }),
});

export default api;
