import React from 'react';

import {
  Note,
  ProtocolToggleContainer,
  ProtocolSubCopy,
  StyleToggle,
  StyledSparkleIcon,
} from 'pages/AccountPreferencesPage/Treatment/Footer.css';

const TreatmentFooter = ({
  checked,
  protocolName,
  onToggle,
}: {
  checked: boolean;
  protocolName: string;
  onToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div>
      <Note>
        Default answers will have the <StyledSparkleIcon /> icon in the case
        submission flow
      </Note>
      <ProtocolToggleContainer>
        <div>
          <div>Set {protocolName} as my default design protocol</div>
          <ProtocolSubCopy>
            You can choose a different protocol during case submission if
            needed.
          </ProtocolSubCopy>
        </div>
        <div>
          <StyleToggle checked={checked} onChange={onToggle} />
        </div>
      </ProtocolToggleContainer>
    </div>
  );
};

export default TreatmentFooter;
