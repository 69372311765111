import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { colors, type } from 'core/components';

export const Container = styled.button`
  position: relative;
  display: inline-block;
  height: 1.5rem;
  padding: 6px 4px;
  width: 48px;
  height: 26px;
  font-size: ${type.size.t1};
  font-weight: ${type.weight.bold};
  line-height: 1.2;
  color: ${colors.white};
  vertical-align: top;
  border-radius: 16px;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  ${({ color }) =>
    color === 'blue' &&
    css`
      background: ${colors.blue};
    `};

  ${({ color }) =>
    color === 'light-blue' &&
    css`
      color: ${colors.black90};
      background: ${colors.blue10};
    `};

  ${({ color }) =>
    color === 'pink' &&
    css`
      color: ${colors.black90};
      background: ${colors.red30};
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.black50};
      background: ${colors.black10};
    `};
`;
