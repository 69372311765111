import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.text30};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-evenly;
  padding: 0.5rem 0.5rem;
`;

export const StyledCheckbox = styled.input<{ $step: number }>`
  display: flex;
  appearance: initial;
  font-family: ${({ theme }) => theme.fonts.modernEraMono};
  font-size: 0.8rem;
  border: 1px solid ${({ theme }) => theme.colors.text50};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.text60};
  :checked {
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.blue};
  }
  :after {
    content: '${({ $step }) => $step}';
  }
`;

export const ArchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  flex-wrap: wrap;
  width: 125%;
  gap: 0.3rem;
`;
