import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import PracticeSearch from 'pages/PracticeManagement/Search';
import PracticeManager from 'pages/PracticeManagement/PracticeManager';

const PracticeSettings = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route component={PracticeSearch} exact path={match.path} />
      <Route
        component={PracticeManager}
        exact
        path={`${match.path}/:practiceId`}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default PracticeSettings;
