import styled from 'styled-components/macro';

export const EmptyText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  color: ${({ theme }) => theme.colors.text70};
`;

export const HistoryList = styled.ul`
  > li {
    position: relative;
    padding-left: 2.5rem;
    margin-bottom: 1.5rem;
  }

  svg {
    position: absolute;
    top: 0.25rem;
    left: 0;
    width: 2rem;
    height: 1.5rem;
  }
`;

export const DateTime = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  color: ${({ theme }) => theme.colors.text70};
`;

export const ReasonTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const Details = styled.div`
  color: ${({ theme }) => theme.colors.text70};
`;

export const Notes = styled.div`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.text70};
`;

export const NotesText = styled.div`
  font-style: italic;
`;

export const ReasonList = styled.ul<{
  isOrtho: boolean;
}>`
  margin: 0.5rem 0;

  > li {
    padding-left: ${({ isOrtho }) => isOrtho && '1rem'};
    margin-bottom: 0.25rem;
  }
`;
