import { colors } from 'core/components';
import styled from 'styled-components/macro';
import { css } from 'styled-components';

export const UploadedScanContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  gap: 16px;
  border: 1px solid ${({ theme }) => theme.colors.text30};
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
`;

export const UploadedScanContainerLeft = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
  > div {
    font-weight: 500;
  }
`;

export const UploadForm = styled.div<{ required?: boolean }>`
  height: 72px;
  ${({ required, theme }) =>
    required &&
    css`
      background: ${colors.blue05};
      border: 1px dashed ${theme.colors.blue50};
      border-radius: 8px;
    `}}
`;

export const RemoveButton = styled.button`
  width: 20px;
  height: 20px;
`;
