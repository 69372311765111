const max = 2 ** 31 - 1;

export default {
  max,
  aboveLiveChat: max,

  alert: 10000,
  modal: 1000,
  menu: 100,
  base: 0,
  buried: -100,
};
