import styled from 'styled-components/macro';
import { colors, Button, theme } from 'core/components';

type SectionProps = {
  isDisabled?: boolean;
};

export const Container = styled.div<SectionProps>`
  display: flex;
  flex-direction: row;
  padding: 24px 24px 32px;
  gap: 24px;
  margin-bottom: 12px;
  max-width: 680px;
  border-radius: ${theme.radii.large};

  ${({ isDisabled }) =>
    isDisabled
      ? `
    border: 1px solid rgba(0, 0, 0, 0.03);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    opacity: 0.5;
    `
      : `
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.13);
  `};
`;

export const CompletedContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px;
  gap: 24px;
  margin-bottom: 12px;
  max-width: 680px;
  border-radius: ${theme.radii.large};
  border: 2px solid rgba(0, 0, 0, 0.07);
  background: ${colors.black05};
  cursor: pointer;
`;

export const RightIconContainer = styled.div`
  align-self: center;
  margin-left: auto;
  min-width: 24px;
`;

export const LeftIconContainer = styled.div`
  min-width: 24px;
`;

export const BodyContainer = styled.div``;

export const Title = styled.div`
  font-size: ${theme.text.body.large.fontSize};
  font-weight: ${theme.fontWeights.bold};
`;

export const Description = styled.div`
  font-size: ${theme.text.body.small.fontSize};
  color: ${colors.black70};
`;

export const ActionButton = styled(Button)`
  margin-top: 1rem;
`;
