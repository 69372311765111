import React, { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  fetchMaterials,
  selectSelectedCase,
  selectAreTreatmentGoalsReadyForSubmit,
  selectAreDiagnosticMaterialsReadyForSubmit,
  selectPatient,
  selectCanCollectTreatmentGoals,
} from 'pages/Patient/patientSlice';
import { usePatientLoadingStates } from 'pages/Patient/utils';
import {
  Wrapper,
  StyledCheckCircle,
  StyledCirleOutline,
  StatusLines,
  StatusLineWrapper,
  StatusLineName,
  ContinueButton,
} from 'pages/Patient/PatientDetail/IncompleteSubmission.css';
import { IncompleteSubmisionSkeleton } from 'pages/Patient/PatientDetail/Skeletons';

export type StatusLineProps = {
  isComplete?: boolean;
  name: string;
};

const StatusLine = ({ isComplete = false, name }: StatusLineProps) => {
  return (
    <StatusLineWrapper>
      {isComplete ? <StyledCheckCircle /> : <StyledCirleOutline />}
      <StatusLineName isComplete={isComplete}>{name}</StatusLineName>
    </StatusLineWrapper>
  );
};

const IncompleteSubmission = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const selectedCase = useSelector(selectSelectedCase);

  const patient = useSelector(selectPatient);
  const canCollectTreatmentGoals = useSelector(selectCanCollectTreatmentGoals);
  const areMaterialsComplete = useSelector(
    selectAreDiagnosticMaterialsReadyForSubmit
  );
  const { areMaterialsFetching } = usePatientLoadingStates();

  const { 'beta-treatment-goals': fetchBetaForms } = useFlags();

  useLayoutEffect(() => {
    if (!selectedCase?.caseRef) {
      return;
    }
    dispatch(
      fetchMaterials({
        caseRef: selectedCase?.caseRef,
        fetchBetaForms,
        formsToFetch: [], //We don't care about fetching all forms, because we're only displaying the answers
      })
    );
  }, [selectedCase, fetchBetaForms]);

  const areTreatmentGoalsComplete = useSelector(
    selectAreTreatmentGoalsReadyForSubmit
  );

  const onContinueClick = () => {
    push(`/patient/${patient?.id}/case-creator`);
  };

  if (areMaterialsFetching) {
    return <IncompleteSubmisionSkeleton />;
  }

  return (
    <Wrapper>
      <StatusLines>
        <StatusLine name="Basic info" isComplete />
        <StatusLine name="Materials" isComplete={areMaterialsComplete} />
        {canCollectTreatmentGoals && (
          <StatusLine
            name="Treatment goals"
            isComplete={areTreatmentGoalsComplete}
          />
        )}
        <StatusLine name="Review & submit" />
      </StatusLines>
      <ContinueButton buttonType="secondary" onClick={onContinueClick}>
        Continue submission
      </ContinueButton>
    </Wrapper>
  );
};

export default IncompleteSubmission;
