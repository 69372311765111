import React, { useState, useRef } from 'react';
import { Popover as MuiPopover } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import {
  BasePopoverProps,
  HelpToolTipIcon,
} from 'core/components/Popover/Popover.css';

const useStyles = makeStyles(() =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    popoverContent: {
      pointerEvents: 'auto',
    },
  })
);

//
// NOTE: This is different from ToolTip!!!
// This allows you to put ANY content in a popup not just text.
//
const PopoverHover = (props: BasePopoverProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const popoverAnchor = useRef(null);

  const { anchor, PaperProps, anchorOrigin, open, ...rest } = props;

  const handlePopoverLeave = () => {
    setIsOpen(false);
  };

  const handlePopoverEnter = () => {
    setIsOpen(true);
  };

  return (
    <div>
      <div
        ref={popoverAnchor}
        aria-haspopup="true"
        aria-owns="candid-popover"
        onMouseEnter={handlePopoverEnter}
        onMouseLeave={handlePopoverLeave}
      >
        {anchor || <HelpToolTipIcon />}
      </div>
      <MuiPopover
        anchorEl={popoverAnchor.current}
        anchorOrigin={anchorOrigin}
        classes={{
          paper: classes.popoverContent,
        }}
        className={classes.popover}
        id="candid-popover"
        open={!!(isOpen || open)}
        PaperProps={{
          onMouseEnter: handlePopoverEnter,
          onMouseLeave: handlePopoverLeave,
          ...PaperProps,
        }}
        {...rest}
      />
    </div>
  );
};

export default PopoverHover;
