import ContactUs from 'pages/ContactUs/ContactUs';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

const ContactUsPage = ({ match }: RouteComponentProps) => (
  <Switch>
    <Route path={match.path} exact>
      <ContactUs />
    </Route>
  </Switch>
);

export default ContactUsPage;
