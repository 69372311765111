export enum EvaluationState {
  approved = 'approved',
  started = 'started',
  rejected = 'rejected',
}

export enum AggregateState {
  Collection = 'collection',
  Evaluation = 'evaluation',
  SubmissionUnderReview = 'submission_under_review',
  Completed = 'completed',
  CouldNotTreat = 'could_not_treat',
}
