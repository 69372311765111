import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { type } from 'core/components';

import {
  FormikInputWrapper,
  FormikDatePickerNoHeader,
} from 'components/FormikForms';
import {
  requiredValidator,
  validateGuardianDateOfBirth,
  phoneValidator,
  normalizePhoneNumber,
} from 'components/FormikForms/utils';

import { HRDividerLine, HeaderContainer } from 'styles/layout.css';
import { SectionHeading } from 'pages/Patient/CaseCreator/BasicInfo/BasicInfo.css';
import { Grid } from 'core/components';
import { FormProps } from 'pages/Patient/CaseCreator/BasicInfo/BasicInfoForm';

type Props = {
  largeHeader?: boolean;
};

const LegalGuardianInfoForm = ({ largeHeader }: Props) => {
  const { dirty, setFieldValue } = useFormikContext<FormProps>();

  useEffect(() => {
    if (dirty) {
      // set changes so if the user tries to leave the while filling the form
      // they will get notified the data wont save
      // setHasChanges(true);
      console.log('dirty');
    }
  }, [dirty]);

  return (
    <>
      <HRDividerLine />
      {largeHeader ? (
        <HeaderContainer>
          <type.H4>Guardian Info</type.H4>
        </HeaderContainer>
      ) : (
        <SectionHeading data-testid="section-heading">
          Guardian Info
        </SectionHeading>
      )}
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        style={{ marginLeft: '-0.5rem', marginTop: 0 }}
      >
        <Grid item xs={6} md={4}>
          <FormikInputWrapper
            label="First name*"
            name="legalGuardian.firstName"
            type="text"
            validate={requiredValidator}
            data-private
            testId={'input-guardian-first-name'}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormikInputWrapper
            label="Middle name"
            name="legalGuardian.middleName"
            type="text"
            data-private
            testId={'input-guardia-middle-name'}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormikInputWrapper
            label="Last name*"
            name="legalGuardian.lastName"
            type="text"
            validate={requiredValidator}
            testId={'input-guardian-last-name'}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormikInputWrapper
            label="Preferred name*"
            name="legalGuardian.preferredName"
            type="text"
            validate={requiredValidator}
            data-private
            testId={'input-guardian-preferred-name'}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormikInputWrapper
            label="Phone number*"
            name="legalGuardian.phone"
            type="tel"
            onChange={(e) =>
              normalizePhoneNumber(
                'legalGuardian.phone',
                e.target.value,
                setFieldValue
              )
            }
            validate={phoneValidator}
            data-private
            testId={'input-guardian-phone'}
          />
        </Grid>
      </Grid>
      <FormikDatePickerNoHeader
        label="Date of birth*"
        name="legalGuardian.birthday"
        type="text"
        validate={validateGuardianDateOfBirth}
        placeholder={'mm/dd/yyyy'}
        data-private
      />
    </>
  );
};

export default LegalGuardianInfoForm;
