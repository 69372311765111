import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import MarketingResources from 'pages/MarketingResources/MarketingResources';

const MarketingResourcesPage = ({ match }: RouteComponentProps) => (
  <Switch>
    <Route path={match.path} exact>
      <MarketingResources />
    </Route>
  </Switch>
);

export default MarketingResourcesPage;
