import {
  Filter,
  QueryResult,
  Sort,
} from 'components/SearchTablePage/customerQuerier/type';

import customerFirstStrategy from 'components/SearchTablePage/customerQuerier/strategy/customerFirst';

/**
 * This is a strategy pattern for querying customers and cases.
 *
 * Depending on the filter/sort conditions being provided, it can be necessary
 * to query customers first and then cases, or vice versa in order to get correct
 * data and in order to return a full page of entries upon each fetch.
 *
 * This function allows to hide the complexity of choosing and applying the right
 * fetching strategy behind a single call exposing none of the internal details.
 */
export default (
  pageSize: number,
  filter: Filter,
  sort: Sort,
  callback: (partial: QueryResult) => void
) => {
  // Currently defaulting to the existing "legacy" strategy that always fetches
  // customers first and then cases eventhough this is known to be incorrect for
  // certain use-cases.
  const res = customerFirstStrategy(true)(pageSize, filter, sort, callback);

  return {
    cancel: res.cancel,
    promise: res.promise(),
  };
};

export * from 'components/SearchTablePage/customerQuerier/type';
