export type FileInfo = {
  url: string;
  filename: string;
};

const filePrefixRegex = /^.+\//;
export const sanitizeMaterialName = (
  name: string | null | undefined
): string => (name ? name.replace(filePrefixRegex, '') : '');

// This function exists to ensure backwards compatability. Going forward for Xrays, we ask users to check a box
// affirming they are up to date. Old cases captured a full date. So if we dont have the checkbox value, we
// check if the capture date was within a year of upload. Currently only used for Xrays but can apply to other materials in the future
export const isUpToDate = (
  createdTime: string,
  captureDate?: string | null
): boolean => {
  if (captureDate) {
    const d = new Date(captureDate);
    d.setFullYear(d.getFullYear() + 1);
    return d.getTime() >= new Date(createdTime).getTime();
  }
  return false;
};

export const cleanFilenameForAws = (filename: string) =>
  filename
    .replace(/\s/g, '_')
    .replace(/[&#,+!@^()$~%\\'":*`?<>~[{}|'%+(*?)\]+]/g, '');

export const formatFilesNames = (files: File[]) => {
  // This utility replaces spaces with underlines and strips special characters
  // so that files can be downloaded from AWS
  return files.map((file) => {
    return new File([file], cleanFilenameForAws(file.name), {});
  });
};
