import { type } from 'core/components';
import { CaseAction } from 'pages/OrthoPrism/CaseActions/constants';
import styled from 'styled-components';

export const Body = styled.div<{
  rightMargin?: boolean;
}>`
  text-align: right;
  margin-right: ${({ rightMargin }) => rightMargin && '1.5rem'};
`;

export const CloseCaseWrap = styled.div`
  min-height: 15rem;
`;

export const CaseLink = styled(type.Link)<{
  action?: CaseAction;
  disabled?: boolean;
}>`
  font-weight: 300;
  color: ${({ disabled }) => (disabled ? 'gray' : '')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  &:hover {
    cursor: pointer;
  }
`;
