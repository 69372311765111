import React, { ReactNode, useState } from 'react';
import { Button, Loading } from 'core/components';
import { HelpTooltip } from 'components/HelpTooltip';
import moment from 'moment';
import CheckSvg from 'assets/check.svg?react';
import styled from 'styled-components/macro';
import { LanguageToggle } from 'components/LanguageToggle';
import { useTranslation } from 'react-i18next';
import { TreatmentPlanStaging } from 'generated/core/graphql';

const WhiteSvg = styled(CheckSvg)`
  path {
    stroke: white;
  }
`;

export const HeaderSection = ({ caseType }: { caseType: string }) => {
  const { t } = useTranslation();
  return (
    <div style={{ alignSelf: 'start', display: 'flex', width: '100%' }}>
      <div style={{ display: 'flex', gap: '2rem', width: '100%' }}>
        <div style={{ flexShrink: 0 }}>
          <h2>{t('common.treatment_planning')}</h2>
          <p>{caseType}</p>
        </div>
        <LanguageToggle />
      </div>
    </div>
  );
};

export const TPVersionSection = ({
  selectedTreatmentPlan,
  treatmentPlans,
  onClick,
  isLoading = false,
}: {
  selectedTreatmentPlan?: TreatmentPlanStaging;
  treatmentPlans?: any[];
  onClick: (plan: TreatmentPlanStaging) => void;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();
  if (treatmentPlans?.length === 1) {
    return null;
  }
  return (
    <div style={{ alignSelf: 'start' }}>
      <h3>{t('common.versions')}</h3>
      <div style={{ display: 'inline-flex' }}>
        {isLoading && <Loading />}
        {!isLoading &&
          treatmentPlans &&
          treatmentPlans.map((plan, index) => {
            return (
              <Button
                style={{
                  color: 'black',
                  marginRight: index === 0 ? '.5rem' : '.2rem',
                  opacity: selectedTreatmentPlan?.id === plan.id ? '1' : '.4',
                  textDecoration:
                    selectedTreatmentPlan?.id === plan.id
                      ? 'underline'
                      : 'none',
                }}
                key={index}
                buttonType="text"
                onClick={() => onClick(plan)}
              >
                {plan.iteration === 0 ? t('common.new') : `v${plan.iteration}`}
              </Button>
            );
          })}
      </div>
    </div>
  );
};

export const VersionDetails = ({
  treatmentPlan,
  state,
}: {
  treatmentPlan: TreatmentPlanStaging;
  state: string | undefined;
}) => {
  const { t } = useTranslation();
  if (!treatmentPlan.createdAt) {
    return null;
  }

  return (
    <ActionSection
      title={`${t('tp_central_enhanced.details')} (${state?.toLowerCase()})`}
      buttonTitle={t('common.show')}
    >
      <div style={{ textAlign: 'left' }}>
        <p>
          {t('tp_central_enhanced.created_on')}{' '}
          {moment(treatmentPlan.createdAt).format('MMM DD, YYYY h:MMa')}
        </p>
        {state && (
          <p>
            {t('common.state')}: {state?.toLowerCase()}
          </p>
        )}
        {treatmentPlan.url && (
          <iframe
            title="treatment plan viewer"
            style={{ width: '100%', height: '500px' }}
            src={treatmentPlan.url}
          />
        )}
      </div>
    </ActionSection>
  );
};

export const ActionSection = ({
  title,
  buttonTitle = 'show',
  buttonAction,
  isDisabled = false,
  disabledText = '',
  isComplete = false,
  showWhenComplete = false,
  children,
}: {
  title: string;
  buttonTitle?: string;
  buttonAction?: () => void;
  isDisabled?: boolean;
  disabledText?: string;
  isComplete?: boolean;
  showWhenComplete?: boolean;
  children?: ReactNode;
}) => {
  const [expandSection, setExpandSection] = useState(false);
  const { t } = useTranslation();
  const handleButtonAction = () => {
    if (buttonAction) {
      buttonAction();
      return;
    }

    setExpandSection(!expandSection);
  };

  // show the Action section if a section is incomplete, or if we are overriding the isComplete flag
  // Currently we override isComplete for the "Upload Manufacturing Files" action section, since we allow users to upload TP tooling files more than once
  const showActionSection = showWhenComplete || !isComplete;
  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          margin: '0 auto',
          border: '1px solid grey',
          borderRadius: '5px',
          textAlign: 'center',
          padding: '1rem',
        }}
      >
        <div style={{ display: 'flex' }}>
          {isComplete && (
            <div
              style={{
                border: '1px solid green',
                borderRadius: '50px',
                background: '#30a973',
                width: '30px',
                height: '30px',
                marginRight: '1rem',
                paddingTop: '2px',
              }}
            >
              <WhiteSvg />
            </div>
          )}
          <h3>{title}</h3>
          {showActionSection && (
            <>
              <div style={{ marginLeft: 'auto' }}>
                <Button
                  buttonType="secondary"
                  disabled={isDisabled}
                  onClick={handleButtonAction}
                >
                  {expandSection ? t('common.hide') : buttonTitle}
                </Button>
              </div>
              {disabledText && isDisabled && (
                <HelpTooltip content={disabledText} />
              )}
            </>
          )}
        </div>
        {expandSection && <>{children}</>}
      </div>
    </div>
  );
};
