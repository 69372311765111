import React, { useContext, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Modal,
  type,
  NotificationContext,
  Grid,
} from 'core/components';
import { Formik, useFormikContext } from 'formik';

import {
  FormikDatePickerNoHeader,
  FormikInputWrapper,
  FormikSelectWrapper,
  ValidateOn,
} from 'components/FormikForms';
import { HRDividerLine } from 'styles/layout.css';
import { AuthContext } from 'components/AuthProvider';
import { convertPracticesToFormikOptions } from 'components/FormikForms/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CaseTypeNames } from 'constants/Case';
import { useCreateCustomerAndCase } from 'pages/Patient/CaseCreator/BasicInfo/helpers';

import {
  emailValidator,
  requiredValidator,
  phoneValidator,
  normalizePhoneNumber,
  validateDateOfBirth,
  validateGuardianDateOfBirth,
} from 'components/FormikForms/utils';
import { getBrandSKUs, convertToBrand, CANDID_BRAND_NAME } from 'utils/brands';

import {
  ButtonContainer,
  Header,
  HeaderTitle,
  StyledCloseButton,
  ModalBody,
  ModalContainer,
  PatientInfoForm,
  PatientInfoFormRow,
  ProductButtonContainer,
  ProductButtonDescription,
  ProductDescriptionBodyText,
  ProductDescriptionHeader,
  ProductSelectionContainer,
  SectionHeader,
  StyledImage,
  StyledCancelButton,
} from 'pages/ActionItems/NewPatientModal/NewPatientModal.css';
import {
  NewPatientModalProps,
  FormProps,
  ProductButtonProps,
  CaseProductType,
} from 'pages/ActionItems/NewPatientModal/types';
import { AddressFormType } from 'components/AddressForm/types';
import moment from 'moment';

import AddressForm from 'components/AddressForm/AddressForm';
import { validateAddressViaGql } from 'components/AddressForm/utils';
import { mediaQueries } from 'core/components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const initialValues: FormProps = {
  firstName: '',
  lastName: '',
  email: '',
  dateOfBirth: null,
  day: '',
  month: '',
  year: '',
  preferredName: '',
  middleName: '',
  phone: '',
  practiceId: '',
  shippingAddress: {
    addressType: '',
    businessName: '',
    countryCode: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateCode: '',
    zip: '',
  },
  legalGuardian: {
    birthday: null,
    firstName: '',
    lastName: '',
    phone: '',
    middleName: '',
    preferredName: '',
  },
};

const NewPatientModal = ({ isOpen, onClose }: NewPatientModalProps) => {
  const { userInfo } = useContext(AuthContext);
  const brand = convertToBrand(userInfo?.brandInfo?.name, CANDID_BRAND_NAME);
  const isMobile = useMediaQuery(mediaQueries.mobile);

  const { push } = useHistory();

  const practiceOptions = convertPracticesToFormikOptions(
    userInfo?.doctor?.practices
  );
  const { createCustomerAndCase } = useCreateCustomerAndCase();
  const { showNotification } = useContext(NotificationContext);

  const [isCreatingCustomerAndCase, setIsCreatingCustomerAndCase] =
    useState(false);
  const [selectedProduct, setSelectedProduct] = useState<CaseProductType>(
    CaseProductType.NONE
  );

  const { 'bypass-ortho-review-process': bypassOrthoReviewProcess } =
    useFlags();

  const innerRef = useRef<HTMLButtonElement>(null);

  const validateSubmittedAddress = async (_: FormProps) => {
    //Trigger the address validation on the inner form
    //TODO: I think this might be able to be handled by an actual inner formik form.
    if (innerRef?.current) {
      innerRef.current?.click();
    }
  };

  const handleSubmit = async (values: FormProps) => {
    try {
      setIsCreatingCustomerAndCase(true);
      const referringDentistId = userInfo?.doctor?.id;
      const requireOrthoReview = !bypassOrthoReviewProcess;

      const {
        firstName,
        lastName,
        email,
        dateOfBirth,
        preferredName,
        phone,
        middleName,
        shippingAddress,
        practiceId,
        legalGuardian,
      } = values;

      const { customerId } = await createCustomerAndCase({
        email,
        firstName,
        lastName,
        preferredName,
        dateOfBirth,
        referringDentistId,
        practiceId,
        phone,
        middleName,
        shippingAddress,
        requireOrthoReview,
        legalGuardian,
        caseType:
          selectedProduct === CaseProductType.ALIGNER
            ? CaseTypeNames.ALIGNER
            : CaseTypeNames.RETAINER,
      });

      push(`/patient/${customerId}/case-creator`);
    } catch (err: unknown) {
      if (err instanceof Error) {
        showNotification(err.message, 'error');
      }
    } finally {
      setIsCreatingCustomerAndCase(false);
    }
  };

  const ProductButton = ({
    img,
    title,
    body,
    isSelected,
    onClick,
  }: ProductButtonProps) => {
    return (
      <ProductButtonContainer isSelected={isSelected} onClick={onClick}>
        <StyledImage src={img} alt="" />
        <ProductButtonDescription>
          <ProductDescriptionHeader>{title}</ProductDescriptionHeader>
          <ProductDescriptionBodyText>{body}</ProductDescriptionBodyText>
        </ProductButtonDescription>
      </ProductButtonContainer>
    );
  };
  const { aligner, retainer } = getBrandSKUs(brand);
  const ProductSelection = () => {
    return (
      <ProductSelectionContainer>
        <SectionHeader>Select a product</SectionHeader>

        <ProductButton
          img={aligner.imageSrc}
          title="Comprehensive aligners"
          body={
            'Our proprietary down-to-the-micron manufacturing process creates some of the best clear aligners in the industry.'
          }
          isSelected={selectedProduct === CaseProductType.ALIGNER}
          onClick={() => setSelectedProduct(CaseProductType.ALIGNER)}
        />
        <ProductButton
          img={retainer.imageSrc}
          title="PermaForm™ retainers"
          body={
            'Our ortho-recommended retention product based on new patient scans. Order up to 4 at a time.'
          }
          isSelected={selectedProduct === CaseProductType.RETAINER}
          onClick={() => setSelectedProduct(CaseProductType.RETAINER)}
        />
      </ProductSelectionContainer>
    );
  };

  const ModalContent = () => {
    const { handleSubmit, isValidating, values, resetForm } =
      useFormikContext<FormProps>();

    //If we're validating on blur, there is an issue where the last form element
    //doesn't get validated until the user leaves the field, creating a situation where
    //they can't continue. Rather than checking if the form is valid, enable the button
    //If all required fields have values. We will preform one last validate anyway before submitting
    const formIsValid = () => {
      return (
        values.firstName &&
        values.lastName &&
        values.email &&
        values.dateOfBirth &&
        values.preferredName &&
        values.phone &&
        values.shippingAddress.addressLine1 &&
        values.shippingAddress.city &&
        values.shippingAddress.stateCode &&
        values.shippingAddress.zip &&
        values.shippingAddress.countryCode
      );
    };

    const canSubmit =
      selectedProduct !== CaseProductType.NONE &&
      !isCreatingCustomerAndCase &&
      formIsValid() &&
      !isValidating;

    const internalOnClose = () => {
      resetForm();
      setSelectedProduct(CaseProductType.NONE);
      onClose();
    };
    const ButtonFooter = () => (
      <ButtonContainer>
        <StyledCancelButton
          buttonType="text"
          onClick={internalOnClose}
          disabled={isCreatingCustomerAndCase}
        >
          Cancel
        </StyledCancelButton>
        <Button
          buttonType="secondary"
          onClick={() => handleSubmit()}
          isLoading={isCreatingCustomerAndCase}
          disabled={!canSubmit}
        >
          Continue
        </Button>
      </ButtonContainer>
    );

    return (
      <>
        <Header>
          <HeaderTitle>New patient</HeaderTitle>
          <button onClick={internalOnClose}>
            <StyledCloseButton />
          </button>
        </Header>
        <ModalBody>
          <ProductSelection />
          <PatientInfo />
          {isMobile && <ButtonFooter />}
        </ModalBody>
        {!isMobile && <ButtonFooter />}
      </>
    );
  };

  const PatientInfo = () => {
    const { setFieldValue, values } = useFormikContext<FormProps>();

    const isMinor =
      values.dateOfBirth && moment().diff(values.dateOfBirth, 'years') < 18;

    return (
      <div>
        <SectionHeader>Patient info</SectionHeader>
        <PatientInfoForm>
          <PatientInfoFormRow>
            <FormikSelectWrapper
              name="practiceId"
              placeholder="Practice *"
              type="select"
              options={practiceOptions}
              validate={requiredValidator}
              showDefaultValue
            />
          </PatientInfoFormRow>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormikInputWrapper
                name="firstName"
                placeholder="First name *"
                type="text"
                validate={requiredValidator}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormikInputWrapper
                name="middleName"
                type="text"
                placeholder="Middle name"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormikInputWrapper
                name="lastName"
                type="text"
                placeholder="Last name *"
                validate={requiredValidator}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormikInputWrapper
                name="preferredName"
                placeholder="Preferred name *"
                type="text"
                validate={requiredValidator}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormikInputWrapper
                name="phone"
                type="tel"
                placeholder="Phone number *"
                onChange={(e) => {
                  normalizePhoneNumber('phone', e.target.value, setFieldValue);
                }}
                validate={phoneValidator}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormikDatePickerNoHeader
                name="dateOfBirth"
                type="text"
                placeholder="Date of birth *"
                validate={validateDateOfBirth}
                validateOn={ValidateOn.Blur}
              />
            </Grid>
          </Grid>
          <PatientInfoFormRow>
            <FormikInputWrapper
              name="email"
              type="text"
              placeholder={
                isMinor
                  ? 'Patient or guardian email address *'
                  : "Patient's email address *"
              }
              validate={emailValidator}
            />
          </PatientInfoFormRow>
        </PatientInfoForm>
        <HRDividerLine />
        {isMinor && (
          <>
            <type.H5>Guardian info</type.H5>
            <PatientInfoForm>
              <PatientInfoFormRow>
                <FormikInputWrapper
                  name="legalGuardian.firstName"
                  placeholder="First name *"
                  type="text"
                  validate={requiredValidator}
                />
                <FormikInputWrapper
                  name="legalGuardian.middleName"
                  type="text"
                  placeholder="Middle name"
                />
                <FormikInputWrapper
                  name="legalGuardian.lastName"
                  type="text"
                  placeholder="Last name *"
                  validate={requiredValidator}
                />
              </PatientInfoFormRow>
              <PatientInfoFormRow>
                <FormikInputWrapper
                  name="legalGuardian.preferredName"
                  placeholder="Preferred name *"
                  type="text"
                  validate={requiredValidator}
                />
                <FormikInputWrapper
                  name="legalGuardian.phone"
                  type="tel"
                  placeholder="Phone number *"
                  onChange={(e) => {
                    normalizePhoneNumber(
                      'legalGuardian.phone',
                      e.target.value,
                      setFieldValue
                    );
                  }}
                  validate={phoneValidator}
                />
                <FormikDatePickerNoHeader
                  name="legalGuardian.birthday"
                  type="text"
                  placeholder="Date of birth *"
                  validate={validateGuardianDateOfBirth}
                  validateOn={ValidateOn.Blur}
                />
              </PatientInfoFormRow>
            </PatientInfoForm>
            <HRDividerLine />
          </>
        )}
        <type.H5>Patient home address</type.H5>
        <type.BodySmall>
          This address will be stored on the patient record, but you’ll be able
          to choose if you want to ship to the patient or your practice after
          you review the treatment plan.
        </type.BodySmall>
        {isOpen && (
          <AddressForm
            addressPath="shippingAddress."
            getAddressData={(values: FormProps) => values.shippingAddress}
            submitButtonRef={innerRef}
            submitForm={handleSubmit}
            updateAddress={(
              value: AddressFormType,
              originalValues: FormProps
            ) => {
              return {
                ...originalValues,
                shippingAddress: {
                  addressLine1: value.addressLine1,
                  addressLine2: value.addressLine2,
                  city: value.city,
                  stateCode: value.stateCode,
                  zip: value.zip,
                  //Smarty street doesn't return country, so use the original.
                  countryCode: originalValues.shippingAddress.countryCode,
                  businessName: originalValues.shippingAddress.businessName,
                },
              };
            }}
          />
        )}
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton
      closeOnOverlayClick={false}
    >
      <ModalContainer isMobile={isMobile}>
        <Formik
          validateOnMount={true}
          validateOnBlur={true}
          validateOnChange={false}
          initialValues={initialValues}
          onSubmit={(values) => validateSubmittedAddress(values)}
          validate={(values) =>
            validateAddressViaGql(
              values,
              (values) => values.shippingAddress,
              'shippingAddress.'
            )
          }
        >
          <ModalContent />
        </Formik>
      </ModalContainer>
    </Modal>
  );
};

export default NewPatientModal;
