import React from 'react';
import styled from 'styled-components/macro';
import { Skeleton, Grid, Divider } from 'core/components';
import {
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledAccordion,
} from 'pages/ActionItems/ActionItems.css';
import Table from 'pages/ActionItems/TaskTable';
import { JourneyStatus } from 'pages/ActionItems/types';

const DEFAULT_TASK_LENGTH = 5;

const SkeletonContainer = styled(Grid)`
  width: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
`;

const SkeletonTaskContainer = styled.div`
  padding-bottom: 0.4rem;
`;

const StyledSkeletonTask = styled(Skeleton)`
  padding-bottom: 1rem;
  width: 100%;
`;

const SkeletonDonutChartContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const SkeletonDonutChart = () => (
  <SkeletonDonutChartContainer data-testid="donut-skeleton">
    <Skeleton variant="circle" width={180} height={180} />
  </SkeletonDonutChartContainer>
);

export const SkeletonTask = () => (
  <SkeletonContainer item data-testid="task-skeleton">
    {Array.from({ length: DEFAULT_TASK_LENGTH }).map((_) => {
      return (
        <>
          <Divider />
          <SkeletonTaskContainer>
            <StyledSkeletonTask />
          </SkeletonTaskContainer>
        </>
      );
    })}
  </SkeletonContainer>
);

export const SkeletonTable = () => (
  <StyledAccordion expanded>
    <StyledAccordionSummary expandIcon>
      <Grid container direction="row">
        <Grid item>
          <Skeleton width={200} height={25} />
        </Grid>
        <Grid item>
          <Skeleton
            style={{ marginLeft: 12 }}
            variant="circle"
            width={30}
            height={30}
          />
        </Grid>
      </Grid>
    </StyledAccordionSummary>
    <StyledAccordionDetails>
      <Table
        journeyStatus={JourneyStatus.incompleteSubmission}
        tasks={[]}
        isFetching
      />
    </StyledAccordionDetails>
  </StyledAccordion>
);
