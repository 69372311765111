import React from 'react';

import DocumentTitle from 'components/DocumentTitle';
import { PageHeading, PageSection } from 'styles/layout.css';

const NotFound = () => (
  <DocumentTitle title="Page not found">
    <PageSection>
      <PageHeading>Page not found</PageHeading>
    </PageSection>
  </DocumentTitle>
);

export default NotFound;
