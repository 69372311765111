import styled from 'styled-components';
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  ResponsiveValue,
  space,
  SpaceProps,
  variant,
  VariantArgs,
} from 'styled-system';

export type HeadingVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type HeadingProps = ColorProps &
  LayoutProps &
  SpaceProps &
  VariantArgs & {
    variant?: ResponsiveValue<HeadingVariants>;
  };

const Heading = styled.div<HeadingProps>`
  ${color}
  ${layout}
  ${space}
  ${variant({
    scale: 'text.headings',
  })}
`;

export default Heading;
