import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { SelectInput } from 'core/components';
import CompareIcon from 'assets/ic_minimize.svg?react';
import { FunctionComponent } from 'react';
import { ValuesOf } from 'utils/types';
import { Materials } from 'constants/Material';

export const TabLabel = {
  TreatmentGoals: 'Treatment goals',
  TreatmentDetails: 'Treatment details',
  Photos: 'Photos',
  Xrays: 'X-rays',
  Plan: 'Plan',
  Scans: 'Scans',
} as const;

export type TabLabelType = ValuesOf<typeof TabLabel>;

export type DiagnosticMaterialTab = {
  id?: Materials;
  name: TabLabelType;
  Component: FunctionComponent<TabProps>;
  fetchType?: string;
  isDisabled?: boolean;
  showReadyForReview?: boolean;
  caseStateKey?: string;
  allowCompareWithSelf?: boolean;
};

type TabProps = {
  refreshMaterials?: (
    reloadScans: boolean,
    reloadXrays: boolean,
    reloadPhotos: boolean,
    caseRef: string
  ) => void;
};

const Wrapper = styled.div`
  display: flex;
  width: 326px;
  flex-direction: row;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
`;

const Label = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 40px;
  justify-content: center;
  padding-bottom: 3px;
`;

const StyledSelectInput = styled(SelectInput)`
  & .value-container {
    border: none;
  }
`;
const style = {
  control: (base: any) => ({
    ...base,
    border: 0,
    // This line disable the blue border
    boxShadow: 'none',
  }),
};
type CompareOption = {
  label: string;
  value: string;
  allowCompareWithSelf: boolean;
};

type Props = {
  comparableTabs: DiagnosticMaterialTab[];
  currentTab: string;
  compareTab: string;
  onCompare: (tab: string) => void;
};

const Compare = ({
  comparableTabs,
  currentTab,
  compareTab,
  onCompare,
}: Props) => {
  const toOption = (section: DiagnosticMaterialTab): CompareOption => ({
    label:
      currentTab === section.name
        ? `Another ${section.name.toLocaleLowerCase()}`
        : section.name,
    value: section.name.toString(),
    allowCompareWithSelf: section.allowCompareWithSelf ?? false,
  });

  const options = comparableTabs.map(toOption);
  const available = options.filter(
    (option) => option.allowCompareWithSelf || option.value !== currentTab
  );
  const current = options.find((option) => option.value === compareTab) ?? null;

  const handleChange = useCallback(
    (option) => {
      if (!option) {
        onCompare('');
        return;
      }

      onCompare(option.value as TabLabelType);
    },
    [onCompare]
  );

  return (
    <Wrapper>
      <Label>
        <CompareIcon />
      </Label>
      <StyledSelectInput
        isClearable
        value={current}
        options={available}
        placeholder="Compare to..."
        onChange={handleChange}
        styles={style}
      />
    </Wrapper>
  );
};

export default Compare;
