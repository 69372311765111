import React from 'react';
import DocumentTitle from 'components/DocumentTitle';
import OrderItems from 'pages/Shipping/OrderItems';
import ShippingOverview, {
  ClearShipmentModal,
} from 'pages/Shipping/ShippingOverview';
import { Route, Switch } from 'react-router-dom';
import ShippingSearchPage from 'components/SearchPages/ShippingSearchPage';
import { ShippingProvider } from 'pages/Shipping/utils';
import { Grid } from 'core/components';
import { Title, TitleContainer } from 'pages/Shipping/Shipping.css';

const ShippingPage = () => {
  return (
    <DocumentTitle title="Shipping Tool">
      <ShippingProvider>
        <Grid container direction="column">
          <TitleContainer>
            <Title>Shipping tool</Title>
          </TitleContainer>
          <Grid
            container
            direction="column"
            style={{ gap: '32px', margin: '32px', width: 'unset' }}
          >
            <Switch>
              <Route path="/shipping/add">
                <ShippingSearchPage pushTo="shipping/add" />
              </Route>
              <Route path="/shipping">
                <ShippingSearchPage pushTo="shipping" />
              </Route>
            </Switch>
            <ShippingOverview />
            <Switch>
              <Route path="/shipping/add">
                <OrderItems addByTrackingID={true} />
              </Route>
              <Route path="/shipping">
                <OrderItems addByTrackingID={false} />
              </Route>
            </Switch>
            <ClearShipmentModal />
          </Grid>
        </Grid>
      </ShippingProvider>
    </DocumentTitle>
  );
};

export default ShippingPage;
