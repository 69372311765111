import { type } from 'core/components';
import React, { ReactNode } from 'react';
import {
  Body,
  ButtonContent,
  Container,
  LeftContainer,
  StyledButton,
  StyleImage as StyledImage,
  StyledMobileImage,
} from 'components/ResourceContainer/ResourceContainer.css';

type ResourceContainerProps = {
  title: string;
  bodyContent: string;
  imageUrl: string;
  buttonAction: () => void;
  buttonContent: ReactNode;
  maxWidth: string;
};

const ResourceContainer = ({
  title,
  bodyContent,
  imageUrl,
  buttonAction,
  buttonContent,
  maxWidth,
}: ResourceContainerProps) => {
  return (
    <Container maxWidth={maxWidth}>
      <StyledMobileImage src={imageUrl}></StyledMobileImage>
      <LeftContainer>
        <type.H4>{title}</type.H4>
        <Body>{bodyContent}</Body>
        <StyledButton buttonType="secondary" onClick={() => buttonAction()}>
          <ButtonContent>{buttonContent}</ButtonContent>
        </StyledButton>
      </LeftContainer>
      <StyledImage src={imageUrl}></StyledImage>
    </Container>
  );
};

export default ResourceContainer;
