import styled from 'styled-components/macro';

export const AlertContainer = styled.div`
  background: ${({ theme }) => theme.colors.red05};
  border: 4px solid ${({ theme }) => theme.colors.red30};
  border-radius: 4px;
  box-sizing: border-box;
  align-items: flex-start;
  align-items: center;
  padding: 24px;
  margin: 16px 0 16px 0;
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const BannerText = styled.div`
  text-align: left;
  font-size: 1.25rem;
`;

export const NavLink = styled.a`
  text-decoration-line: underline;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-left: 2.25rem;
`;
