import React from 'react';
import moment from 'moment';
import styled from 'styled-components/macro';

import { Column } from 'react-table';
import { MaterialFragment } from 'pages/OrthoPrism/orthoSlice';
import { ReactTable } from 'components/ReactTable';
import { HelpTooltip } from 'components/HelpTooltip';

import { ScanTypes } from 'generated/core/graphql';
import { mediaQueries, type } from 'core/components';
import { isUpToDate, sanitizeMaterialName } from 'utils/materials';

import MandibularSVG from 'assets/mandibular_lower.svg?react';
import MaxillarySVG from 'assets/maxillary_upper.svg?react';
import BiteSVG from 'assets/bite.svg?react';

const scanIconMap: Record<
  ScanTypes,
  React.FC<React.SVGProps<SVGSVGElement>>
> = {
  [ScanTypes.UpperScan]: MaxillarySVG,
  [ScanTypes.LowerScan]: MandibularSVG,
  [ScanTypes.BiteScan]: BiteSVG,
};

const PhotoTile = styled.div<{
  photo?: string;
}>`
  width: 4rem;
  height: 4rem;
  background: ${({ photo }) => photo && `url(${photo}) no-repeat center`};
  background-size: cover;

  @media ${mediaQueries.tabletAndAbove} {
    width: 6rem;
    height: 6rem;
  }
`;

const SvgTile = styled.div`
  svg {
    width: 4rem;
    height: 4rem;

    @media ${mediaQueries.tabletAndAbove} {
      width: 6rem;
      height: 6rem;
    }
  }
`;

const UpToDateHeaderWrapper = styled.div`
  display: inline-flex;
`;

const TipContainer = styled.div`
  margin-left: 0.25rem;
  margin-top: 0.2rem;
  display: inline-flex;
`;

const FileLink = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    font-weight: ${type.weight.default};
    text-decoration: underline;
  }
`;

type SelfProps = {
  value: MaterialFragment;
};
const self = (material: MaterialFragment) => material;

const DATE_FORMAT = 'M/D/YY';
const DateCell = ({ value }: { value: string | Date }) =>
  value ? moment(value).format(DATE_FORMAT) : null;

const FileNameCell = ({ value: { url, filename } }: SelfProps) => (
  <FileLink>
    <a href={url!}>{sanitizeMaterialName(filename)}</a>
  </FileLink>
);

const XrayThumbnail = ({ value: { url } }: SelfProps) => (
  <PhotoTile photo={url!} />
);

const ScanThumbnail = ({ value: { materialType } }: SelfProps) => {
  const { name } = materialType;
  const Icon = scanIconMap[name as ScanTypes];
  return <SvgTile>{Icon ? <Icon /> : 'Unknown Type'}</SvgTile>;
};

const ThumbnailCell = ({ value }: SelfProps) => {
  const materialTypeName = value.materialType?.name || '';
  if (materialTypeName.includes('xray')) {
    return <XrayThumbnail value={value} />;
  }
  if (materialTypeName.includes('scan')) {
    return <ScanThumbnail value={value} />;
  }
  return null;
};

const UpToDateCell = ({ value }: SelfProps) => {
  // TODO: is there a better way to check the type?
  if (value.data.__typename === 'XrayMaterialData') {
    return value.data?.capturedWithinYearOfSubmission ? 'Yes' : 'No';
  } else if (value.data.__typename === 'ScanMaterialData') {
    return isUpToDate(value.createdAt, value.data?.captureDate) ? 'Yes' : 'No';
  }
  return '-';
};

const UpToDateHeader = () => {
  return (
    <UpToDateHeaderWrapper>
      {' '}
      Up to date{'  '}
      <TipContainer>
        <HelpTooltip content="Taken within a year of submission and after any dental work" />
      </TipContainer>{' '}
    </UpToDateHeaderWrapper>
  );
};

export const MaterialTableColumns = {
  THUMBNAIL: 'Thumbnail',
  FILE_NAME: 'File name',
  MATERIAL_TYPE: 'Material Type',
  UPLOADED_ON: 'Uploaded on',
  UP_TO_DATE: 'Up To Date',
};

const columns: Column<MaterialFragment>[] = [
  {
    Header: MaterialTableColumns.THUMBNAIL,
    accessor: self,
    Cell: ThumbnailCell,
  },
  {
    Header: MaterialTableColumns.FILE_NAME,
    accessor: self,
    Cell: FileNameCell,
  },
  {
    Header: UpToDateHeader,
    id: MaterialTableColumns.UP_TO_DATE,
    accessor: self,
    Cell: UpToDateCell,
  },
  {
    Header: MaterialTableColumns.UPLOADED_ON,
    accessor: 'createdAt',
    Cell: DateCell,
  },
];

const MaterialsTable = ({
  materials,
  excludedColumns = [],
}: {
  materials: MaterialFragment[];
  excludedColumns?: string[];
}) => {
  const displayedColumns = columns.filter(
    (c) =>
      !excludedColumns.includes(c.Header!.toString()) &&
      (!c.id || !excludedColumns.includes(c.id))
  );
  return <ReactTable columns={displayedColumns} data={materials} />;
};

export default MaterialsTable;
