import React, { useContext, useState } from 'react';
import { Button, Tabs, NotificationContext, Tooltip } from 'core/components';
import PracticeOrderForm from 'pages/OrdersPortal/PracticeOrderForm';
import PatientOrderForm from 'pages/OrdersPortal/PatientOrderForm';
import {
  OrdersPortalPage,
  TabContent,
  Actions,
} from 'pages/OrdersPortal/OrdersPortal.css';
import { Formik, Field } from 'formik';
import api from 'state/api';

import { object, boolean, string, number, array } from 'yup';
import {
  MutationsCreateOrderArgs,
  OrderItemInputType,
} from 'generated/core/graphql';
import { getAddressInputFromAddressForm } from 'components/AddressForm/utils';
import { PatientType, CaseType } from 'pages/OrdersPortal/types';

const DEFAULT_ID = 0;

const OrdersPortal = () => {
  const [createOrder] = api.useCreateOrderMutation({});
  const [currentTabId, setCurrentTabId] = useState('PatientOrderForm');
  // The following state is used to force a re-render of the form when a new order is submitted
  const [numSubmittedOrders, setNumSubmittedOrders] = useState(0);
  const [selectedPatient, setSelectedPatient] = useState<PatientType>();
  const [selectedCase, setSelectedCase] = useState<CaseType>();
  const { showNotification } = useContext(NotificationContext);
  const addressFormSubmitBtnRef = React.createRef<HTMLButtonElement>();
  const [addressFormIsValid, setAddressFormIsValid] = useState(true);
  const [sendPatientUpdate, setSendPatientUpdate] = useState(false);
  // This function returns the validation schema for the order form.
  // Shipping address is optional because the form will not have a shipping address
  // if the address selection is "Somewhere else"
  const orderFormSchema = () => {
    return object({
      autoActivate: boolean().required(),
      practiceId: number(),
      orderItems: array()
        .of(
          object().shape({
            productVariantSku: string().required(),
            quantity: number().required().positive().integer(),
          })
        )
        .required()
        .min(1),
      patientId: number().optional(),
      shippingAddress: object()
        .shape({
          addressLines: array().of(string()).optional(),
          city: string().optional(),
          country: string().optional(),
          postalCode: string().optional(),
          adminRegion: string().optional(),
        })
        .optional(),
    });
  };
  const submit = async (
    values: MutationsCreateOrderArgs,
    resetForm: () => void,
    validateForm: () => void
  ) => {
    try {
      const createOrderVars = {
        ...values,
        practiceId:
          values.practiceId == DEFAULT_ID
            ? Number(selectedPatient?.practice?.id)
            : values.practiceId,
        patientId: Number(values.patientId),
        caseRef: selectedCase?.caseRef,
        orderItems: values.orderItems.map((orderItem: OrderItemInputType) => ({
          ...orderItem,
          sentPatientShippingUpdate: sendPatientUpdate,
        })),
      };
      const response = await createOrder(createOrderVars).unwrap();
      showNotification(
        `Successfully created OrderID: ${response?.order?.id}`,
        'success'
      );
      // the following actions have to be done within this try block to ensure
      // they are only executed if the order was successfully created
      setNumSubmittedOrders(numSubmittedOrders + 1);
      resetForm();
      validateForm();
    } catch (error: unknown) {
      if (error instanceof Object && 'message' in error) {
        showNotification(error.message as string, 'error');
      } else {
        showNotification('An error occurred', 'error');
      }
    }
  };

  return (
    <OrdersPortalPage>
      <Formik
        initialValues={
          {
            practiceId: DEFAULT_ID,
            autoActivate: true,
            orderItems: [],
            patientId: DEFAULT_ID,
          } as MutationsCreateOrderArgs
        }
        validationSchema={orderFormSchema}
        validateOnMount
        onSubmit={async (values, { resetForm, validateForm }) => {
          if (addressFormSubmitBtnRef.current) {
            addressFormSubmitBtnRef.current.click();
            return;
          }
          if (values) {
            await submit(values, resetForm, validateForm);
          }
        }}
        onReset={() => {
          setSelectedPatient(undefined);
          setSelectedCase(undefined);
        }}
      >
        {({ handleSubmit, isValid, values, resetForm, validateForm }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Tabs
                contentWrapper={TabContent}
                onTabChange={(tabId) => {
                  setCurrentTabId(tabId);
                  setTimeout(() => {
                    // using setTimeout to ensure the form is reset after the tab change
                    resetForm();
                    validateForm();
                  }, 0);
                }}
                items={[
                  {
                    content: (
                      <PatientOrderForm
                        setSelectedPatient={setSelectedPatient}
                        setSelectedCase={setSelectedCase}
                        addressFormSubmitBtnRef={addressFormSubmitBtnRef}
                        handleConfirmedAddress={(confirmedAddress) => {
                          submit(
                            {
                              ...values,
                              shippingAddress:
                                getAddressInputFromAddressForm(
                                  confirmedAddress
                                ),
                            },
                            resetForm,
                            validateForm
                          );
                        }}
                        setAddressFormIsValid={setAddressFormIsValid}
                        setSendPatientUpdate={setSendPatientUpdate}
                        key={`PatientOrderForm-${currentTabId}-${numSubmittedOrders}`}
                      />
                    ),
                    id: 'PatientOrderForm',
                    name: 'Order for patient',
                  },
                  {
                    content: (
                      <PracticeOrderForm
                        addressFormSubmitBtnRef={addressFormSubmitBtnRef}
                        handleConfirmedAddress={(confirmedAddress) => {
                          submit(
                            {
                              ...values,
                              shippingAddress:
                                getAddressInputFromAddressForm(
                                  confirmedAddress
                                ),
                            },
                            resetForm,
                            validateForm
                          );
                        }}
                        setAddressFormIsValid={setAddressFormIsValid}
                        key={`PracticeOrderForm-${currentTabId}-${numSubmittedOrders}`}
                      />
                    ),
                    id: 'PracticeOrderForm',
                    name: 'Order for practice',
                  },
                ]}
              />
              <Actions>
                <label>
                  <Field type="checkbox" name="autoActivate" />
                  Auto activate order?
                  <Tooltip title="Keep this checked to begin manufacturing and shipping the order as soon as possible." />
                </label>
                <Button
                  buttonType="secondary"
                  type="submit"
                  disabled={
                    !isValid || !(addressFormIsValid || values.shippingAddress)
                  }
                >
                  Submit Order
                </Button>
              </Actions>
            </form>
          );
        }}
      </Formik>
    </OrdersPortalPage>
  );
};

export default OrdersPortal;
