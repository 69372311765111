import React from 'react';
import { useMyPracticesDoctorsQuery } from 'generated/legacy/graphql';
import { FormikOption } from 'components/FormikForms/index';
import { FormikSelectWrapper } from 'components/FormikForms';

type DoctorSelectorProps = {
  onClear?: () => void;
  onSelectionChange?: (value: FormikOption<string>[]) => void;
  value?: FormikOption<string> | FormikOption<string>[];
};

export default function DoctorSelector({
  onClear,
  onSelectionChange,
  value,
}: DoctorSelectorProps) {
  const { data, error, loading } = useMyPracticesDoctorsQuery();

  const options =
    data?.myPracticesDoctors
      .map((doctor) => ({
        value: doctor.id,
        displayValue: doctor.fullName,
      }))
      .sort((lhs, rhs) => lhs.displayValue.localeCompare(rhs.displayValue)) ??
    [];

  const isUserDoctorOnly = options.length <= 1;

  if (isUserDoctorOnly) {
    return null;
  }

  return (
    <FormikSelectWrapper
      disabled={Boolean(error ?? loading)}
      isClearable={true}
      label="Treating provider"
      multiple={true}
      name="doctors"
      onClear={onClear}
      onSelectChange={onSelectionChange}
      options={options}
      placeholder="Select"
      type="select"
      value={value}
    />
  );
}
