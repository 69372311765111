import React, { useContext } from 'react';
import {
  getTreatmentPlanStagingViewerUrl,
  toCreatedString,
} from 'utils/treatmentPlan';
import {
  Container,
  Attributes,
  StyledAttributeLabel,
  StyledAttributeValue,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/TreatmentPlanTab/PlanDetails.css';
import { TreatmentPlanStaging } from 'generated/core/graphql';
import { NotificationContext } from 'core/components';
import { Button } from 'core/components';
import { Link, OpenInNew } from '@material-ui/icons';

const PlanStagingDetails = ({
  treatmentPlan,
}: {
  treatmentPlan: TreatmentPlanStaging;
}) => {
  const created = treatmentPlan?.createdAt ?? null;
  const { showNotification } = useContext(NotificationContext);

  return (
    <Container>
      <Attributes>
        <li>
          <StyledAttributeLabel>Steps</StyledAttributeLabel>
          <StyledAttributeValue>
            {treatmentPlan?.data?.steps}
          </StyledAttributeValue>
        </li>
        <li>
          <StyledAttributeLabel>Created</StyledAttributeLabel>
          <StyledAttributeValue>
            {toCreatedString(created)}
          </StyledAttributeValue>
        </li>
      </Attributes>
      <li
        style={{
          display: 'inline-flex',
          alignItems: 'end',
          gap: '12px',
        }}
      >
        <Button
          buttonType="secondary"
          onClick={() => {
            navigator.clipboard.writeText(
              getTreatmentPlanStagingViewerUrl(
                treatmentPlan as TreatmentPlanStaging
              )
            );
            showNotification('Copied Treatment Plan Viewer Link', 'success');
          }}
        >
          <Link /> Copy link
        </Button>
        <Button
          buttonType="secondary"
          onClick={() => {
            window.open(
              getTreatmentPlanStagingViewerUrl(
                treatmentPlan as TreatmentPlanStaging
              ),
              '_blank',
              'noopener'
            );
          }}
        >
          <OpenInNew /> Open in new tab
        </Button>
      </li>
    </Container>
  );
};

export default PlanStagingDetails;
