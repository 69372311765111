import React from 'react';
import DocumentTitle from 'components/DocumentTitle';
import { DashboardContainer } from 'pages/AccountCreation/AccountCreation.css';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AccountCreationForm } from 'pages/AccountCreation/AccountCreationForm';

const AccountCreation = ({ match }: RouteComponentProps) => (
  <Switch>
    <Route path={match.path} exact>
      <DocumentTitle title="Create Account">
        <DashboardContainer container>
          <AccountCreationForm />
        </DashboardContainer>
      </DocumentTitle>
    </Route>
  </Switch>
);

export default AccountCreation;
