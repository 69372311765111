import { Button } from 'core/components';
import DocumentTitle from 'components/DocumentTitle';
import { Formik } from 'formik';
import {
  SplitContainer,
  LoginContainer,
  SuccessForm,
  SuccessText,
  Logo,
  Box,
} from 'pages/Login/Login.css';
import React from 'react';
import { getBrandSettings, getBrandFromDomain } from 'utils/brands';

const PasswordResetRequestSuccess = () => {
  const {
    label,
    logoSrc,
    caseSupport: { email: supportEmail },
  } = getBrandSettings(getBrandFromDomain());
  const params = new URLSearchParams(location.search);
  const email = params.get('email') || '';

  const onSubmit = async () => {
    window.location.href = '/login';
  };

  return (
    <DocumentTitle title="Password reset">
      <SplitContainer>
        <Formik initialValues={{}} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <LoginContainer>
              <SuccessForm>
                {logoSrc && (
                  <Logo alt={label as string} src={logoSrc as string} />
                )}
                <SuccessText>Success!</SuccessText>
                <Box>
                  {
                    'If the account exists, an email with password reset instructions was sent to '
                  }
                  <b>{email}</b>.
                  <br />
                  {
                    "If the email doesn't show up soon, check your spam folder. We sent it from "
                  }
                  <b>{supportEmail}</b>.
                  <Button
                    testId="login-btn"
                    buttonType="secondary"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    isFullWidth={true}
                    type="submit"
                    style={{ marginTop: '1rem' }}
                  >
                    {'Back to login'}
                  </Button>
                </Box>
              </SuccessForm>
            </LoginContainer>
          )}
        </Formik>
      </SplitContainer>
    </DocumentTitle>
  );
};

export default PasswordResetRequestSuccess;
