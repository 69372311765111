import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';

export default createGlobalStyle`
  ${normalize()};

  @font-face {
    font-family: ${({ theme }) => theme.fonts.modernEra};
    src: url('https://assets.candidco.com/fonts/modern_era_regular-webfont.woff2') format('woff2'),
      url('https://assets.candidco.com/fonts/modern_era_regular-webfont.woff') format('woff');
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.default};
    font-display: swap;
  }

  @font-face {
    font-family: ${({ theme }) => theme.fonts.modernEra};
    src: url('https://assets.candidco.com/fonts/modern_era_medium-webfont.woff2') format('woff2'),
      url('https://assets.candidco.com/fonts/modern_era_medium-webfont.woff') format('woff');
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-display: swap;
  }

  @font-face {
    font-family: ${({ theme }) => theme.fonts.modernEra};
    src:  url('https://assets.candidco.com/fonts/modern_era_bold-webfont.woff2') format('woff2'),
      url('https://assets.candidco.com/fonts/modern_era_bold-webfont.woff') format('woff');
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-display: swap;
  }

  @font-face {
    font-family: ${({ theme }) => theme.fonts.modernEraMono};
    src: url('https://assets.candidco.com/fonts/modern_era_mono_bold-webfont.woff2') format('woff2'),
      url('https://assets.candidco.com/fonts/modern_era_mono_bold-webfont.woff') format('woff');
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.default};
    font-display: swap;
  }

  html {
    box-sizing: border-box;
    text-rendering: geometricPrecision;
    -webkit-tap-highlight-color: transparent;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    font-family: ${({ theme }) =>
      `${theme.fonts.modernEra}, ${theme.fonts.sansSerifStack}`};
    font-size: ${({ theme }) => theme.fontSizes.default};
    font-weight: ${({ theme }) => theme.fontWeights.default};
    line-height: ${({ theme }) => theme.lineHeights.default};
    color: ${({ theme }) => theme.palette.text.main};
  }

  [tabindex='-1']:focus {
    outline: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  p,
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ol,
  ul {
    padding: 0;
    list-style-type: none;
  }

  dd {
    margin-left: 0;
  }

  blockquote {
    margin: 0 0 1rem;
  }

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  a {
    ${({ theme }) => theme.text.links.default};
  }

  figure {
    margin: 0;
  }

  iframe,
  img,
  svg,
  video {
    max-width: 100%;
    vertical-align: middle;
  }

  table {
    border-collapse: collapse;
  }

  button,
  input,
  textarea {
    padding: 0;
    background: transparent;
    border: 0;
    border-radius: 0;
    appearance: none;
  }

  button,
  [role='button'],
  input[type='radio'],
  input[type='submit'] {
    cursor: pointer;

    &[disabled] {
      cursor: default;
    }
  }

  textarea {
    resize: vertical;
  }

  button,
  input,
  select,
  textarea {
    color: inherit;

    &:focus {
      outline: 0;
    }
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
`;
