import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import NotFound from 'pages/NotFound';

import TpUploadOverview from 'pages/TpCentralEnhanced/TpUploadOverview';
import TpUploadSearch from 'pages/TpCentralEnhanced/TpUploadSearch';
import TpCentralEnhancedProvider from 'pages/TpCentralEnhanced/TpCentralEnhancedProvider';

const TpUpload = ({ match }: RouteComponentProps) => (
  <Switch>
    <TpCentralEnhancedProvider>
      <Route component={TpUploadSearch} exact path={`${match.path}/:id?`} />
      <Route
        component={TpUploadOverview}
        exact
        path={`${match.path}/:id/:caseRef`}
      />
      <Route component={NotFound} exact path={`${match.path}/:id/not-found`} />
    </TpCentralEnhancedProvider>
  </Switch>
);

export default TpUpload;
