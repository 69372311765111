import React, { useMemo } from 'react';
import { Column } from 'react-virtualized';
import { Skeleton } from 'core/components';
import { mediaQueries } from 'core/components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import VirtualizedSearchTable, {
  SortableColumn,
  monitoringLinkRenderer,
  customerCellRenderer,
  privateCellRenderer,
} from 'components/SearchTablePage/table';
import { getBrandSettings, getBrandFromDomain } from 'utils/brands';
import { getCaseTypeLabel } from 'utils/case';

export const PatientSearchTable = () => {
  const tabletSize = useMediaQuery(mediaQueries.tabletAndAbove);
  const { monitoringLabel } = getBrandSettings(getBrandFromDomain());
  const mobileColumnes = useMemo(
    () => [
      <SortableColumn
        label="Name"
        dataKey="firstName"
        key="firstName"
        sortField="FirstName"
        width={125}
        flexGrow={1}
        cellRenderer={({ rowData }) =>
          customerCellRenderer(
            rowData?.id,
            'patient',
            rowData?.firstName + ' ' + rowData?.lastName
          )
        }
      />,
      <Column
        label="Status"
        dataKey="providerFacingStatus"
        key="providerFacingStatus"
        width={180}
        flexGrow={1}
        cellRenderer={({ cellData }) =>
          cellData !== '-' ? cellData : <Skeleton width={100} />
        }
      />,
      <Column
        label={'Monitoring'}
        dataKey="email"
        key="email"
        width={70}
        flexGrow={1}
        cellRenderer={({ cellData }) => monitoringLinkRenderer(cellData)}
      />,
    ],
    []
  );
  const columns = useMemo(
    () => [
      <Column
        label="ID"
        dataKey="id"
        key="id"
        width={100}
        cellRenderer={({ cellData }) =>
          customerCellRenderer(cellData, 'patient')
        }
      />,
      <SortableColumn
        label="First name"
        dataKey="firstName"
        sortField="FirstName"
        key="firstName"
        headerStyle={{ textTransform: 'none' }}
        width={125}
        flexGrow={1}
        cellRenderer={({ cellData }) => privateCellRenderer(cellData)}
      />,
      <SortableColumn
        label="Last name"
        dataKey="lastName"
        sortField="LastName"
        key="lastName"
        width={125}
        flexGrow={1}
        headerStyle={{ textTransform: 'none' }}
      />,
      <Column
        label="Case type"
        dataKey="caseType"
        key="caseType"
        width={125}
        flexGrow={1}
        headerStyle={{ textTransform: 'none' }}
        cellRenderer={({ cellData }) =>
          cellData !== '-' ? (
            getCaseTypeLabel(cellData ?? '')
          ) : (
            <Skeleton width={100} />
          )
        }
      />,
      <Column
        label="Status"
        dataKey="providerFacingStatus"
        key="providerFacingStatus"
        headerStyle={{ textTransform: 'none' }}
        width={250}
        flexGrow={1}
        cellRenderer={({ cellData }) =>
          cellData !== '-' ? cellData : <Skeleton width={100} />
        }
      />,
      <SortableColumn
        label="Treating provider"
        dataKey="treatingProvider"
        sortField="ReferringDentistFullName"
        key="treatingProvider"
        width={150}
        flexGrow={1}
        headerStyle={{ textTransform: 'none' }}
      />,
      <Column
        label={monitoringLabel}
        dataKey="email"
        key="email"
        width={150}
        flexGrow={1}
        cellRenderer={({ cellData }) => monitoringLinkRenderer(cellData)}
      />,
    ],
    []
  );

  return (
    <VirtualizedSearchTable
      columns={tabletSize ? columns : mobileColumnes}
      filterType="provider_facing_status"
      title="Patients"
    />
  );
};
