import React from 'react';
import Tabs, { Tab } from 'components/Tabs';
import { Formik } from 'formik';
import { DoctorPreferencesInput } from 'generated/legacy/graphql';
import AutoSaveForm from 'pages/AccountPreferencesPage/AutoSaveForm';
import { MenuContainer } from 'pages/AccountPreferencesPage/PreferenceSection.css';

type PreferencesTabbedMenuProps = {
  preferenceTabs: Tab[];
  initialValues: DoctorPreferencesInput;
  userId: string | undefined;
};

const PreferencesTabbedMenu = ({
  preferenceTabs,
  initialValues,
  userId,
}: PreferencesTabbedMenuProps) => {
  return (
    <MenuContainer>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={() => {}}
      >
        <>
          <Tabs tabs={preferenceTabs} />
          <AutoSaveForm userId={Number(userId)} initialValues={initialValues} />
        </>
      </Formik>
    </MenuContainer>
  );
};

export default PreferencesTabbedMenu;
