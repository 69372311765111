// Commonly used PropTypes that can be imported
import PropTypes from 'prop-types';

import { AUTH_GROUP_TYPES, PHOTO_VIEW_TYPES } from 'constants';

export const children = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.arrayOf(PropTypes.node),
]);

export const routerHistory = PropTypes.shape({
  action: PropTypes.string,
  block: PropTypes.func,
  go: PropTypes.func,
  goBack: PropTypes.func,
  goForward: PropTypes.func,
  length: PropTypes.number,
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object,
  }),
  push: PropTypes.func,
  replace: PropTypes.func,
});

export const routerLocation = PropTypes.shape({
  hash: PropTypes.string,
  key: PropTypes.string,
  pathname: PropTypes.string,
  search: PropTypes.string,
  state: PropTypes.object,
});

export const routerMatch = (params) =>
  PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape(params),
    path: PropTypes.string,
    url: PropTypes.string,
  });

export const authGroups = PropTypes.oneOf(AUTH_GROUP_TYPES);

const photoView = PropTypes.oneOf(PHOTO_VIEW_TYPES);

export const photo = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  original: PropTypes.shape({
    id: PropTypes.string,
    operations: PropTypes.shape({
      height: PropTypes.number,
      rotate: PropTypes.number,
      width: PropTypes.number,
      x: PropTypes.number,
      y: PropTypes.number,
    }),
    url: PropTypes.string,
  }),
  url: PropTypes.string,
  view: photoView,
});

export const rejectionReasons = PropTypes.arrayOf(
  PropTypes.shape({
    reason_label: PropTypes.string,
    type: PropTypes.string,
  })
);
