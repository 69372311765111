import styled, { css } from 'styled-components';
import { hideVisually } from 'polished';

import theme from 'core/styles/theme';

export const TabContainer = styled.nav<{
  disabledBreakpoint?: keyof typeof theme.mediaQueries;
  hasBottomMargin?: boolean;
}>`
  display: flex;
  width: 100%;
  height: 2rem;
  margin-bottom: ${({ hasBottomMargin }) => hasBottomMargin && '1rem'};
  border-bottom: ${({ theme }) => theme.borders.default};

  ${({ disabledBreakpoint, theme }) =>
    disabledBreakpoint &&
    css`
      @media ${theme.mediaQueries[disabledBreakpoint]} {
        display: none;
      }
    `};
`;

export const Tab = styled.button<{
  isActive?: boolean;
  isHidden?: boolean;
}>`
  display: ${({ isHidden }) => isHidden && 'none'};
  height: 100%;
  padding: 0.5rem 1rem 0.375rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.palette.white};
      background: ${theme.palette.secondary.main};
      border-top-right-radius: ${theme.radii.medium};
      border-top-left-radius: ${theme.radii.medium};
    `};
`;

export const TabContent = styled.div<{
  disabledBreakpoint?: keyof typeof theme.mediaQueries;
  isVisible?: boolean;
}>`
  ${({ disabledBreakpoint, isVisible, theme }) =>
    disabledBreakpoint
      ? css`
          @media not all and ${theme.mediaQueries[disabledBreakpoint]} {
            ${!isVisible && hideVisually()};
          }
        `
      : !isVisible && hideVisually()};
`;
