import { ScanTypes } from 'generated/core/graphql';

export enum ScanType {
  MAXILLARY = 'maxillary',
  MANDIBULAR = 'mandibular',
  UPPER = 'upper',
  LOWER = 'lower',
  BITE = 'bite',
}

export const getCoreScanType = (fileName = '') => {
  const fileNameLower = fileName.toLowerCase();

  if (
    fileNameLower.includes(ScanType.MAXILLARY) ||
    fileNameLower.includes(ScanType.UPPER)
  ) {
    return ScanTypes.UpperScan;
  }

  if (
    fileNameLower.includes(ScanType.MANDIBULAR) ||
    fileNameLower.includes(ScanType.LOWER)
  ) {
    return ScanTypes.LowerScan;
  }

  if (fileNameLower.includes(ScanType.BITE)) {
    return ScanTypes.BiteScan;
  }

  return null;
};

export const getScanTypeLabel = (fileName = '') => {
  const fileNameLower = fileName.toLowerCase();

  if (
    fileNameLower.includes(ScanType.MAXILLARY) ||
    fileNameLower.includes(ScanType.UPPER)
  ) {
    return 'Upper';
  }

  if (
    fileNameLower.includes(ScanType.MANDIBULAR) ||
    fileNameLower.includes(ScanType.LOWER)
  ) {
    return 'Lower';
  }

  if (fileNameLower.includes(ScanType.BITE)) {
    return 'Bite';
  }

  return '–';
};
