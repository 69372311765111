import Autosuggest from 'react-autosuggest';

import {
  autocompleteAddress,
  AUTOCOMPLETE_LIMIT,
  validateAddress,
} from 'api/validation/smartystreet';
import { AutocompleteAddress, AddressFields } from 'api/validation/types';
import {
  RenderSuggestionsContainerProps,
  AutoSuggestWrapper,
} from 'components/FormikForms';
import { requiredValidator } from 'components/FormikForms/utils';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import React, { useState, useCallback } from 'react';
import { AddressFormType } from 'components/AddressForm/types';

interface Line1Props<T> {
  disabled: boolean;
  addressPath: string;
  getAddressData: (values: T) => AddressFormType;
}

const Line1 = <T,>({
  disabled,
  addressPath,
  getAddressData,
}: Line1Props<T>) => {
  const { setFieldValue, values, validateForm } = useFormikContext<T>();
  const countryCode = getAddressData(values)?.countryCode;

  const [suggestions, setSuggestions] = useState<AutocompleteAddress[]>([]);
  const populateAutocompleteSuggestions = async (input: string) => {
    if (countryCode) {
      setSuggestions(
        await autocompleteAddress(input, AUTOCOMPLETE_LIMIT, countryCode)
      );
    }
  };

  const withDebounce = useCallback(
    debounce(populateAutocompleteSuggestions, 500),
    [countryCode]
  );

  const onAddressUpdated = async (
    e: React.ChangeEvent<HTMLElement>,
    params: Autosuggest.ChangeEvent
  ) => {
    //The update seems to trigger for the suggestion drop down as well, which we don't want.
    const htmlInput = e.currentTarget as HTMLInputElement;
    if (htmlInput?.name) {
      setFieldValue(htmlInput.name, params.newValue);
    }
  };

  const onSuggestionsFetchRequested = (input: {
    reason: string;
    value: string;
  }) => {
    if (input.value.length) {
      withDebounce(input.value);
    }
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderSuggestion = (suggestion: AutocompleteAddress) => {
    return <div>{suggestion.text}</div>;
  };

  const renderSuggestionsContainer = ({
    containerProps,
    children,
  }: RenderSuggestionsContainerProps) => {
    return (
      <div {...containerProps} style={{ zIndex: 20 }}>
        {children}
      </div>
    );
  };

  const onAddressSuggestionSelected = async (
    _event: React.FormEvent,
    data: { suggestion: AutocompleteAddress }
  ) => {
    const addressFields: AddressFields = {
      addressLine1: data.suggestion.street_line,
      addressLine2: '',
      city: data.suggestion.city,
      stateCode: data.suggestion.state,
      zip: data.suggestion?.postalCode || '',
      countryCode: countryCode ?? '',
    };
    setFieldValue(`${addressPath}addressLine1`, data.suggestion.street_line);
    setFieldValue(`${addressPath}city`, data.suggestion.city);
    setFieldValue(`${addressPath}stateCode`, data.suggestion.state);
    if (data.suggestion?.postalCode) {
      setFieldValue(`${addressPath}zip`, data.suggestion.postalCode);
    } else {
      //we do not get the postal_code back from auto completion
      const resp = await validateAddress(addressFields);
      if (resp.length === 1 && resp[0]) {
        setFieldValue(`${addressPath}zip`, resp[0].zip);
      }
    }

    validateForm();
  };
  const getSuggestionValue = (suggestion: AutocompleteAddress): string => {
    return suggestion.text;
  };
  return (
    <AutoSuggestWrapper
      testId="basicinfo-address-line-one"
      name={`${addressPath}addressLine1`}
      label="Address*"
      validate={requiredValidator}
      suggestions={suggestions}
      onChange={onAddressUpdated}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={renderSuggestionsContainer}
      onSuggestionSelected={onAddressSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      disabled={disabled}
    />
  );
};

export default Line1;
