import { ALIGNER_SKU } from 'constants/loyalty';

export const isPracticeGrandfathered = (quotes) => {
  /*
     We can determine if a practice is grandfathered if all of their aligner tiered aligner
    quotes are the same price
 */
  const tieredQuotes = quotes.filter(
    (quote) => quote.tierName !== 'Contract Rate'
  );
  const tieredAlignerQuotes = tieredQuotes.flatMap((quote) =>
    quote?.products.filter((product) => product.sku === ALIGNER_SKU)
  );
  return tieredAlignerQuotes.every(
    (product) => product.quote === tieredAlignerQuotes[0].quote
  );
};

export const displayLoyaltyPricing = (quotes, parentOrganization = null) => {
  /*
    Loyalty pricing should not be displayed for DSOs or for grandfathered SMB practices 
    We can tell if a practice is a part of a DSO if it has a parent organization
    */

  if (parentOrganization) {
    return false;
  } else if (quotes) {
    return !isPracticeGrandfathered(quotes);
  }
  return true;
};
