import api from 'state/api';

type AddTemplatedTreatmentToolingVariables = {
  patientId: number;
  krakenCaseId: string;
  caseRef: string;
};

export default () => {
  const [generateToolingFile, generateToolingFileState] =
    api.useGenerateTemplatedTreatmentPlanToolingFileMutation();
  const [saveManufacturingFiles, saveManufacturingFilesState] =
    api.useSaveManufacturingFilesMutation();

  const executor = async ({
    caseRef,
    krakenCaseId,
    patientId,
  }: AddTemplatedTreatmentToolingVariables) => {
    const { fileLocation } = await generateToolingFile({
      krakenCaseId,
      patientId,
    }).unwrap();

    return await saveManufacturingFiles({
      caseRef,
      files: [fileLocation],
      isTest: true,
    });
  };

  const states = [generateToolingFileState, saveManufacturingFilesState];

  return [
    executor,
    {
      ...saveManufacturingFilesState,
      isError: states.some((state) => state.isError),
      isLoading: states.some((state) => state.isLoading),
      isSuccess: states.every((state) => state.isSuccess),
      isUninitialized: states.every((state) => state.isUninitialized),
    },
  ] as const;
};
