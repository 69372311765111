import IgnitionKitPng from 'assets/ignition-kit.png';
import InOfficeMarketingPng from 'assets/in-office-marketing.png';

export const IgnitionKit = {
  title: 'Digital Ignition Kit',
  body: 'Everything you need to up your digital marketing game and bring in more patients – social media posts, photos, videos website materials, and logos.',
  buttonCTA: 'Browse assets',
  url: 'https://drive.google.com/drive/folders/1gVLyio96QQl2Iy4nH0vlsbmXeZutZbb6',
  imageUrl: IgnitionKitPng,
};

export const InOfficeMarketing = {
  title: 'Ignition Kit order form',
  body: 'Do you need more marketing collateral for your office? We’re here to help. You can either order more materials or download digital files to print your own.',
  buttonCTA: 'Order marketing collateral',
  url: 'https://formfacade.com/public/107319577265448485085/all/form/1FAIpQLScSKXFxzDGRkRgirgusktsuHRujObgcz6mmiRu8sFI-QFQMtQ',
  imageUrl: InOfficeMarketingPng,
};
