import React, { useState } from 'react';
import FullPageModal from 'components/FullPageModal/FullPageModal';
import { Formik } from 'formik';
import {
  FormikTextAreaWrapper,
  FormikInputWrapper,
} from 'components/FormikForms';
import { useGQLMutation } from 'hooks/useGQL';
import {
  SyncQualityTicketToSalesforceDocument,
  SyncQualityTicketToSalesforceMutation,
  SyncQualityTicketToSalesforceMutationVariables,
} from 'generated/core/graphql';
import { HRDividerLine } from 'styles/layout.css';

import ToothChart from 'components/ToothChart/ToothChart';
import { QualityTicketMappings } from 'components/QualityTicketModal/Config';
import {
  StyledForm,
  ProblemCategoryHeader,
  ProblemCategorySubHeader,
  FieldContainer,
  StyledAccordion,
  EscalationCategoryHeader,
  AdditionalDetailsContainer,
  Label,
  SubLabel,
} from 'components/QualityTicketModal/QualityTicketModal.css';
import ProblemCategoryAccordion from 'components/QualityTicketModal/ProblemCategoryAccordion';
import { requiredValidator } from 'components/FormikForms/utils';

export type QualityTicketModalProps = {
  isOpen?: boolean;
  onClose: () => void;
  onSuccess: () => void;
  patientName: string;
  caseRef: string;
  patientId: string;
};

type QualityTicketFormValues = {
  serialNumber: string;
  impactedStageAndArch: string;
  measurableSignsAndSymptoms: string;
  dentalWorkSinceSubmission: string;
};

const initialValues: QualityTicketFormValues = {
  serialNumber: '',
  impactedStageAndArch: '',
  measurableSignsAndSymptoms: '',
  dentalWorkSinceSubmission: '',
};

const QualityTicketModal = ({
  isOpen,
  onClose,
  onSuccess,
  patientName,
  caseRef,
  patientId,
}: QualityTicketModalProps) => {
  const [toothChartValues, setToothChartValues] = useState<number[]>([]);
  const [submitQualityTicket] = useGQLMutation<
    SyncQualityTicketToSalesforceMutation,
    SyncQualityTicketToSalesforceMutationVariables
  >(SyncQualityTicketToSalesforceDocument);

  const [escalationCategoryStates, setEscalationStates] = useState<{
    [key: string]: boolean;
  }>({});

  const handleSubmit = (values: QualityTicketFormValues) => {
    const selectedCategories: string[] = [];
    Object.keys(escalationCategoryStates).forEach((kvp) => {
      if (escalationCategoryStates[kvp]) {
        selectedCategories.push(kvp);
      }
    });
    submitQualityTicket({
      patientId: Number(patientId),
      caseRef,
      qualityTicketInput: {
        serialNumber: values.serialNumber,
        impactedStageAndArch: `${values.impactedStageAndArch};${toothChartValues.join(',')}`,
        measurableSignsOrSymptoms: values.measurableSignsAndSymptoms,
        visibleIrregularities: '', //Not used but still required by SF
        dentalWorkSinceSubmission: values.dentalWorkSinceSubmission,
        escalationCategory: selectedCategories.join(';'),
      },
    });
    onSuccess();
  };

  const onCheckboxUpdated = (key: string, value: boolean) => {
    setEscalationStates({
      ...escalationCategoryStates,
      [key]: value,
    });
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ resetForm, values, isValid }) => {
        const resetData = () => {
          setEscalationStates({});
          resetForm();
          setToothChartValues([]);
        };
        const internalOnClose = () => {
          resetData();
          onClose();
        };
        return (
          <FullPageModal
            titleText={'Report an Issue'}
            isOpen={isOpen || false}
            onClose={internalOnClose}
            continueDisabled={!isValid}
            patientName={patientName}
            onContinue={() => {
              handleSubmit(values);
              resetData();
            }}
            isLoading={false}
            continueText={'Submit'}
          >
            <StyledForm>
              <EscalationCategoryHeader>
                <ProblemCategoryHeader>
                  <div style={{ margin: 0 }}>Problem category</div>
                </ProblemCategoryHeader>
                <ProblemCategorySubHeader>
                  Please select your problem category below. You can select
                  multiple issues across categories.
                </ProblemCategorySubHeader>
              </EscalationCategoryHeader>
              <StyledAccordion collapsible>
                {QualityTicketMappings.map((option) => {
                  return (
                    <ProblemCategoryAccordion
                      key={option.header}
                      problem={option}
                      checked={escalationCategoryStates}
                      onCheckboxUpdated={onCheckboxUpdated}
                    />
                  );
                })}
              </StyledAccordion>

              <HRDividerLine style={{ margin: 0 }} />
              <ProblemCategoryHeader>
                <div style={{ margin: 0 }}>Additional details</div>
              </ProblemCategoryHeader>
              <AdditionalDetailsContainer>
                <FieldContainer>
                  <Label>Describe the problem</Label>
                  <SubLabel>
                    Include detailed observations and context around the problem
                    for fastest resolution.
                  </SubLabel>
                  <FormikTextAreaWrapper
                    type="text"
                    name="measurableSignsAndSymptoms"
                    validate={requiredValidator}
                  />
                </FieldContainer>
                <FieldContainer>
                  <Label>Stage and Arch</Label>
                  <SubLabel>
                    Which step or steps, and upper or lower arch
                  </SubLabel>
                  <FormikInputWrapper
                    type="text"
                    name="impactedStageAndArch"
                    validate={requiredValidator}
                  />
                </FieldContainer>

                <FieldContainer>
                  <Label>Where is your problem</Label>
                  <SubLabel>Select all areas where your issue exists</SubLabel>
                  <ToothChart
                    onChange={setToothChartValues}
                    values={toothChartValues}
                  />
                </FieldContainer>

                <FieldContainer>
                  <Label>Serial number on the device</Label>
                  <SubLabel>
                    An alphanumeric identifier located on the surface of the
                    molar region of the device.
                  </SubLabel>
                  <FormikInputWrapper
                    type="text"
                    name="serialNumber"
                    validate={requiredValidator}
                  />
                </FieldContainer>
                <FieldContainer>
                  <Label>
                    Dental work since the photos and scans/impressions were
                    taken
                  </Label>
                  <SubLabel>If yes, please describe</SubLabel>
                  <FormikTextAreaWrapper
                    type="text"
                    name="dentalWorkSinceSubmission"
                    validate={requiredValidator}
                  />
                </FieldContainer>

                <FieldContainer>
                  <Label style={{ marginBottom: '8px' }}>
                    Thank you for reporting your issue, we will send an email
                    confirmation shortly. Please respond to that email by
                    attaching photos that clearly depict the product or patient
                    issue.
                  </Label>
                  <SubLabel>
                    While photos are not required, reports without evidence may
                    not be eligible to participate in our free replacement and
                    refinement policy. Our speediest resolutions also occur when
                    photos are provided.
                  </SubLabel>
                </FieldContainer>
              </AdditionalDetailsContainer>
            </StyledForm>
          </FullPageModal>
        );
      }}
    </Formik>
  );
};

export default QualityTicketModal;
