import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { NotificationContext } from 'core/components';
import { useTransitionJourneyMutation } from 'generated/legacy/graphql';
import { coreClient } from 'gql/GraphQLProvider';
import {
  selectActiveCase as selectActiveCasePatientSlice,
  selectScansUploadType,
  updateScanUploadType,
} from 'pages/Patient/patientSlice';
import {
  Container,
  CheckboxContainer,
} from 'pages/Patient/CaseCreator/DiagnosticMaterials/Scans/Styles.css';
import { ScanJourneyTransitions } from 'utils/journey';
import { AppDispatch } from 'state/store';
import { selectLatestScanSubmission } from 'pages/Patient/patientSlice';
import {
  CaseSource,
  WorkflowUploadSources,
  UpdateCaseWorkflowMutation,
  UpdateCaseWorkflowDocument,
  UpdateCaseWorkflowMutationVariables,
} from 'generated/core/graphql';
import ContactSupport from 'components/ContactSupport';

const SyncScans = ({ isReadOnly = false }: { isReadOnly?: boolean }) => {
  const activeCase = useSelector(selectActiveCasePatientSlice);
  const submittedScans = useSelector(selectLatestScanSubmission);
  const scanUploadSource = useSelector(selectScansUploadType);
  const [syncSelected, setSyncSelected] = useState<boolean>(false);
  const [transitionJourney] = useTransitionJourneyMutation();
  const dispatch = useDispatch<AppDispatch>();
  const { showNotification } = useContext(NotificationContext);

  //This use effect gets triggered from external source.
  //For example if the journey state was changed outside of this component
  useEffect(() => {
    const syncSelectedInWorkflow =
      scanUploadSource === WorkflowUploadSources.ThirdPartySync;
    if (!syncSelected && syncSelectedInWorkflow) {
      setSyncSelected(true);
    } else if (syncSelected && !syncSelectedInWorkflow) {
      setSyncSelected(false);
    }
  }, [activeCase]);

  const onCheckboxClicked = async () => {
    if (isReadOnly) {
      return;
    }

    const newValue = !syncSelected;

    //If we're coming from a rejection/needs clarification, make sure the state is kept in sync
    //Assumption here is if we have a submission, the original state is
    const transition = submittedScans
      ? ScanJourneyTransitions.REQUEST_ACTION
      : ScanJourneyTransitions.RESET;

    try {
      if (activeCase?.source === CaseSource.Core) {
        await coreClient.mutate<
          UpdateCaseWorkflowMutation,
          UpdateCaseWorkflowMutationVariables
        >({
          fetchPolicy: 'no-cache',
          mutation: UpdateCaseWorkflowDocument,
          variables: {
            caseRef: activeCase?.caseRef!,
            workflowOptions: {
              scansUploadSource: newValue
                ? WorkflowUploadSources.ThirdPartySync
                : WorkflowUploadSources.Provider,
            },
          },
        });
      } else {
        await submitJourneyTransition(
          newValue ? ScanJourneyTransitions.SYNC_SCANS : transition
        );
      }

      dispatch(
        updateScanUploadType({
          caseRef: activeCase?.caseRef!,
          newState: newValue
            ? WorkflowUploadSources.ThirdPartySync
            : WorkflowUploadSources.Provider,
        })
      );
      setSyncSelected(newValue);
    } catch (err) {
      showNotification(
        'An error has occured, please refresh and try again',
        'error'
      );
      Sentry.captureException(err);
    }
  };

  const submitJourneyTransition = async (transition: string) => {
    await transitionJourney({
      variables: {
        caseRef: activeCase?.caseRef!,
        component: 'scans_aligner_leg',
        transition: transition,
      },
    });
  };

  return (
    <>
      <Container>
        <div>
          For question about sending scans directly from your scanner to our
          labs, please <ContactSupport text="contact support" />
        </div>
        <CheckboxContainer onClick={onCheckboxClicked} isReadonly={isReadOnly}>
          <input type="checkbox" checked={syncSelected} disabled={isReadOnly} />
          <div>
            I have sent scans from my scanner to ProLabs. I understand my case
            will be delayed if I don’t send the scans in a timely manner.
          </div>
        </CheckboxContainer>
      </Container>
    </>
  );
};

export default SyncScans;
