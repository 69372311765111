import React, { useState, useEffect } from 'react';
import {
  Container,
  TabButton,
  TabContent,
  TabsContainer,
} from 'components/Tabs/Tabs.css';

export type Tab = {
  id: string;
  name: string;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  Component: React.FunctionComponent;
};

const Tabs = ({
  tabs,
  initialTabId,
  renderFooter,
}: {
  tabs: Tab[];
  initialTabId?: string | null;
  renderFooter?: (activeTabId?: string | null) => React.ReactNode;
}) => {
  const [activeTabId, setActiveTabId] = useState<string | null>(tabs[0]?.id);
  useEffect(() => {
    if (initialTabId) {
      setActiveTabId(initialTabId);
    }
  }, [initialTabId]);

  return (
    <Container>
      <TabsContainer>
        {tabs.map(({ id, name, Icon }) => {
          return (
            <TabButton
              isSelected={id === activeTabId}
              onClick={() => setActiveTabId(id)}
            >
              {Icon && <Icon />}
              {name}
            </TabButton>
          );
        })}
      </TabsContainer>
      {tabs.map(({ id, Component }) => {
        return (
          <TabContent isVisible={id === activeTabId}>
            <Component />
          </TabContent>
        );
      })}
      {renderFooter && renderFooter(activeTabId)}
    </Container>
  );
};

export default Tabs;
