import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Loading, AlertCard } from 'core/components';
import { Snackbar } from '@material-ui/core';
import { useIsLoading } from 'state/system/slice';
import VersionPills, { useVersionPills } from 'components/StyledVersionPills';
import MaterialReview from 'pages/OrthoPrism/MaterialReview/MaterialReview';
import {
  fetchXrays,
  selectXrays,
  selectXraySubmissions,
  selectXrayMaterialState,
  selectLatestXrayMaterialEvaluations,
  XrayFragment,
  selectSelectedCase,
  selectCustomer,
} from 'pages/OrthoPrism/orthoSlice';
import {
  MaterialReviewFormContainer,
  MaterialReviewFormItem,
} from 'pages/OrthoPrism/OrthoPrism.css';
import XrayGallery from 'components/XrayGallery';
import MaterialSubmissionHistory from 'components/MaterialSubmissionHistory';
import { Wrapper } from 'pages/OrthoPrism/Xrays/Xrays.css';
import { TabProps } from 'pages/OrthoPrism/types';
import { useHistory } from 'react-router-dom';
import MaterialApprovedAlertCard from 'components/AlertCards/MaterialApprovedAlertCard';
import { LinkItem } from 'pages/OrthoPrism/OrthoPrism.css';
import { PROVIDER_FACING_STATUSES } from 'constants/caseStatus';
import { MaterialStates } from 'generated/core/graphql';
import { DownloadPhotosButton } from 'pages/Patient/PatientDetail/DiagnosticMaterials/PhotosTab.css';
import { downloadFiles } from 'pages/OrthoPrism/utils';

const Xrays = ({ refreshMaterials }: TabProps) => {
  const [reviewSubmitted, setReviewSubmitted] = useState(false);
  const [isZippingPhotos, setIsZippingPhotos] = useState(false);

  const xrays = useSelector(selectXrays);
  const xraySubmissions = useSelector(selectXraySubmissions);
  const latestXrayEvaluations = useSelector(
    selectLatestXrayMaterialEvaluations
  );
  const latestXrayEvaluation = latestXrayEvaluations?.[0];
  const xrayMaterialState = useSelector(selectXrayMaterialState);
  const selectedCase = useSelector(selectSelectedCase);
  const customer = useSelector(selectCustomer);
  const isLoading = useIsLoading(fetchXrays.typePrefix);
  const vpProps = useVersionPills(xraySubmissions, true);
  const { push } = useHistory();
  //This should be the same for all aggregates, so just pick the first
  const caseRef = xrays?.[0]?.caseRef!;

  //Because of 'Needs clarification` actions taken within one tab may affect any other tab
  const handleOnSubmit = (
    reloadScans: boolean,
    reloadXrays: boolean,
    reloadPhotos: boolean
  ) => {
    if (refreshMaterials) {
      refreshMaterials(reloadScans, reloadXrays, reloadPhotos, caseRef);
    }
    setReviewSubmitted(true);
    window.scrollTo(0, 0);
  };

  const handleOnReverse = () => {
    refreshMaterials && refreshMaterials(false, true, false, caseRef);
    setReviewSubmitted(true);
    window.scrollTo(0, 0);
  };

  const XrayBody = () => {
    if (isLoading) {
      return <Loading isCentered />;
    }

    if (
      !xrays?.length ||
      selectedCase?.caseState?.internal ===
        PROVIDER_FACING_STATUSES.INCOMPLETE_SUBMISSION
    ) {
      const alertHeaderText = selectedCase?.isGen2
        ? 'X-rays not yet submitted'
        : '';
      const alertBodyText = selectedCase?.isGen2
        ? 'Continue submission'
        : 'X-rays not yet submitted';
      return (
        <AlertCard type="warning" header={alertHeaderText} displayIcon={true}>
          {selectedCase?.isGen2 ? (
            <LinkItem
              onClick={() =>
                push(`/patient/${selectedCase?.patientId}/case-creator`)
              }
            >
              Continue submission
            </LinkItem>
          ) : (
            <> {alertBodyText} </>
          )}
        </AlertCard>
      );
    }

    const selectedSubmission = xraySubmissions[vpProps.currentIndex];
    const selectedXrays = selectedSubmission
      ? xrays.filter((xray) =>
          xray.submissions.some(
            (submission) => submission.id === selectedSubmission.id
          )
        )
      : [];

    const isLatestSubmission =
      selectedSubmission?.id === xraySubmissions[0]?.id;
    const showMaterialReview =
      isLatestSubmission &&
      xrayMaterialState?.state ===
        MaterialStates.AwaitingOrthodonticEvaluation &&
      !!selectedXrays.length;

    return (
      <Wrapper>
        <VersionPills {...vpProps} />
        {!!latestXrayEvaluation?.approved && (
          <MaterialApprovedAlertCard
            evaluationSetId={latestXrayEvaluation.evaluationSet?.id!}
            materialName="xray"
            onReverse={handleOnReverse}
            selectedCase={selectedCase!}
          />
        )}
        {!!selectedXrays?.length && (
          <div>
            <XrayGallery photos={selectedXrays.map(toGalleryShape)} />
            <DownloadPhotosButton
              buttonType="secondary-outline"
              isShort
              isLoading={isZippingPhotos}
              disabled={isZippingPhotos}
              onClick={async () => {
                setIsZippingPhotos(true);
                await downloadFiles(
                  xrays.map((xray) => {
                    return {
                      url: xray.url ?? '',
                      filename: xray.filename ?? '',
                    };
                  }),
                  `X-rays_${customer?.firstName}_${customer?.lastName}`
                );
                setIsZippingPhotos(false);
              }}
            >
              Download X-rays
            </DownloadPhotosButton>
          </div>
        )}
        <MaterialReviewFormContainer
          container
          direction="column"
          alignItems="center"
          spacing={0}
        >
          <MaterialReviewFormItem item xs={12}>
            {showMaterialReview && (
              <MaterialReview
                materials={selectedXrays}
                materialName="x-rays"
                onSubmit={handleOnSubmit}
              />
            )}
          </MaterialReviewFormItem>
          <MaterialReviewFormItem item xs={12}>
            <MaterialSubmissionHistory
              submissions={xraySubmissions}
              materialName="X-rays"
            />
          </MaterialReviewFormItem>
        </MaterialReviewFormContainer>
      </Wrapper>
    );
  };

  return (
    <>
      <Snackbar
        open={reviewSubmitted}
        message="Review submitted"
        onClose={() => setReviewSubmitted(false)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        data-testid="X-Ray-review-snackbar"
      />
      <XrayBody />
    </>
  );
};

const toGalleryShape = ({
  id,
  data: { capturedWithinYearOfSubmission },
  createdAt,
  materialType,
  url,
}: XrayFragment) => ({
  id,
  capture_date: createdAt,
  captured_within_year_of_submission: capturedWithinYearOfSubmission,
  material_source: { type: materialType },
  url,
  created: createdAt,
});

export default Xrays;
