import { Action, Middleware } from 'redux';
import { createAction } from '@reduxjs/toolkit';

declare global {
  interface Window {
    /**
       Only works when NODE_ENV === 'development' but calling `__stopPolling()` from
       the console will cancel all polls and clear the registry.
     */
    __stopPolling: () => void;

    /**
       For debugging purposes. This _is_ available in prod because why shouldn't it be.
    */
    __dumpPollRegistry: () => void;
    /**
       Also for debugging, available in all envs. Toggles console logging of polling actions
    */
    __logPolling: () => void;
  }
}

export interface PollingMeta {
  interval: number;
}

/**
   Polling actions are any action that fulfills the `PollingMeta`
   interface for its `meta` property.

   To make an action poll at an interval of every N ms:

      store.dispatch(
         action('arg', {
           poll: {
             interval: N,
           },
         })
      )
 */
interface ActionWithPollingMeta extends Action {
  meta: {
    poll: PollingMeta;
  };
}

interface PollSpec {
  iteration: number;
  intervalId: NodeJS.Timeout;
  isActive: boolean;
}

const isPollSpec = (spec: any): spec is PollSpec =>
  typeof spec.iteration === 'number' &&
  typeof spec.isActive === 'boolean' &&
  typeof spec.intervalId !== 'undefined';

type PollingRegistry = Record<string, PollSpec>;

const registry: PollingRegistry = {};

export const cancel = createAction('polling/cancel', (actionType: string) => ({
  payload: actionType,
}));

type PollingAction = ReturnType<typeof cancel> | ActionWithPollingMeta;

const isPollingMeta = (action: any): action is ActionWithPollingMeta =>
  !!action.meta?.poll;

const isPollingAction = (action: any): action is PollingAction =>
  isPollingMeta(action) || cancel.match(action);

window.__stopPolling = () => {
  if (import.meta.env.VITE_NODE_ENV !== 'development') {
    console.warn('Can only call __stopPolling() in development');
    return;
  }

  for (const actionType in registry) {
    console.log(`Clearing ${actionType} from registry, cancelling signals`);
    clearTimeout(registry[actionType].intervalId);
    delete registry[actionType];
  }
};

window.__dumpPollRegistry = () => {
  console.log(registry);
};

let isNoisy = false;
window.__logPolling = () => {
  isNoisy = !isNoisy;
};

const updateRegistry = (key: string, spec: Partial<PollSpec>): void => {
  const existing = registry[key];

  if (existing) {
    registry[key] = {
      ...existing,
      ...spec,
    };
    return;
  }

  // needs to be a complete spec
  if (isPollSpec(spec)) {
    registry[key] = spec;
    return;
  }

  throw new Error(
    `Cannot update registry with incomplete spec: ${JSON.stringify(spec)}`
  );
};

const logPoll = (actionType: string) => {
  if (isNoisy) {
    console.log(`[poll]: ${actionType}{${registry[actionType]?.iteration}}`);
  }
};

/**
   Polling middleware works like so

   - An action with a poll spec in the metadata is sent up the stack.

   - `setTimeout` is called to dispatch the action again using the
     `action.meta.poll.interval` property to determine the timeout
     between dispatches.

   - This continues until `polling/cancel` is dispatched with
     a payload matching `type` of the polling action.

   - The logs are a little chatty. To shut them up, type `-[poll]` into
     the `Filter` text field at the top of your Chrome dev tools
     console.
 */
const polling: Middleware = (store) => (next) => (action) => {
  next(action);

  if (!isPollingAction(action)) {
    return;
  }

  if (cancel.match(action)) {
    const spec = registry[action.payload];

    if (spec) {
      clearTimeout(spec.intervalId);
      delete registry[action.payload];
    }

    return;
  }

  const { poll } = action.meta;
  const spec = registry[action.type];

  // start polling
  if (!spec) {
    const intervalId = setTimeout(() => {
      logPoll(action.type);
      store.dispatch(action);
      updateRegistry(action.type, { isActive: false });
    }, poll.interval);

    updateRegistry(action.type, {
      intervalId,
      iteration: 1,
      isActive: true,
    });

    return;
  }

  // otherwise, we're already polling. Always clear the timeout
  clearTimeout(spec.intervalId);

  // then kick off the next poll
  const intervalId = setTimeout(() => {
    logPoll(action.type);
    store.dispatch(action);

    // mark the (now defunct) iteration as inactive
    updateRegistry(action.type, {
      isActive: false,
    });
  }, poll.interval);

  updateRegistry(action.type, {
    intervalId,
    iteration: spec.iteration + 1,
    isActive: true,
  });
};

export default polling;
