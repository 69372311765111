import { useState, useContext } from 'react';
import { NotificationContext } from 'core/components';

import { coreClient } from 'gql/GraphQLProvider';

import {
  SyncCaseToSalesforceMutation,
  SyncCaseToSalesforceDocument,
  SyncCaseToSalesforceMutationVariables,
  SyncDmUrlToSfMutation,
  SyncDmUrlToSfDocument,
  SyncDmUrlToSfMutationVariables,
} from 'generated/core/graphql';
import {
  useSyncToThirdPartyMutation,
  ThirdPartiesAvailableForSyncing,
} from 'generated/legacy/graphql';

const useSyncToThirdParty = () => {
  const { showNotification } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);

  const [syncToThirdPartyMn] = useSyncToThirdPartyMutation();

  const syncToThirdParty = ({
    thirdParty,
    customerId,
    coreCases = [],
    onCompleted = () => {},
  }: {
    thirdParty: ThirdPartiesAvailableForSyncing;
    customerId: string;
    coreCases?: { caseRef: string }[];
    onCompleted?: () => void;
  }) => {
    setIsLoading(true);
    syncToThirdPartyMn({
      variables: { thirdParty, customerId },
      onError: (err) => {
        const errorMessages = err.graphQLErrors
          .map((e) => e.message)
          .join('\n');
        showNotification(errorMessages, 'error');
      },
      onCompleted: async () => {
        if (thirdParty === ThirdPartiesAvailableForSyncing.Salesforce) {
          if (coreCases.length > 0) {
            await Promise.all(
              coreCases.map(async (coreCase) => {
                coreClient.mutate<
                  SyncCaseToSalesforceMutation,
                  SyncCaseToSalesforceMutationVariables
                >({
                  fetchPolicy: 'no-cache',
                  mutation: SyncCaseToSalesforceDocument,
                  variables: {
                    caseRef: coreCase.caseRef,
                  },
                });
              })
            );
          }

          await coreClient.mutate<
            SyncDmUrlToSfMutation,
            SyncDmUrlToSfMutationVariables
          >({
            fetchPolicy: 'no-cache',
            mutation: SyncDmUrlToSfDocument,
            variables: {
              patientId: customerId,
            },
          });
        }

        setIsLoading(false);
        onCompleted();
      },
    });
  };

  return {
    syncToThirdParty,
    isSyncingToThirdParty: isLoading,
  };
};

export default useSyncToThirdParty;
