import React from 'react';
import styled from 'styled-components/macro';

import {
  PatientRejectionReason,
  RejectionReason,
} from 'generated/legacy/graphql';

const Label = styled.label`
  display: block;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 2rem;
  }
`;

const Checkbox = styled.input`
  margin-top: 0.125rem;
  margin-right: 0.75rem;
  vertical-align: text-top;
  cursor: pointer;
  appearance: checkbox;
`;

type Props = {
  reasons: PatientRejectionReason[];
  selected: RejectionReason[];
  onToggle: (reason: RejectionReason, addReason: boolean) => void;
};

const RejectionChecklist = ({ reasons, selected, onToggle }: Props) => (
  <ul>
    {reasons.map(({ name, label }) => {
      const reason = { name, label };
      const isSelected = selected.some((r) => r.name === name);
      return (
        <li key={name}>
          <Label>
            <Checkbox
              type="checkbox"
              checked={isSelected}
              onChange={() => onToggle(reason, !isSelected)}
              data-testid="OrthoPrism-Photo-RejectionCheckbox"
            />
            {label}
          </Label>
        </li>
      );
    })}
  </ul>
);

export default RejectionChecklist;
