import moment from 'moment';
import React from 'react';

import { Grid } from 'core/components';
import {
  CaseCreatedAtLabel,
  CaseTabContainer,
  CaseTag,
  CaseTypeLabel,
} from 'components/CaseTab/CaseTab.css';
import { BaseSkeleton } from 'components/Skeletons';
import { getCaseTypeLabel } from 'utils/case';

export const TabLabelSkeleton = () => (
  <CaseTabContainer isSelected style={{ padding: '9px 0' }}>
    <BaseSkeleton />
  </CaseTabContainer>
);

export type CaseTabProps = {
  created_at: string;
  label: string;
  isSelected?: boolean;
  isActive?: boolean;
  onClick?: () => void;
};

const CaseTab = ({
  created_at,
  label = 'Unknown Case Type',
  isSelected = false,
  isActive = false,
  onClick = () => {},
}: CaseTabProps) => {
  const caseType = getCaseTypeLabel(label ?? '');

  return (
    <CaseTabContainer
      isSelected={isSelected}
      onClick={onClick}
      data-testid="pdp-case-tab"
    >
      <CaseCreatedAtLabel isSelected={isSelected}>
        {moment(created_at).format('MMM D, YYYY')}
      </CaseCreatedAtLabel>
      <Grid container direction="row" alignItems="center">
        <CaseTypeLabel isSelected={isSelected}>{caseType}</CaseTypeLabel>
        {isActive && <CaseTag isSelected={isSelected}>Active</CaseTag>}
      </Grid>
    </CaseTabContainer>
  );
};

export default CaseTab;
