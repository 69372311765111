import { colors } from 'core/components';
import { DefinitionsMapType } from 'pages/ActionItems/types';

export enum Definitions {
  InTreatment = 'In treatment',
  TpReview = 'Treatment plan ready for your review',
  Rejected = 'Materials rejected',
  NeedsClarification = 'Needs clarification',
  Incomplete = 'Incomplete submission',
  PreTreatment = 'Pre-treatment',
  TotalCases = 'Total cases',
}

export const definitionsMap: DefinitionsMapType = {
  [Definitions.InTreatment]: {
    color: colors.green05,
    definition: 'The patient is currently undergoing treatment.',
    display: true,
  },
  [Definitions.TpReview]: {
    color: colors.orange50,
    definition:
      'The treatment plan has been prepared by our orthodontic team and is ready for you to review.',
    display: true,
  },
  [Definitions.Rejected]: {
    color: colors.red,
    definition:
      'Our orthodontic review team found an issue with one or more of your materials. You will need to resubmit whichever materials are rejected.',
    display: true,
  },
  [Definitions.NeedsClarification]: {
    color: colors.yellow90,
    definition:
      'Our orthodontic review team is unsure about one or more of your materials, and needs to ask some clarifying questions. You’ll need to review their note and respond promptly to figure out the next step.',
    display: true,
  },
  [Definitions.Incomplete]: {
    color: colors.black60,
    definition:
      'You started to submit a case, but didn’t complete all of the required steps.',
    display: true,
  },
  [Definitions.PreTreatment]: {
    color: colors.blue,
    definition:
      'Our team is reviewing your materials. Depending on the quality of your submitted materials, we will either begin treatment planning or notify you if further action is required.',
    display: true,
  },
};
