import { Maybe } from 'graphql/jsutils/Maybe';
import { AggregateTypeFieldsFragment, Case } from 'generated/legacy/graphql';

/* Abstract */
export interface StringMap {
  [key: string]: string;
}

export interface StringToBooleanMap {
  [key: string]: boolean;
}

export interface CustomerInfo {
  id: string;
  name: string;
  firstName?: string;
  dob?: Maybe<string>;
  preferredName?: Maybe<string>;
  sex?: Maybe<string>;
}

export type ProblemCategory = {
  label: string;
  name: string;
};

export enum NavDirection {
  right = 'right',
  left = 'left',
}

export type SubmissionPayload = ArrayElement<
  AggregateTypeFieldsFragment['submissionSet']
>;

/* Error */
export class NamedError extends Error {
  constructor(name: string, message: string) {
    super(message);
    this.name = name;
  }
}

/* HTML */
export type ReactSelectOption<T = string> = {
  label: string;
  value: T;
};

export type Version = number;

/* General */
export type ValuesOf<T> = T[keyof T];

type ArrayElement<A extends readonly unknown[]> = A extends readonly (infer T)[]
  ? T
  : never;

export type Nullable<T> = T | null | undefined;

/**
 * Guard function to check if a value is not null or undefined.
 *
 * @param target
 * @returns
 */
export function isNotNullish<T>(target: Nullable<T>): target is T {
  return target !== null && target !== undefined;
}

export type LocalCase = Case;
