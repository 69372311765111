import { AddressFormType } from 'components/AddressForm/types';

export type NewPatientModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export type ProductButtonProps = {
  img: string;
  title: string;
  body: string;
  isSelected: boolean;
  onClick: () => void;
};

type LegalGuardianFormType = {
  firstName: string;
  lastName: string;
  preferredName?: string;
  middleName?: string;
  phone: string;
  birthday: string | null;
};

export type FormProps = {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string | null;
  day: string;
  month: string;
  year: string;
  preferredName?: string;
  middleName?: string;
  phone?: string;
  shippingAddress: AddressFormType;
  practiceId?: string;
  legalGuardian?: LegalGuardianFormType;
};

export enum CaseProductType {
  ALIGNER = 'aligner',
  RETAINER = 'retainer',
  NONE = '',
}
