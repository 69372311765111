import {
  CoreCase,
  Customer,
} from 'components/SearchTablePage/customerQuerier/type';
import { combineData } from 'components/SearchTablePage/customerQuerier/util';
import { CustomerSearchRow } from 'components/SearchTablePage/types';

export const createQuerierState = () => {
  let cachedCases: readonly CoreCase[] = [];
  let cachedCustomers: readonly Customer[] = [];
  let cachedData: readonly CustomerSearchRow[] = [];
  let failed: boolean = false;

  const addCustomers = (customers: readonly Customer[]) => {
    cachedCustomers = cachedCustomers.concat(customers);
    cachedData = combineData(cachedCustomers, cachedCases);
  };

  const addCases = (cases: readonly CoreCase[]) => {
    cachedCases = cachedCases.concat(cases);
    cachedData = combineData(cachedCustomers, cachedCases);
  };

  const getData = () => cachedData;
  const getFailed = () => failed;
  const setFailed = () => (failed = true);

  return { addCustomers, addCases, getData, getFailed, setFailed };
};
