import { css } from 'styled-components';

const viewNums = [...Array(8).keys()];

export const arrayContainer = css`
  display: grid;
  grid-template-areas:
    'view1 view2 view3'
    'view4   .   view5'
    'view6 view7 view8';

  grid-template-rows: repeat(3, minmax(auto, 1fr));
  grid-template-columns: repeat(3, minmax(auto, 1fr));
`;

export const arrayItem = css`
  ${viewNums
    .map(
      (num) => `
      &:nth-child(${num + 1}) {
        grid-area: view${num + 1};
      }
    `
    )
    .join('')}
`;

export const itiArrayContainer = css`
  display: grid;
  grid-template-areas:
    'view1   .   view2'
    'view3 view4 view5';

  grid-template-rows: repeat(2, minmax(auto, 1fr));
  grid-template-columns: repeat(3, minmax(auto, 1fr));
`;
