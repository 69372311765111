import React from 'react';
import { type } from 'core/components';

import { useIsOnlyCandidPro } from 'hooks/useIsOnlyCandidPro';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { Wrapper, APILink } from 'components/PatientHeader/OpenInActions.css';

type OpenInActionProps = {
  customerId: string;
  caseRef?: string;
  showOrthoPrism?: boolean;
};

const OpenInActions = ({
  customerId,
  caseRef,
  showOrthoPrism,
}: OpenInActionProps) => {
  if (useIsOnlyCandidPro()) {
    return null;
  }

  let tpCentralLink = `/tp-central-enhanced/${customerId}`;
  if (caseRef) {
    tpCentralLink += `/${caseRef}`;
  }

  return (
    <Wrapper>
      <Dropdown showCaret title={<type.Link>Open in:</type.Link>}>
        <APILink
          href={`${import.meta.env.VITE_REACT_APP_API_LEGACY}/admin/app/customer/${customerId}/`}
          rel="noopener noreferrer"
          target="_blank"
        >
          API
        </APILink>
        {showOrthoPrism && (
          <APILink
            href={`/ortho-prism/${customerId}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            Ortho Prism
          </APILink>
        )}
        <APILink
          href={`/patient/${customerId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {'Patient Detail (Provider View)'}
        </APILink>
        <APILink href={tpCentralLink} rel="noopener noreferrer" target="_blank">
          TP Central
        </APILink>
        <APILink
          href={`/prism/${customerId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {'Photo Review (Prism)'}
        </APILink>
        <APILink
          href={`/stl-upload/${customerId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          STL Uploader
        </APILink>
      </Dropdown>
    </Wrapper>
  );
};

export default OpenInActions;
