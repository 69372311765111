import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { colors } from 'core/components';

const TRANSITION_SPEED = 500;
const SIDEBAR_WIDTH = '300px';

type Props = {
  children: ReactNode | ReactNode[];
  isOpen: boolean;
  onToggle?: () => void; // TODO: AK not being used yet, but will be when the side navigation is implemented
  hideToggle?: boolean; // TODO: AK not being used yet, but will be when the side navigation is implemented
};

type SidebarStyleProps = {
  isOpen: boolean;
};

const Container = styled.div<SidebarStyleProps>`
  width: ${SIDEBAR_WIDTH};
  left: ${({ isOpen }) => (isOpen ? '0px' : `-${SIDEBAR_WIDTH}`)};
  position: fixed;
  padding: 1rem;
  z-index: 100;
  border-right: 1px solid ${colors.black20};
  box-shadow:
    0px 2px 5px rgba(0, 0, 0, 0.1),
    0px 4px 4px rgba(0, 0, 0, 0.05);
  height: 100%;
  transition: left ${TRANSITION_SPEED}ms;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    position: relative;
    width: 100%;
    padding: 0;
  }
`;

const Wrapper = styled.div<SidebarStyleProps>`
  position: relative;
  width: ${({ isOpen }) => (isOpen ? `${300 + 35}px` : '40px')};
  transition: width ${TRANSITION_SPEED}ms;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100vw;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 101;
  width: 35px;
  height: 35px;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
    background-color: #1976d2;
    color: #fff;
    padding: 0px 8px;
    box-sizing: content-box;
    border-radius: 4px;
  }
`;

export const Sidebar = ({
  children,
  isOpen,
  onToggle = () => null,
  hideToggle = true,
}: Props) => {
  return (
    <Wrapper isOpen={isOpen}>
      {hideToggle === false && (
        <IconContainer onClick={onToggle}>
          <MenuOpenIcon color="primary" />
        </IconContainer>
      )}
      <Container isOpen={isOpen}>{children}</Container>
    </Wrapper>
  );
};
