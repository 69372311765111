import { ContractRateType } from 'generated/core/graphql';
import { ModifiableContractRateType } from 'pages/PracticeManagement/PricingTable/PricingTable';

export const flexibleWidthProps = (isDesktop: boolean) => {
  return {
    style: {
      width: !isDesktop ? undefined : 'calc((100vw - 220px)/4)',
    },
  };
};

export const fixedWidthProps = (isDesktop: boolean) => {
  return {
    style: {
      width: !isDesktop ? undefined : '220px',
    },
  };
};

export const filtered = (
  search: string,
  rates: ContractRateType[] | ModifiableContractRateType[]
) => {
  if (search === '') {
    return rates;
  }
  return rates.filter((rate) => {
    return rate.sku.toLowerCase().includes(search.toLowerCase());
  });
};

export const dollarFormat = (amount: number) => {
  return `${(amount / 100).toFixed(2)}`;
};

export const renderDateTime = (dateTime: string) =>
  new Date(dateTime)
    .toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZoneName: 'longOffset',
    })
    //get rid of time zone name and any commas
    .replace(new RegExp('[A-Z,]+', 'g'), '');

export const handleRateChange = (
  input: number,
  updatingRateInCents: ModifiableContractRateType['rateInCents'],
  modifiableContractRates: ModifiableContractRateType[]
) => {
  const newRates = modifiableContractRates?.map((originalRate) => {
    if (originalRate.rateInCents.id === updatingRateInCents.id) {
      return {
        ...originalRate,
        rateInCents: {
          ...originalRate.rateInCents,
          value: input,
          id: originalRate.rateInCents.id,
          touched: originalRate.rateInCents.originalValue !== input,
        },
      };
    }
    return originalRate;
  });
  return newRates;
};
