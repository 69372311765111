import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  selectCases,
  selectSelectedCase,
  fetchCases,
} from 'pages/OrthoPrism/orthoSlice';
import CaseTab, { TabLabelSkeleton } from 'components/CaseTab/CaseTab';
import { CaseTabsContainer } from 'components/CaseTab/CaseTab.css';
import { useIsLoading } from 'state/system';

interface Props {
  basePath: string;
}

const CaseSelectionNav = ({ basePath }: Props) => {
  const { push } = useHistory();

  const cases = useSelector(selectCases);
  const selectedCase = useSelector(selectSelectedCase);
  const isFetchingCases = useIsLoading(fetchCases.typePrefix);

  const onCaseTabClick = (caseRef: string) => {
    push(`${basePath}/${caseRef}`);
  };

  if (!cases.length) {
    return <div>There are no cases for this customer.</div>;
  }

  return (
    <CaseTabsContainer>
      {isFetchingCases ? (
        <TabLabelSkeleton />
      ) : (
        cases.map((caseData) => (
          <CaseTab
            key={caseData.caseRef}
            isSelected={caseData.caseRef === selectedCase?.caseRef}
            label={caseData.caseType.label}
            onClick={() => onCaseTabClick(caseData.caseRef)}
            created_at={caseData.createdAt}
            // cases are sorted when fetched, so the first case is the latest
            isActive={!!caseData.isActive}
          />
        ))
      )}
    </CaseTabsContainer>
  );
};

export default CaseSelectionNav;
