import { Chip } from 'core/components';
import styled from 'styled-components/macro';

export const StyledChip = styled(Chip)<{
  background: string;
  foreground: string;
}>`
  ${({ theme, background, foreground }) => `
        &.MuiChip-root {
            background-color: ${background};
            font-size: ${theme.fontSizes.small};
            font-weight: ${theme.fontWeights.default};
        }

        .MuiChip-label {
            color: ${foreground};
        }
    `}
`;
