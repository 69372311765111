import styled from 'styled-components/macro';
import { Button, colors, type } from 'core/components';

import CircleOutline from 'assets/circle-outlined.svg?react';
import CheckedCircle from 'assets/checked-circle.svg?react';

import { StatusLineProps } from 'pages/Patient/PatientDetail/IncompleteSubmission';

export const Wrapper = styled.div`
  padding-left: 32px;
  padding-top: 32px;
  border-top: 1px solid ${colors.black20};
`;

export const StyledCheckCircle = styled(CheckedCircle)`
  display: inline-block;
  width: 24px;
  height: 24px;
`;

export const StyledCirleOutline = styled(CircleOutline)`
  width: 24px;
  height: 24px;

  path {
    stroke: ${colors.black40};
  }
`;

export const StatusLines = styled.div`
  margin-bottom: 32px;
`;

export const StatusLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin-bottom: 16px;
`;

export const StatusLineName = styled(type.Body)<
  Pick<StatusLineProps, 'isComplete'>
>`
  display: inline-block;

  margin-bottom: 0;
  margin-left: 8px;
  text-align: center;

  ${({ isComplete }) => !isComplete && `color:${colors.black40};`}
`;

export const ContinueButton = styled(Button)``;
