import styled from 'styled-components/macro';
import { Grid, Skeleton } from 'core/components';

import { AccordionContainer } from 'pages/Patient/PatientDetail/PatientDetail.css';

export const StyledMaterialSkeleton = styled(AccordionContainer)`
  border-bottom: none;
`;

export const StyledMaterialTabGrid = styled(Grid)`
  padding-top: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

export const StyledMaterialTabSkeleton = styled(Skeleton)`
  margin-right: 32px;
`;
