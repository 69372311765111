import styled from 'styled-components/macro';

export const SelectedPracticeContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
`;

export const AddressSelectionContainer = styled.div`
  margin-top: 2rem;
`;

export const PracticeName = styled.p`
  margin-left: 0.5rem;
`;
