import styled from 'styled-components/macro';
import { colors, mediaQueries, type } from 'core/components';

export const SubHeading = styled(type.H5)`
  margin-bottom: 1rem;
`;

export const InfoBlocks = styled.ul`
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${mediaQueries.mobile} {
    li:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }

  @media ${mediaQueries.tabletAndAbove} {
    display: flex;
    width: 100%;

    li {
      flex: 1;
      font-size: ${type.size.t1};

      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }
`;

export const FormContainerBorderless = styled.form<{ disabled?: boolean }>`
  display: inline;
  padding: 1.5rem;
  margin-bottom: 2rem;
  pointer-events: ${({ disabled }) => disabled && 'none'};
  border-radius: 0.1875rem;
`;

export const FileNameContainer = styled(type.H5)`
  padding: 1rem;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
  text-align: center;
  border: 1px solid ${colors.black20};
  border-radius: 0.1875rem;
`;

export const UploadContainerSmall = styled.div`
  background: ${colors.black05};
  height: 120px;
`;

export const FileTypesText = styled.div`
  margin-top: 1rem;
  color: ${colors.black70};
  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding: 1rem 0;
  margin: 0 auto;
  width: fit-content;

  button {
    margin: 0 0.5rem;
  }
`;
