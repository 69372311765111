import React from 'react';
import {
  Container,
  TextContainer,
  HeaderText,
  BodyText,
  Image,
} from 'pages/Patient/ProductSelectionModal/Card.css';

type ProductCardProps = {
  header: string;
  body: string;
  imageSrc: string;
  PriceComponent?: React.ReactNode | null;
  isSelected: boolean;
  onClick: () => void;
};

const ProductCard = ({
  header,
  body,
  PriceComponent,
  imageSrc,
  isSelected,
  onClick,
}: ProductCardProps) => {
  return (
    <Container
      isSelected={isSelected}
      onClick={onClick}
      selectionType="products"
    >
      <Image src={imageSrc} alt="" />
      <TextContainer selectionType="products">
        <HeaderText>{header}</HeaderText>
        <BodyText>{body}</BodyText>
        {PriceComponent}
      </TextContainer>
    </Container>
  );
};

export default ProductCard;
