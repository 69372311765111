import { Divider } from '@material-ui/core';
import {
  ArchContainer,
  CheckboxContainer,
  Container,
  StyledCheckbox,
} from 'components/StepPicker/StepPicker.css';
import React, { useMemo } from 'react';
import { Overline } from 'styles/text.css';
import { Nullable } from 'utils/types';

type Arch = 'upper' | 'lower';
type ArchPrefix = 'U' | 'L';
type AvailableSteps = Record<Arch, Nullable<number>>;
type SelectedSteps = Record<Arch, Set<number>>;
const ARCH_PREFIXES: Record<Arch, ArchPrefix> = {
  upper: 'U',
  lower: 'L',
};

const toggle = (index: number, set: Set<number>): Set<number> => {
  if (set.has(index)) {
    set.delete(index);
  } else {
    set.add(index);
  }
  return set;
};

const formatStepSetWithPrefix = (prefix: ArchPrefix, stepSet: Set<number>) => {
  /*
   * Formats a set of steps with an arch prefix
   * @param prefix - the prefix to add to each step
   * @param stepSet - the set of steps to format
   * @returns an array of formatted step strings
   */
  const formattedSteps: string[] = [];
  stepSet.forEach((step) => {
    formattedSteps.push(`${prefix}${String(step).padStart(2, '0')}`);
  });
  return formattedSteps;
};
export const formatSelectedSteps = (selectedSteps: SelectedSteps) => {
  /*
   * Formats the selected steps for input into the CreateOrder mutation
   * @param selectedSteps - the selected steps
   * @returns an array of formatted step strings
   */
  const formattedSteps: string[] = [];
  (Object.keys(selectedSteps) as Arch[]).forEach((arch) => {
    formattedSteps.push(
      ...formatStepSetWithPrefix(ARCH_PREFIXES[arch], selectedSteps[arch])
    );
  });
  return formattedSteps;
};
export const numSelectedSteps = (selectedSteps: SelectedSteps) => {
  return selectedSteps.upper.size + selectedSteps.lower.size;
};

export type StepPickerProps = {
  selectedSteps: SelectedSteps;
  setSelectedSteps: (selectedSteps: SelectedSteps) => void;
  availableSteps: AvailableSteps;
};

export const StepPicker: React.FC<StepPickerProps> = (
  props: StepPickerProps
) => {
  const { selectedSteps, setSelectedSteps, availableSteps } = props;

  const showDivider = useMemo(() => {
    return availableSteps?.upper && availableSteps?.lower;
  }, [availableSteps]);

  const IndexedCheckbox: React.FC<{
    index: number;
    arch: Arch;
  }> = ({ index, arch }) => {
    const step = index + 1;
    return (
      <StyledCheckbox
        key={index}
        onClick={() => {
          setSelectedSteps({
            ...selectedSteps,
            [arch]: toggle(step, selectedSteps[arch]),
          });
        }}
        type="checkbox"
        defaultChecked={selectedSteps[arch].has(step)}
        $step={step}
      />
    );
  };

  return (
    <Container>
      {availableSteps?.upper ? (
        <ArchContainer>
          <>
            <Overline>Upper Steps</Overline>
            <CheckboxContainer>
              {Array.from({ length: availableSteps.upper }, (_, index) => (
                <IndexedCheckbox index={index} arch={'upper'} key={index} />
              ))}
            </CheckboxContainer>
          </>
        </ArchContainer>
      ) : null}
      {showDivider ? <Divider /> : null}
      {availableSteps?.lower ? (
        <ArchContainer>
          <>
            <Overline>Lower Steps</Overline>
            <CheckboxContainer>
              {Array.from({ length: availableSteps.lower }, (_, index) => (
                <IndexedCheckbox index={index} arch={'lower'} key={index} />
              ))}
            </CheckboxContainer>
          </>
        </ArchContainer>
      ) : null}
    </Container>
  );
};
export default StepPicker;
