import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Grid } from 'core/components';
import { Spacer, HRDividerLine } from 'styles/layout.css';
import { Promotion } from 'pages/Promotion/promotionsSlice';
import { CreateOrUpdateCouponFormValues } from 'components/Modals/CreateCouponsModal/CreateOrUpdateCouponModal';
import {
  FormikInputWrapper,
  FormikSelectWrapper,
  FormikDatePickerNoHeader,
  ValidateOn,
} from 'components/FormikForms';
import {
  requiredValidator,
  afterStartDateValidator,
  convertPromotionsToFormikOptions,
  textInCommaSeparatedFormatValidator,
} from 'components/FormikForms/utils';
import { selectPromotions } from 'pages/Promotion/promotionsSlice';
import { useSelector } from 'react-redux';

type Props = {
  promotions: Promotion[];
  disabled?: boolean;
  isEditing?: boolean;
};

const CreateOrUpdateCouponForm = ({ disabled, isEditing }: Props) => {
  const { values, validateForm } =
    useFormikContext<CreateOrUpdateCouponFormValues>();
  const promotions = useSelector(selectPromotions);

  useEffect(() => {
    validateForm(values);
  }, [values]);

  const promotionsFormikOptions = convertPromotionsToFormikOptions(
    promotions || []
  );

  return (
    <div data-testid="create-coupon-form">
      <Spacer spacing="2rem" isVertical>
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          style={{ marginLeft: '-0.5rem', marginTop: 0 }}
        >
          <Grid item xs={12} md={12}>
            <FormikSelectWrapper
              testId="input-promotions-id"
              label="Promotion Name*"
              name="promotionName"
              options={promotionsFormikOptions}
              showDefaultValue={true}
              type="select"
              validate={requiredValidator}
              disabled={isEditing}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormikInputWrapper
              testId="input-coupon-code"
              label="Coupon code*"
              name="couponCode"
              type="text"
              placeholder="Enter coupon code or leave blank to generate one"
              disabled={isEditing}
            />
          </Grid>
          {/* Only show the coupon code if it is not an edit */}
          {!isEditing && (
            <>
              <Grid item xs={12} md={12}>
                <FormikInputWrapper
                  testId="input-coupon-description"
                  label="Patients"
                  name="patients"
                  type="text"
                  placeholder="Enter patient IDs in comma separated format"
                  validate={textInCommaSeparatedFormatValidator}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormikInputWrapper
                  testId="input-coupon-description"
                  label="Practices"
                  name="practices"
                  type="text"
                  placeholder="Enter practice IDs in comma separated format"
                  validate={textInCommaSeparatedFormatValidator}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={12}>
            <FormikInputWrapper
              testId="input-redemption-limit"
              label="Redemption Limit"
              name="redemptionLimit"
              type="number"
              placeholder="Enter redemption limit or leave blank for no limit"
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <FormikDatePickerNoHeader
              testId="input-start-date"
              label="Start Date*"
              name="startsAt"
              type="text"
              placeholder="Select a start date"
              disabled={disabled}
              validateOn={ValidateOn.Blur}
              validate={requiredValidator}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <FormikDatePickerNoHeader
              testId="input-end-date"
              label="End Date"
              name="endsAt"
              type="text"
              placeholder="Select an end date or leave blank for no end date"
              validate={(value) =>
                values.startsAt
                  ? afterStartDateValidator(values.startsAt, value)
                  : ''
              }
              disabled={disabled}
              validateOn={ValidateOn.Blur}
            />
          </Grid>
        </Grid>
        {/* Only show the updated reason if it is an edit */}
        {isEditing && (
          <Grid item xs={12} md={12}>
            <FormikInputWrapper
              testId="input-updated-reason"
              label="Reason Updated*"
              name="updatedReason"
              type="text"
              placeholder="Briefly explain why you're updating the coupon"
              validate={requiredValidator}
              validateOn={ValidateOn.Blur}
            />
          </Grid>
        )}
      </Spacer>
      <HRDividerLine />
    </div>
  );
};

export default CreateOrUpdateCouponForm;
