import React from 'react';
import DocumentTitle from 'components/DocumentTitle';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import CreateOrderForm from 'pages/Orders/CreateOrderForm';

const Orders = ({ match }: RouteComponentProps) => (
  <DocumentTitle title="Orders">
    <Switch>
      <Route component={CreateOrderForm} exact path={`${match.path}/new`} />
      <Route component={NotFound} />
    </Switch>
  </DocumentTitle>
);

export default Orders;
