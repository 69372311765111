import styled from 'styled-components/macro';
import { colors } from 'core/components';

export const TitleContainer = styled.div`
  padding: 32px;
  background-color: ${colors.black05};
  width: 100%;
`;
export const Title = styled.div`
  font-family: Modern Era;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
`;
