import moment from 'moment';
import { AlertCardGetPropsType } from 'pages/Patient/types';

export const getRefinementsRequestedAlertCardProps = ({
  subsequentCase,
  selectedCase,
}: AlertCardGetPropsType) => {
  const {
    currentStep,
    daysOnCurrentStep,
    itiType,
    hasDentalWorkBeenDone,
    notes,
    itiPaymentCategory,
  } = subsequentCase?.data || {};
  return {
    currentStep,
    daysOnCurrentStep,
    itiType,
    providerFacingState: selectedCase?.caseState?.providerFacing ?? '',
    hasDentalWorkBeenDone,
    notes: notes,
    paymentCategory: itiPaymentCategory,
  };
};

export const getCaseCanceledDataAlertCardProps = ({
  selectedCase,
}: AlertCardGetPropsType) => {
  const { journey, data } = selectedCase || {};
  if (journey?.journeyState === 'could-not-treat') {
    return {
      reason: `Should not treat`,
      notes: `Please see notes on the plan below for details on why we don't think CandidPro treatment is the best option for this patient.`,
    };
  }
  const dataObject = typeof data === 'string' ? JSON.parse(data) : data;
  const transitionReason = dataObject?.transition_reason;

  return {
    reason:
      (journey?.transitionReason || transitionReason) ?? 'No reason provided',
    notes: `Closed on ${moment(journey?.lastUpdated).format('MMM D, YYYY')}.`, //TODO: closed by whom? do we have this information
  };
};

//TODO: Add transition reason on the backend, and make the reason dynamic
export const getCaseCompletedDataAlertCardProps = ({
  selectedCase,
}: AlertCardGetPropsType) => {
  const { journey, caseState } = selectedCase || {};
  let reason = 'No reason provided';
  if (journey?.transitionReason) {
    reason = journey.transitionReason;
  } else if (caseState?.transitionReason) {
    reason = caseState.transitionReason;
  }

  return {
    reason: reason,
    notes:
      'If you’ve closed this case in error and want to order more retainers for this case, contact support.',
  };
};
