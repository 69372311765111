import React, { useMemo } from 'react';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { Modal } from 'core/components';

import {
  AnswerType,
  EditableFormQuestion,
  QuestionFormikProps,
} from 'pages/IntakeForms/types';
import QuestionForm from 'pages/IntakeForms/QuestionModal/QuestionForm';

import {
  ConsentTypes,
  QuestionTypes,
  FormQuestion,
} from 'generated/core/graphql';

type QuestionModalProps = {
  data?: EditableFormQuestion | null;
  isOpen?: boolean;
  onSave: (data: QuestionFormikProps, position: number) => Promise<void>;
  onClose: () => void;
  onDelete: (position: number) => Promise<void>;
};

const defaultFormValues = {
  'question-type': QuestionTypes.Boolean,
  'question-text': '',
  'answer-type-condition': '',
  'consent-type-condition': '',
  'require-explanation': false,
  'explanation-label': '',
  'cnt-reason-text': '',
  choices: undefined,
  'is-follow-up-question': false,
  'is-required': true,
  'has-default-preference-option': false,
};

const getInitialValues = (data: FormQuestion) => {
  let answerTypeCondition = '';
  let consentTypesCondition = '';

  if (typeof data.isCouldNotTreatFor === 'boolean') {
    answerTypeCondition = data.isCouldNotTreatFor
      ? AnswerType.YES
      : AnswerType.NO;
    consentTypesCondition = ConsentTypes.CouldNotTreat;
  }

  if (typeof data.isNonStandardFor === 'boolean') {
    answerTypeCondition = data.isNonStandardFor
      ? AnswerType.YES
      : AnswerType.NO;
    consentTypesCondition = ConsentTypes.NonStandard;
  }

  return {
    'question-type': data.questionType || QuestionTypes.Boolean,
    'question-text': data.question || '',
    'question-key': data.questionKey || '',
    'answer-type-condition': answerTypeCondition,
    'consent-type-condition': consentTypesCondition,
    'require-explanation': typeof data.needExplanationFor === 'boolean',
    'explanation-label': data.explanationLabel || '',
    'cnt-reason-text': data.isCouldNotTreatExplanation || '',
    'date-format': data.meta?.dateFormat || '',
    choices: data.meta?.choices ?? undefined,
    'is-follow-up-question': data.isFollowUpQuestion || false,
    'is-required': data.isRequired ?? true,
    'question-tooltip': data.tooltip ?? null,
    'has-default-preference-option': data.hasDefaultPreferenceOption || false,
  };
};

const _validateBoolean = (
  values: QuestionFormikProps,
  errors: FormikErrors<QuestionFormikProps>
) => {
  if (!values['answer-type-condition'] && values['consent-type-condition']) {
    errors['answer-type-condition'] = 'Select an answer';
  }

  if (values['answer-type-condition'] && !values['consent-type-condition']) {
    errors['consent-type-condition'] = 'Select a condition';
  }

  const hasCondition =
    !!values['answer-type-condition'] || !!values['consent-type-condition'];
  if (
    hasCondition &&
    values['require-explanation'] &&
    !values['explanation-label']
  ) {
    errors['explanation-label'] = 'Byline text for explaining details';
  }

  if (
    values['consent-type-condition'] === ConsentTypes.CouldNotTreat &&
    !values['cnt-reason-text']
  ) {
    errors['cnt-reason-text'] = 'Reason is required';
  }
};

const _validateDate = (
  values: QuestionFormikProps,
  errors: FormikErrors<QuestionFormikProps>
) => {
  if (!values['date-format']) {
    errors['date-format'] = 'Date format required';
  }
};

const validateForm = (values: QuestionFormikProps) => {
  const errors: FormikErrors<QuestionFormikProps> = {};

  if (!values['question-text']) {
    errors['question-text'] = 'Please write a question';
  }

  if (values['question-type'] === QuestionTypes.Boolean) {
    _validateBoolean(values, errors);
  } else if (values['question-type'] === QuestionTypes.Date) {
    _validateDate(values, errors);
  }

  return errors;
};

const QuestionModal = ({
  data,
  isOpen,
  onSave,
  onClose,
  onDelete,
}: QuestionModalProps) => {
  const initialValues = useMemo(
    () => (data ? getInitialValues(data) : defaultFormValues),
    [data]
  );

  const onSubmit = async (
    values: QuestionFormikProps,
    { resetForm }: FormikHelpers<QuestionFormikProps>
  ) => {
    onSave(values, data?.position!);
    onClose();
    resetForm();
  };

  const handleDelete = async (resetForm: () => void, callback: () => void) => {
    await onDelete(data?.position!);
    onClose();
    resetForm();
    callback();
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} hideCloseButton>
      <Formik
        enableReinitialize
        initialValues={initialValues as QuestionFormikProps}
        onSubmit={onSubmit}
        validate={validateForm}
      >
        <QuestionForm
          handleCloseModal={onClose}
          handleDelete={handleDelete}
          position={data?.position!}
          isEditing={data?.question !== ''}
          questionKeyExist={!!data?.originalQuestionKey}
        />
      </Formik>
    </Modal>
  );
};

export default QuestionModal;
