import Cookie from 'js-cookie';

type SetCookie = (value: string) => string | undefined;
type RemoveCookie = () => void;

export const useCookie = (
  cookieName: string
): [string | undefined, SetCookie, RemoveCookie] => {
  const cookie = Cookie.get(cookieName);
  const setCookie = (value: string) => Cookie.set(cookieName, value);
  const removeCookie = () => Cookie.remove(cookieName);

  return [cookie, setCookie, removeCookie];
};
