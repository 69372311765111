import { Maybe } from 'graphql/jsutils/Maybe';
import { dashesToCapitalizedWords } from 'utils/string';

/**
 * e.g. 'starter-kit-shipped' -> 'Starter Kit Shipped'
 * @param state
 */
export const formatComponentState = (state: Maybe<string>) => {
  if (!state) {
    return '-';
  }

  return dashesToCapitalizedWords(state);
};
