import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Button, colors, mediaQueries, type } from 'core/components';

import CandidTable from 'components/CandidTable';
import { centeredLayout, headerHeight } from 'styles/layout.css';
import LeftArrowIcon from 'assets/arrow-left.svg';
import DragIcon from 'assets/drag.svg';
import PlusCircleIcon from 'assets/plus-circle-outline.svg';

export const Heading = styled(type.H3)`
  margin-bottom: 1rem;
`;

export const FormsTable = styled(CandidTable.Table)`
  display: table;
  tbody tr {
    &:nth-child(even) td {
      background: ${colors.black05};
    }
  }
`;

export const HeaderRow = styled(CandidTable.Row)`
  th:first-child {
    width: 30%;
  }
  th:nth-child(2) {
    width: 55%;
  }
  th:last-child {
    width: 15%;
  }
`;

export const TitleLink = styled(Link)`
  font-size: 1rem;
  font-weight: ${type.weight.default};
  text-decoration: underline;
`;

export const SectionContainer = styled.div`
  padding-top: 6rem;
`;

export const SectionHeader = styled.header`
  position: fixed;
  top: ${headerHeight};
  right: 0;
  left: 0;
  background: ${colors.white};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
`;

export const SectionHeaderContent = styled.div`
  ${centeredLayout};
  display: flex;
  align-items: flex-end;
  padding: 2.5rem 1.25rem 1rem;
  @media ${mediaQueries.tabletAndAbove} {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

export const BackLink = styled(Link)`
  position: absolute;
  top: 1rem;
  padding-left: 1rem;
  font-weight: ${type.weight.default};
  color: ${colors.black90};
  background: url(${LeftArrowIcon}) no-repeat 0 0.3125rem;
  background-size: 0.4375rem auto;
`;

export const HeaderHeading = styled(type.H4)`
  margin-right: auto;
  margin-bottom: 0;
`;

export const HeaderButton = styled(Button)`
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  margin-left: 1rem;
`;

export const AddQuestionButton = styled(HeaderButton)`
  padding-left: 2.5rem;
  color: ${colors.text90};
  background: ${colors.blue10} url(${PlusCircleIcon}) no-repeat 1rem center;
  &:hover,
  &:active {
    background: ${colors.blue10} url(${PlusCircleIcon}) no-repeat 1rem center;
  }
`;

export const DragTable = styled.div`
  display: table;
  width: 100%;
  border-collapse: collapse;
`;

export const DragTableCell = styled.div<{ width?: string }>`
  display: table-cell;
  width: ${({ width }) => width};
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.625rem;
  vertical-align: middle;
`;

export const DragTableHeader = styled.div`
  display: table-header-group;
  font-size: 1rem;
  font-weight: ${type.weight.bold};
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
`;

export const DragTableHeaderRow = styled.div`
  display: table-row;
  background: ${colors.white};
  ${DragTableCell} {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    padding-left: 0.625rem;
    vertical-align: bottom;
  }
`;

export const DragTableBody = styled.ul<{ isDisabled?: boolean }>`
  display: table-row-group;
  font-size: 0.875rem;
  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};
`;

export const DragToggleCell = styled.div`
  position: relative;
  display: table-cell;
  width: 3rem;
`;

export const DragToggle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  cursor: grab;
  background: url(${DragIcon}) no-repeat right 1rem center;
  background-size: 1rem auto;
  &:active {
    cursor: grabbing;
  }
`;

export const DragTableRow = styled.li<{
  isDraggedAbove?: boolean;
  isDraggedBelow?: boolean;
}>`
  display: table-row;
  cursor: pointer;
  background: ${colors.white};
  border-top: ${({ isDraggedAbove }) =>
    isDraggedAbove && `1px solid ${colors.blue50}`};
  border-bottom: ${({ isDraggedBelow }) =>
    isDraggedBelow && `1px solid ${colors.blue50}`};
  &:nth-child(even) {
    background: ${colors.black05};
  }
  &:hover {
    background: ${colors.blue10};
    ${DragToggle} {
      display: block;
    }
  }
`;

export const ConditionText = styled.span`
  white-space: nowrap;
`;
