import colors from 'core/styles/colors';

const palette = {
  primary: {
    main: colors.red50,
    contrastText: colors.white,
    hover: {
      background: colors.red70,
      boxShadow:
        '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 14px rgba(0, 0, 0, 0.08)',
    },
    active: {
      background: colors.red30,
      boxShadow:
        '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 14px rgba(0, 0, 0, 0.08)',
    },
    outline: {
      hover: {
        color: colors.red70,
        background: colors.red10,
        borderColor: colors.red70,
        boxShadow:
          '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 14px rgba(0, 0, 0, 0.1)',
      },
    },
  },
  secondary: {
    main: colors.blue50,
    contrastText: colors.white,
    hover: {
      background: colors.blue70,
      boxShadow:
        '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 14px rgba(0, 0, 0, 0.08);',
    },
    active: {
      background: colors.blue30,
      boxShadow:
        '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 14px rgba(0, 0, 0, 0.08)',
    },
    outline: {
      hover: {
        color: colors.blue70,
        background: colors.blue10,
        borderColor: colors.blue70,
        boxShadow:
          '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 14px rgba(0, 0, 0, 0.1)',
      },
    },
  },
  tertiary: {
    main: colors.black20,
    contrastText: colors.black90,
    hover: {
      background: colors.black30,
      boxShadow:
        '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 14px rgba(0, 0, 0, 0.08)',
    },
    outline: {
      hover: {
        background: colors.black10,
        boxShadow:
          '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 14px rgba(0, 0, 0, 0.1)',
      },
    },
  },
  textButton: {
    main: colors.white,
    contrastText: colors.red50,
    hover: {
      background: colors.black05,
    },
  },
  disabled: {
    main: colors.black10,
    contrastText: colors.black50,
  },
  success: {
    main: colors.success,
    contrastText: colors.white,
  },
  warning: {
    main: '',
  },
  error: {
    main: colors.red70,
    contrastText: colors.white,
  },
  info: {
    main: colors.white,
  },
  text: {
    main: colors.text90,
    secondary: colors.text70,
    contrastText: colors.white,
  },
  white: colors.white,
  black: colors.black90,
  border: {
    main: colors.black20,
    hover: colors.black30,
    style: 'solid',
    width: '1px',
  },
};

type Breakpoints = ['350px', '768px', '1024px'] & {
  tiny: 0;
  mobile: '350px';
  tablet: '768px';
  desktop: '1024px';
};

const breakpoints = ['350px', '768px', '1024px'] as Breakpoints;
breakpoints.tiny = 0;
breakpoints.mobile = breakpoints[0];
breakpoints.tablet = breakpoints[1];
breakpoints.desktop = breakpoints[2];

const mediaQueries = {
  tiny: `(max-width: ${breakpoints.mobile})`,
  mobile: `(max-width: ${parseInt(breakpoints.tablet, 10) - 1}px)`,
  tablet: `(min-width: ${breakpoints.tablet}) and (max-width: ${
    parseInt(breakpoints.desktop, 10) - 1
  }px)`,
  tabletAndAbove: `(min-width: ${breakpoints.tablet})`,
  tabletAndBelow: `(max-width: ${parseInt(breakpoints.desktop, 10) - 1}px)`,
  desktop: `(min-width: ${breakpoints.desktop})`,
};

const fonts = {
  modernEra: `'Modern Era'`,
  modernEraMono: `'Modern Era Mono'`,
  sansSerifStack: `'Helvetica Neue', Helvetica, Arial, sans-serif`,
} as const;

type FontSizes = [
  '0.625rem',
  '0.75rem',
  '0.875rem',
  '1rem',
  '1.25rem',
  '1.5rem',
  '2rem',
  '3rem',
  '4rem',
  '6rem',
  '8rem',
] & {
  default: '1rem';
  micro: '0.625rem';
  tiny: '0.75rem';
  small: '0.875rem';
  medium: '1.25rem';
  large: '1.5rem';
  larger: '2rem';
  largest: '3rem';
};

const fontSizes = [
  '0.625rem',
  '0.75rem',
  '0.875rem',
  '1rem',
  '1.25rem',
  '1.5rem',
  '2rem',
  '3rem',
  '4rem',
  '6rem',
  '8rem',
] as FontSizes;

fontSizes.default = fontSizes[3];
fontSizes.micro = fontSizes[0];
fontSizes.tiny = fontSizes[1];
fontSizes.small = fontSizes[2];
fontSizes.medium = fontSizes[4];
fontSizes.large = fontSizes[5];
fontSizes.larger = fontSizes[6];
fontSizes.largest = fontSizes[7];

const fontWeights = {
  default: 400,
  medium: 500,
  bold: 700,
} as const;

const lineHeights = {
  default: 1.5,
  body: 1.6,
  bodySmall: 1.7,
  heading: 1.2,
  button: 1.3125,
} as const;

const letterSpacings = [0, '0.015em', '0.02em', '0.05em', '0.08em'] as const;

type Space = [0, '0.25rem', '0.5rem', '1rem', '2rem', '4rem', '6rem'] & {
  xs: '0.25rem';
  sm: '0.5rem';
  md: '1rem';
  lg: '2rem';
  xl: '4rem';
  xxl: '6rem';
};

const space = [0, '0.25rem', '0.5rem', '1rem', '2rem', '4rem', '6rem'] as Space;

space.xs = space[1];
space.sm = space[2];
space.md = space[3];
space.lg = space[4];
space.xl = space[5];
space.xxl = space[6];

const radii = {
  small: '2px',
  medium: '4px',
  large: '8px',
  circle: '9999px',
} as const;

const zIndices = {
  max: 10000,
  alert: 1000,
  modal: 500,
  menu: 100,
  base: 0,
  buried: -100,
} as const;

const borders = {
  default: `1px solid ${palette.border.main}`,
};

const shadows = {
  default: '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
  small: '0 1px 5px 0 rgba(0, 0, 0, 0.15)',
} as const;

const transitions = {
  default: 'all 0.2s ease-in-out',
  color: 'color 0.2s ease-in-out',
} as const;

// Enamel variants
const text = {
  headings: {
    h1: {
      fontSize: fontSizes.largest,
      fontWeight: fontWeights.bold,
      lineHeight: lineHeights.heading,
    },
    h2: {
      fontSize: fontSizes.larger,
      fontWeight: fontWeights.bold,
      lineHeight: lineHeights.heading,
    },
    h3: {
      fontSize: fontSizes.large,
      fontWeight: fontWeights.bold,
      lineHeight: lineHeights.heading,
    },
    h4: {
      fontSize: fontSizes.medium,
      fontWeight: fontWeights.bold,
      lineHeight: lineHeights.heading,
    },
    h5: {
      fontSize: fontSizes.default,
      fontWeight: fontWeights.medium,
      lineHeight: lineHeights.heading,
    },
    h6: {
      fontSize: fontSizes.small,
      fontWeight: fontWeights.medium,
      lineHeight: lineHeights.heading,
    },
  },
  overline: {
    fontFamily: fonts.modernEraMono,
    fontSize: fontSizes.tiny,
    fontWeight: fontWeights.default,
    letterSpacing: letterSpacings[4],
    textTransform: 'uppercase' as const,
  },
  body: {
    default: {
      fontSize: fontSizes.default,
      lineHeight: lineHeights.default,
    },
    large: {
      fontSize: fontSizes.medium,
      lineHeight: lineHeights.body,
    },
    small: {
      fontSize: fontSizes.small,
      lineHeight: lineHeights.bodySmall,
    },
    micro: {
      fontSize: fontSizes.micro,
      lineHeight: lineHeights.body,
    },
  },
  links: {
    default: {
      color: palette.secondary.main,
      textDecoration: 'none',
      transition: transitions.color,
      ':hover': {
        color: colors.blue70,
      },
    },
    secondary: {
      color: palette.text.main,
      textDecoration: 'underline',
      transition: transitions.color,
      ':hover': {
        color: colors.text70,
      },
    },
  },
};

const elevation = {
  no_elevation: 0,
  elevation: 3,
};

const buttons = {
  primary: {
    color: palette.primary.contrastText,
    background: palette.primary.main,
    ':hover': palette.primary.hover,
    ':active': palette.primary.active,
  },
  'primary-disabled': {
    color: palette.disabled.contrastText,
    background: palette.disabled.main,
  },
  'primary-outline': {
    color: palette.primary.main,
    border: `2px solid ${palette.primary.main}`,
    ':hover': palette.primary.outline.hover,
  },
  'primary-outline-disabled': {
    color: palette.disabled.contrastText,
    border: `2px solid ${palette.disabled.main}`,
  },
  secondary: {
    color: palette.primary.contrastText,
    background: palette.secondary.main,
    ':hover': palette.secondary.hover,
    ':active': palette.secondary.active,
  },
  'secondary-disabled': {
    color: palette.disabled.contrastText,
    background: palette.disabled.main,
  },
  'secondary-outline': {
    color: palette.secondary.main,
    border: `2px solid ${palette.secondary.main}`,
    ':hover': palette.secondary.outline.hover,
  },
  'secondary-outline-disabled': {
    color: palette.disabled.contrastText,
    border: `2px solid ${palette.disabled.main}`,
  },
  'black-outline': {
    color: palette.black,
    border: `2px solid ${palette.black}`,
    ':hover': palette.tertiary.outline.hover,
  },
  'black-outline-disabled': {
    color: palette.disabled.contrastText,
    border: `2px solid ${palette.disabled.main}`,
  },
  tertiary: {
    color: palette.tertiary.contrastText,
    background: palette.tertiary.main,
    ':hover': palette.tertiary.hover,
  },
  'tertiary-disabled': {
    color: palette.disabled.contrastText,
    background: palette.disabled.main,
  },
  text: {
    color: palette.textButton.contrastText,
    background: palette.textButton.main,
    ':hover': palette.textButton.hover,
  },
  'text-disabled': {
    color: palette.disabled.contrastText,
    background: palette.textButton.main,
  },
};

export default {
  palette,
  colors,
  breakpoints,
  mediaQueries,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  space,
  radii,
  zIndices,
  borders,
  shadows,
  transitions,
  text,
  buttons,
  elevation,
};
