import React, { useState } from 'react';

import {
  FormRow,
  NoteHeading,
  SmallHeading,
  SmallSubHeading,
  TextArea,
} from 'pages/OrthoPrism/Plan/TreatmentReviewForm/TreatmentReviewForm.css';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import * as TreatmentPlans from 'pages/OrthoPrism/treatmentPlans';

type Props = {
  tpState: TreatmentPlans.ReviewOption | undefined;
  isProClinician?: boolean;
  onChange: (notes?: string) => void;
};

type TextChange = React.ChangeEvent<HTMLTextAreaElement>;

const Notes = ({ tpState, isProClinician, onChange }: Props) => {
  const isRejected = TreatmentPlans.isRejectionOption(tpState);
  const isMissingInfo =
    tpState === TreatmentPlans.ReviewOption.MissingInformation;

  const [note, setNote] = useState('');

  const onChangeNotes = (e: TextChange) => {
    onChange(e.target.value);
  };

  const isPlainTextForm = isRejected || isProClinician;
  const isRichTextForm = !isRejected;

  if (isPlainTextForm) {
    return (
      <FormRow>
        <SmallHeading>Notes {isRejected && '(required)'}</SmallHeading>
        <TextArea
          maxLength={5000}
          onChange={onChangeNotes}
          placeholder={
            !isRejected ? 'Your notes' : 'Your notes or instructions'
          }
        />
      </FormRow>
    );
  }

  if (isRichTextForm) {
    return (
      <FormRow>
        <NoteHeading>Notes</NoteHeading>
        {!isMissingInfo && (
          <SmallSubHeading>
            Leave detailed notes to let the provider know how we are treating
            this patient
          </SmallSubHeading>
        )}
        <RichTextEditor
          onChange={(s: string) => {
            setNote(s);
            onChange(s);
          }}
          content={note}
        />
      </FormRow>
    );
  }
  return null;
};

export default Notes;
