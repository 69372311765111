import React from 'react';
import { Grid, Box, Loading } from 'core/components';
import ProductThumbnail from 'components/ProductThumbnail';
import { Title } from 'pages/Shipping/Shipping.css';
import ShipmentDetails from 'components/ShipmentDetails';
import ShipmentDetailsByTrackingId from 'components/ShipmentDetailsByTrackingId';
import { useShippingContext } from 'pages/Shipping/utils';
import { REFACTOR_ANY } from '@Types/refactor';

const OrderItems = ({
  addByTrackingID = false,
  ...containerProps
}: {
  addByTrackingID?: boolean;
}) => {
  const {
    selectedOrder,
    selectedOrderItem,
    setSelectedOrderItem,
    selectedRate,
    setSelectedRate,
    loadingGetKrakenOrders,
  } = useShippingContext();

  if (!selectedOrder) {
    return null;
  }

  return (
    <Box
      {...containerProps}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
    >
      <Grid container direction="column">
        <Title>Order items</Title>
        <Grid container direction="row" wrap="nowrap" style={{ gap: '22px' }}>
          {loadingGetKrakenOrders && <Loading />}
          <ProductThumbnail
            orderItems={
              selectedOrder?.orderItems as REFACTOR_ANY /* TODO: Casting is a quickfix */
            }
            selectedOrderItem={selectedOrderItem ?? null}
            onSelectOrderItem={setSelectedOrderItem}
          />
          {addByTrackingID ? (
            <ShipmentDetailsByTrackingId
              orderItem={
                selectedOrderItem as REFACTOR_ANY /* TODO: Casting is a quickfix */
              }
              order={selectedOrder}
            />
          ) : (
            <ShipmentDetails
              orderItem={
                selectedOrderItem as REFACTOR_ANY /* TODO: Casting is a quickfix */
              }
              order={selectedOrder}
              selectedRate={selectedRate}
              setSelectedRate={setSelectedRate}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderItems;
