import StandardIcon from 'assets/loyalty_standard.png';
import SilverIcon from 'assets/loyalty_silver.png';
import GoldIcon from 'assets/loyalty_gold.png';
import PlatinumIcon from 'assets/loyalty_platinum.png';
import DiamondIcon from 'assets/loyalty_diamond.png';
import { theme } from 'core/components';

type TierInfo = {
  icon: string;
  chipBackground: string;
  chipBorder: string;
};

export const ALIGNER_SKU = 'AK00007';

export const LoyaltyTierInfo: TierInfo[] = [
  {
    icon: StandardIcon,
    chipBackground: theme.colors.white,
    chipBorder: theme.colors.black40,
  },
  {
    icon: SilverIcon,
    chipBackground: theme.colors.black05,
    chipBorder: theme.colors.black40,
  },
  {
    icon: GoldIcon,
    chipBackground: theme.colors.yellow10,
    chipBorder: theme.colors.yellow90,
  },
  {
    icon: PlatinumIcon,
    chipBackground: theme.colors.green30,
    chipBorder: theme.colors.green50,
  },
  {
    icon: DiamondIcon,
    chipBackground: theme.colors.blue10,
    chipBorder: theme.colors.blue30,
  },
];
