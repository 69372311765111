import React from 'react';
import {
  DefinitionsContainer,
  TitleAndDefinitionsContainer,
  ColoredBullet,
  DefinitionDescription,
  PopoverTitle,
  StyledPaper,
} from 'pages/ActionItems/OverviewModules/DefinitionsPopover/Definitions.css';

import { TaskTitle } from 'pages/ActionItems/OverviewModules/Overview.css';

import { Divider } from 'core/components';
import {
  DefinitionsPopoverProps,
  DefinitionsMapType,
} from 'pages/ActionItems/types';

export const DefinitionsPopover = ({
  definitionsMap,
}: DefinitionsPopoverProps) => {
  return (
    <StyledPaper>
      <PopoverTitle style={{ paddingBottom: '.6rem' }}>
        Status definitions{' '}
      </PopoverTitle>
      {Object.keys(definitionsMap).map((taskName: string) => {
        if (!definitionsMap[taskName as keyof DefinitionsMapType]?.display) {
          return null;
        }
        const bulletColor =
          definitionsMap[taskName as keyof DefinitionsMapType]?.color;
        const taskDefinition =
          definitionsMap[taskName as keyof DefinitionsMapType]?.definition;
        return (
          <>
            <Divider />
            <DefinitionsContainer>
              <ColoredBullet color={bulletColor} />
              <TitleAndDefinitionsContainer>
                <TaskTitle>{taskName}</TaskTitle>
                <DefinitionDescription>{taskDefinition}</DefinitionDescription>
              </TitleAndDefinitionsContainer>
            </DefinitionsContainer>
          </>
        );
      })}
    </StyledPaper>
  );
};
