import { useState, useEffect } from 'react';

type ScrollDirection = 'up' | 'down' | 'none';
type ScrollDirectionHookProps = {
  thresholdPixels?: number;
};

const useScrollDirection = (props?: ScrollDirectionHookProps) => {
  const thresholdPixels = props?.thresholdPixels || 0;
  const [scrollDir, setScrollDir] = useState<ScrollDirection>('none');
  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const listener = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < thresholdPixels) {
        // We haven't exceeded the thresholdPixels
        return;
      }

      setScrollDir(scrollY > lastScrollY ? 'down' : 'up');
      lastScrollY = scrollY;
    };

    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('scroll', listener);
    };
  });

  return scrollDir;
};

export { useScrollDirection };
