import styled from 'styled-components/macro';
import NewScanIcon from 'assets/ic-file-plus.svg?react';
import ReuseLastStepIcon from 'assets/fi_refresh-cw.svg?react';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: center;
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.large};
`;

export const SelectionContainer = styled.div`
    display flex;
    flex-direction: column;
    gap: 12px;
    max-width:680px;
`;

export const StyledNewScansIcon = styled(NewScanIcon)`
  min-width: 24px;
  min-height: 24px;
`;

export const StyledLastStepIcon = styled(ReuseLastStepIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.blue50};
  }
  min-width: 24px;
  min-height: 24px;
`;

export const CostContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.black90};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const StrikethroughText = styled.div`
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.black70};
`;
