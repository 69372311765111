import React, { useState } from 'react';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { ToolButton } from 'components/PhotoReview/PhotoTools/PhotoTools.css';
import KebabSVG from 'assets/kebab.svg?react';
import styled from 'styled-components/macro';
import { type } from 'core/components';
import { Row } from 'react-table';
import ConfirmDialog from 'components/ConfirmDialog';

type RowMenuProps<T extends object> = {
  rowValue: T;
  row: Row<T>;
  rows: Row<T>[];
  onDelete?: (value: T, row: Row<T>, rows: Row<T>[]) => void;
  onEdit?: (value: T, row: Row<T>, rows: Row<T>[]) => void;
};

export const RowMenu = <T extends object>({
  rowValue,
  row,
  rows,
  onDelete,
  onEdit,
}: RowMenuProps<T>) => {
  const MenuLink = styled(type.Link)<{
    action?: () => void;
    disabled?: boolean;
  }>`
    font-weight: 300;
    color: ${({ disabled }) => (disabled ? 'gray' : '')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

    &:hover {
      cursor: pointer;
    }
  `;

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [rowToDelete, setRowToDelete] = useState<{
    value: T;
    row: Row<T>;
    rows: Row<T>[];
  } | null>(null);

  const onDeleteWithConfirmation = (value: T, row: Row<T>, rows: Row<T>[]) => {
    setShowDeleteConfirmation(true);
    setRowToDelete({ value, row, rows });
  };

  const onCancelDelete = () => {
    setShowDeleteConfirmation(false);
    setRowToDelete(null);
  };

  return (
    <>
      <ConfirmDialog
        confirmButtonText="Delete"
        isOpen={showDeleteConfirmation}
        onConfirm={() =>
          onDelete
            ? onDelete(
                rowToDelete?.value!,
                rowToDelete?.row!,
                rowToDelete?.rows!
              )
            : undefined
        }
        onCancel={onCancelDelete}
        onClose={onCancelDelete}
      >
        Are you want to delete this item?
      </ConfirmDialog>
      <Dropdown
        title={
          <ToolButton style={{ marginTop: '1rem' }}>
            <KebabSVG aria-hidden />
          </ToolButton>
        }
      >
        <MenuLink
          onClick={() => (onEdit ? onEdit(rowValue, row, rows) : undefined)}
        >
          Edit
        </MenuLink>
        <MenuLink
          onClick={() =>
            onDelete ? onDeleteWithConfirmation(rowValue, row, rows) : undefined
          }
        >
          Delete
        </MenuLink>
      </Dropdown>
    </>
  );
};
