import { ACCESS_GROUPS } from 'constants/index';
import { AuthContext } from 'components/AuthProvider';
import { useContext } from 'react';

/*
 * function that checks if the user is a candid pro user but NOT either othro or support.
 * @returns whether the user is in candid pro, but not othro or support
 */
export function useIsOnlyCandidPro(): boolean {
  const { checkHasAccess } = useContext(AuthContext);

  return (
    checkHasAccess &&
    checkHasAccess(ACCESS_GROUPS.CANDID_PRO) &&
    !checkHasAccess(ACCESS_GROUPS.ORTHO.concat(ACCESS_GROUPS.SUPPORT))
  );
}
