import styled from 'styled-components/macro';
import { hideVisually } from 'polished';
import { Button } from 'core/components';

import { imageThumbStyles } from 'styles/imageThumb.css';

export const Container = styled.div`
  flex: 1;
  padding: 2.5rem 3.75rem;
`;

export const Heading = styled.h2`
  ${({ theme }) => theme.text.headings.h2};
  margin-bottom: 1.5rem;
`;

export const Section = styled.section`
  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }
`;

export const SectionHeading = styled.h3`
  ${({ theme }) => theme.text.headings.h4};
  margin-bottom: 1rem;
`;

export const EmptyText = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.black70};
`;

const CANDID_GREEN = '#7dc2a4';
const CANDID_RED = '#e68099';

const StatusDotColors: { [key: string]: any } = {
  success: CANDID_GREEN,
  failure: CANDID_RED,
  none: 'gray',
};

export const StatusDot = styled.span<{ status: string }>`
  height: 10px;
  width: 10px;
  background-color: ${({ status }) => StatusDotColors[status]};
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5em;
`;

export const UploadedImages = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 4.25rem);
  grid-auto-rows: 4.25rem;
  grid-gap: 0.75rem;
  width: 100%;
  padding-bottom: 1.75rem;
`;

export const ImageThumbWrap = styled.div`
  position: relative;
`;

export const ImageThumbButton = styled.button<{
  isApproved?: boolean;
  isRejected?: boolean;
  isSelected?: boolean;
  photo?: string;
}>`
  ${imageThumbStyles};
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const MoveButton = styled(Button)`
  position: absolute;
  bottom: -1.75rem;
  left: 0;
  height: 1.25rem;
  padding: 0.25rem 0.75rem;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 1.2;
  border-radius: 0.625rem;
`;

export const OriginalPhoto = styled.img`
  ${hideVisually()};
`;
