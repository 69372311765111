import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { colors, mediaQueries, type } from 'core/components';

import { centeredLayout } from 'styles/layout.css';

export const Container = styled.header`
  background: ${colors.black05};
`;

export const Content = styled.div<{ hasTabNav: boolean }>`
  ${centeredLayout};
  padding: 1rem 1.25rem;
  padding-bottom: ${({ hasTabNav }) => hasTabNav && '0'};

  @media ${mediaQueries.tabletAndAbove} {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

export const ActionLinks = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TopLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  a {
    height: 1.5rem;
    font-weight: ${type.weight.default};
    color: ${colors.black90};

    svg {
      width: 0.4375rem;
      margin-top: 0.125rem;
      vertical-align: top;
    }

    &:first-child svg {
      margin-right: 0.625rem;
    }

    &:last-child svg {
      margin-left: 0.625rem;
    }
  }
`;

export const InfoWrap = styled.div`
  @media ${mediaQueries.tabletAndAbove} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

export const InfoBlocks = styled.ul`
  display: flex;
  margin-bottom: 0;

  @media ${mediaQueries.mobile} {
    margin-top: 1.25rem;
  }

  li {
    font-size: ${type.size.small};
    font-weight: ${type.weight.bold};
    text-align: center;

    &:not(:first-child) {
      margin-left: 2rem;
    }
  }
`;

export const Overline = styled(type.Overline)`
  margin-bottom: 0.25rem;
`;

export const CustomerName = styled(type.H2)`
  margin-bottom: 0;
`;

export const ViewConsentButton = styled.button`
  font-weight: inherit;
  color: ${colors.blue50};
`;

export const TabNavScrollContainer = styled.div`
  margin-top: 1rem;

  @media ${mediaQueries.mobile} {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
`;

export const TabNav = styled.nav`
  display: flex;
  width: 100%;

  @media ${mediaQueries.mobile} {
    padding-left: 1.25rem;

    &::after {
      display: block;
      flex: none;
      width: 1.25rem;
      height: 1.75rem;
      content: '';
    }
  }
`;

export const TabLink = styled(NavLink)`
  flex: none;
  padding: 0 0.375rem 0.5rem;
  font-size: ${type.size.t1};
  font-weight: ${type.weight.default};
  color: ${colors.black90};

  &:not(:last-child) {
    margin-right: 0.625rem;
  }

  &.active {
    font-weight: ${type.weight.bold};
    color: ${colors.blue50};
    border-bottom: 0.125rem solid ${colors.blue50};
  }
`;
