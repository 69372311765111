import styled, { css } from 'styled-components';

import { ButtonType, ButtonSize } from 'core/components/Button/Button';

export const Container = styled.button<{
  buttonType: ButtonType;
  buttonSize?: ButtonSize;
  isFixedOnMobile?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  hasIcon?: boolean;
}>`
  width: ${({ isFullWidth }) => isFullWidth && '100%'};

  ${({ buttonSize, disabled, buttonType, theme }) => {
    const themedButton =
      theme.buttons[
        `${buttonType}${
          disabled ? '-disabled' : ''
        }` as keyof typeof theme.buttons
      ];
    let padding;
    let fontSize;
    let borderRadius;
    let iconMargin;
    let iconWidth;
    let lineHeight;

    switch (buttonSize) {
      case 'jumbo':
        padding = '1rem 2rem';
        fontSize = '1.25rem';
        borderRadius = '40px';
        iconMargin = '0.75rem';
        iconWidth = '1.5rem';
        lineHeight = '30px';
        break;
      case 'large':
        padding = '0.75rem 1.5rem';
        fontSize = '1rem';
        borderRadius = '25px';
        iconMargin = '0.5rem';
        iconWidth = '1.25rem';
        lineHeight = '24px';
        break;
      case 'small':
        padding = '6px 18px 5px';
        fontSize = '0.875rem';
        borderRadius = '25px';
        iconMargin = '0.25rem';
        iconWidth = '1.25rem';
        lineHeight = '21px';
        break;
      case 'medium':
      default:
        padding = '0.5rem 1.5rem';
        fontSize = '1rem';
        borderRadius = '25px';
        iconMargin = '0.5rem';
        iconWidth = '1.25rem';
        lineHeight = '24px';
        break;
    }

    return css`
      ${css(themedButton)}
      position: relative;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      transition:
        background 0.2s ease-in-out,
        border-color 0.2s ease-in-out,
        color 0.2s ease-in-out;
      font-weight: ${theme.fontWeights.bold};
      border-radius: ${borderRadius};
      font-size: ${fontSize};
      padding: ${padding};
      line-height: ${lineHeight};

      .left-icon,
      .right-icon {
        position: relative;
        display: ${buttonSize === 'small' ? 'none' : 'inline-block'};

        width: ${iconWidth};
        height: ${iconWidth};
        svg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          path {
            stroke: ${themedButton.color};
          }
        }
      }

      .right-icon {
        margin-left: ${iconMargin};
      }

      .left-icon {
        margin-right: ${iconMargin};
      }
    `;
  }};

  ${({ isLoading }) =>
    isLoading &&
    css`
      > * {
        visibility: hidden;
        display: block;
        overflow: hidden;
      }
    `};
  ${({ disabled }) =>
    disabled &&
    css`
      &[disabled] {
        cursor: not-allowed;
      }
    `};

  ${({ isFixedOnMobile, theme }) =>
    isFixedOnMobile &&
    css`
      @media ${theme.mediaQueries.mobile} {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3.75rem;
        border-radius: 0;
      }
    `};
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  visibility: visible;
  transform: translate(-50%, -50%);
`;
