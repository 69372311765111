import { BRAND_NAMES } from 'constants/index';

export const generateAccountPassword = ({
  brandName,
  practiceName = '',
}: {
  brandName?: string;
  practiceName?: string;
}) => {
  const brandDisplayValue = BRAND_NAMES.find(
    (name) => name.value === brandName
  )?.displayValue;

  const prefix = brandDisplayValue ?? 'Candid';

  const trimmed_practice_name = practiceName.split(' ')[0];

  return `${prefix}_${trimmed_practice_name}${new Date().getFullYear()}!`;
};
