import styled from 'styled-components/macro';
import { type } from 'core/components';

export const APILink = styled(type.Link)`
  display: block;
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeights.default};
`;

export const Wrapper = styled.div`
  margin-left: 24px;
`;
