import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import styled from 'styled-components/macro';

const RichTextEditorContainer = styled.div`
  ul,
  ol {
    padding-left: 32px;
  }

  ul > li {
    list-style-type: disc;
  }
  ol > li {
    list-style-type: decimals;
  }

  iframe {
    aspect-ratio: 16/9;
    width: 100%;
  }

  a {
    color: #0452c7 !important;
    text-decoration: underline !important;
  }
`;

export const renderRichTextAsHtml = (input: string) => {
  return (
    <RichTextEditorContainer>
      <ReactMarkdown
        linkTarget="_blank"
        components={{
          p: 'div',
        }}
        rehypePlugins={[rehypeRaw]}
      >
        {input}
      </ReactMarkdown>
    </RichTextEditorContainer>
  );
};
