import React, { useContext } from 'react';
import { AuthContext } from 'components/AuthProvider';
import { HelpTooltip } from 'components/HelpTooltip';

type Props = {
  forbiddenGroups?: string[];
  errorText?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const PermissionedInput = ({
  forbiddenGroups = [],
  errorText = "You can't use this",
  ...props
}: Props) => {
  const { checkHasAccess } = useContext(AuthContext);

  const userUnauthorized = checkHasAccess(forbiddenGroups);

  const canUseInput = !userUnauthorized;

  const tooltipText = `${errorText} because you are in one of these groups: ${forbiddenGroups.join(
    ', '
  )}`;

  // Only allow the input box to be seen if user has permission to touch it.
  // If no permission, just show the label and a tooltip.
  return (
    <div
      style={{
        display: 'inline-flex',
      }}
    >
      {canUseInput ? (
        <input {...props} />
      ) : (
        <HelpTooltip content={tooltipText} />
      )}
    </div>
  );
};
