// @TODO deprecate
const old = {
  red: '#f64850',
  redblack: '#3b0407',
  blue: '#2b60c7',
  slate: '#222',
  teal: '#68c9c4',
} as const;

const base = {
  white: '#fff',
  black: '#000',
  black90: '#222222',
  black80: '#464646',
  black70: '#6f6f6f',
  black60: '#8f8f8f',
  black50: '#a3a3a3',
  black40: '#b5b5b5',
  black30: '#c9c9c9',
  black20: '#dedede',
  black10: '#f1f1f1',
  black05: '#fafafa',
  text: 'rgba(0, 0, 0, 1)',
  text90: 'rgba(0, 0, 0, 0.87)',
  text80: 'rgba(0, 0, 0, 0.73)',
  text70: 'rgba(0, 0, 0, 0.56)',
  text60: 'rgba(0, 0, 0, 0.44)',
  text50: 'rgba(0, 0, 0, 0.36)',
  text40: 'rgba(0, 0, 0, 0.29)',
  text30: 'rgba(0, 0, 0, 0.21)',
  text20: 'rgba(0, 0, 0, 0.13)',
  text10: 'rgba(0, 0, 0, 0.05)',
  text05: 'rgba(0, 0, 0, 0.02)',
  red90: '#660005',
  red70: '#C20F18',
  red50: '#F93039',
  red30: '#FCB1B4',
  red10: '#FFF1EE',
  red05: '#FFFAF9',
  blue90: '#001535',
  blue70: '#003584',
  blue50: '#0452C7',
  blue30: '#9FC0F2',
  blue10: '#E8F3FC',
  blue05: '#F8FBFE',
  yellow90: '#EFB600',
  yellow70: '#FFCE32',
  yellow50: '#FFE389',
  yellow30: '#FFF0C1',
  yellow10: '#FFF9E3',
  green90: '#218256',
  green70: '#31A973',
  green50: '#89D6B3',
  green30: '#D4F9E8',
  green10: '#F4FDF9',
  green05: '#388E3C',
  orange50: '#FF8D39',
  orange30: '#FFB37A',
  orange20: '#FFD1B0',
  orange10: '#FFE8D7',
  orange05: '#FFF9F5',
  pink50: '#FB8388',
  pink30: '#FDACB0',
  pink20: '#FED6D7',
  pink10: '#FEEAEB',
  pink05: '#FFF5F5',
} as const;

const named = {
  border: base.black20,
  error: base.red50,
  disabled: '#e8ebef',
  success: base.green05,
} as const;

export default {
  ...old,
  ...base,
  ...named,
};
