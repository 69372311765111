import { object, string, number, array, InferType } from 'yup';

const loyaltyTierFormSchema = object({
  name: string().required(),
  discount: number().required().positive().integer(),
  tpasRequired: number().required().positive().integer(),
});

const loyaltyProgramFormSchema = object({
  name: string().required(),
  rules: array(loyaltyTierFormSchema).required(),
});

export const validateLoyaltyProgramForm = async (
  form: LoyaltyProgramFormType
) => {
  return await loyaltyProgramFormSchema.validate(form);
};

type LoyaltyProgramFormType = InferType<typeof loyaltyProgramFormSchema>;
export type LoyaltyProgramTierFormType = InferType<
  typeof loyaltyTierFormSchema
>;
