import React, { ReactNode } from 'react';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NextLink,
  NormalizedCacheObject,
  Observable,
  Operation,
  ServerError,
} from '@apollo/client';

import { getNewToken } from 'utils/api';
import { getAuthToken, setTokens } from 'utils/auth';

const cache = new InMemoryCache();

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAuthToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  };
});

const retryConnection = (
  operation: Operation,
  forward: NextLink
): Observable<any> =>
  new Observable((observer) => {
    getNewToken().then((data) => {
      // Set tokens if they would not be set elsewhere
      if (!data.fromExisting) {
        setTokens(data.access_token, data.refresh_token);
      }

      const subscriber = {
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      };

      operation.setContext(({ headers = {} }: { headers: object }) => ({
        headers: {
          // Re-add old headers
          ...headers,
          // Switch out old access token for new one
          authorization: `JWT ${data.access_token}` || '',
        },
      }));

      // Retry last failed request
      forward(operation).subscribe(subscriber);
    });
  });

// This fixes issues when saving request objects that we've mutated
// for example, we want to update a property of an object from
// a query we just made. Apollo adds a '__typename' property on our
// query objects. This means if we alter that objects property and then
// use the object in a mutation, we'd get an error because we're not
// expecting __typename.
// This ApolloLink, preemptively removes __typename when making queries
// to avoid this situation
const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: any) =>
      key === '__typename' ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

function isServerError(error: Error): error is ServerError {
  return 'result' in error;
}

const makeApolloClient = (uri: string) => {
  return new ApolloClient<NormalizedCacheObject>({
    link: ApolloLink.from([
      cleanTypeName,
      onError(({ graphQLErrors, networkError, operation, forward }) => {
        const gqlTokenHasExpired = (graphQLErrors || []).find((error) =>
          error.message.toLowerCase().includes('expired')
        );

        // TODO: (tdror) The core proxy returns the expired token error in the networkError
        // We'll need to fix this on the backend which is a bit more lift right now.
        let proxyTokenHasExpired = false;
        if (networkError) {
          proxyTokenHasExpired = networkError.message.includes('expired');

          if (isServerError(networkError)) {
            if (typeof networkError.result === 'string') {
              proxyTokenHasExpired = networkError.result.includes('expired');
            } else if (Array.isArray(networkError.result.messages)) {
              proxyTokenHasExpired = networkError.result.messages.some(
                (message) => message.message.toLowerCase().includes('expired')
              );
            }
          }
        }

        if (gqlTokenHasExpired || proxyTokenHasExpired) {
          return retryConnection(operation, forward);
        }
        if (networkError) {
          console.error(`[Network error]: ${networkError}`);
        }
        console.error(graphQLErrors);
      }),
      authLink.concat(
        new HttpLink({
          uri,
          credentials: 'same-origin',
        })
      ),
    ]),
    cache,
    defaultOptions: {
      mutate: {
        fetchPolicy: 'no-cache',
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
};

export const client = makeApolloClient(
  import.meta.env.VITE_REACT_APP_API_LEGACY_GQL
);

export const coreClient = makeApolloClient(
  import.meta.env.VITE_REACT_APP_API_CORE_GQL
);

const GraphQLProvider = ({ children }: { children: ReactNode }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export type ApolloClientType = ApolloClient<NormalizedCacheObject>;

export default GraphQLProvider;
