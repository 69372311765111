import React from 'react';
import { ActionSection } from 'pages/TpCentralEnhanced/Actions/components';
import { openVisionSoftware } from 'pages/Case/TreatmentPlan/Vision';
import { useTranslation } from 'react-i18next';
import { TreatmentPlanStaging } from 'generated/core/graphql';

export const OpenTPSoftware = ({
  isLoading = true,
  customerRef,
  selectedTP,
}: {
  isLoading: boolean;
  customerRef: string;
  selectedTP: TreatmentPlanStaging;
}) => {
  const { t } = useTranslation();
  return (
    <ActionSection
      title={t('tp_central_enhanced.open_treatment_plan')}
      buttonTitle={t('common.open')}
      buttonAction={() =>
        openVisionSoftware(customerRef, selectedTP?.iteration || 0)
      }
      isDisabled={isLoading}
      disabledText={t('tp_central_enhanced.this_is_disabled')}
    />
  );
};
