import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';

import styled from 'styled-components/macro';
import { Table, Box, Loading, Button, type, colors } from 'core/components';
import RightArrowSVG from 'assets/arrow-right.svg?react';

import { BackLink } from 'pages/BillingManagement/Invoices/styles';

import {
  GetInvoicesDocument,
  GetInvoicesQuery,
  GetInvoicesQueryVariables,
} from 'generated/core/graphql';
import { useGQLQuery } from 'hooks/useGQL';
import {
  GetAllPracticeNamesDocument,
  GetAllPracticeNamesQuery,
  GetAllPracticeNamesQueryVariables,
} from 'generated/legacy/graphql';
import { Nullable } from 'utils/types';

const StyledTable = styled(Table)<{ loading: boolean }>`
  tr {
    cursor: pointer;
  }
  opacity: ${({ loading }) => (loading ? 0.3 : 1)};
`;

const StyledLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const YaypayViewButton = styled.button`
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: ${type.weight.bold};
  color: ${colors.blue};
  fill: currentColor;
  margin-left: 0.5rem;

  svg {
    margin: 0.0625rem 0.5rem 0 0;
    padding-left: 
    vertical-align: text-top;
  }
`;

const InvoiceLinks = styled.div`
  display: flex;
  justify-content: space-between;
`;

const YaypayLink = () => (
  <Link to="/invoice-summary">
    <YaypayViewButton>
      View provider facing invoices
      <RightArrowSVG aria-hidden style={{ marginLeft: '0.5rem' }} />
    </YaypayViewButton>
  </Link>
);

const columns: MUIDataTableColumnDef[] = [
  {
    name: 'practiceId',
    label: 'Practice Id',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'practiceName',
    label: 'Practice Name',
    options: {
      filter: true,
      filterType: 'textField',
      sort: false,
    },
  },
  {
    name: 'periodStartEnd',
    label: 'Period Start - End Date',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'paymentDueDate',
    label: 'Due Date',
    options: {
      filter: false,
      sort: false,
    },
  },
];

type PracticeMap = {
  [key: string]: string;
};

const InvoicesOverview = () => {
  const { push } = useHistory();
  const [
    getInvoicesData,
    { data: getInvoicesDataResult, loading: isFetchingData },
  ] = useGQLQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(
    GetInvoicesDocument
  );

  const [getPracticeData, { data: getPracticeDataResult }] = useGQLQuery<
    GetAllPracticeNamesQuery,
    GetAllPracticeNamesQueryVariables
  >(GetAllPracticeNamesDocument, true, false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const queryResult = getInvoicesDataResult?.invoices;
  const invoices = queryResult?.edges;
  const [mappedInvoices, setMappedInvoices] = useState<any[]>([]);

  const [page, setPage] = useState<number>(0);
  const [beforeCursor, setBeforeCursor] = useState<Nullable<string>>(null);
  const [afterCursor, setAfterCursor] = useState<Nullable<string>>(null);
  const [practiceNameFilter, setPracticeNameFilter] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);

  useEffect(() => {
    getInvoicesDataWithValues();
    getPracticeData({});
  }, []);

  const indexedPractices = useMemo(() => {
    if (!getPracticeDataResult) {
      return;
    }
    setIsLoading(true);

    return getPracticeDataResult.practices?.reduce<PracticeMap>(
      (acc, practice) => {
        acc[practice.id] = practice.name;
        return acc;
      },
      {}
    );
  }, [getPracticeDataResult]);

  const getInvoicesDataWithValues = (
    before?: string | null,
    after?: string | null
  ) => {
    if (before) {
      getInvoicesData({
        last: rowsPerPage,
        practiceName: practiceNameFilter,
        before,
      });
    } else if (after) {
      getInvoicesData({
        first: rowsPerPage,
        practiceName: practiceNameFilter,
        after,
      });
    } else {
      getInvoicesData({
        first: rowsPerPage,
        practiceName: practiceNameFilter,
      });
    }
  };

  useEffect(() => {
    getInvoicesDataWithValues();
  }, [rowsPerPage, practiceNameFilter]);

  useEffect(() => {
    if (!invoices?.length || !indexedPractices) {
      return;
    }

    setBeforeCursor(queryResult?.pageInfo?.startCursor);
    setAfterCursor(queryResult?.pageInfo?.endCursor);

    const mappedInvoices = invoices?.map((invoice) => ({
      invoiceId: invoice?.node?.id,
      practiceId: invoice?.node?.practiceId,
      practiceName: indexedPractices[invoice?.node?.practiceId ?? 0] || '',
      periodStartEnd: `${moment
        .utc(invoice?.node?.periodStart)
        .format('MM/DD/YYYY')} - ${moment
        .utc(invoice?.node?.periodEnd)
        .format('MM/DD/YYYY')}`,
      paymentDueDate: moment
        .utc(invoice?.node?.paymentDueDate)
        .format('MM/DD/YYYY'),
    }));

    setMappedInvoices(mappedInvoices);
    setIsLoading(false);
  }, [queryResult, indexedPractices]);

  const handlePageChange = (newPage: number) => {
    if (newPage === page) {
      return;
    }

    if (newPage > page) {
      getInvoicesDataWithValues(null, afterCursor);
    } else {
      getInvoicesDataWithValues(beforeCursor, null);
    }
    setPage(newPage);
  };
  const options: MUIDataTableOptions = {
    selectableRows: 'none',
    fixedHeader: true,
    pagination: true,
    filter: true,
    serverSide: true,
    count: queryResult?.totalCount ?? 0,
    page: page,
    tableBodyMaxHeight: 'calc(80vh)',
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 20, 30, 50, 100],
    filterType: 'textField',
    customFilterDialogFooter: (_, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            buttonSize="small"
            onClick={() => {
              if (applyNewFilters) {
                applyNewFilters();
              }
            }}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    onFilterConfirm(filterList) {
      handleFilterChange(filterList);
    },

    onChangeRowsPerPage: (rowsPerPage: number) => {
      setRowsPerPage(rowsPerPage);
    },
    onChangePage: handlePageChange,
    onRowClick: (_rowData, rowMeta) => {
      push(`/billing/invoices/${mappedInvoices[rowMeta.dataIndex].invoiceId}`);
    },
  };

  const handleFilterChange = (filterList: string[][]) => {
    //We only allow a single search term, and practice is the second column
    if (filterList.length >= 2 && filterList[1].length) {
      const practiceNameFilter = filterList[1][0];
      setPage(0);
      setPracticeNameFilter(practiceNameFilter);
    } else {
      setPracticeNameFilter('');
    }
  };
  //Display this if no data has loaded yet
  if (isLoading && mappedInvoices.length === 0) {
    return <Loading isCentered />;
  }

  return (
    <Box py={['1rem']} m={['auto']} style={{ maxWidth: '1080px' }}>
      <InvoiceLinks>
        <BackLink />
        <YaypayLink />
      </InvoiceLinks>

      <Box>
        {mappedInvoices && (isFetchingData || isLoading) && (
          <StyledLoader>
            <Loading />
          </StyledLoader>
        )}
        <StyledTable
          title="Invoices"
          data={mappedInvoices}
          columns={columns}
          options={options}
          loading={isLoading || isFetchingData}
        />
      </Box>
    </Box>
  );
};

export default InvoicesOverview;
