import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'core/components';

import { useAccordionItemContext } from '@reach/accordion';
import '@reach/accordion/styles.css';

import {
  ArrowIcon,
  Button,
  Item,
  Panel,
  StatusBadge,
  Title,
  ColoredDot,
  IconContainer,
} from 'components/StepsAccordion/AccordionStep.css';

export enum ColorProfileKey {
  Red = 'Red',
  Yellow = 'Yellow',
  Green = 'Green',
  Gray = 'Gray',
}

type AccordionStepProps = {
  testId?: string;
  number?: string;
  title?: string;
  isOpen?: boolean;
  isComplete?: boolean;
  isLoading?: boolean;
  hasError?: boolean;
  isDisabled?: boolean;
  statusBadgeText?: string;
  dotColorOverride?: ColorProfileKey;
  badgeColorOverride?: ColorProfileKey;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
};

const Arrow = () => {
  const { isExpanded } = useAccordionItemContext();

  return <ArrowIcon $isExpanded={isExpanded} role="img" />;
};

const AccordionStep: React.FC<AccordionStepProps> = ({
  testId,
  number,
  title,
  isOpen = false,
  isComplete = false,
  isLoading = false,
  hasError = false,
  isDisabled,
  children,
  statusBadgeText,
  dotColorOverride,
  badgeColorOverride,
  Icon,
}) => {
  return (
    <Item
      data-testid={testId}
      disabled={isDisabled}
      $isComplete={isComplete}
      $hasError={hasError}
      $isLoading={isLoading}
      $colorProfileKey={badgeColorOverride}
      $isExpanded={isOpen}
    >
      <Button data-testid="customer-creator-step-label">
        <IconContainer>
          {Icon ? (
            <Icon height="24px" width="24px" aria-hidden />
          ) : (
            <ColoredDot colorProfileKey={dotColorOverride}>{number}</ColoredDot>
          )}
        </IconContainer>
        <Title data-testid="customer-creator-step-label">{title}</Title>
        <StatusBadge data-testid={`pill-${number}`}>
          {isLoading ? <Loading /> : statusBadgeText}
        </StatusBadge>
        <Arrow />
      </Button>
      <Panel>{children}</Panel>
    </Item>
  );
};

AccordionStep.propTypes = {
  number: PropTypes.string,
  title: PropTypes.string,
  isComplete: PropTypes.bool,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default AccordionStep;
