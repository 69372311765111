import React, { useState, useEffect, useCallback } from 'react';
import { colors } from 'core/components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  Wrapper,
  TabLabels,
  Tab,
  TabLabelContainer,
  CompareBar,
  TabContent,
  RightPanel,
  LeftPanel,
  MaybeSideBySide,
  StyledChip,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/DiagnosticMaterials.css';
import PhotosTab from 'pages/Patient/PatientDetail/DiagnosticMaterials/PhotosTab';
import XraysTab from 'pages/Patient/PatientDetail/DiagnosticMaterials/XraysTab';
import ScansTab from 'pages/Patient/PatientDetail/DiagnosticMaterials/ScansTab';
import TreatmentGoalsTab from 'pages/Patient/PatientDetail/DiagnosticMaterials/TreatmentGoalsTab';
import { useSelector, useDispatch } from 'react-redux';
import { CaseTypeNames } from 'constants/Case';
import {
  fetchMaterials,
  selectSelectedCase,
  selectProviderFacingStatus,
  fetchTreatmentPlanStagings,
  selectSelectedCaseMaterials,
} from 'pages/Patient/patientSlice';
import { PROVIDER_FACING_STATUSES } from 'constants/caseStatus';
import { Materials } from 'constants/Material';

import Compare, { TabLabel, DiagnosticMaterialTab } from 'components/Compare';
import TreatmentPlanStagingTab from 'pages/Patient/PatientDetail/DiagnosticMaterials/TreatmentPlanTab/TreatmentPlanStaging';
import { usePatientLoadingStates } from 'pages/Patient/utils';

export const allTabs = [
  {
    id: Materials.Photos,
    name: TabLabel.Photos,
    Component: PhotosTab,
  },
  {
    id: Materials.Xrays,
    name: TabLabel.Xrays,
    Component: XraysTab,
  },
  {
    id: Materials.Scans,
    name: TabLabel.Scans,
    Component: ScansTab,
  },
  {
    id: Materials.TreatmentGoals,
    name: TabLabel.TreatmentGoals,
    Component: TreatmentGoalsTab,
  },
  {
    id: Materials.TreatmentPlan,
    name: TabLabel.Plan,
    Component: TreatmentPlanStagingTab,
    showReadyForReview: true,
    allowCompareWithSelf: true,
  },
] as DiagnosticMaterialTab[];

const getTabs = ({
  caseTypeName,
  caseMaterials,
}: {
  caseTypeName?: string;
  caseMaterials?: string[];
}) => {
  if (!caseTypeName || !caseMaterials) {
    return allTabs;
  }

  return allTabs.filter((tab) => {
    // Hide TP tab for retainer cases
    if (
      caseTypeName === CaseTypeNames.RETAINER &&
      tab.id === Materials.TreatmentPlan
    ) {
      return false;
    }

    return caseMaterials?.includes(tab?.id as Materials);
  });
};

const ReadyForReviewChip = () => (
  <StyledChip
    size="tiny"
    label={'Ready for review'}
    background={colors.orange50}
    foreground={colors.white}
  />
);

const DiagnosticMaterialsSection = () => {
  const dispatch = useDispatch();
  const providerFacingStatus = useSelector(selectProviderFacingStatus);
  const selectedCase = useSelector(selectSelectedCase);
  const caseMaterials = useSelector(selectSelectedCaseMaterials);
  const {
    areMaterialsFetching,
    isFetchingPatient,
    isFetchingCases,
    isFetchingTPs,
  } = usePatientLoadingStates();
  const isLoadingTpTab =
    areMaterialsFetching ||
    isFetchingPatient ||
    isFetchingCases ||
    isFetchingTPs;
  const tabs = getTabs({
    caseTypeName: selectedCase?.caseType?.name,
    caseMaterials,
  });
  const tpTabIndex = tabs.findIndex(
    (tab) => tab.id === Materials.TreatmentPlan
  );
  const TpReadyForReview =
    providerFacingStatus ===
    PROVIDER_FACING_STATUSES.TREATMENT_PLAN_READY_FOR_YOUR_REVIEW;
  const [compareTab, setCompareTab] = useState<string>('');
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  useEffect(() => {
    if (selectedCase?.caseRef) {
      dispatch(fetchTreatmentPlanStagings({ caseRef: selectedCase?.caseRef }));
    }
  }, [selectedCase?.caseRef]);
  useEffect(() => {
    // set default tab to treatment plan if it exists,
    // otherwise set to first tab
    setCurrentTabIndex(tpTabIndex > -1 ? tpTabIndex : 0);
  }, [tpTabIndex]);
  useEffect(() => {
    // There are no other tabs to compare with
    if (tabs.length < 2) {
      return;
    }
  }, [currentTabIndex, compareTab]);

  const onCompare = useCallback(
    (tabId: string) => {
      setCompareTab(tabId);
    },
    [setCompareTab]
  );

  const { 'beta-treatment-goals': fetchBetaForms } = useFlags();
  useEffect(() => {
    if (!selectedCase?.caseRef) {
      return;
    }
    dispatch(
      fetchMaterials({
        caseRef: selectedCase?.caseRef,
        fetchBetaForms,
        formsToFetch: [], //Don't care about fetching all forms
      })
    );
  }, [selectedCase, fetchBetaForms]);

  const currentTab = tabs[currentTabIndex];
  const ContentArea = tabs.map(({ name, Component }) => (
    <TabContent isVisible={name === currentTab?.name} key={name}>
      <Component />
    </TabContent>
  ));

  const CompareComponent = tabs.find((t) => t.name === compareTab)?.Component;
  const CompareArea = CompareComponent && (
    <RightPanel>
      <CompareComponent />
    </RightPanel>
  );
  return (
    <Wrapper>
      <TabLabels data-testid="pdp-diagnostic-material-tab">
        {tabs.map(({ name, showReadyForReview }, index) => (
          <Tab onClick={() => setCurrentTabIndex(index)} key={index}>
            <TabLabelContainer isActive={index === currentTabIndex}>
              {`${name}`}
              {showReadyForReview && !isLoadingTpTab && TpReadyForReview && (
                <ReadyForReviewChip />
              )}
            </TabLabelContainer>
          </Tab>
        ))}
      </TabLabels>
      <CompareBar>
        <Compare
          compareTab={compareTab}
          currentTab={currentTab?.name}
          onCompare={onCompare}
          comparableTabs={tabs}
        />
      </CompareBar>
      <MaybeSideBySide>
        <LeftPanel>{ContentArea}</LeftPanel>
        {CompareArea}
      </MaybeSideBySide>
    </Wrapper>
  );
};

export default DiagnosticMaterialsSection;
