import React from 'react';
import { AccordionSummaryProps as Props } from '@material-ui/core/AccordionSummary';
import {
  DefaultExpandIcon,
  StyledMuiAccordionSummary,
} from 'core/components/Accordion/Accordion.css';

const AccordionSummary = (props: Props) => {
  const { children, expandIcon, ...rest } = props;

  return (
    <StyledMuiAccordionSummary
      expandIcon={expandIcon || <DefaultExpandIcon />}
      {...rest}
    >
      {children}
    </StyledMuiAccordionSummary>
  );
};

export default AccordionSummary;
