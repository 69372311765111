import styled from 'styled-components/macro';
import { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: -1.25rem;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 4rem;
`;

const buttonStyles = css<{ isSelected?: boolean }>`
  width: 2.5rem;
  height: 2.5rem;
  border: 0.125rem solid transparent;
  border-radius: 50%;
  box-shadow: 0 1px 0.3125rem 0 rgba(0, 0, 0, 0.05);
  transition:
    transform 0.2s,
    box-shadow 0.2s;

  ${({ isSelected }) =>
    isSelected
      ? css`
          width: 4rem;
          height: 4rem;
          box-shadow: 0 1px 0.3125rem 0 rgba(0, 0, 0, 0.15);
        `
      : css`
          &:hover {
            box-shadow: 0 1px 0.3125rem 0 rgba(0, 0, 0, 0.15);
            transform: scale(1.15);
          }
        `};

  svg {
    width: 48%;
    height: 48%;
    pointer-events: none;
  }
`;

export const Approve = styled.button<{
  isFaded?: boolean;
  isSelected?: boolean;
}>`
  ${buttonStyles};
  margin-right: 0.625rem;
  margin-left: ${({ isSelected }) => isSelected && '-0.75rem'};
  background: #7dc2a4;
  border-color: #67a389;

  ${({ isFaded }) =>
    isFaded &&
    css`
      background: #c6d9d1;
      border-color: #afc2ba;
    `};

  svg {
    margin-bottom: 0.125rem;
  }

  > span[color='white'] {
    /* Make loading dots smaller */
    transform: scale(0.75);
  }
`;

export const Reject = styled.button<{
  isFaded?: boolean;
  isSelected?: boolean;
}>`
  ${buttonStyles};
  background: #e68099;
  border-color: #d07188;

  ${({ isFaded }) =>
    isFaded &&
    css`
      background: #ebc5ce;
      border-color: #d1a9b3;
    `};

  svg {
    margin-top: 0.125rem;
  }
`;
