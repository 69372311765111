import React from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router-dom';

import { DecodedCase } from 'utils/case';
import * as propTypes from 'utils/propTypes';
import CaseSearchPage from 'components/SearchPages/CaseSearchPage';

type MatchParams = {
  customerId?: string;
};

const JourneySearch = ({
  history,
  match,
}: RouteComponentProps<MatchParams>) => {
  const { customerId } = match.params;

  const handleSubmit = ({ caseRef }: DecodedCase) => {
    if (!caseRef) {
      return;
    }

    history.push(`${match.url}/${caseRef}`);
  };

  return (
    <CaseSearchPage
      patientId={customerId}
      onSelectCase={handleSubmit}
      pageTitle="Journey admin"
    />
  );
};

JourneySearch.propTypes = {
  history: propTypes.routerHistory.isRequired,
  match: propTypes.routerMatch({
    customerId: PropTypes.string,
  }).isRequired,
};

export default JourneySearch;
