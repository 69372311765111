import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import * as propTypes from 'utils/propTypes';
import NotFound from 'pages/NotFound';

import STLUploadOverview from 'pages/STLUpload/STLUploadOverview';
import STLUploadSearch from 'pages/STLUpload/STLUploadSearch';

const STLUpload = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route component={STLUploadSearch} exact path={`${match.path}/:id?`} />
      <Route
        component={STLUploadOverview}
        exact
        path={`${match.path}/:id/:caseRef`}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

STLUpload.propTypes = {
  match: propTypes.routerMatch(),
};

export default STLUpload;
