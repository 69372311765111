import styled from 'styled-components/macro';

import {
  AttributeLabel,
  AttributeValue,
} from 'pages/Patient/PatientDetail/ProfileSideBar/ProfileSideBar.css';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const Attributes = styled.ul`
  margin-bottom: 0;

  li {
    font-size: ${({ theme }) => theme.fontSizes[2]};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-align: left;
    display: inline-block;

    &:not(:first-child) {
      margin-left: 2rem;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 1rem;
  }
`;

export const StyledAttributeLabel = styled(AttributeLabel)`
  margin: 0;
`;

export const StyledAttributeValue = styled(AttributeValue)`
  width: inherit;
  padding: 0;
  margin: 0;
`;
