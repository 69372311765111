import React, { useContext, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Loading } from 'core/components';

import DocumentTitle from 'components/DocumentTitle';
import JourneyProvider from 'components/JourneyProvider';
import PatientHeader from 'components/PatientHeader';

import { CustomerData } from 'pages/XrayUpload/types';
import { XrayUploadContext } from 'pages/XrayUpload/XrayUploadProvider';
import UploadForm from 'pages/XrayUpload/UploadForm';

type MatchParams = {
  caseRef: string;
  id: string;
};

export const formatCustomerInfo = (customerData: CustomerData) => {
  const {
    date_of_birth: dob,
    first_name: firstName,
    id,
    last_name: lastName,
  } = customerData;
  const [firstInitial] = firstName;
  const name = `${firstInitial}. ${lastName}`;

  return {
    id: id.toString(),
    dob,
    name,
  };
};

const XrayUploadOverview = ({ match }: RouteComponentProps<MatchParams>) => {
  const { customerData, getXrayData, isFetchingData } =
    useContext(XrayUploadContext);

  const { caseRef, id } = match.params;
  const isLoading = !customerData || isFetchingData;
  const customerInfo = customerData && formatCustomerInfo(customerData);

  useEffect(() => {
    getXrayData(id, caseRef);
  }, [id, caseRef]);

  if (isLoading) {
    return (
      <DocumentTitle data-private title="Loading…">
        <Loading isCentered />;
      </DocumentTitle>
    );
  }

  return (
    <JourneyProvider caseRef={caseRef}>
      <DocumentTitle
        data-private
        title={`${customerInfo?.name} - X-ray uploader`}
      >
        <PatientHeader customerInfo={customerInfo!} showTopLinks />
        <UploadForm caseRef={caseRef} />
      </DocumentTitle>
    </JourneyProvider>
  );
};

export default XrayUploadOverview;
