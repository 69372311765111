import React from 'react';
import {
  ContactContainer,
  ContactHeaderSection,
  ContactSection,
  ContactSectionTitle,
  ContactText,
  ContactTypeTitle,
  ContactHeader,
  ContactSubHeader,
  HoursText,
  PhoneNumberSubtext,
  PageBody,
  Title,
  ContactBody,
  PageSection,
  ChatSvg,
  MailSvg,
  PhoneSvg,
} from 'pages/ContactUs/ContactUs.css';
import { getBrandSettings, getBrandFromDomain } from 'utils/brands';
import {
  PhoneNumber,
  PhoneNumberContainer,
} from 'pages/ContactUs/ContactUs.css';
import { colors, type } from 'core/components';
import DocumentTitle from 'components/DocumentTitle';
import useZendesk from 'hooks/useZendesk';

type ContactInfoProps = {
  phoneNumbers: PhoneNumber[];
  email: string;
};

type PhoneNumber = {
  phoneNumber: string;
  subtext: string;
};

const ContactInfo = ({ phoneNumbers, email }: ContactInfoProps) => {
  const { handleOpenWidget, showZendesk } = useZendesk();
  return (
    <ContactContainer>
      {!!phoneNumbers.length && (
        <ContactSection>
          <PhoneSvg />
          <ContactText>
            <ContactTypeTitle>PHONE</ContactTypeTitle>

            {phoneNumbers.map((phoneNumber, idx) => (
              <PhoneNumberContainer key={idx}>
                <PhoneNumber>{phoneNumber.phoneNumber}</PhoneNumber>•
                <PhoneNumberSubtext>{phoneNumber.subtext}</PhoneNumberSubtext>
              </PhoneNumberContainer>
            ))}
            <HoursText>Weekdays 9:00 am - 8:00 pm EST</HoursText>
          </ContactText>
        </ContactSection>
      )}
      <ContactSection>
        <MailSvg stroke={colors.black70} />
        <ContactText>
          <ContactTypeTitle>EMAIL</ContactTypeTitle>
          <type.Link>{email}</type.Link>
        </ContactText>
      </ContactSection>
      {showZendesk && (
        <ContactSection>
          <ChatSvg width={'20px'} height={'20px'} />
          <ContactText>
            <ContactTypeTitle>LIVE CHAT</ContactTypeTitle>
            <type.Link
              onClick={() => {
                handleOpenWidget();
              }}
            >
              Start a live chat
            </type.Link>
            <HoursText>Weekdays 9:00 am - 8:00 pm EST</HoursText>
          </ContactText>
        </ContactSection>
      )}
    </ContactContainer>
  );
};

const ContactUs = () => {
  const brand = getBrandFromDomain();
  const { caseSupport, billingSupport } = getBrandSettings(brand);

  const header = "We're here to help";

  return (
    <DocumentTitle title="Help center">
      <PageSection>
        <PageBody>
          <Title>Contact us</Title>
          <ContactBody>
            <ContactHeaderSection>
              <ContactHeader>{header}</ContactHeader>

              <ContactSubHeader>
                Have a technical, clinical, or patient status question? Here are
                two more ways to connect with our Provider Support Team:
              </ContactSubHeader>
            </ContactHeaderSection>
            <ContactInfo
              email={caseSupport.email}
              phoneNumbers={caseSupport.phoneNumbers ?? []}
            />
            {billingSupport && (
              <>
                <ContactSectionTitle>
                  For billing/finance support
                </ContactSectionTitle>
                <ContactInfo
                  email={billingSupport.email}
                  phoneNumbers={billingSupport.phoneNumbers}
                />
              </>
            )}
          </ContactBody>
        </PageBody>
      </PageSection>
    </DocumentTitle>
  );
};

export default ContactUs;
