import { useSelector } from 'react-redux';
import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

type AsyncPredicate = (a: AnyAction) => boolean;

const isPending: AsyncPredicate = (action) => action.type.endsWith('/pending');
const isRejected: AsyncPredicate = (action) =>
  action.type.endsWith('/rejected');
const isFulfilled: AsyncPredicate = (action) =>
  action.type.endsWith('/fulfilled');

const typePrefix = (a: AnyAction) =>
  a.type.replace(/\/(graphql\/)?(pending|rejected|fulfilled)/, '');

type SystemState = {
  errorMap: Record<string, string>;
  loadingMap: Record<string, number>;
  lastError: null | string;
};

const initialState: SystemState = {
  errorMap: {},
  loadingMap: {},
  lastError: null,
};

let globalFriendlyErrors: Record<string, string> = {};

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder.addMatcher(isPending, (state, action) => {
      const prefix = typePrefix(action);
      if (!state.loadingMap[prefix]) {
        state.loadingMap[prefix] = 0;
      }
      state.loadingMap[prefix] += 1;
      delete state.errorMap[prefix];
    });
    builder.addMatcher(isRejected, (state, action) => {
      const prefix = typePrefix(action);

      state.loadingMap[prefix] -= 1;
      state.errorMap[prefix] = action.error.message;
      state.lastError = globalFriendlyErrors[prefix] ?? action.error.message;
    });
    builder.addMatcher(isFulfilled, (state, action) => {
      const prefix = typePrefix(action);
      state.loadingMap[prefix] -= 1;
      delete state.errorMap[prefix];
    });
  },
});

const selectLoadingMap = (state: RootState) => state.system.loadingMap;
const selectErrorMap = (state: RootState) => state.system.errorMap;

export const useIsLoading = (actionType: string) => {
  const loadingMap = useSelector(selectLoadingMap);
  return !!loadingMap[actionType];
};

const useError = (actionType: string) => {
  const errorMap = useSelector(selectErrorMap);
  return errorMap[actionType];
};

/**
   Registers user friendly errors by action type by modifying the
   globalFriendlyErrors map. Returns a hook with the same signature as
   `useError`


   Motivation: The `errorMap` property on the system reducer contains
   the raw `Error.message` which can be useful for diagnostics but is
   user hostile. To present more use friendly errors, define a mapping
   in your slice.

   Example:
   ```
     const friendlyMessages = {
       [fetchEntity.type]: 'This entity is not available, please try again later.',
     }


     export const useFriendlyError = registerFriendlyErrors(friendlyMessages);
   ```

   Inside your component:
   ```
     const EntityView = () => {
        const fetchError  = useFriendlyError(fetchEntity.type);

        if (fetchError) {
           showNotification(friendlyError);
        }
     }
   ```
 */
export const registerFriendlyErrors = (
  friendlyErrors: Record<string, string>
) => {
  // Update the global message map so that we can use
  //`selectLastError` to get a friendly message.
  globalFriendlyErrors = {
    ...globalFriendlyErrors,
    ...friendlyErrors,
  };

  return (actionType: string) => {
    const friendly = friendlyErrors[actionType];
    const rude = useError(actionType);

    if (rude) {
      return friendly ?? rude; // fallback if no friendly message is present
    }

    return null;
  };
};

export default systemSlice.reducer;
