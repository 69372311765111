import axios, { AxiosPromise } from 'axios';
import * as Sentry from '@sentry/react';

import {
  getAuthToken,
  getRefreshToken,
  removeAuthGroups,
  removeTokens,
  setTokens,
} from 'utils/auth';
import { RestMethod } from 'utils/api-types';
import { StringMap } from 'utils/types';

const logOut = () => {
  removeTokens();
  removeAuthGroups();
  window.location.pathname = '/login';
};

interface NewTokenPayload {
  access_token: string;
  refresh_token: string;
}

interface NewTokenResponse extends NewTokenPayload {
  fromExisting: boolean;
}

// The `getNewToken` function may be called by both graphql provider
// and fetchApi when making calls to REST endpoints.
// Below we enure that in a case of simultaneous calls to `getNewToken`,
// the second call to `jwt-auth/refresh/` will await the first one
// and both return the same new tokens.
let isGettingNewToken = false;
let newTokenPromise: AxiosPromise<NewTokenPayload>;

export const getNewToken = async (): Promise<NewTokenResponse> => {
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    logOut();
  }

  if (isGettingNewToken) {
    // await the same promise to get the same token
    const { data } = await newTokenPromise;

    return {
      ...data,
      fromExisting: true,
    };
  }

  try {
    isGettingNewToken = true;
    newTokenPromise = axios({
      method: 'POST',
      url: `${import.meta.env.VITE_REACT_APP_API_LEGACY}/api/v1/jwt-auth/refresh/`,
      data: {
        refresh: refreshToken,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const { data } = await newTokenPromise;
    isGettingNewToken = false;

    return {
      ...data,
      fromExisting: false,
    };
  } catch (err) {
    logOut();
    throw err;
  }
};

export const fetchApi = async (
  path: string,
  method: RestMethod,
  body: any = {},
  config: StringMap = {}
): Promise<any> => {
  const token = getAuthToken();

  try {
    const { data } = await axios({
      method,
      url: import.meta.env.VITE_REACT_APP_API_LEGACY + path,
      data: body,
      headers: {
        'Content-Type': 'application/json',
        ...(token && {
          authorization: `JWT ${token}`,
        }),
      },
      ...config,
    });

    return data;
  } catch (err: any) {
    const {
      data: { code = null },
      status,
    } = err.response;

    if (status === 401 && code === 'token_not_valid') {
      const res = await getNewToken();
      // Set tokens if they would not be set elsewhere
      if (!res.fromExisting) {
        setTokens(res.access_token, res.refresh_token);
      }

      return await fetchApi(path, method, body, config);
    }

    throw err;
  }
};

export const reportError = (err: any) => {
  Sentry.captureException(err);

  return Promise.reject(err);
};
