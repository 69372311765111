import {
  TreatmentPlanStaging,
  TreatmentPlanStagingStates,
} from 'generated/core/graphql';
import { RejectionDetailProblem } from 'pages/OrthoPrism/Plan/utils';
import { ReviewOption } from 'pages/OrthoPrism/treatmentPlans';
import {
  SelectedAddress,
  SelectedAddressType,
} from 'components/AddressForm/types';

export type TreatmentPlanStagingSubmitCallbackArgs = {
  transition: ReviewOption;
  problems: RejectionDetailProblem[];
  reasons?: string[];
  notes?: string;
  proOrderArgs?: {
    shippingAddress: SelectedAddress;
    shippingAddressType: SelectedAddressType;
    sendPatientUpdate: boolean;
    clientCouponCode?: string;
  };
};

export const isApproved = (plan?: TreatmentPlanStaging): boolean => {
  switch (plan?.state) {
    case TreatmentPlanStagingStates.Approved:
      return true;
    default:
      return false;
  }
};

export const isRejected = (plan?: TreatmentPlanStaging): boolean => {
  switch (plan?.state) {
    case TreatmentPlanStagingStates.OrthoRejected:
    case TreatmentPlanStagingStates.ProRejected:
    case TreatmentPlanStagingStates.QcRejected:
      return true;
    default:
      return false;
  }
};

export const isQcRejected = (plan?: TreatmentPlanStaging): boolean =>
  plan?.state === TreatmentPlanStagingStates.QcRejected;
