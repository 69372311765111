import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  FunctionComponent,
} from 'react';
import { mapValues, mapKeys } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import {
  Loading,
  NotificationContext,
  SelectInput,
  type,
} from 'core/components';

import DocumentTitle from 'components/DocumentTitle';
import {
  PageHeader,
  PageHeaderContent,
  PageHeading,
  GridContainer,
} from 'styles/layout.css';
import { ErrorText } from 'styles/inputs.css';
import { Overline } from 'styles/text.css';

import { CustomerCasesContext } from 'pages/Journey/CustomerCasesProvider';
import { JourneyContext } from 'components/JourneyProvider';
import { formatCustomerName } from 'utils/customer';
import {
  snakeCaseToCapitalizedWords,
  camelCaseToSnakeCase,
  snakeCaseToCamelCase,
  camelCaseToCapitalizedWords,
} from 'utils/string';
import { ReactSelectOption, StringMap } from 'utils/types';
import { isValidJourneyComponentData } from 'utils/journey';
import {
  useJourneyComponentsLazyQuery,
  useTransitionJourneyMutation,
} from 'generated/legacy/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';

import { formatComponentState } from 'pages/Journey/utils';
import {
  EmptyColumn,
  Section,
  UpdateJourneyContainer,
  UpdateJourneyButton,
} from 'pages/Journey/JourneyView.css';
import useSyncToThirdParty from 'hooks/useSyncToThirdParty';
import { ThirdPartiesAvailableForSyncing } from 'generated/legacy/graphql';

type JourneyViewProps = RouteComponentProps<{
  customerId: string;
  caseRef: string;
}>;

const JourneyView: FunctionComponent<JourneyViewProps> = ({ match }) => {
  const { customerId, caseRef } = match.params;
  const [selectedComponent, setSelectedComponent] =
    useState<Maybe<ReactSelectOption>>(null);
  const [selectedComponentState, setSelectedComponentState] =
    useState<Maybe<ReactSelectOption>>(null);
  const [currentLegState, setCurrentLegState] = useState<Maybe<string>>(null);
  const { syncToThirdParty, isSyncingToThirdParty } = useSyncToThirdParty();

  const [
    getJourneyComponents,
    {
      data: journeyComponentsData,
      loading: isLoadingJourneyComponents,
      error: journeyComponentsError,
    },
  ] = useJourneyComponentsLazyQuery();

  const [transitionJourney, { loading: isTransitionJourneyLoading }] =
    useTransitionJourneyMutation();

  const { customerWithCases, getCustomerWithCasesData } =
    useContext(CustomerCasesContext);
  const { showNotification } = useContext(NotificationContext);

  const { journeyData, journeyError, isLoadingJourney, getJourneyByCaseRef } =
    useContext(JourneyContext);

  const currentCase = useMemo(
    () =>
      customerWithCases?.validCases.find(
        (validCase) => validCase.caseRef === caseRef
      ),
    [customerWithCases, caseRef]
  );

  const customerName = useMemo(
    () => (customerWithCases ? formatCustomerName(customerWithCases) : null),
    [customerWithCases]
  );

  const validJourneyComponentData = useMemo(
    () =>
      journeyComponentsData?.journeyComponents?.filter(
        isValidJourneyComponentData
      ),
    [journeyComponentsData]
  );

  const componentOptions = useMemo(() => {
    if (!validJourneyComponentData) {
      return [];
    }

    return validJourneyComponentData.map(({ component }) => ({
      label: snakeCaseToCapitalizedWords(component),
      value: component,
    }));
  }, [validJourneyComponentData]);

  const componentStateOptions = useMemo(() => {
    if (!validJourneyComponentData) {
      return [];
    }

    const selectedComponentData = validJourneyComponentData.find(
      ({ component }) => component === selectedComponent?.value
    );

    if (!selectedComponentData) {
      return [];
    }

    return selectedComponentData.states.map(({ state, transition }) => ({
      label: state,
      value: transition,
    }));
  }, [validJourneyComponentData, selectedComponent]);

  /**
   * format the state value of each journey component for display
   */
  const formattedJourneyComponent = useMemo(() => {
    const { legs, journeyState, journeyType } = journeyData?.journey ?? {};

    if (!legs) {
      return {};
    }
    // 'legs' will have all leg states for all journey types
    // kit_aligner_leg=null, kit_whitening_leg="Starting Kit Not Shipped"...
    // so we should clean up the empty/null states
    const legsMap = legs as StringMap;
    const validLegs = Object.keys(legsMap).reduce((acc, key) => {
      // ignore null leg states and '__typename' property
      if (legsMap[key] && key !== '__typename') {
        acc[key] = legsMap[key];
      }
      return acc;
    }, {} as StringMap);

    const formattedLegs = mapValues(validLegs ?? {}, formatComponentState);
    const journeyStateKey = snakeCaseToCamelCase(journeyType!); // alignerJourney or whiteningJourney
    return {
      [journeyStateKey]: formatComponentState(journeyState),
      ...formattedLegs,
    };
  }, [journeyData]);

  /**
   * format the key of each journey component to snake case
   */
  const snakeCaseJourneyComponents = useMemo(
    () =>
      mapKeys(formattedJourneyComponent, (_value, key) =>
        camelCaseToSnakeCase(key)
      ),
    [formattedJourneyComponent]
  );

  /**
   * Find current state of the selected component
   * @param componentOption
   */
  const findSelectedComponentState = (componentOption: ReactSelectOption) => {
    // find current state for selected component
    const currentState = snakeCaseJourneyComponents[componentOption.value];

    // find data (i.e. list of possible states) for selected component
    const validComponentData = validJourneyComponentData?.find(
      (compData) => compData.component === componentOption.value
    );

    if (currentState && validComponentData) {
      // look for a state in the list matching to the current state
      return validComponentData.states.find(
        (stateData) => stateData.state === currentState
      );
    }
  };

  const onJourneyComponentSelect = (selected: ReactSelectOption) => {
    const currentState = findSelectedComponentState(selected);
    if (currentState) {
      setCurrentLegState(currentState.state);
      setSelectedComponentState({
        label: currentState.state,
        value: currentState.transition,
      });
    } else {
      setSelectedComponentState(null);
    }

    setSelectedComponent(selected);
  };

  const submitJourneyUpdate = async () => {
    if (!selectedComponent || !selectedComponentState) {
      return;
    }

    try {
      const result = await transitionJourney({
        variables: {
          caseRef,
          component: selectedComponent.value,
          transition: selectedComponentState.value,
        },
      });

      if (result.errors?.length) {
        showNotification(result.errors[0].message, 'error');
      } else {
        syncToThirdParty({
          thirdParty: ThirdPartiesAvailableForSyncing.Salesforce,
          customerId,
          onCompleted: () =>
            showNotification(
              'Journey updated + Synced to Salesforce',
              'success'
            ),
        });

        setSelectedComponent(null);
        setSelectedComponentState(null);
        setCurrentLegState(null);
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      const errorMessage = err?.message || 'error updating journey';
      showNotification(errorMessage, 'error');
    }
  };

  useEffect(() => {
    if (journeyData?.journey?.journeyType) {
      getJourneyComponents({
        variables: {
          journeyType: journeyData?.journey?.journeyType,
        },
      });
    }
  }, [journeyData]);

  useEffect(() => {
    // if customerWithCases.id is different than customerId in url,
    // this is old data cached from a previous search
    if (!customerWithCases || customerWithCases.id !== customerId) {
      getCustomerWithCasesData(customerId);
    }

    getJourneyByCaseRef(caseRef);
  }, [caseRef, customerId]);

  if (isLoadingJourney || isLoadingJourneyComponents) {
    return <Loading isCentered />;
  }

  if (journeyError) {
    return <ErrorText>{journeyError}</ErrorText>;
  }

  if (!journeyData?.journey) {
    return <ErrorText>Journey data not found</ErrorText>;
  }

  const renderUpdateJourney = () => {
    if (isLoadingJourneyComponents) {
      return <Loading isCentered />;
    }

    if (journeyComponentsError) {
      return <ErrorText> {journeyComponentsError} </ErrorText>;
    }

    if (!validJourneyComponentData) {
      return <ErrorText> No data for journey states </ErrorText>;
    }

    const disableSubmit =
      isTransitionJourneyLoading ||
      isSyncingToThirdParty ||
      !selectedComponent ||
      !selectedComponentState ||
      selectedComponentState?.label === currentLegState;

    return (
      <UpdateJourneyContainer>
        <type.H3>Update Journey</type.H3>
        <GridContainer numColumns={4}>
          <SelectInput
            options={componentOptions}
            label="Journey component"
            value={selectedComponent}
            onChange={(option) =>
              onJourneyComponentSelect(option as ReactSelectOption)
            }
          />
          <SelectInput
            options={componentStateOptions}
            label="State"
            value={selectedComponentState}
            onChange={(option) =>
              setSelectedComponentState(option as ReactSelectOption)
            }
          />
          <UpdateJourneyButton
            isShort
            buttonType="primary"
            disabled={disableSubmit}
            isLoading={isTransitionJourneyLoading || isSyncingToThirdParty}
            onClick={submitJourneyUpdate}
          >
            Update
          </UpdateJourneyButton>
          <EmptyColumn />
        </GridContainer>
      </UpdateJourneyContainer>
    );
  };

  const formattedJourneyComponentKeys = Object.keys(formattedJourneyComponent);

  return (
    <DocumentTitle title="Journey admin">
      <>
        <PageHeader>
          <PageHeaderContent>
            <PageHeading>Journey admin</PageHeading>
          </PageHeaderContent>
        </PageHeader>
        <Section>
          <type.H3>Journey Summary</type.H3>
          <GridContainer numColumns={5}>
            <div>
              <Overline data-testid="overline-heading">Patient</Overline>
              {customerName?.formattedFullName} ({customerId})
            </div>
            <div>
              <Overline data-testid="overline-heading"> Case ID</Overline>
              {currentCase ? currentCase.id : '...'}
            </div>
            <div>
              <Overline data-testid="overline-heading">
                Primary Journey State
              </Overline>
              {journeyData.journey.humanReadableState ?? '...'}
            </div>
            <EmptyColumn />
            <EmptyColumn />
          </GridContainer>
        </Section>
        <Section>
          <type.H3>Journey Components</type.H3>
          <GridContainer numColumns={formattedJourneyComponentKeys.length}>
            {formattedJourneyComponentKeys.map((componentKey, i) => (
              <div key={`${componentKey}-${i}`}>
                <Overline data-testid="overline-heading">
                  {camelCaseToCapitalizedWords(componentKey)}
                </Overline>
                {formattedJourneyComponent[componentKey]}
              </div>
            ))}
          </GridContainer>
        </Section>
        <Section>{renderUpdateJourney()}</Section>
      </>
    </DocumentTitle>
  );
};

export default JourneyView;
