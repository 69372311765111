import axios, { AxiosPromise } from 'axios';
import * as Sentry from '@sentry/browser';
import {
  AddressFields,
  ValidatedUSAddress,
  ValidatedInternationalAddress,
  AutocompleteAddress,
} from 'api/validation/types';
import { AddressFormType } from 'components/AddressForm/types';
import {
  COUNTRY_CODE_MAPPING_ALPHA_2_TO_ALPHA_3,
  US_COUNTRY_CODE,
} from 'pages/Case/constants';

const SMARTY_STREETS_AUTO_COMPLETE =
  'https://us-autocomplete-pro.api.smartystreets.com/suggest';
const SMARTY_STREETS_AUTO_COMPLETE_FALLBACK =
  'https://us-autocomplete.api.smartystreets.com/suggest';
const SMARTY_STREETS_ADDRESS_VALIDATION_URL =
  'https://us-street.api.smartystreets.com/street-address';
const SMARTY_STREETS_INTERNATIONAL_AUTOCOMPLETE =
  'https://international-autocomplete.api.smarty.com/v2/lookup';

const SMARTY_STREETS_INTERNATIONAL_VALIDATION_URL =
  'https://international-street.api.smarty.com/verify';

export const AUTOCOMPLETE_LIMIT = 5;

type SmartyStreetInternationalAddressAutocompleteType = {
  entries: number;
  address_text: string;
  address_id: string;
};

const validationUrlMapper = (address: AddressFields): URLSearchParams => {
  const lookupObj = {
    addressLine1: 'street',
    addressLine2: 'secondary',
    city: 'city',
    stateCode: 'state',
    zip: 'zipcode',
    countryCode: 'country',
  };
  const searchParams = new URLSearchParams();

  (Object.keys(address) as (keyof AddressFields)[]).forEach((field) => {
    const lookupKey = lookupObj[field];
    if (lookupKey) {
      searchParams.set(lookupKey, address[field]);
    }
  });
  return searchParams;
};

const getAuthKey = () => {
  return import.meta.env.VITE_REACT_APP_SMARTY_AUTH_KEY;
};

export const validateAddress = async (
  params: AddressFields
): Promise<AddressFormType[]> => {
  const iso2CountryCode = params.countryCode;
  if (!(iso2CountryCode in COUNTRY_CODE_MAPPING_ALPHA_2_TO_ALPHA_3)) {
    console.error('Invalid country code given');
    return Promise.resolve([]);
  }
  if (isUS(iso2CountryCode)) {
    return validateUSAddress(params);
  } else {
    return validateInternationalAddress(params);
  }
};

const validateUSAddress = async (
  params: AddressFields
): Promise<AddressFormType[]> => {
  const searchParams = validationUrlMapper(params);
  const authKey = getAuthKey() ?? '';
  if (authKey === '') {
    console.error('No smarty streets auth key given');
    return Promise.resolve([]);
  }
  searchParams.set('key', authKey);

  const withUrlParams = `${SMARTY_STREETS_ADDRESS_VALIDATION_URL}?${searchParams.toString()}`;

  try {
    const resp = await axios.get(withUrlParams);
    return resp.data.map(mapUSSuggestions);
  } catch (err: unknown) {
    if (err instanceof Error) {
      console.error('smarty streets validate us address request failed', err);
    }
    return Promise.resolve([]);
  }
};

const mapUSSuggestions = (input: ValidatedUSAddress): AddressFormType => {
  const ret: AddressFormType = {
    addressLine1: `${input.components.primary_number} ${input.components.street_name} ${input.components.street_suffix}`,
    city: `${input.components.city_name}`,
    stateCode: `${input.components.state_abbreviation}`,
    zip: `${input.components.zipcode}`,
    countryCode: 'US',
  };

  if (
    input.components.primary_number &&
    input.components.secondary_designator
  ) {
    ret.addressLine2 = `${input.components.secondary_designator} ${input.components.secondary_number}`;
  }

  return ret;
};

const mapInternationalSuggestions = (
  input: ValidatedInternationalAddress
): AddressFormType => {
  return {
    addressLine1: `${input.components.premise} ${input.components.thoroughfare}`,
    city: `${input.components.locality}`,
    stateCode: `${input.components.administrative_area}`,
    zip: `${input.components.postal_code}`,
  };
};

const validateInternationalAddress = async (
  params: AddressFields
): Promise<AddressFormType[]> => {
  const autocompleteSearchParams = new URLSearchParams();
  autocompleteSearchParams.set('country', params.countryCode);
  autocompleteSearchParams.set('address1', params.addressLine1);
  autocompleteSearchParams.set('address2', params.addressLine2);
  autocompleteSearchParams.set('locality', params.city);
  autocompleteSearchParams.set('administrative_area', params.stateCode);
  autocompleteSearchParams.set('postal_code', params.zip);
  autocompleteSearchParams.set('key', getAuthKey());

  const withUrlParams = `${SMARTY_STREETS_INTERNATIONAL_VALIDATION_URL}?${autocompleteSearchParams.toString()}`;

  try {
    const resp = await axios.get(withUrlParams);
    return resp.data.map(mapInternationalSuggestions);
  } catch (err: unknown) {
    if (err instanceof Error) {
      console.error(
        'smarty streets validate international address request failed',
        err
      );
    }
    return Promise.resolve([]);
  }
};
const handleUSAutocomplete = async (
  input: string,
  limit: number,
  authKey: string,
  withUrlParams: string,
  fallbackWithUrlParams: string
) => {
  const searchParams = new URLSearchParams();
  searchParams.set('prefix', input);
  searchParams.set('suggestions', limit.toString());
  searchParams.set('key', authKey);
  withUrlParams = `${SMARTY_STREETS_AUTO_COMPLETE}?${searchParams.toString()}`;
  // If the request to the Autocomplete api fails, try the fallback URL
  fallbackWithUrlParams = `${SMARTY_STREETS_AUTO_COMPLETE_FALLBACK}?${searchParams.toString()}`;
  try {
    const resp = await axios.get(withUrlParams);
    return resp.data?.suggestions ?? [];
  } catch (err: unknown) {
    if (err instanceof Error) {
      console.error('smarty streets us autocomplete request failed', err);
    }
    try {
      const resp = await axios.get(fallbackWithUrlParams);
      return resp.data?.suggestions ?? [];
    } catch (err: unknown) {
      if (err instanceof Error) {
        Sentry.captureException(err);
      }
      return Promise.resolve([]);
    }
  }
};

const handleInternationalAutocomplete = async (
  countryCode: string,
  input: string,
  limit: number,
  authKey: string,
  withUrlParams: string
) => {
  // Set the search params for the International address autocomplete request
  const autocompleteSearchParams = new URLSearchParams();
  autocompleteSearchParams.set('country', countryCode);
  autocompleteSearchParams.set('search', input);
  autocompleteSearchParams.set('max_results', limit.toString());
  autocompleteSearchParams.set('key', authKey);
  withUrlParams = `${SMARTY_STREETS_INTERNATIONAL_AUTOCOMPLETE}?${autocompleteSearchParams.toString()}`;
  try {
    const resp = await axios.get(withUrlParams);
    const suggestions = resp.data?.candidates ?? [];
    const responses: AxiosPromise[] = [];
    suggestions.forEach(
      (suggestion: SmartyStreetInternationalAddressAutocompleteType) => {
        if (suggestion.entries > 1) {
          // Skip the address if there is more than one suggested address for the given input text
          return;
        }
        const fetchAddressSearchParams = new URLSearchParams();
        fetchAddressSearchParams.set('key', authKey);
        fetchAddressSearchParams.set('country', countryCode);
        const fetchAddressUrl = `${SMARTY_STREETS_INTERNATIONAL_AUTOCOMPLETE}/${suggestion.address_id}?${fetchAddressSearchParams.toString()}`;
        // Prepare a batch call to the address validation API with the address ID to fetch the full address
        responses.push(axios.get(fetchAddressUrl));
      }
    );
    // Resolve all the batch calls to the address validation API and return their response
    return await Promise.all(responses).then((responses) => {
      const addresses: AutocompleteAddress[] = [];
      responses.forEach((response) => {
        if (response.data?.candidates) {
          addresses.push({
            city: response.data.candidates[0].locality,
            state: response.data.candidates[0].administrative_area,
            street_line: response.data.candidates[0].street,
            text: `${response.data.candidates[0].street}, ${response.data.candidates[0].locality}, ${response.data.candidates[0].administrative_area}`,
            postalCode: response.data.candidates[0].postal_code,
          });
        }
      });
      return addresses;
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      console.error(
        'smarty streets international autocomplete request failed',
        err
      );
      Sentry.captureException(err);
    }
    return Promise.resolve([]);
  }
};

const isUS = (iso2CountryCode: string) => {
  return iso2CountryCode === US_COUNTRY_CODE;
};

export const autocompleteAddress = async (
  input: string,
  limit: number = AUTOCOMPLETE_LIMIT,
  iso2CountryCode: string = US_COUNTRY_CODE
): Promise<AutocompleteAddress[]> => {
  const authKey = getAuthKey() ?? '';
  if (authKey === '') {
    console.error('No smarty streets auth key given');
    return Promise.resolve([]);
  }
  const withUrlParams = '';
  const fallbackWithUrlParams = '';
  if (!(iso2CountryCode in COUNTRY_CODE_MAPPING_ALPHA_2_TO_ALPHA_3)) {
    console.error('Invalid country code given');
    return Promise.resolve([]);
  }
  if (isUS(iso2CountryCode)) {
    return await handleUSAutocomplete(
      input,
      limit,
      authKey,
      withUrlParams,
      fallbackWithUrlParams
    );
  } else {
    const iso3CountryCode =
      COUNTRY_CODE_MAPPING_ALPHA_2_TO_ALPHA_3[
        iso2CountryCode as keyof typeof COUNTRY_CODE_MAPPING_ALPHA_2_TO_ALPHA_3
      ];
    return await handleInternationalAutocomplete(
      iso3CountryCode,
      input,
      limit,
      authKey,
      withUrlParams
    );
  }
};
