import styled from 'styled-components/macro';

export const Title = styled.div`
  color: var(--black-transparent-87, rgba(0, 0, 0, 0.87));
  font-feature-settings:
    'clig' off,
    'liga' off;

  /* Large screens/HEADLINES/H5 */
  font-family: Modern Era;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 16px */
`;
export const Question = styled.div`
  color: ${({ theme }) => theme.colors.black90};
  font-feature-settings:
    'clig' off,
    'liga' off;

  /* Large screens/HEADLINES/H4 */
  font-family: Modern Era;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 20px */
  margin-bottom: 24px;
`;
export const CartItemContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: 160px;
  max-height: 350px;
  padding: 24px;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.black20};
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0.5rem;
    gap: 0.5rem;
  }
`;
export const Description = styled.div`
  color: ${({ theme }) => theme.colors.black70};

  /* Large screens/BODY/Small */
  font-family: Modern Era;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
`;
export const ItemBody = styled.div`
  max-width: 342px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const CartItemCostContainer = styled.div`
  display: flex;
  size: ${({ theme }) => theme.fontSizes.default};
  color: ${({ theme }) => theme.colors.black90};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
export const StrikethroughText = styled.span`
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.black70};
  margin-right: 0.5rem;
  margin-left: 0.5rem;
`;
