import React, { useEffect, useState } from 'react';
import DocumentTitle from 'components/DocumentTitle';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { useLazyQuery } from 'utils/lazyQuery';
import {
  GetTreatmentPlanUrlDocument,
  GetTreatmentPlanUrlQuery,
  GetTreatmentPlanUrlQueryVariables,
} from 'generated/legacy/graphql';
import * as Sentry from '@sentry/react';
import { SoftsmileIframe } from 'pages/SoftsmileViewer/SoftsmileViewer.css';

const SoftsmileViewer = ({ match }: RouteComponentProps) => {
  const query = useQuery();
  const case_ref = query.get('case_ref');
  const iteration = query.get('iteration');
  const treatmentPlanStagingId = query.get('treatment_plan_staging_id');
  const treatmentPlanUrlQuery = useLazyQuery<
    GetTreatmentPlanUrlQuery,
    GetTreatmentPlanUrlQueryVariables
  >(GetTreatmentPlanUrlDocument);
  const [softsmileViewerLink, setSoftsmileViewerLink] = useState('');

  const fetchSignedAssetUrl = async () => {
    if (!treatmentPlanStagingId) {
      Sentry.captureException(
        `Could not generate Treatment Plan URL for treatmentPlanStagingId: ${treatmentPlanStagingId}`
      );
    }

    try {
      const { data } = await treatmentPlanUrlQuery({
        treatmentPlanStagingId,
      });

      setSoftsmileViewerLink(data.treatmentPlanUrl);
    } catch {
      Sentry.captureException(
        `Could not generate Treatment Plan URL for treatmentPlanStagingId: ${treatmentPlanStagingId}`
      );
    }
  };

  useEffect(() => {
    fetchSignedAssetUrl();
  }, [case_ref, iteration, treatmentPlanStagingId]);
  return (
    <Switch>
      <Route path={match.path} exact>
        <DocumentTitle title="Softsmile Viewer">
          <SoftsmileIframe
            title={'Dynamic Softsmile Viewer'}
            src={softsmileViewerLink}
          />
        </DocumentTitle>
      </Route>
    </Switch>
  );
};

export default SoftsmileViewer;
