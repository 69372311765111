import MUIDataTable from 'mui-datatables';
import styled from 'styled-components';

export const StyledMUIDataTable = styled(MUIDataTable)<{ card: boolean }>`
  ${({ theme, card }) => `
    &.MuiButton-root{
      font-weight: ${theme.fontWeights.bold};
    }
    &.MuiTablePagination-caption{
      color: ${theme.colors.text60}
    }
    &.MuiPaper-root{
      border: ${card ? theme.borders.default : undefined};
      box-sizing: ${card ? `border-box` : `content-box`};
    }
`}
`;
