import React from 'react';
import { Grid } from 'core/components';

import {
  OrderDetailWrapper,
  OrderDetailHeader,
  OrderDetailContents,
  OrderDetailFieldTitle,
  OrderDetailFieldContainer,
  ShipTo,
  ShippingText,
} from 'components/Modals/OrderShippingModal/OrderShipping.css';

import { GenericAddress } from 'types/GenericAddress';

const getShipToText = (addressType: string): string | null => {
  switch (addressType) {
    case 'PATIENT':
      return 'to patient';
    case 'PRACTICE':
      return 'to my practice';
    default:
      return null;
  }
};

type AddressLines = {
  addressLine: string;
  cityLine: string;
};

const getAddressLines = (shipping_address: GenericAddress): AddressLines => {
  if (shipping_address) {
    return {
      addressLine:
        shipping_address.address_line_1 +
        (shipping_address.address_line_2
          ? `, ${shipping_address.address_line_2}`
          : ''),
      cityLine: `${shipping_address.city}, ${shipping_address.state_code} ${shipping_address.zip}`,
    };
  } else {
    return {
      addressLine: '',
      cityLine: '',
    };
  }
};

const renderAddressField = (
  shipping_address: GenericAddress,
  shipping_address_type: string
) => {
  const name = shipping_address?.name;
  const addressLines = getAddressLines(shipping_address);
  const shipToText = getShipToText(shipping_address_type);
  return (
    <>
      {!!shipping_address && (
        <>
          {shipToText && <ShipTo>{`Ship ${shipToText}`}</ShipTo>}
          {name && <ShippingText>{name}</ShippingText>}
        </>
      )}
      <ShippingText>{addressLines['addressLine']}</ShippingText>
      <ShippingText>{addressLines['cityLine']}</ShippingText>
    </>
  );
};

type OrderShippingAddressDetailsProps = {
  short_order_ref: string;
  shipping_address: GenericAddress;
  shipping_address_type: string;
};

const OrderShippingAddressDetails = ({
  short_order_ref,
  shipping_address,
  shipping_address_type,
}: OrderShippingAddressDetailsProps) => {
  return (
    <OrderDetailWrapper>
      <OrderDetailHeader>{`${'Intended Shipping Address'} - Order Ref ${short_order_ref}`}</OrderDetailHeader>
      <OrderDetailContents>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <OrderDetailFieldContainer>
              <OrderDetailFieldTitle>{'Ship Address'}</OrderDetailFieldTitle>
              <div>
                {renderAddressField(shipping_address, shipping_address_type)}
              </div>
            </OrderDetailFieldContainer>
          </Grid>
        </Grid>
      </OrderDetailContents>
    </OrderDetailWrapper>
  );
};

export default OrderShippingAddressDetails;
