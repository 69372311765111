import React from 'react';

import {
  NonEditingContentContainer,
  ItemPhotosContainer,
  SmallerImage,
} from 'pages/OrthoPrism/Plan/TreatmentReviewForm/RejectionDetails.css';

const maxPhotosToDisplay = 3;

/*
 * The non expanded view for individual rejection problem
 */
const RejectionProblemItem: React.FC<{
  photoUrls: Array<string>;
  notes?: string;
  onClick?: () => void;
}> = ({ photoUrls, notes, onClick }) => {
  return (
    <NonEditingContentContainer onClick={onClick}>
      <ItemPhotosContainer>
        {photoUrls.length > 0 && (
          <ItemPhotosContainer>
            {photoUrls.slice(0, maxPhotosToDisplay).map((url, photoIdx) => {
              return <SmallerImage key={photoIdx} src={url} />;
            })}
          </ItemPhotosContainer>
        )}
        {notes && <span>{notes}</span>}
      </ItemPhotosContainer>
    </NonEditingContentContainer>
  );
};

export default RejectionProblemItem;
