import styled from 'styled-components';
import { Chip, colors } from 'core/components';

export const TicketContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border: 1px solid ${colors.black30};
  border-radius: 8px !important;
  max-width: 600px;
  h4 {
      margin: 0;
    }
  }
`;

export const RowItem = styled.div`
  flex-basis: 64px;
`;

export const StatusBadge = styled(Chip)<{ $status: string }>`
  font-weight: bold;
  padding: 10px 5px !important;
  ${({ $status }) =>
    $status === 'Open' ? `background-color: ${colors.green70} !important;` : ''}
  color: ${({ $status }) =>
    $status === 'Open' ? 'white' : colors.black70} !important;
  > span {
`;

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const CategoryBadge = styled(Chip)`
  font-weight: bold;
  padding: 5px 3px !important;
  border: 1px solid ${colors.black70} !important;
  color: ${colors.black70} !important;
  > span {
    text-align: center;
  }
`;

export const OverflowCategoryBadge = styled(CategoryBadge)`
  font-weight: bold;
  padding: 5px 3px !important;
  background-color: ${colors.black70} !important;
  color: white !important;
`;
