import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import NotFound from 'pages/NotFound';

import PrismSearch from 'pages/Prism/PrismSearch';
import PrismView from 'pages/Prism/PrismView';

const Prism = ({ match }: RouteComponentProps) => (
  <Switch>
    <Route path={`${match.path}/:id?`} exact>
      <PrismSearch />
    </Route>
    <Route path={`${match.path}/:id/:aggregateRef`} exact>
      <PrismView />
    </Route>
    <Route>
      <NotFound />
    </Route>
  </Switch>
);

export default Prism;
