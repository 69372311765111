import React from 'react';
import {
  Container,
  Title,
  Description,
} from 'pages/AccountPreferencesPage/PreferenceSection.css';

const PreferenceSection = ({
  title,
  description,
  children,
  id,
}: {
  title: string;
  description: string | React.ReactNode;
  children?: React.ReactNode;
  id?: string;
}) => {
  return (
    <Container id={id}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {children}
    </Container>
  );
};

export default PreferenceSection;
