import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { AlertCard, Divider, Grid, type } from 'core/components';
import { AddressFormType } from 'components/AddressForm/types';
import { useDispatch } from 'react-redux';

import {
  FormikInputWrapper,
  FormikOption,
  FormikSelectWrapper,
  FormikDatePickerNoHeader,
} from 'components/FormikForms';
import {
  emailValidator,
  requiredValidator,
  phoneValidator,
  normalizePhoneNumber,
  validateDateOfBirth,
} from 'components/FormikForms/utils';
import { InfoTooltip } from 'components/InfoTooltip';

import { GridContainer, Spacer, HRDividerLine } from 'styles/layout.css';
import {
  SectionHeading,
  PatientInfo,
} from 'pages/Patient/CaseCreator/BasicInfo/BasicInfo.css';
import { StyledAlertCardContainer } from 'pages/Patient/styles.css';

import LegalGuardianInfoForm from 'pages/Patient/CaseCreator/BasicInfo/LegalGuardianInfoForm';
import { setBasicInfo } from 'pages/Patient/patientSlice';
import CaseCreatorFooter, { Steps } from 'pages/Patient/Footer';
import useConfirmChanges from 'hooks/useConfirmChanges';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AlertTypeEnum } from 'pages/Patient/types';
import AddressForm from 'components/AddressForm/AddressForm';

type Props = {
  customerExists?: boolean;
  practiceList?: FormikOption[];
  disabled?: boolean;
  submitButtonRef?: React.RefObject<HTMLButtonElement>;
  handleSubmit: (values: FormProps) => void;
};

type LegalGuardianFormType = {
  firstName: string;
  lastName: string;
  preferredName?: string;
  middleName?: string;
  phone: string;
  birthday: string | null;
};

export type FormProps = {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string | null;
  day: string;
  month: string;
  year: string;
  preferredName?: string;
  middleName?: string;
  phone?: string;
  shippingAddress: AddressFormType;
  billingAddress?: AddressFormType;
  practiceId?: string;
  legalGuardian?: LegalGuardianFormType;
};

const BasicInfoForm = ({
  customerExists,
  practiceList = [],
  disabled = false,
  submitButtonRef,
  handleSubmit,
}: Props) => {
  const {
    dirty,
    isSubmitting,
    setFieldValue,
    values,
    errors,
    validateForm,
    isValid,
    submitForm,
  } = useFormikContext<FormProps>();
  const dispatch = useDispatch();
  const [displayErrorAlert, setDisplayErrorAlert] = useState(false);
  const { 'case-creator-auto-save': enableAutoSave } = useFlags();
  useConfirmChanges(dirty && enableAutoSave);
  useEffect(() => {
    dispatch(setBasicInfo({ basicInfoFormValues: values }));
  }, [values]);

  //Check if there are any errors. If there are none, it could be because validation hasn't been run yet
  //If there are still no errors, return true
  const formContainErrors = () => {
    if (!Object.keys(errors).length) {
      validateForm();
    }
    return !!Object.keys(errors).length;
  };

  useEffect(() => {
    if (isSubmitting) {
      if (formContainErrors()) {
        setDisplayErrorAlert(true);
        window.scrollTo(0, 0);
      } else {
        setDisplayErrorAlert(false);
      }
    }
  }, [isSubmitting]);

  const displayAlertCardType = () => {
    if (displayErrorAlert) {
      if (!formContainErrors()) {
        setDisplayErrorAlert(false);
        return null;
      }

      //True if all data is valid or missing. If any is invalid this is false
      //There's special handlingin here because  shipping is an object
      const allFieldsMissingData = Object.entries(errors).every(([k, v]) => {
        return (
          (k === 'shippingAddress' &&
            Object.values(errors.shippingAddress!).every(
              (shippingValues) => shippingValues === 'Required'
            )) ||
          v === 'Required'
        );
      });

      if (allFieldsMissingData) {
        return (
          <StyledAlertCardContainer>
            <AlertCard type={AlertTypeEnum.Critical} header="Missing fields">
              <div>
                Please fill out all required fields, highlighted below in red
                and resubmit.
              </div>
            </AlertCard>
          </StyledAlertCardContainer>
        );
      } else {
        return (
          <StyledAlertCardContainer>
            <AlertCard type={AlertTypeEnum.Critical} header="Invalid fields">
              <div>
                Please correct all invalid fields, highlighted below in red and
                resubmit.
              </div>
            </AlertCard>
          </StyledAlertCardContainer>
        );
      }
    }
    return null;
  };

  const isMinor =
    values.dateOfBirth && moment().diff(values.dateOfBirth, 'years') < 18;

  const minorEmailToolTipText =
    'If the guardian also plans to go through Candid treatment, or has already, emails can’t be used for multiple patients. Emails for existing patients can be edited on the patient detail page if needed.';

  return (
    <div data-testid="basic-info-form">
      {displayAlertCardType()}
      <Spacer spacing="2rem" isVertical>
        <GridContainer padding="0" numColumns={1} clearBackground={true}>
          <FormikSelectWrapper
            testId="input-practice-id"
            label="Practice*"
            name="practiceId"
            options={practiceList}
            showDefaultValue={true}
            type="select"
            validate={requiredValidator}
            disabled={customerExists}
            data-private
          />
          <Divider />
        </GridContainer>
        <PatientInfo>Patient info</PatientInfo>
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          style={{ marginLeft: '-0.5rem', marginTop: 0 }}
        >
          <Grid item xs={6} md={4}>
            <FormikInputWrapper
              testId="input-first-name"
              label="First name*"
              name="firstName"
              type="text"
              validate={requiredValidator}
              data-private
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FormikInputWrapper
              testId="input-middle-name"
              label="Middle name"
              name="middleName"
              type="text"
              data-private
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FormikInputWrapper
              testId="input-last-name"
              label="Last name*"
              name="lastName"
              type="text"
              validate={requiredValidator}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FormikInputWrapper
              testId="input-preferred-name"
              label="Preferred name*"
              name="preferredName"
              type="text"
              validate={requiredValidator}
              data-private
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FormikInputWrapper
              testId="input-phone"
              label="Phone number*"
              name="phone"
              type="tel"
              onChange={(e) =>
                normalizePhoneNumber('phone', e.target.value, setFieldValue)
              }
              validate={phoneValidator}
              data-private
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <FormikDatePickerNoHeader
          label="Date of birth*"
          name="dateOfBirth"
          type="text"
          validate={validateDateOfBirth}
          placeholder={'mm/dd/yyyy'}
          data-private
          disabled={disabled}
        />
        <FormikInputWrapper
          testId="input-email"
          label={
            isMinor ? (
              <InfoTooltip
                content={minorEmailToolTipText}
                label="Patient or guardian email address*"
              />
            ) : (
              'Patient’s email address*'
            )
          }
          name="email"
          type="email"
          validate={emailValidator}
          data-private
          disabled={disabled}
        />

        {isMinor && <LegalGuardianInfoForm />}
      </Spacer>
      <HRDividerLine />

      <SectionHeading data-testid="section-heading">
        Patient home address
      </SectionHeading>
      <type.BodySmall>
        This address will be stored on the patient record, but you’ll be able to
        choose if you want to ship to the patient or your practice after you
        review the treatment plan.
      </type.BodySmall>

      <AddressForm
        addressPath="shippingAddress."
        submitButtonRef={submitButtonRef}
        getAddressData={(values: FormProps) => values.shippingAddress}
        submitForm={handleSubmit}
        disabled={disabled}
        updateAddress={(value: AddressFormType, originalValues: FormProps) => {
          return {
            ...originalValues,
            shippingAddress: {
              addressLine1: value.addressLine1,
              addressLine2: value.addressLine2,
              city: value.city,
              stateCode: value.stateCode,
              zip: value.zip,
              //Smarty street doesn't return country, so use the original.
              countryCode: originalValues.shippingAddress.countryCode,
            },
          };
        }}
      />
      <CaseCreatorFooter
        currentStep={Steps.BasicInfo}
        disabled={!isValid || isSubmitting || disabled}
        isCtaLoading={isSubmitting}
        onSubmit={submitForm}
        rightMessage={isValid ? '' : 'Complete all required fields to continue'}
      />
    </div>
  );
};

export default BasicInfoForm;
