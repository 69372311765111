type StepConfig = {
  label: string;
  defaultContent: string;
  index: number;
};

export type StepConfigs = { [key: string]: StepConfig };

export const alignerStepConfig = {
  caseCreated: {
    label: 'Case created',
    defaultContent: 'You created your case.',
    index: 0,
  },
  submissionUnderReview: {
    label: 'Submission under review',
    defaultContent:
      'Our orthodontic team reviews these diagnostic materials and checks for any issues that could complicate treatment. If needed, we’ll request new materials or ask for clarification.',
    index: 1,
  },
  treatmentPlanningInProgress: {
    label: 'Treatment planning in progress',
    defaultContent:
      'Each treatment plan is built by a human, not AI, and reviewed by our quality control team and a US-licensed orthodontist. Treatment plans typically take between 3-5 business days.',
    index: 2,
  },
  treatmentPlanReadyForReview: {
    label: 'Treatment plan ready for review',
    defaultContent:
      'Requesting revisions will delay the overall time for your patient to start treatment.',
    index: 3,
  },
  alignerKitInProduction: {
    label: 'Aligner kit in production',
    defaultContent:
      'Manufacturing can take up to 10 business days to manufacture and ship.',
    index: 4,
  },
  shipped: {
    label: 'Aligner kit shipped',
    defaultContent: 'Most aligner kits arrive within 3-5 business days.',
    index: 5,
  },
  alignerKitDelivered: {
    label: 'Aligner kit delivered',
    defaultContent: 'Once delivered, the patient should begin treatment ASAP.',
    index: 6,
  },
};

export const retainerStepConfig = {
  caseCreated: {
    label: 'Case Created',
    defaultContent: 'You created your case.',
    index: 0,
  },
  submissionUnderReview: {
    label: 'Submission under review',
    defaultContent:
      'If needed, we’ll request new materials or ask for clarification.',
    index: 1,
  },
  shipped: {
    label: 'Order processing',
    defaultContent: 'Retainer orders typically ship within 2 weeks.',
    index: 2,
  },
};
