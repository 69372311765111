import React, { useState } from 'react';
import { Popover as MuiPopover } from '@material-ui/core';

import {
  BasePopoverProps,
  HelpToolTipIcon,
} from 'core/components/Popover/Popover.css';

//
// NOTE: This is different from ToolTip!!!
// This allows you to put ANY content in a popup not just text.
//
const PopoverClick = (props: BasePopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { anchor, open: openProps, ...rest } = props;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = !!(anchorEl || openProps);
  const id = open ? 'candid-popover' : undefined;

  return (
    <div>
      <div aria-describedby={id} onClick={handleClick}>
        {anchor || <HelpToolTipIcon />}
      </div>
      <MuiPopover
        anchorEl={anchorEl}
        id={id}
        onClose={handleClose}
        open={open}
        {...rest}
      />
    </div>
  );
};

export default PopoverClick;
