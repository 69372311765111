import { MaterialStates, TreatmentPlanStaging } from 'generated/core/graphql';
import { CaseData } from 'pages/TpCentralEnhanced/types';

export const getIsApproved = (
  tp: TreatmentPlanStaging,
  latestTp: TreatmentPlanStaging,
  caseData: CaseData
): boolean => {
  if (!latestTp || !tp) {
    return false;
  }
  if (tp?.id === latestTp.id) {
    return (
      caseData.caseState?.treatmentPlanStaging?.state ===
      MaterialStates.Completed
    );
  } else {
    return false;
  }
};

export const getTpState = (
  tp: TreatmentPlanStaging,
  latestTp: TreatmentPlanStaging,
  caseData: CaseData
) => {
  if (!latestTp || !tp) {
    return '';
  }
  if (tp.id === latestTp.id) {
    return caseData.caseState?.treatmentPlanStaging?.state;
  } else {
    return 'inactive';
  }
};
