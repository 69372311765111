import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import NotFound from 'pages/NotFound';

import InvoicesView from 'pages/BillingManagement/Invoices/InvoicesView';
import InvoiceView from 'pages/BillingManagement/Invoices/InvoiceView';

const Invoices = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route component={InvoicesView} exact path={match.path} />
      <Route component={InvoiceView} exact path={`${match.path}/:invoiceId`} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Invoices;
