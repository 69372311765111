import React from 'react';
import { Formik, Form } from 'formik';
import {
  Border,
  Container,
  InnerContainer,
  Title,
  Flex,
  ButtonContainer,
} from 'components/ShipmentDetailsByTrackingId/ShipmentDetails.css';
import { Button, Grid, Box, Text, colors } from 'core/components';
import { SupportedCarriers } from 'generated/core/graphql';
import { OrderItemType } from 'generated/legacy/graphql';
import {
  buildToAddress,
  EnrichedOrderType,
  ExtendedOrderItemType,
  useShippingContext,
} from 'pages/Shipping/utils';

import { useTranslation } from 'react-i18next';
import {
  FormikInputWrapper,
  FormikSelectWrapper,
} from 'components/FormikForms';

const ShipmentDetails = ({
  orderItem,
  order,
}: {
  orderItem?: OrderItemType | null;
  order?: EnrichedOrderType | null;
}) => {
  const { loading, addShippedItem } = useShippingContext();
  const { t } = useTranslation();
  if (!orderItem) {
    return <Container empty />;
  }

  const address = buildToAddress(
    orderItem as ExtendedOrderItemType /* TODO: Casting is a quickfix */,
    order
  );

  const validate = (values: {
    carrier?: SupportedCarriers;
    trackingId?: string;
  }) => {
    const errors: { carrier?: string; trackingId?: string } = {};

    if (!values.carrier) {
      errors.carrier = 'Required';
    }
    if (!/1Z[A-Z0-9]{16}/i.test(values.trackingId ?? '')) {
      // UPS | UPS Surepost Tracking ID
      errors.trackingId = 'Invalid tracking id';
    }

    return errors;
  };
  return (
    <Container>
      <Title padding>Shipment details</Title>
      <Border />
      <Formik
        initialValues={{ carrier: undefined, trackingId: undefined }}
        onSubmit={({ trackingId, carrier }) => {
          if (trackingId && carrier) {
            addShippedItem(trackingId, carrier);
          }
        }}
        validate={validate}
      >
        <Form>
          <InnerContainer>
            <Title>{t('shipping.carrier')}</Title>
            <FormikSelectWrapper
              label={t('shipping.carrier')}
              name="carrier"
              options={[
                {
                  value: SupportedCarriers.Ups,
                  displayValue: 'UPS',
                },
                {
                  value: SupportedCarriers.Upssurepost,
                  displayValue: 'UPS Surepost',
                },
              ]}
              type="select"
            />
            <Border />
            <Title>{t('shipping.shipment_tracking_id')}</Title>
            <FormikInputWrapper
              name="trackingId"
              type="text"
              label={t('shipping.shipment_tracking_id')}
              placeholder="e.g. 1ZXF0111YN98306944"
            />
            <Title>Shipping destination</Title>
            {address! && (
              <Grid container direction="row" justifyContent="space-between">
                <Grid>
                  <Box>
                    <Text color={address.name ? colors.text80 : colors.red50}>
                      {address.name ?? 'Name is a required field'}
                    </Text>
                    {address?.company && (
                      <Text color={colors.text80}>{address.company}</Text>
                    )}
                    <Text
                      color={
                        address.addressLines ? colors.text80 : colors.red50
                      }
                    >
                      {(
                        address.addressLines ?? [
                          'Address Lines are a required field.',
                        ]
                      ).join(' ')}
                    </Text>
                  </Box>
                  <Flex>
                    <Text color={address.city ? colors.text80 : colors.red50}>
                      {address.city ?? 'City is a required field'},{' '}
                    </Text>
                    <Text
                      ml="5px"
                      color={address.adminRegion ? colors.text80 : colors.red50}
                    >
                      {address.adminRegion ??
                        'Admin Region is a required field.'}
                    </Text>
                    <Text
                      ml="5px"
                      color={address.postalCode ? colors.text80 : colors.red50}
                    >
                      {address.postalCode ?? 'Postal Code is a required field.'}
                    </Text>
                  </Flex>
                </Grid>
                <ButtonContainer>
                  <Button
                    type="submit"
                    buttonType="secondary"
                    isLoading={loading}
                  >
                    Create Shipment
                  </Button>
                </ButtonContainer>
              </Grid>
            )}
          </InnerContainer>
        </Form>
      </Formik>
    </Container>
  );
};

export default ShipmentDetails;
