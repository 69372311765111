import styled from 'styled-components/macro';
import { type } from 'core/components';

export const LinkText = styled(type.Link)`
  margin-top: -0.75rem;
  font-size: ${type.size.t2};
`;

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AlertContainer = styled.div`
  margin-bottom: 1.5rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
