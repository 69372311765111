import styled from 'styled-components/macro';
import { Theme, type, mediaQueries } from 'core/components';

export const TableContainer = styled.table<{ fontSize?: string }>`
  display: block;
  grid-area: 2 / 1 / 2 / 1;
  width: 100%;
  font-family: ${type.family.modernEra};
  font-size: ${(props) => props.fontSize};

  @media ${mediaQueries.tabletAndAbove} {
    grid-area: 1 / 1 / 1 / 1;
  }
`;

export const TableHeaderContainer = styled.thead<{ align?: string }>`
  text-align: ${(props) => props.align};
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);

  th {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    padding-left: 0.625rem;
  }
`;

type RowContainerProps = {
  theme: Theme;
  noStripes?: boolean;
  isSelected?: boolean;
};
const toTRBackground = (
  even: 'even' | 'odd',
  { theme, isSelected, noStripes }: RowContainerProps
) => {
  if (isSelected) {
    return theme.colors.blue10;
  }

  if (noStripes || even === 'odd') {
    return '';
  }

  // even === 'even'
  return theme.colors.black05;
};

export const RowContainer = styled.tr<RowContainerProps>`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  text-align: justify;

  &:nth-child(even) {
    td {
      background-color: ${(props) => toTRBackground('even', props)};
    }
  }

  &:nth-child(odd) {
    td {
      background-color: ${(props) => toTRBackground('odd', props)};
    }
  }
`;

export const BodyContainer = styled.tbody`
  font-size: 0.875rem;

  td {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.625rem;
  }
`;
