import React from 'react';
import PropTypes from 'prop-types';
import { type } from 'core/components';

import * as propTypes from 'utils/propTypes';

import {
  TableContainer,
  TableHeaderContainer,
  RowContainer,
  BodyContainer,
} from 'components/CandidTable/CandidTable.css';

/**
 * This is the basic table component that renders just the styles
 */
const Table = ({ children, fontSize = type.size.t2, className = '' }) => (
  <TableContainer className={className} fontSize={fontSize}>
    {children}
  </TableContainer>
);

const Row = ({ children, className = '', onClick, isSelected = false }) => (
  <RowContainer className={className} isSelected={isSelected} onClick={onClick}>
    {children}
  </RowContainer>
);

const Header = ({ children, align = 'left', className = '' }) => (
  <TableHeaderContainer align={align} className={className}>
    {children}
  </TableHeaderContainer>
);

const Body = ({ children, className = '' }) => (
  <BodyContainer className={className}>{children}</BodyContainer>
);

const HeaderCell = ({ children, className = '' }) => (
  <th className={className}>{children}</th>
);

const BodyCell = ({ children, className = '' }) => (
  <td className={className}>{children}</td>
);

Table.propTypes = {
  children: propTypes.children.isRequired,
  className: PropTypes.string,
  fontSize: PropTypes.string,
};

Row.propTypes = {
  children: propTypes.children.isRequired,
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

Header.propTypes = {
  align: PropTypes.oneOf(['center', 'char', 'justify', 'left', 'right']),
  children: propTypes.children,
  className: PropTypes.string,
};

Body.propTypes = {
  children: propTypes.children.isRequired,
  className: PropTypes.string,
};

HeaderCell.propTypes = {
  children: propTypes.children.isRequired,
  className: PropTypes.string,
};

BodyCell.propTypes = {
  children: propTypes.children.isRequired,
  className: PropTypes.string,
};

export default {
  Table,
  Row,
  BodyCell,
  HeaderCell,
  Header,
  Body,
};
