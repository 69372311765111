import { fetchApi } from 'utils/api';

export const logIn = async (email, password, brandId) => {
  try {
    const res = await fetchApi('/api/v1/jwt-auth/create/', 'POST', {
      email,
      brand_id: brandId,
      password,
    });

    return res;
  } catch (err) {
    const { non_field_errors: nonFieldErrors = null } =
      err?.response?.data ?? {};
    throw new Error(nonFieldErrors || 'Unable to log in');
  }
};

export const resetPassword = async (email, brandId) => {
  try {
    const res = await fetchApi('/api/v1/auth/password/reset/', 'POST', {
      email,
      brand_id: brandId,
    });
    return res;
  } catch (err) {
    const { non_field_errors: nonFieldErrors = null } =
      err?.response?.data ?? {};
    throw new Error(nonFieldErrors || 'Unable to reset password');
  }
};

export const changePassword = async (new_password, token, uid) => {
  try {
    const res = await fetchApi('/api/v1/auth/password/reset/confirm/', 'POST', {
      uid,
      token,
      new_password,
      re_new_password: new_password,
    });
    return res;
  } catch (err) {
    console.log(err);
    const { non_field_errors: nonFieldErrors = null } =
      err?.response?.data ?? {};
    throw new Error(nonFieldErrors || 'Unable to change password');
  }
};
