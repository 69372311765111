import styled from 'styled-components/macro';
import { css } from 'styled-components';

import { Button, type } from 'core/components';
import checkCircleIconPath from 'assets/check-circle-outline.svg';
import minusCircleIconPath from 'assets/minus-circle-outline.svg';

import { arrayContainer, itiArrayContainer, arrayItem } from 'styles/array.css';
import { evaluationIndicator } from 'styles/imageThumb.css';

export const SectionHeading = styled(type.H4)`
  margin-bottom: 1rem;
`;

export const DownloadPhotosButton = styled(Button)`
  margin-top: 2rem;
`;

export const StatusBar = styled.div<{
  isPending?: boolean;
  isApproved?: boolean;
  isRejected?: boolean;
}>`
  padding: 0.8125rem 1rem 0.6875rem 2.5rem;
  margin-bottom: 1.5rem;
  background-repeat: no-repeat;
  background-position: 1rem 0.9375rem;

  :only-child {
    margin-bottom: 0;
  }

  ${({ isPending }) =>
    isPending &&
    css`
      padding-left: 1rem;
      background-color: ${({ theme }) => theme.colors.yellow30};
    `};

  ${({ isApproved }) =>
    isApproved &&
    css`
      background-color: ${({ theme }) => theme.colors.green30};
      background-image: url(${checkCircleIconPath});
    `};

  ${({ isRejected }) =>
    isRejected &&
    css`
      background-color: ${({ theme }) => theme.colors.red30};
      background-image: url(${minusCircleIconPath});
    `};
`;

export const TechNote = styled.div`
  margin-bottom: 1.5rem;
`;

export const Overline = styled.p`
  display: block;
  margin-bottom: 0.25rem;
  font-weight: bold;
  color: ${(p) => p.theme.colors.black90};
`;

export const Note = styled.div`
  color: ${(p) => p.theme.colors.black70};
`;

export const AlertContainer = styled.div`
  margin-bottom: 1.5rem;
`;

export const PhotoTypeGrid = styled.div<{ iti?: boolean }>`
  ${({ iti }) => (iti ? itiArrayContainer : arrayContainer)};
  grid-gap: 3vw;
  width: 100%;

  @media ${({ theme }) => theme.mediaQueries.tabletAndAbove} {
    grid-gap: 1.25rem;
  }
`;

export const PhotoTypeItem = styled.button<{
  isRejected?: boolean;
  photo?: string;
}>`
  ${arrayItem};
  ${evaluationIndicator};
  padding-top: 73.34%;
  background: ${({ theme }) => theme.colors.black10} no-repeat center;
  background-size: contain;

  ${({ photo }) =>
    photo &&
    css`
      background-image: url(${photo});
    `};
`;
