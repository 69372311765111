import styled from 'styled-components';
import { StylesConfig } from 'react-select';

import { colors, theme } from 'core/styles';

export const Wrap = styled.div`
  width: 100%;
`;

export const StyledSelectInput: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: state.menuIsOpen
      ? theme.palette.secondary.main
      : theme.palette.border.main,
    borderRadius: '2px',
    transition: 'border 200ms ease-in-out',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: colors.black60,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: theme.palette.border.hover,
  }),
  menu: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: '0 4px 11px rgba(0,0,0,0.1)',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0.5rem 0',
  }),
  option: (provided, state) => {
    const selectedBackground = state.isSelected
      ? theme.palette.secondary.main
      : 'transparent';
    const selectedColor = state.isSelected
      ? theme.palette.white
      : theme.palette.black;

    return {
      ...provided,
      backgroundColor: state.isFocused ? colors.blue10 : selectedBackground,
      color: state.isFocused ? theme.palette.secondary.main : selectedColor,
      fontSize: theme.fontSizes.default,
    };
  },
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};
