import React, { Fragment, useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Prompt } from 'react-router-dom';
import {
  Button,
  Loading,
  NotificationContext,
  AlertCard,
} from 'core/components';

import readFile from 'utils/readFile';
import { formatFilesNames } from 'utils/materials';
import useConfirmChanges from 'hooks/useConfirmChanges';
import FileUpload from 'components/FileUpload';
import { PageSection } from 'styles/layout.css';
import { XrayUploadContext } from 'pages/XrayUpload/XrayUploadProvider';
import {
  FileTypesText,
  Heading,
  DeleteButton,
  FileName,
  PhotoTile,
  SelectedPhotos,
  UploadContainer,
  UploadTile,
  FormContent,
  Label,
  Checkbox,
  CheckboxContainer,
  AlertContainer,
} from 'pages/XrayUpload/UploadForm/UploadForm.css';

const UploadForm = ({ caseRef }) => {
  const {
    customerData,
    isUploadingXrays,
    uploadXrays,
    xrayData: existingPhotos,
  } = useContext(XrayUploadContext);

  const { showNotification } = useContext(NotificationContext);
  const [isCapturedWithinYear, setIsCapturedWithinYear] = useState(false);
  const [isReadingFiles, setIsReadingFiles] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [photoFiles, setPhotoFiles] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  useConfirmChanges(hasChanges);

  const hasPhotos = !!(selectedPhotos.length || existingPhotos?.length);

  const handleDelete = (photosIndex) => {
    const removeIndex = (photos) => photos.filter((_p, i) => i !== photosIndex);
    setSelectedPhotos(removeIndex);
    setPhotoFiles(removeIndex);
  };

  const handleSelectPhotos = async (filesConstructor) => {
    const files = [...filesConstructor];

    if (files.some((file) => !file.name.includes(customerData.id))) {
      showNotification(
        `All file names must include patient ID: ${customerData.id}`,
        'error'
      );

      return;
    }

    setPhotoFiles((prevFiles) => [...prevFiles, ...files]);
    setIsReadingFiles(true);
    const selectionData = await Promise.all(
      files.map((file) => new Promise((resolve) => readFile(file, resolve)))
    );
    setSelectedPhotos((prevPhotos) => [...prevPhotos, ...selectionData]);
    setIsReadingFiles(false);
    setHasChanges(true);
  };

  const handleSubmit = () => {
    const formattedFiles = formatFilesNames(photoFiles);

    uploadXrays(formattedFiles, isCapturedWithinYear, caseRef, () => {
      setHasChanges(false);
      setIsCapturedWithinYear(false);
      setPhotoFiles([]);
      setSelectedPhotos([]);
    });
  };

  const alertBody =
    'If X-rays aren’t recent, then we may not be able to achieve ideal results for the patient, and case delays are likely. Please submit recent X-rays, if possible.';

  return (
    <PageSection isNarrow>
      <Heading>X-ray upload</Heading>
      {!hasPhotos ? (
        <Fragment>
          <UploadContainer>
            {isReadingFiles ? (
              <Loading isCentered />
            ) : (
              <FileUpload
                fileType=".jpg, .jpeg, .png"
                allowMultipleFiles
                onSelectFile={handleSelectPhotos}
              />
            )}
          </UploadContainer>
        </Fragment>
      ) : (
        <>
          <SelectedPhotos>
            {existingPhotos.map((photo, i) => (
              <PhotoTile key={`${i}-${photo.id}`} photo={photo.url} />
            ))}
            {selectedPhotos.map((photo, i) => (
              <PhotoTile key={i} photo={photo}>
                <DeleteButton onClick={() => handleDelete(i)} />
                {!!photoFiles[i] && <FileName>{photoFiles[i].name}</FileName>}
              </PhotoTile>
            ))}
            <UploadTile>
              {isReadingFiles ? (
                <Loading isCentered />
              ) : (
                <FileUpload
                  fileType=".jpg, .jpeg, .png"
                  allowMultipleFiles
                  hideText
                  isDisabled={isUploadingXrays}
                  onSelectFile={handleSelectPhotos}
                />
              )}
            </UploadTile>
          </SelectedPhotos>
          <CheckboxContainer isDisabled={isUploadingXrays}>
            <FormContent>
              <Label>
                <Checkbox
                  checked={isCapturedWithinYear}
                  onChange={() =>
                    setIsCapturedWithinYear(!isCapturedWithinYear)
                  }
                  type="checkbox"
                />
                These x-rays were taken within the last year and after any
                relevant dental work
                {!isCapturedWithinYear && (
                  <AlertContainer>
                    <AlertCard
                      header="Recent X-rays are strongly preferred"
                      displayIcon={true}
                      body={alertBody}
                      type="warning"
                    />
                  </AlertContainer>
                )}
              </Label>
            </FormContent>
          </CheckboxContainer>
        </>
      )}
      {hasPhotos && (
        <Button
          buttonType="secondary"
          disabled={isUploadingXrays}
          isLoading={isUploadingXrays}
          isShort
          onClick={handleSubmit}
        >
          Upload
        </Button>
      )}
      <Prompt message="Changes you made may not be saved." when={hasChanges} />
      <FileTypesText>
        <b>Accepted file types:</b> .jpg, .jpeg, .png
      </FileTypesText>
    </PageSection>
  );
};

UploadForm.propTypes = {
  caseRef: PropTypes.string,
};

export default UploadForm;
