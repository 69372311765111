import React, { useEffect, ReactChild } from 'react';
import styled from 'styled-components/macro';
import { Sidebar } from 'components/SidebarLayout/sidebar';
import { SidebarBody } from 'components/SidebarLayout/body';

const TABLET_MIN_WIDTH = 768;
const TOP_NAV_HEIGHT = 65;

type Props = {
  children: NamedChildrenSlots;
  isOpen: boolean;
  onToggle?: () => void; // TODO: AK not being used yet, but will be when the side navigation is implemented
  hideToggle?: boolean; // TODO: AK not being used yet, but will be when the side navigation is implemented
};

type NamedChildrenSlots = {
  sidebar: ReactChild;
  body: ReactChild;
};

const Container = styled.div`
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: flex;
    flex-direction: column;
    height: calc(100vh - ${TOP_NAV_HEIGHT}px);
  }
`;

export const SidebarLayout = ({
  children,
  isOpen,
  onToggle,
  hideToggle,
}: Props) => {
  useEffect(() => {
    const onResizeHandler = () => {
      if (window.innerWidth < TABLET_MIN_WIDTH && isOpen) {
        onToggle && onToggle();
      }
    };
    // component did mount
    window.addEventListener('resize', onResizeHandler);

    // component unmount
    return () => {
      window.removeEventListener('resize', onResizeHandler);
    };
  }, [isOpen]);
  const { sidebar, body } = children;

  return (
    <Container>
      <Sidebar isOpen={isOpen} onToggle={onToggle} hideToggle={hideToggle}>
        {sidebar}
      </Sidebar>
      <SidebarBody isOpen={isOpen}>{body}</SidebarBody>
    </Container>
  );
};
