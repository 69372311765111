import { Button, theme } from 'core/components';
import styled from 'styled-components/macro';

export const Container = styled.div<{ maxWidth: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  maxwidth: ${({ maxWidth }) => maxWidth};
  padding: 32px;
  gap: 40px;

  border: 1px solid ${theme.colors.black20};
  border-radius: 8px;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const StyleImage = styled.img`
  width: 132px;
  height: 132px;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`;

export const StyledMobileImage = styled.img`
  width: 132px;
  height: 132px;

  @media ${({ theme }) => theme.mediaQueries.tabletAndAbove} {
    display: none;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 24px;
`;

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const Body = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
`;
