import React from 'react';
import { Title } from 'components/KeyValueDisplay/KeyValueDisplay.css';

const KeyValueDisplay = ({
  label,
  value,
  testId,
  ref,
  titleStyle,
}: {
  label: string;
  value?: string | React.ReactNode;
  testId?: string;
  ref?: React.RefObject<HTMLDivElement>;
  titleStyle?: React.CSSProperties;
}) => {
  return (
    <>
      <Title data-testid={testId} ref={ref} style={titleStyle}>
        {label}
      </Title>
      {React.isValidElement(value) ? value : <h5>{value}</h5>}
    </>
  );
};

export default KeyValueDisplay;
