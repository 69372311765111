import { useTheme as useThemeSC } from 'styled-components';
import { default as theme } from 'core/styles/theme';

// tests would break if we tried to just use styled-components
// because the the raw component doesnt have a theme (no theme provider wrapped)
// since styled-components doesn't allow a default value for useTheme, we wrap that
const useTheme = (defaultTheme = theme): typeof theme =>
  useThemeSC() || defaultTheme;

export default useTheme;
