import styled from 'styled-components/macro';
import { arrayContainer, itiArrayContainer, arrayItem } from 'styles/array.css';
import { baseimgThumbStyles } from 'styles/imageThumb.css';

export const ModalContainer = styled.div`
  max-width: 40rem;
  padding: 1.25rem;
  text-align: center;
`;

export const ModalArrayContainer = styled.div<{ iti?: boolean }>`
  ${({ iti }) => (iti ? itiArrayContainer : arrayContainer)};
  grid-gap: 1rem;
  height: ${({ iti }) => (iti ? '10rem' : '15rem')};
  width: 15rem;
  margin: 1rem 4rem 4rem;
`;

export const ModalArrayItem = styled.button<{
  isSelected: boolean;
  key: string;
  view: string;
}>`
  #circle {
    display: none;
  }
  svg {
    width: 100%;
    height: 100%;
  }
  ${arrayItem};
  background: ${({ theme }) => `${theme.colors.black10} no-repeat center`};
  ${baseimgThumbStyles};
`;

export const Title = styled.p`
  ${({ theme }) => theme.text.body.small};
  margin-bottom: 0;
  ${({ theme }) => theme.fontWeights.bold};
`;

export const Content = styled.div`
  margin-bottom: 1.25rem;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-align: left;
`;

export const Label = styled.label`
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: block;
  cursor: pointer;
`;
