import React, { useState, useEffect } from 'react';
import {
  Button,
  Chip as RawChip,
  colors,
  Tooltip as RawTooltip,
  Text,
} from 'core/components';
import { useSelector } from 'react-redux';
import PlusIcon from 'assets/ic_plus.svg?react';
import styled from 'styled-components/macro';
import { withStyles } from '@material-ui/core/styles';
import {
  selectRefinementCartResult,
  selectPolicyMessage,
  selectAppliedRefinementDiscountType,
  RefinementType,
  selectAppliedRefinementCoupon,
  selectNumberOfFreeRefinementsLeft,
} from 'pages/Promotion/promotionsSlice';
import { useIsLoading } from 'state/system';

const Chip = styled(RawChip)`
  margin-left: 10px;
`;

const Tooltip = withStyles({
  tooltip: {
    backgroundColor: colors.black90,
  },
  arrow: {
    color: colors.black90,
  },
})(RawTooltip);

const RefinementButton = ({ onClick }: { onClick: () => void }) => {
  const isLoadingCoupon = useIsLoading('promotions/fetchCouponsForPatient');
  const isLoadingRefinement = useIsLoading(
    'promotions/applyPromotionsToRefinementCart'
  );
  const isLoading = isLoadingCoupon || isLoadingRefinement;

  const [buttonLabel, setButtonLabel] = useState('Refinement');
  const [policyChip, setPolicyChip] = useState('-');

  const refinementCartResult = useSelector(selectRefinementCartResult);
  const numberOfFreeRefinementLeft = useSelector(
    selectNumberOfFreeRefinementsLeft
  );
  const policyMessage = useSelector(selectPolicyMessage);
  const refinementPolicyCoupon = useSelector(
    selectAppliedRefinementDiscountType
  );
  const appliedCoupon = useSelector(selectAppliedRefinementCoupon);

  // Update button label and policy chip on refinement cart result change
  useEffect(() => {
    setButtonLabel(refinementPolicyCoupon === null ? 'New case' : 'Refinement');
    setPolicyChip(getPolicyChipText());
  }, [refinementCartResult]);

  const getPolicyChipText = () => {
    if (refinementPolicyCoupon === null) {
      return '';
    }

    if (
      refinementPolicyCoupon === RefinementType.Free ||
      refinementPolicyCoupon === RefinementType.Ifi
    ) {
      if (appliedCoupon) {
        return `${numberOfFreeRefinementLeft} Free`;
      } else {
        return 'Free';
      }
    } else {
      return 'Paid';
    }
  };

  return (
    <Tooltip
      title={<Text variant={'small'}>{policyMessage}</Text>}
      placement="top"
      disableHoverListener={refinementPolicyCoupon === null}
    >
      <div>
        <Button
          isFullWidth
          buttonType="secondary-outline"
          onClick={() => onClick()}
          leftIcon={<PlusIcon />}
          isLoading={isLoading}
        >
          <span>{buttonLabel}</span>
          {refinementPolicyCoupon != null && (
            <Chip label={policyChip} size="small" customColor={colors.blue30} />
          )}
        </Button>
      </div>
    </Tooltip>
  );
};

export default RefinementButton;
