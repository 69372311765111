import { ButtonType } from 'core/components/Button/Button';
import { CSSProperties, FC, SVGProps } from 'react';

export type RouteParams = {
  id?: string;
  caseRef?: string;
};

export enum ActionType {
  NOT_STARTED,
  STARTED,
  REJECTED,
  NEEDS_CLARIFICATION,
}

export type ButtonInfo = {
  text: string;
  buttonType: ButtonType;
  LeftIcon: FC<SVGProps<SVGSVGElement>>;
  buttonStyle?: CSSProperties;
};
