import { colors, type } from 'core/components';
import styled from 'styled-components/macro';
import ArrowLeft from 'assets/ic_chevron-left.svg?react';
import { Button } from 'core/components';
export const Container = styled.div`
  border-top: 1px solid ${colors.black20};
  border-bottom: 1px solid ${colors.black20};
`;

export const CaseStatusContainer = styled.div`
  ${({ theme }) => `
  padding: 32px;
  @media ${theme.mediaQueries.mobile} {
    padding: 16px 20px;
  }
`}
`;

export const ProviderFacingStatus = styled(type.Body)`
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.black90};

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 16px;

    ${({ theme }) => theme.text.body.default};
  }
`;

export const CaseNumber = styled(type.Overline)`
  color: ${colors.text70};
  margin-bottom: 0;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.micro};
  }
`;

export const AdditionalItemsMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  max-width: 16.5rem;
  gap: 1rem;
`;

export const LinkItem = styled.div`
  ${({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: 1rem;
  > a,
  button {
    font-size: ${theme.fontSizes.small};
    text-rendering: geometricPrecision;
    line-height: 1.25rem;
    color: ${theme.colors.black80};
    text-align: left;
  }
  > svg {
    > path {
      stroke: ${theme.colors.black80};
    }
    width: 1.25rem;
    height: 1.25rem;
  }
`}
`;

export const ArrowDown = styled(ArrowLeft)`
  ${({ theme }) => `
  transform: rotate(-90deg);
  path {
    stroke: ${theme.colors.black70};
  }
`}
`;

export const ActionButton = styled(Button)`
  ${({ theme }) => `
    color: ${theme.colors.black70};
    border: 2px solid ${theme.colors.black70};
    .right-icon {
      svg {
        path {
          stroke: ${theme.colors.black70};
        }
      }
    }
  `}
`;

export const InfoCardContainer = styled.div`
  padding-top: 10px;
`;

export const Label = styled.label`
  display: block;
  cursor: pointer;
  font-size: ${type.size.t2};
  margin-top: 1rem;
`;

export const Checkbox = styled.input`
  margin-right: 0.5rem;
  cursor: pointer;
  appearance: checkbox;
`;

export const MarkCompleteButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ActionGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: block;
    width: calc(100vw - 50px);
  }
`;
