import styled from 'styled-components/macro';

import { colors } from 'core/components';
import DeleteIcon from 'assets/delete.svg';

export const RejectionDetailsContainer = styled.div`
  margin-bottom: 2rem;
`;

export const ProblemsContainer = styled.div`
  margin-bottom: 2rem;
  width: 60%;
`;

export const ProblemItemContainer = styled.div`
  border: 1px solid ${colors.border};
  padding: 4rem;
  margin-bottom: 2rem;
  border-radius: 0.1875rem;
  box-shadow: 0 1px 0.375rem 0 rgba(0, 0, 0, 0.05);
  position: relative;
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 2rem;
  right: 2rem;
`;

export const Image = styled.img`
  height: 5rem;
  margin-top: 1rem;
  margin-right: 1rem;
  position: relative;
`;

export const SmallerImage = styled(Image)`
  height: 3rem;
`;

export const ItemPhotosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  span {
    margin-top: 1rem;
    color: ${colors.black70};
    /* wrap text if longer than outer container */
    display: block;
    word-wrap: break-word;
    width: 200px;
    white-space: normal;
  }
`;

export const FileUploadContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.7rem;
  background-color: ${colors.black05};
  width: 100%;
  height: 164px;
  border-radius: 0.1875rem;
`;

export const PhotoSectionContainer = styled.div`
  margin-top: 2rem;
`;

export const NotesContainer = styled.div`
  margin-top: 2rem;
  textarea {
    margin-top: 0.7rem;
  }
`;

export const DeleteButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  background: url(${DeleteIcon}) no-repeat;
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const ActionButton = styled.div`
  color: ${colors.black70};
  font-weight: 600;
  :hover {
    cursor: pointer;
  }
  :first-child {
    margin-right: 10px;
  }
`;

export const NonEditingContentContainer = styled.div`
  align-items: center;
  display: flex;
  ${({ onClick }) =>
    !!onClick &&
    `
    :hover {
      cursor: pointer;
    }
  `}
`;

export const TopicSpan = styled.span`
  margin-left: 0.75rem;
  color: ${colors.black90};
  font-weight: normal;
`;
