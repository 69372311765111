import { ReactNode } from 'react';
import styled from 'styled-components';
import { PopoverProps as MuiPopoverProps } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export type BasePopoverProps = Omit<MuiPopoverProps, 'open'> & {
  anchor?: ReactNode;
  direction: 'top' | 'left' | 'right' | 'bottom';
  // open is required by mui but not needed at all by us,
  // leaving this as a prop in case some weird edge case needs
  // us to manage this component outside of enamel
  open?: boolean;
};

export const HelpToolTipIcon = styled(HelpOutlineIcon)`
  color: ${({ theme }) => theme.colors.black70};
  max-width: 1rem;
  position: relative;
  bottom: 1px;
  left: 2px;

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;
