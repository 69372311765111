import React from 'react';
import { useHistory } from 'react-router-dom';
import { Logo, LoyaltyChip, LogoContainer } from 'components/Header/Header.css';
import { LoyaltyTierInfo } from 'constants/loyalty';
import { LoyaltyProgramTier } from 'generated/core/graphql';
import { usePartnerOrgs } from 'hooks/usePartnerOrgs';
import {
  getBrandSettings,
  getBrandFromDomain,
  CANDID_BRAND_NAME,
} from 'utils/brands';

type Props = {
  logoSubString?: string | undefined;
  loyaltyTier?: LoyaltyProgramTier | null;
  canExit?: boolean;
  isCentered?: boolean;
};

const HeaderLogo = ({
  loyaltyTier,
  canExit = true,
  isCentered = false,
}: Props) => {
  const { push } = useHistory();
  const brand = getBrandFromDomain();
  const { logoSrc, showLoyaltyInfo } = getBrandSettings(brand);
  const tierInfo = loyaltyTier && LoyaltyTierInfo[loyaltyTier?.sortOrder];
  const { partnerOrgs } = usePartnerOrgs();
  let logoUrl = logoSrc as string;

  if (partnerOrgs[0]?.logoUrl && brand === CANDID_BRAND_NAME) {
    // if there is partnerOrgs logo and coming into Candid brand page, this is glidewell power by candid pro before migration
    logoUrl = partnerOrgs[0]?.logoUrl;
  }

  return (
    <LogoContainer data-testid="header-logo" isCentered={isCentered}>
      <Logo
        alt="Candid"
        src={logoUrl}
        isClickable={canExit}
        onClick={() => {
          if (canExit) {
            push('/');
          }
        }}
      />

      {showLoyaltyInfo && tierInfo && (
        <LoyaltyChip
          variant="outlined"
          size="small"
          background={tierInfo.chipBackground}
          border={tierInfo.chipBorder}
          label={loyaltyTier?.name}
          icon={<img alt="" src={tierInfo.icon}></img>}
          onClick={() => {
            if (canExit) {
              push('/loyalty');
            }
          }}
        />
      )}
    </LogoContainer>
  );
};

export default HeaderLogo;
