import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Promotions from 'pages/Promotion/Promotions';
import NotFound from 'pages/NotFound';

const PromotionsSwitch = () => (
  <Switch>
    <Route component={Promotions} exact path={'/promotions'} />
    <Route component={NotFound} />
  </Switch>
);

export default PromotionsSwitch;
