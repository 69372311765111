import React, { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import ReactSelect, { ValueType } from 'react-select';
import { useFormikContext } from 'formik';
import api from 'state/api';
import { AddressSelection } from 'components/AddressSelection/AddressSelection';
import { SelectedAddress } from 'components/AddressForm/types';
import ProductSelect from 'components/ProductSelect';
import { getAddressInputFromAddressForm } from 'components/AddressForm/utils';
import {
  AddressSelectionContainer,
  PracticeName,
  SelectedPracticeContainer,
} from 'pages/OrdersPortal/PracticeOrderForm.css';
import { FormType, SelectOption } from 'pages/OrdersPortal/types';
import { CANDID_BRAND_NAME, convertToBrand } from 'utils/brands';

type PracticeOrderFormProps = {
  addressFormSubmitBtnRef: React.RefObject<HTMLButtonElement>;
  handleConfirmedAddress: (confirmedAddress: SelectedAddress) => void;
  setAddressFormIsValid: (isValid: boolean) => void;
};

const PracticeOrderForm = ({
  addressFormSubmitBtnRef,
  handleConfirmedAddress,
  setAddressFormIsValid,
}: PracticeOrderFormProps) => {
  const practiceBrandMap: { [key: string]: string } = {};
  const [practiceBrand, setPracticeBrand] = useState<string | null>('');
  const { setFieldValue } = useFormikContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPractice, setSelectedPractice] = useState<SelectOption | null>(
    null
  );
  const [
    getFilteredPractices,
    { data: practicesData, isFetching: isFetchingSearchResults },
  ] = api.useLazyGetFilteredPracticesQuery();

  const practiceOptions: SelectOption[] =
    practicesData?.reduce<SelectOption[]>((options, practice) => {
      practiceBrandMap[practice.id] = practice?.brand?.name ?? '';
      options.push({
        value: `${practice.id}`,
        label: `${practice.name}`,
      });

      return options;
    }, []) || [];

  const debouncedGetFilteredPractices = useCallback(
    debounce(getFilteredPractices, 500),
    []
  );

  const handlePracticeChange = (option: ValueType<SelectOption>) => {
    const selectedOption = option as SelectOption;
    setSelectedPractice(selectedOption);
    setFieldValue('practiceId', Number(selectedOption.value));
    setFieldValue('orderItems', []);
  };

  useEffect(() => {
    if (selectedPractice) {
      setPracticeBrand(practiceBrandMap[selectedPractice.value]);
    }
  }, [selectedPractice]);

  return (
    <div>
      <h3>Practice Order</h3>
      <ReactSelect
        placeholder="Search for a practice..."
        options={practiceOptions}
        isLoading={isFetchingSearchResults}
        inputValue={searchTerm}
        onInputChange={(newValue) => {
          setSearchTerm(newValue);
          if (newValue) {
            debouncedGetFilteredPractices({ name: newValue });
          }
        }}
        onChange={handlePracticeChange} // Use the new handlePracticeChange function
      />
      {selectedPractice && (
        <>
          <SelectedPracticeContainer>
            <h4>Selected Practice:</h4>
            <PracticeName>{selectedPractice.label}</PracticeName>
          </SelectedPracticeContainer>
          <ProductSelect
            brand={convertToBrand(practiceBrand, CANDID_BRAND_NAME)}
            includeGenericProducts={true}
            formType={FormType.practice}
            onProductChange={({ sku }) => {
              setFieldValue('orderItems', [
                { productVariantSku: sku, quantity: 1 },
              ]);
            }}
            key={`ProductSelect-${selectedPractice.value}`} // reset the product select when the practice changes
          />
          <AddressSelectionContainer>
            <AddressSelection
              practiceId={selectedPractice.value}
              setResultAddress={(address) => {
                setFieldValue(
                  'shippingAddress',
                  getAddressInputFromAddressForm(address.value)
                );
              }}
              addressFormSubmitBtnRef={addressFormSubmitBtnRef}
              handleConfirmedAddress={handleConfirmedAddress}
              setAddressFormIsValid={setAddressFormIsValid}
              key={`AddressSelection-${selectedPractice.value}`} // reset the address selection when the practice changes
            />
          </AddressSelectionContainer>
        </>
      )}
    </div>
  );
};

export default PracticeOrderForm;
