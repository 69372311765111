import styled, { css, keyframes } from 'styled-components';
import { hideVisually, transparentize } from 'polished';

import { zIndex as zIndexConfig } from 'core/styles';

export const transitionDuration = 300;

const fadeIn = keyframes`
  0% { opacity: 0;}
  100% { opacity: 1; }
`;

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

export const Container = styled.div<{
  isFullScreenOnMobile?: boolean;
  isHidden: boolean;
  isOpen?: boolean;
  isOverlayHidden?: boolean;
  overlayColor?: string;
  zIndex?: number;
}>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ zIndex }) => zIndex || zIndexConfig.modal};
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  align-items: flex-start;
  padding: 1.25rem 0;
  overflow-y: auto;
  background: ${({ isOverlayHidden, overlayColor, theme }) =>
    !isOverlayHidden &&
    (overlayColor || transparentize(0.2, theme.palette.white))};
  animation: ${({ isOpen }) => (isOpen ? fadeIn : fadeOut)}
    ${transitionDuration}ms both;
  -webkit-overflow-scrolling: touch;

  ${({ isFullScreenOnMobile, theme }) =>
    isFullScreenOnMobile &&
    css`
      @media ${theme.mediaQueries.mobile} {
        padding: 0;
      }
    `}
`;

export const Content = styled.div<{ isFullScreenOnMobile?: boolean }>`
  position: relative;
  z-index: 1;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
  background: ${({ theme }) => theme.palette.white};
  border-radius: 0.375rem;
  box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.1);

  ${({ isFullScreenOnMobile, theme }) =>
    isFullScreenOnMobile &&
    css`
      @media ${theme.mediaQueries.mobile} {
        width: 100%;
        min-height: 100%;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
      }
    `}
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;

  span {
    ${hideVisually()};
  }

  svg {
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    fill: ${({ color, theme }) => color || theme.palette.black};
  }
`;
