import React, { useContext, useState, useEffect, useMemo } from 'react';

import {
  FormRow,
  Label,
  Card,
  AddressDetailCard,
  StyledImage,
  Checkbox,
  TextLine,
  BoldLine,
  AddressContainer,
  EditPatientText,
  LeftAddressSection,
  SideNote,
  ContactSupportSection,
  ContactSupportText,
  HelpSvg,
  Container,
} from 'components/AddressSelection/AddressSelection.css';
import {
  Grid,
  Avatar as CoreAvatar,
  AlertCard,
  type,
  colors,
  NotificationContext,
} from 'core/components';
import {
  patientAddressSimilarToPractice,
  Address as AddressMatchType,
} from 'utils/shippingAddress';
import HomeAddressPng from 'assets/home-address.png';
import PracticeAddressPng from 'assets/candid-academy.png';
import OtherAddressPng from 'assets/other-address.png';
import EditPatientModal from 'components/EditPatientModal/EditPatientModal';

import {
  SelectedAddressType,
  SelectedAddressInfo,
  SelectedAddress,
} from 'components/AddressForm/types';

import OtherAddressForm from 'components/AddressSelection/OtherAddressForm';
import ContactSupport from 'components/ContactSupport';
import api from 'state/api';
import { AddressType } from 'generated/legacy/graphql';
import { GridSize } from '@material-ui/core';

type AvatarProps = {
  selectedAddress: SelectedAddressInfo;
  patient: any;
};
const Avatar = ({ selectedAddress, patient }: AvatarProps) => {
  return (
    <CoreAvatar
      style={{
        width: 67,
        height: 67,
        backgroundColor: colors.blue50,
        paddingTop: '0.25rem',
        marginTop: '0.2rem',
        fontSize: '2rem',
        marginRight: '16px',
      }}
    >
      {selectedAddress?.value?.businessName ? (
        <>{`${selectedAddress?.value?.businessName[0]}`}</>
      ) : (
        <>{patient && `${patient?.firstName[0]}${patient?.lastName[0]}`}</>
      )}
    </CoreAvatar>
  );
};

const AddressText = ({
  selectedAddress,
}: {
  selectedAddress: SelectedAddressInfo;
}) => (
  <>
    <TextLine>
      {selectedAddress?.value?.addressLine1}{' '}
      {selectedAddress?.value?.addressLine2}
    </TextLine>
    <TextLine>{`${selectedAddress?.value?.city}, ${selectedAddress?.value?.stateCode} ${selectedAddress?.value?.zip}`}</TextLine>
  </>
);

type AddressProps = {
  selectedAddress: SelectedAddressInfo;
  patient: any;
};
const Address = ({ selectedAddress, patient }: AddressProps) => {
  switch (selectedAddress?.addressType) {
    case 'patient':
      return (
        <>
          <Avatar selectedAddress={selectedAddress} patient={patient} />
          <AddressContainer>
            {selectedAddress?.value?.businessName ? (
              <BoldLine>{selectedAddress?.value?.businessName}</BoldLine>
            ) : (
              <BoldLine>{`${patient?.firstName} ${patient?.lastName}`}</BoldLine>
            )}
            <AddressText selectedAddress={selectedAddress} />
          </AddressContainer>
        </>
      );
    case 'practice':
      return (
        <AddressContainer>
          <BoldLine>{selectedAddress?.value?.businessName}</BoldLine>
          <AddressText selectedAddress={selectedAddress} />
        </AddressContainer>
      );
    default:
      return null;
  }
};

type AddressSelectionCardProps = {
  addressSelections: SelectedAddressInfo[];
  selectedAddress: SelectedAddressInfo;
  handleAddressSelection: (address: SelectedAddressInfo) => void;
};
const AddressSelectionCard = ({
  addressSelections,
  selectedAddress,
  handleAddressSelection,
}: AddressSelectionCardProps) => {
  return (
    <Grid container spacing={1}>
      {addressSelections.map((address) => (
        <Grid
          key={address.addressType}
          item
          xs={12}
          md={(12 / addressSelections.length) as GridSize}
        >
          <Card
            style={{ cursor: 'pointer' }}
            selected={address.addressType === selectedAddress?.addressType}
            onClick={() => handleAddressSelection(address)}
            isRowOnMobile
          >
            <StyledImage src={address.image}></StyledImage>
            <type.H6>{address.label}</type.H6>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

type AddressDetailProps = {
  selectedAddress: SelectedAddressInfo;
  setSelectedAddress: (address: SelectedAddressInfo) => void;
  addressFormSubmitBtnRef: React.RefObject<HTMLButtonElement>;
  patient: any;
  handleOpenEditPatient: () => void;
  handleConfirmedAddress: (confirmedAddress: SelectedAddress) => void;
  addressMatch: boolean;
  setAddressFormIsValid?: (isValid: boolean) => void;
};
const AddressDetail = ({
  selectedAddress,
  setSelectedAddress,
  patient,
  handleOpenEditPatient,
  addressFormSubmitBtnRef,
  handleConfirmedAddress,
  addressMatch,
  setAddressFormIsValid,
}: AddressDetailProps) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {['patient', 'practice'].some(
          (a) => a === selectedAddress?.addressType
        ) ? (
          <AddressDetailCard>
            <Grid
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0}
              container
            >
              <Grid item xs={7}>
                <Grid
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  container
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <LeftAddressSection>
                      <Address
                        selectedAddress={selectedAddress}
                        patient={patient}
                      />
                    </LeftAddressSection>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5} style={{ textAlign: 'right' }}>
                {selectedAddress?.addressType === 'patient' && (
                  <EditPatientText onClick={handleOpenEditPatient}>
                    Edit patient home address
                  </EditPatientText>
                )}
              </Grid>
            </Grid>
            {selectedAddress?.addressType === 'practice' && (
              <Grid
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
                container
              >
                <Grid item xs={12} style={{ textAlign: 'left' }}>
                  <ContactSupportSection>
                    <HelpSvg />
                    <ContactSupportText>
                      <ContactSupport text="Contact support" />
                      {` if your practice address isn't accurate`}
                    </ContactSupportText>
                  </ContactSupportSection>
                </Grid>
              </Grid>
            )}
          </AddressDetailCard>
        ) : (
          <OtherAddressForm
            submitButtonRef={addressFormSubmitBtnRef}
            onFormSubmit={(values) => {
              setSelectedAddress({
                ...selectedAddress,
                value: values,
              });
              handleConfirmedAddress({ ...values });
            }}
            setAddressFormIsValid={setAddressFormIsValid}
          />
        )}

        {addressMatch && selectedAddress?.addressType === 'patient' && (
          <div style={{ marginBottom: '32px' }}>
            <AlertCard
              header="Double check address"
              displayIcon={true}
              body="It looks you’re using your practice’s address for the patient home address."
              type="default"
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

type SentPatientShippingUpdateSelectionProps = {
  sendPatientUpdate: boolean;
  setSendPatientUpdate: (value: boolean) => void;
};
const SentPatientShippingUpdateSelection = ({
  sendPatientUpdate,
  setSendPatientUpdate,
}: SentPatientShippingUpdateSelectionProps) => {
  return (
    <FormRow>
      <Label style={{ marginBottom: 0 }}>
        <Checkbox
          checked={sendPatientUpdate}
          onChange={() => setSendPatientUpdate(!sendPatientUpdate)}
          type="checkbox"
          data-testid="PrismPhotoReview-ReasonsModal-Checkbox"
        />
        Send shipping update emails to the patient's email address?
      </Label>
      <SideNote>You will receive shipping update emails either way</SideNote>
    </FormRow>
  );
};

type AddressSelectionProps = {
  setResultAddress: (value: SelectedAddressInfo) => void;
  setResultSendPatientUpdate?: (value: boolean) => void;
  addressFormSubmitBtnRef: React.RefObject<HTMLButtonElement>;
  handleConfirmedAddress: (confirmedAddress: SelectedAddress) => void;
  patientId?: string;
  practiceId?: string;
  setAddressFormIsValid?: (isValid: boolean) => void;
};
export const AddressSelection = ({
  setResultAddress,
  setResultSendPatientUpdate,
  addressFormSubmitBtnRef,
  handleConfirmedAddress,
  patientId,
  practiceId,
  setAddressFormIsValid,
}: AddressSelectionProps) => {
  const [getPatient, { data: patient }] = api.useLazyGetPatientQuery();
  const [getPractice, { data: practice }] = api.useLazyGetPracticeQuery();
  const patientAddress = useMemo(
    () =>
      patient?.addresses?.find(
        (a) => a.addressType === 'Shipping Address'
      ) as AddressType,
    [patient]
  );
  const practiceAddress = useMemo(() => {
    return patient?.practice?.address ?? practice?.address;
  }, [patient, practice]);

  const [sendPatientUpdate, setSendPatientUpdate] = useState<boolean>(false);
  const practiceAddressSelection: SelectedAddressInfo = {
    addressType: 'practice' as SelectedAddressType,
    image: PracticeAddressPng,
    sendPatientUpdateDefaultOption: false,
    value: {
      firstName: patient?.firstName,
      lastName: patient?.lastName,
      businessName: practiceAddress?.name,
      addressLine1: practiceAddress?.addressLine1,
      addressLine2: practiceAddress?.addressLine2,
      city: practiceAddress?.city,
      zip: practiceAddress?.zip,
      stateCode: practiceAddress?.stateCode,
      countryCode: practiceAddress?.countryCode,
      validatedBy: practiceAddress?.validatedBy ?? null,
    },
    label: 'Practice address',
  };

  const patientAddressSelection: SelectedAddressInfo = {
    addressType: 'patient' as SelectedAddressType,
    image: HomeAddressPng,
    sendPatientUpdateDefaultOption: true,
    value: {
      firstName: patient?.firstName,
      lastName: patient?.lastName,
      businessName: patientAddress?.businessName,
      addressLine1: patientAddress?.addressLine1,
      addressLine2: patientAddress?.addressLine2,
      city: patientAddress?.city,
      zip: patientAddress?.zip,
      stateCode: patientAddress?.stateCode,
      countryCode: patientAddress?.countryCode,
      validatedBy: patientAddress?.validatedBy ?? null,
    },
    label: 'Patient home address',
  };
  const otherAddressSelection: SelectedAddressInfo = {
    addressType: 'other' as SelectedAddressType,
    image: OtherAddressPng,
    sendPatientUpdateDefaultOption: false,
    value: {
      validatedBy: null,
    },
    label: 'Somewhere else',
  };
  const addressSelections: SelectedAddressInfo[] = useMemo(() => {
    return patientAddressSelection
      ? [
          practiceAddressSelection,
          patientAddressSelection,
          otherAddressSelection,
        ]
      : [practiceAddressSelection, otherAddressSelection];
  }, [patientId, practiceId, patientAddress, practiceAddress]);
  const [selectedAddress, setSelectedAddress] = useState<SelectedAddressInfo>(
    addressSelections.find((a) => a.addressType === 'practice') ??
      addressSelections[0]
  );

  useEffect(() => {
    if (patientId) {
      getPatient({ customerId: patientId });
    }
  }, [patientId]);

  useEffect(() => {
    if (practiceId) {
      getPractice({ id: practiceId });
    }
  }, [practiceId]);

  useEffect(() => {
    setResultAddress(selectedAddress);
  }, [selectedAddress]);

  useEffect(() => {
    setResultSendPatientUpdate && setResultSendPatientUpdate(sendPatientUpdate);
  }, [sendPatientUpdate]);

  const { showNotification } = useContext(NotificationContext);

  const handleCancelEditPatient = () => {
    setIsEditPatientModalOpen(false);
  };

  const handleOpenEditPatient = () => {
    setIsEditPatientModalOpen(true);
  };

  // use effect to re-set selectedAddress
  useEffect(() => {
    // if existing address is selected, update it
    if (['patient', 'practice'].includes(selectedAddress?.addressType)) {
      handleAddressSelection(
        addressSelections.find(
          (a) => a.addressType === selectedAddress.addressType
        ) ?? addressSelections[0]
      );
    }
  }, [patient, practice]);

  const handleAddressSelection = (addressInfo: SelectedAddressInfo) => {
    setSelectedAddress(addressInfo);
    setSendPatientUpdate(
      (addressInfo?.sendPatientUpdateDefaultOption && !!patientId) || false
    );
    setAddressFormIsValid &&
      setAddressFormIsValid(!(addressInfo.addressType === 'other'));
  };

  const [isEditPatientModalOpen, setIsEditPatientModalOpen] = useState(false);

  const addressMatch = patientAddressSimilarToPractice(
    {
      ...practiceAddress,
      businessName: practiceAddress?.name || '',
    } as AddressMatchType,
    { ...patientAddress } as AddressMatchType
  );

  if (!(patient || practice)) {
    return null;
  }

  const handleEditPatientSuccess = () => {
    showNotification('Successfully updated patient info', 'success');
    setIsEditPatientModalOpen(false);
    patientId && getPatient({ customerId: patientId });
  };

  return (
    <Container>
      {patient && (
        <EditPatientModal
          showShippingNote={false}
          isOpen={isEditPatientModalOpen}
          onClose={handleCancelEditPatient}
          onSuccess={handleEditPatientSuccess}
          patientData={patient}
          shippingAddress={patientAddress}
        />
      )}
      <AddressSelectionCard
        addressSelections={addressSelections}
        selectedAddress={selectedAddress}
        handleAddressSelection={handleAddressSelection}
      />
      <AddressDetail
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        patient={patient}
        handleOpenEditPatient={handleOpenEditPatient}
        addressMatch={addressMatch}
        addressFormSubmitBtnRef={addressFormSubmitBtnRef}
        handleConfirmedAddress={handleConfirmedAddress}
        setAddressFormIsValid={setAddressFormIsValid}
      />
      {patient && (
        <SentPatientShippingUpdateSelection
          sendPatientUpdate={sendPatientUpdate}
          setSendPatientUpdate={setSendPatientUpdate}
        />
      )}
    </Container>
  );
};
