import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'state/store';
import { useIsLoading } from 'state/system';
import {
  createCase,
  fetchPatient,
  fetchCases,
  fetchPrismAggregates,
  fetchScans,
  fetchXrays,
  fetchTreatmentGoalQuestions,
  fetchTreatmentPlanStagings,
  Patient,
  setSelectedCaseRef,
  XrayFragment,
} from 'pages/Patient/patientSlice';
import { ColorProfileKey } from 'components/StepsAccordion';
import {
  PrismAggregatesQuery,
  SupportedFileTypes,
  ScanIntervalDaysOptions,
} from 'generated/legacy/graphql';
import {
  MaterialState,
  MaterialStates,
  StateTransitions,
} from 'generated/core/graphql';
import { CUST_CREATOR_ERROR_MESSAGES } from 'constants/index';
import {
  PROVIDER_FACING_STATUSES,
  statusToActiveStepMapping,
  retainerStatusToActiveStepMapping,
} from 'constants/caseStatus';
import { CaseTypeNames } from 'constants/Case';
import {
  CoreClinicianActionStates,
  ClinicianActionState,
  ClinicianActionStateDict,
  LegacyMaterialStates,
} from 'constants/Material';
import { colors } from 'core/components';
import { AlertTypeEnum } from 'pages/Patient/types';
import { sortByCreated, Sort } from 'utils/prism';

export const getDiagnosticMaterialsPageInfo = (
  patientId: string | undefined | null
) => {
  return {
    text: 'Continue to materials',
    nextPage: `/patient/${patientId}/case-creator/diagnostic-materials`,
  };
};

export const getTreatmentGoalsPageInfo = (
  patientId: string | undefined | null
) => {
  return {
    text: 'Continue to treatment goals',
    nextPage: `/patient/${patientId}/case-creator/treatment-goals`,
  };
};

export const getCheckoutPageInfo = (patientId: string | undefined | null) => {
  return {
    text: 'Continue to checkout',
    nextPage: `/patient/${patientId}/case-creator/checkout?new_scans=true`,
  };
};

// hack to get around that we need to go through a different retainer flow for rejected
// or needs clarification cases and can't just go to checkout
export const getSubmitCaseInfo = (patientId: string | undefined | null) => {
  return {
    text: 'Submit case',
    nextPage: `/patient/${patientId}`,
  };
};

export const getReviewAndSubmitPageInfo = (
  patientId: string | undefined | null
) => {
  return {
    text: 'Review and submit case',
    nextPage: `/patient/${patientId}/case-creator`,
  };
};

export const getBasicInfoNextPageInfo = (
  customerId: string | null | undefined = null,
  areDiagnosticMaterialsComplete: boolean,
  areTreatmentGoalsComplete: boolean,
  pushLocalToComponent = false
) => {
  /*
  Basic Info needs to handle its own push to the next page so that it can complete
  its local methods like updateCustomer, createCustomer and address validation. For that reason, we have
  this helper method that keeps the next page logic in sync between the footer button
  and the basic info component
  */
  if (!areDiagnosticMaterialsComplete) {
    const nextPageUrl = pushLocalToComponent
      ? getDiagnosticMaterialsPageInfo(customerId).nextPage
      : null;
    return {
      text: 'Continue to materials',
      nextPage: nextPageUrl,
    };
  }

  if (areDiagnosticMaterialsComplete && !areTreatmentGoalsComplete) {
    return getTreatmentGoalsPageInfo(customerId);
  }
  return getReviewAndSubmitPageInfo(customerId);
};

export const getCarrierTrackingUrl = (
  carrier: string | null,
  trackingNumber: string
) => {
  const carrierBaseUrlMapping: { [key: string]: string } = {
    UPS: 'https://www.ups.com/track?loc=en_US&tracknum=',
    USPS: 'https://tools.usps.com/go/TrackConfirmAction?tLabels=',
    FedEx: 'https://www.fedex.com/apps/fedextrack/?tracknumbers=',
    DHL: 'https://www.dhl.com/en/express/tracking.html?AWB=',
  };

  return carrier && trackingNumber
    ? `${carrierBaseUrlMapping[carrier]}${trackingNumber}`
    : '';
};

export const toGalleryShape = ({
  id,
  data: { capturedWithinYearOfSubmission },
  createdAt,
  materialType,
  url,
}: XrayFragment) => ({
  id,
  capture_date: createdAt,
  captured_within_year_of_submission: capturedWithinYearOfSubmission,
  material_source: { type: materialType },
  url,
  created: createdAt,
});

export const getProfilePhoto = (
  aggregates: PrismAggregatesQuery['prismAggregates']
) => {
  if (!aggregates) {
    return null;
  }

  const { submissionSet = [], photoSet = [] } = aggregates[0] || {};

  if (submissionSet.length) {
    // sort by submission set by descending submission date
    const sortedSubmissionSet = [...submissionSet].sort(
      sortByCreated(Sort.Desc)
    );
    const approvedSubmissionSet = sortedSubmissionSet.filter(
      (submission) => submission.stateData?.data === 'approved'
    );
    // Get the latest approved submission or the latest submission if there are no approved submissions
    const latestSubmission = approvedSubmissionSet[0] || sortedSubmissionSet[0];
    const submissionItems = latestSubmission?.submissionItems;
    if (submissionItems?.length) {
      const intraoralSmile = submissionItems?.find(
        (item) => item?.photo?.photoType?.name === 'extraoral_smile'
      );
      return intraoralSmile?.photo?.photoUrl;
    }
  }

  // If there is no submissionSet but we have an unreviewed photoSet, use that as fallback
  if (photoSet.length) {
    const extraoralSmile = photoSet?.find(
      (photo) => photo?.photoType?.name === 'extraoral_smile'
    );
    return extraoralSmile?.photoUrl;
  }
  return null;
};

export const usePatientLoadingStates = () => {
  const isFetchingPatient = useIsLoading(fetchPatient.type);
  const isFetchingCases = useIsLoading(fetchCases.typePrefix);
  const isFetchingPrism = useIsLoading(fetchPrismAggregates.typePrefix);
  const isFetchingScans = useIsLoading(fetchScans.typePrefix);
  const isFetchingXrays = useIsLoading(fetchXrays.typePrefix);
  const isFetchingTGs = useIsLoading(fetchTreatmentGoalQuestions.typePrefix);
  const isFetchingTPs = useIsLoading(fetchTreatmentPlanStagings.typePrefix);

  const areMaterialsFetching =
    isFetchingPatient ||
    isFetchingCases ||
    isFetchingPrism ||
    isFetchingScans ||
    isFetchingXrays ||
    isFetchingTGs;

  return {
    isFetchingPatient,
    isFetchingCases,
    isFetchingPrism,
    isFetchingScans,
    isFetchingXrays,
    isFetchingTGs,
    isFetchingTPs,
    areMaterialsFetching,
  };
};

export const getProfileBorderColor = (
  providerFacingStatus: string,
  alertStatus: AlertTypeEnum | null
): string => {
  switch (providerFacingStatus) {
    case PROVIDER_FACING_STATUSES.INCOMPLETE_SUBMISSION:
      return colors.black50;
    case PROVIDER_FACING_STATUSES.ACTION_NEEDED:
      if (alertStatus === AlertTypeEnum.Warning) {
        return colors.yellow70;
      }
      return colors.red50;
    case PROVIDER_FACING_STATUSES.IN_TREATMENT:
      return colors.green70;
    default:
      return colors.blue50;
  }
};

const fileTypesByExt = {
  jpg: SupportedFileTypes.Jpg,
  jpeg: SupportedFileTypes.Jpeg,
  png: SupportedFileTypes.Png,
};

export const getValidFileExtension = (file: File): SupportedFileTypes => {
  const allowedMimeTypes = ['image/jpeg', 'image/jpg', 'image/png'];

  const rawFileExt = file.name.toLowerCase().split('.').pop();
  const validFileExt = fileTypesByExt[rawFileExt as SupportedFileTypes];

  if (!allowedMimeTypes.includes(file.type) || !validFileExt) {
    throw new Error(CUST_CREATOR_ERROR_MESSAGES.unsupported_photo_file_type);
  }
  return validFileExt;
};

type BadgeInfo = {
  text: string;
  color: ColorProfileKey;
};

const coreStatusBadges = {
  [MaterialStates.Completed]: {
    text: 'Complete',
    color: ColorProfileKey.Green,
  },
  [StateTransitions.Reject]: {
    text: 'Rejected',
    color: ColorProfileKey.Red,
  },
  [MaterialStates.NeedsClarification]: {
    text: 'Needs clarification',
    color: ColorProfileKey.Yellow,
  },
  [MaterialStates.NotStarted]: {
    text: 'Incomplete',
    color: ColorProfileKey.Gray,
  },
};

export const getCoreMaterialBadgeInfo = (
  materialState?: MaterialState | null,
  readyToSubmit?: boolean
): BadgeInfo => {
  if (materialState?.transition === StateTransitions.Reject && !readyToSubmit) {
    return coreStatusBadges[StateTransitions.Reject];
  }
  if (!materialNeedsClinicianAction(materialState)) {
    return coreStatusBadges[MaterialStates.Completed];
  }
  if (readyToSubmit) {
    return coreStatusBadges[MaterialStates.Completed];
  }
  if (materialState?.state === MaterialStates.NeedsClarification) {
    return coreStatusBadges[MaterialStates.NeedsClarification];
  }

  return coreStatusBadges[MaterialStates.NotStarted];
};

export const materialNeedsClinicianAction = (
  materialState?: MaterialState | null
) => materialState && CoreClinicianActionStates.includes(materialState.state);

// DEPRECATED - But still used for prism materials.  Materials in core use coreStatusBadges
const legacyBadgeInfoMap: ClinicianActionStateDict<BadgeInfo> = {
  [LegacyMaterialStates.REJECTED]: {
    text: 'Rejected',
    color: ColorProfileKey.Red,
  },
  [LegacyMaterialStates.NEEDS_CLARIFICATION]: {
    text: 'Needs clarification',
    color: ColorProfileKey.Yellow,
  },
  [LegacyMaterialStates.NOT_STARTED]: {
    text: 'Incomplete',
    color: ColorProfileKey.Gray,
  },
  [LegacyMaterialStates.UPLOADED]: {
    text: 'Incomplete',
    color: ColorProfileKey.Gray,
  },
  [LegacyMaterialStates.COLLECTION]: {
    text: 'Incomplete',
    color: ColorProfileKey.Gray,
  },
  [LegacyMaterialStates.READY_TO_SUBMIT]: {
    text: 'Complete',
    color: ColorProfileKey.Green,
  },
};

// DEPRECATED - But still used for prism materials.  Materials in core use getCoreMaterialBadgeInfo
export const getPrismBadgeInfo = (state: string): BadgeInfo => {
  const complete = {
    text: 'Complete',
    color: ColorProfileKey.Green,
  };
  return legacyBadgeInfoMap[state as ClinicianActionState] || complete;
};

export const getActiveStepForCaseType = ({
  caseTypeName,
  providerFacingStatus,
}: {
  caseTypeName?: string;
  providerFacingStatus?: string;
}) => {
  switch (caseTypeName) {
    case CaseTypeNames.RETAINER:
      return retainerStatusToActiveStepMapping[providerFacingStatus ?? ''];
    case CaseTypeNames.ALIGNER:
    default:
      return statusToActiveStepMapping[providerFacingStatus ?? ''];
  }
};

export const useCreateRetainerCase = () => {
  const { push } = useHistory();
  const dispatch = useDispatch<AppDispatch>();

  const createRetainerCase = async ({ patient }: { patient: Patient }) => {
    const result = await dispatch(
      createCase({
        caseCreationInput: {
          caseOptions: {
            patientId: Number(patient?.id),
            caseType: CaseTypeNames.RETAINER,
            practiceId: Number(patient?.practice?.id),
          },
        },
      })
    ).unwrap();
    if (result?.createCase?.case) {
      await dispatch(
        fetchCases({ patientIds: [Number(patient?.id)] })
      ).unwrap();

      dispatch(setSelectedCaseRef(result?.createCase?.case?.caseRef));

      push(`/patient/${patient?.id}/case-creator/diagnostic-materials`);
    }
  };

  return createRetainerCase;
};

// ScanIntervalDaysOptions is defined in candid-app and passed down as the enum name
// but we need the enum value for a mutation in candid-services
export const scanIntervalDaystoNumber = (options: ScanIntervalDaysOptions) => {
  switch (options) {
    case ScanIntervalDaysOptions.Seven:
      return 7;
    case ScanIntervalDaysOptions.Fourteen:
      return 14;
  }
};
