import React from 'react';
import styled from 'styled-components/macro';
import {
  AutoSizer,
  InfiniteLoader,
  Table,
  TableProps,
  InfiniteLoaderProps,
  Index,
} from 'react-virtualized';
import { Omit } from '@material-ui/core';
import { colors } from 'core/components';

// Most of react-virtualized's styles are functional (eg position, size).
// Functional styles are applied directly to DOM elements.
// The Table component ships with a few presentational styles as well.
// They are optional, but if you want them you will need to also import the CSS file.
// This only needs to be done once; probably during your application's bootstrapping process.
import 'react-virtualized/styles.css';

// TODO: Wrote all the different styles that exist for the table component
// to move quickly when styling it to match the designs
const TableWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`;

// TODO: Wrote all the different styles that exist for the table component
// to move quickly when styling it to match the designs
const TableContainer = styled.div`
  flex: 1 1 auto;
  height: 100%;

  .ReactVirtualized__Table {
    font-size: 14px;
  }

  .ReactVirtualized__Table__Grid {
    outline: none;
  }

  .ReactVirtualized__Table__headerColumn {
    align-items: center;
    display: flex;
    height: 100%;
    border-right: 1px solid ${colors.black20};
  }
  .ReactVirtualized__Table__headerColumn:last-child {
    border-right: none;
  }

  .ReactVirtualized__Table__headerRow {
    background-color: ${colors.black05};
    text-transform: capitalize;
    border-bottom: 1px solid ${colors.black20};
    border-top: 1px solid ${colors.black20};
  }

  .ReactVirtualized__Table__row {
    border-bottom: 1px solid ${colors.black20};
  }

  .ReactVirtualized__Table__rowColumn {
    align-items: center;
    display: flex;
    height: 100%;
  }

  .ReactVirtualized__Table__sortableHeaderColumn {
  }

  .ReactVirtualized__Table__sortableHeaderIcon {
  }
`;

export const VirtualizedTable = ({
  rows,
  children,
  loadMoreRows,
  rowCount,
  ...props
}: Omit<Omit<TableProps, 'width'>, 'height'> &
  Omit<Omit<InfiniteLoaderProps, 'children'>, 'isRowLoaded'>) => {
  return (
    <TableWrapper>
      <TableContainer>
        <InfiniteLoader
          isRowLoaded={(index: Index) => !!rows[index.index]}
          loadMoreRows={loadMoreRows}
          rowCount={100000} // Ideally we could get the total count of the search results then we can set this to the total number of results
          threshold={10}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  {...(props as TableProps)}
                  ref={registerChild}
                  onRowsRendered={onRowsRendered}
                  width={width}
                  height={height}
                  rowCount={rowCount}
                  rowGetter={({ index }) => rows[index]}
                >
                  {children}
                </Table>
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </TableContainer>
    </TableWrapper>
  );
};
