import { type } from 'core/components';
import styled from 'styled-components/macro';

export const ModalContainer = styled.div`
  width: 35rem;
`;

export const HeaderText = styled.h1`
  font-size: ${type.size.medium};
  text-align: center;
`;

export const Subheading = styled.div`
  font-size: ${type.size.default};
  width: 80%;
  margin: 0 auto;
  text-align: center;
`;

export const Subtext = styled.div`
  font-size: ${type.size.small};
  color: ${(p) => p.theme.colors.black60};
`;

export const IconContainer = styled.div`
  width: 35%;
  height: 35%;
  margin: 0 auto;
`;

export const HeaderContainer = styled.div`
  width: 100%
  height: 30%
`;

export const ButtonContainer = styled.div`
  width: 28rem;
  margin-top: 1rem;
`;

export const BulletPoint = styled.div`
  background-color: ${(p) => p.theme.colors.blue};
  border-radius: 50%;
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 1rem;
`;
