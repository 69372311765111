import styled from 'styled-components';
import MuiAccordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

export const StyledMuiAccordion = styled(MuiAccordion)`
  width: 100%;
  border-radius: 4px;
`;
export const StyledMuiAccordionSummary = styled(MuiAccordionSummary)`
  ${({ theme }) => `
  &.MuiAccordionSummary-root{
    background-color: ${theme.colors.black05};
    border-radius: 4px; 
  }

  &.MuiAccordionSummary-root.Mui-expanded{
    background-color: ${theme.colors.white};
    border-radius: 4px;
    min-height: 0px;
  }

  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`}
`;

export const DefaultExpandIcon = styled(ExpandMoreIcon)`
  ${({ theme }) => `
  border-radius: 50%;
  width: 36px;
  height: 36px;
  &:hover {
    background-color: ${theme.colors.black20};
  `}
`;
