import { PartialLineItem } from 'components/TotalBox/types';

export const findCachedLineItem = (
  sku: string,
  quantity: number,
  lineItemDiscountsCache: PartialLineItem[]
): PartialLineItem | undefined => {
  return lineItemDiscountsCache.find(
    (lineItem) => lineItem?.sku === sku && lineItem?.quantity === quantity
  );
};
