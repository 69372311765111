import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { AlertCard, Button, Tooltip, colors } from 'core/components';
import { arrayContainer, arrayItem, itiArrayContainer } from 'styles/array.css';
import PencilIcon from 'assets/pencil-2.svg?react';
import SparkleSVG from 'assets/sparkle.svg?react';

export const RejectedPhotoOverlay = styled.div<{
  enabled: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.black};
  width: 100%;
  height: 100%;
  ${({ enabled }) =>
    enabled &&
    css`
      position: fixed;
      border: solid red 2px;
    `}
`;

export const StyledTooltip = styled(Tooltip)`
  z-index: 140;
  position: absolute;
`;

export const ArrayContainer = styled.div`
  ${arrayContainer};
  grid-gap: 1.125rem;
  height: 29rem;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const StyledAlertCard = styled(AlertCard)`
  margin-bottom: 16px;
  &:first-child {
    margin-bottom: 0;
  }
`;

export const UploadForm = styled.div`
  height: 210px;
  margin-bottom: 1.5rem;
  ${({ theme }) => css`
    border: 1px dashed ${theme.colors.black40};
    border-radius: 8px;
    @media ${theme.mediaQueries.mobile} {
      height: 240px;
    }
  `}}
`;

export const RefinementsArrayContainer = styled.div`
  ${itiArrayContainer};
  grid-gap: 1.125rem;
  height: 19rem;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const UnderlinedLink = styled.a`
  text-decoration: underline;
`;

export const ArrayItem = styled.div<{ showBorder: boolean }>`
  ${arrayItem};
  position: relative;
  min-height: 0;
  background: ${colors.black05};
  border-radius: 4px;
  overflow:hidden;

    ${({ showBorder }) =>
      !!showBorder &&
      css`
        box-shadow 0 0 0 2px ${colors.blue50};
      `};
  }
`;

export const UploadTextContainer = styled.div<{ canEdit: boolean }>`
  align-self: center;
  text-align: center;
  color: ${colors.text70};
  ${({ canEdit }) => !canEdit && css`opacity 0.5`}
`;

export const UploadHeader = styled.div`
  max-width: 6rem;
  margin: 0 auto;
  justify-self: center;
`;

export const UploadBody = styled.div`
  font-size: 12px;
`;

export const StyledPencilIcon = styled(PencilIcon)`
  width: 14px;
  height: 14px;
`;

export const EditButton = styled.button`
  position: absolute;
  top: 12px;
  left: 8px;
  z-index: 1;
  width: 28px;
  height: 28px;

  background: ${colors.text60};

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  label:hover {
    background-color: inherit;
  }
`;

export const StyledReUploadButton = styled(Button)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 150;
  pointer-events: none;
`;

export const DisplayImage = styled.img<{
  opacity: number;
}>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${({ opacity }) =>
    opacity &&
    css`
      opacity ${opacity}
    `}
`;

export const TextHeader = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.text.headings.h5.fontSize};

  display: flex
  justify-content: flex-start
`;

export const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  padding: 0 16px;
`;

export const EmptyText = styled.p`
  text-align: center;
  max-width: 480px;
  margin: 0;
`;

export const StyledSparkle = styled(SparkleSVG)`
  fill: ${colors.blue50};
`;

export const FakeButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  border: 2px solid ${colors.blue50};
  color: ${colors.blue50};
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  font-weight: 700;

  svg {
    margin-left: 0.5rem;
  }
`;
