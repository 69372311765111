import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { GetThinPracticesQuery } from 'generated/legacy/graphql';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchThinPractices,
  selectAllPractices,
} from 'pages/PracticeManagement/shared/slice';
import DocumentTitle from 'components/DocumentTitle';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components/macro';
import { Formik } from 'formik';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import { SelectInput } from 'core/components';

type ThinPractice = GetThinPracticesQuery['practices'][0];

const Heading = styled.h2`
  margin-bottom: 1.5rem;
`;

const Container = styled.div`
  padding: 3rem;
  width: calc(400px + 6rem);
  max-width: 100vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;

const PracticeSearchBar = styled(SelectInput)`
  width: 400px;
  max-width: calc(100vw - 6rem);
  input {
    padding-left: 1rem;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  opacity: 0.25 !important;
  margin-right: 0.5rem;
`;

const PracticeSearch = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const allPractices = useSelector(selectAllPractices);
  const [displayedPractices, setDisplayedPractices] = useState<ThinPractice[]>(
    []
  );
  const [searchInputText, setSearchInputText] = useState<string>('');
  const [showResults, setShowResults] = useState<boolean>(false);
  const searchComponents = useMemo(() => {
    return !displayedPractices.length
      ? {
          DropdownIndicator: StyledSearchIcon,
          NoOptionsMessage: () => null,
          Menu: () => null,
        }
      : {
          DropdownIndicator: StyledSearchIcon,
        };
  }, [displayedPractices]);

  const setSearchTextDebounced = useCallback(
    debounce((text) => {
      const doSearch = text.length >= 3;
      if (doSearch) {
        dispatch(fetchThinPractices({ name: text }));
      } else {
        setDisplayedPractices([]);
      }
      setShowResults(doSearch);
    }, 300),
    []
  );

  useEffect(() => {
    setDisplayedPractices(showResults ? allPractices : []);
  }, [allPractices]);

  return (
    <DocumentTitle title="Practice Management">
      <Container>
        <Heading>Practice management</Heading>
        <Formik initialValues={{ name: '' }} onSubmit={() => {}}>
          <PracticeSearchBar
            placeholder={'Search for a practice'}
            components={searchComponents}
            name="name"
            testId="customer-search-input"
            onInputChange={(e: string) => {
              setSearchInputText(e);
              setSearchTextDebounced(e);
            }}
            onChange={(e: { value: string; label: string }) => {
              push(`/management/practice/${e.value}`);
            }}
            value={searchInputText}
            options={displayedPractices.map((practice: ThinPractice) => ({
              value: practice.id,
              label: practice.name,
            }))}
          />
        </Formik>
      </Container>
    </DocumentTitle>
  );
};

export default PracticeSearch;
