import React, { ChangeEvent, useMemo } from 'react';
import { useFormikContext } from 'formik';
import {
  FormikInputWrapper,
  FormikDatePickerNoHeader,
  ValidateOn,
} from 'components/FormikForms';
import {
  EditPatientInfo,
  EditPatientError,
} from 'components/EditPatientModal/EditPatientModal';
import {
  FormContainer,
  ActionButtons,
  StyledButton,
  AlertBody,
  AlertText,
  NormalBoldText,
  ModalBody,
  Header,
} from 'components/EditPatientModal/EditPatientModal.css';
import { type, Grid, AlertCard, Divider } from 'core/components';
import {
  notRequiredEmailValidator,
  validateDateOfBirth,
} from 'components/FormikForms/utils';
import {
  patientAddressSimilarToPractice,
  Address,
} from 'utils/shippingAddress';
import {
  requiredValidator,
  validateGuardianDateOfBirth,
  normalizePhoneNumber,
} from 'components/FormikForms/utils';
import AddressForm from 'components/AddressForm/AddressForm';
import { AddressFormType } from 'components/AddressForm/types';
import { CandidProCustomerQuery } from 'generated/legacy/graphql';
import ContactSupport from 'components/ContactSupport';

type EditPatientFormProps = {
  handleCloseModal: () => void;
  editErrors: EditPatientError;
  showShippingNote?: boolean;
  submitForm: (values: EditPatientInfo) => void;
  submitButtonRef: React.RefObject<HTMLButtonElement>;
  isSubmitting: boolean;
  patientData: CandidProCustomerQuery['customer'];
};

const EditPatientForm = ({
  handleCloseModal,
  editErrors,
  showShippingNote = true,
  submitForm,
  submitButtonRef,
  isSubmitting,
  patientData,
}: EditPatientFormProps) => {
  const { values, dirty, handleSubmit, resetForm, setFieldValue } =
    useFormikContext<EditPatientInfo>();
  const closeModal = () => {
    handleCloseModal();
    resetForm();
  };

  const legalGuardian = useMemo(
    () => patientData?.legalGuardian,
    [patientData]
  );
  const practiceAddress = useMemo(
    () => patientData?.practice?.address,
    [patientData]
  );

  const addressMatch = patientAddressSimilarToPractice(
    {
      ...practiceAddress,
      businessName: practiceAddress?.name || '',
    } as Address,
    { ...values } as Address
  );

  const handleCancel = () => {
    if (
      !dirty ||
      (dirty && window.confirm('You will lose any unsaved changes'))
    ) {
      closeModal();
    }
  };
  const formatNumberSync = (event: ChangeEvent<HTMLInputElement>) => {
    normalizePhoneNumber('phone', event.target.value, setFieldValue);
  };
  const formatGuardianNumberSync = (event: ChangeEvent<HTMLInputElement>) => {
    normalizePhoneNumber('guardian_phone', event.target.value, setFieldValue);
  };

  const renderGuardianInfoFields = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <NormalBoldText>{'Guardian info'}</NormalBoldText>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormikInputWrapper
              label="Guardian's first name"
              name="guardian_firstName"
              type="text"
              validate={requiredValidator}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikInputWrapper
              label="Guardian's middle name"
              name="guardian_middleName"
              type="text"
            />
          </Grid>
          <Grid item xs={6}>
            <FormikInputWrapper
              label="Guardian's last name"
              name="guardian_lastName"
              type="text"
              validate={requiredValidator}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikInputWrapper
              label="Guardian's preferred name"
              name="guardian_preferredName"
              type="text"
              validate={requiredValidator}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikInputWrapper
              label="Guardian's phone number"
              name="guardian_phone"
              type="text"
              validate={requiredValidator}
              onChange={formatGuardianNumberSync}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikDatePickerNoHeader
              label="Guardian's date of birth"
              name="guardian_birthday"
              placeholder="ex. MM/YYYY or DD-MM-YYYY"
              type="text"
              validate={validateGuardianDateOfBirth}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const duplicateEmailAlertBody = (
    <AlertBody>
      <AlertText>
        Please use a different email or{' '}
        <ContactSupport text="contact support" /> to investigate where this
        email is already in use
      </AlertText>
    </AlertBody>
  );

  const invalidDateOfBirthAlertBody = (
    <AlertBody>
      <AlertText>
        The selected date of birth is not allowed for the “adult” case type. If
        you chose this case type in error,{' '}
        <ContactSupport text="contact support" />
        to change the case type.
      </AlertText>
    </AlertBody>
  );

  return (
    <FormContainer>
      <Header>
        <Grid container>
          <Grid item xs={12}>
            <type.H4>{'Edit patient info'}</type.H4>
          </Grid>
        </Grid>
      </Header>
      <Divider />
      <ModalBody>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormikInputWrapper
              label="First name"
              name="firstName"
              placeholder="First name *"
              type="text"
              validate={requiredValidator}
            />
          </Grid>
          <Grid item xs={4}>
            <FormikInputWrapper
              label="Middle name"
              name="middleName"
              type="text"
              placeholder="Middle name"
            />
          </Grid>
          <Grid item xs={4}>
            <FormikInputWrapper
              label="Last name"
              name="lastName"
              type="text"
              placeholder="Last name *"
              validate={requiredValidator}
            />
          </Grid>
          <Grid item xs={4}>
            <FormikInputWrapper
              label="Phone"
              name="phone"
              type="text"
              onChange={formatNumberSync}
            />
          </Grid>
          <Grid item xs={4}>
            <FormikInputWrapper
              label="Preferred name"
              name="preferredName"
              placeholder="Preferred name *"
              type="text"
              validate={requiredValidator}
            />
          </Grid>
          <Grid item xs={4}>
            <FormikDatePickerNoHeader
              label={'Date of birth'}
              name="birthday"
              type="text"
              placeholder="Date of birth *"
              validate={validateDateOfBirth}
              validateOn={ValidateOn.Blur}
            />
          </Grid>
        </Grid>
        {editErrors['birthDate'] && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AlertCard
                header="Invalid date of birth"
                displayIcon={true}
                type="critical"
              >
                {invalidDateOfBirthAlertBody}
              </AlertCard>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormikInputWrapper
              label="Email"
              name="email"
              type="text"
              validate={notRequiredEmailValidator}
            />
          </Grid>
        </Grid>
        {editErrors['email'] && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AlertCard
                header="This email address is already in use"
                displayIcon={true}
                type="critical"
              >
                {duplicateEmailAlertBody}
              </AlertCard>
            </Grid>
          </Grid>
        )}
        {legalGuardian && renderGuardianInfoFields()}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <NormalBoldText>{'Patient home address'}</NormalBoldText>
            {showShippingNote && (
              <AlertText>
                This address will be stored on the patient record, but you’ll be
                able to choose if you want to ship to the patient or your
                practice when you review a treatment plan. If you have recently
                approved a treatment plan and need to change the shipping
                address,{` `}
                <ContactSupport text="contact support." />
              </AlertText>
            )}
          </Grid>
        </Grid>

        <AddressForm
          addressPath=""
          getAddressData={(values: EditPatientInfo) => values}
          submitForm={submitForm}
          updateAddress={(
            value: AddressFormType,
            originalValues: EditPatientInfo
          ) => {
            return {
              ...originalValues,
              addressLine1: value.addressLine1 ?? '',
              addressLine2: value.addressLine2 ?? '',
              city: value.city ?? '',
              stateCode: value.stateCode ?? '',
              zip: value.zip ?? '',
              //Smarty street doesn't return country, so use the original.
              countryCode: originalValues.countryCode,
              businessName: originalValues.businessName,
            };
          }}
          submitButtonRef={submitButtonRef}
        />

        <Grid item xs={12}>
          {addressMatch && (
            <div style={{ marginBottom: '32px' }}>
              <AlertCard
                header="Double check address"
                displayIcon={true}
                body="It looks you’re using your practice’s address for the patient home address."
                type="default"
              />
            </div>
          )}
        </Grid>
      </ModalBody>
      <Divider />
      <ActionButtons>
        <StyledButton
          onClick={handleCancel}
          type="button"
          buttonType="tertiary"
          disabled={isSubmitting}
        >
          Cancel
        </StyledButton>
        <StyledButton
          type={'button'}
          buttonType="secondary"
          isShort
          isLoading={isSubmitting}
          disabled={!dirty}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </StyledButton>
      </ActionButtons>
    </FormContainer>
  );
};

export default EditPatientForm;
