export const canUpdatePhotoOrView = (
  isEditing: boolean,
  // isRefreshingPhotos: boolean,
  isUploadingPhoto: boolean
) => {
  // if (isRefreshingPhotos) {
  //   return false;
  // }

  if (isUploadingPhoto) {
    window.alert('Photo upload in progress');

    return false;
  }

  if (isEditing) {
    return window.confirm('You will lose any unsaved edits');
  }

  return true;
};
