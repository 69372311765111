import React, { useEffect, useContext } from 'react';
import { NotificationContext } from 'core/components';

import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
} from 'react-router-dom';
import { GetLoyaltyProgramsDocument } from 'generated/core/graphql';
import { useGQLQuery } from 'hooks/useGQL';

import Home from 'pages/LoyaltyProgram/Home';
import Viewer from 'pages/LoyaltyProgram/Viewer';
import Create from 'pages/LoyaltyProgram/Create';

const LoyaltyProgramPages = ({ match }: RouteComponentProps) => {
  const history = useHistory();
  const { showNotification } = useContext(NotificationContext);
  const [getLoyaltyProgramData, { data: getLoyaltyProgramResult }] =
    useGQLQuery<any, any>(GetLoyaltyProgramsDocument);
  const loyaltyPrograms = getLoyaltyProgramResult?.getLoyaltyPrograms || [];

  useEffect(() => {
    getLoyaltyProgramData({});
  }, []);

  const handleSubmitSuccess = (programName: string) => {
    history.push('/loyalty-program');
    getLoyaltyProgramData({});
    showNotification(`${programName} is created`, 'success');
  };
  return (
    <Switch>
      <Route path={match.path} exact>
        <Home loyaltyPrograms={loyaltyPrograms} />
      </Route>
      <Route path={`${match.path}/view/:id`} exact>
        <Viewer loyaltyPrograms={loyaltyPrograms} />
      </Route>
      <Route path={`${match.path}/create`} exact>
        <Create
          onSubmitSuccess={handleSubmitSuccess}
          showErrorNotification={(errorMessage: string) => {
            showNotification(errorMessage, 'error');
          }}
        />
      </Route>
    </Switch>
  );
};

export default LoyaltyProgramPages;
