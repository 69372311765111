import * as graphql_ from 'state/system/graphql';
import * as autoQuery_ from 'state/system/autoQuery';
import * as polling_ from 'state/system/polling';
import * as debounce_ from 'state/system/debounce';
export * from 'state/system/slice';
// this two-step is because prettier chokes on `export * as graphql from 'state/system/graphql'`
export const graphql = graphql_;
export const polling = polling_;
export const autoQuery = autoQuery_;
export const debounce = debounce_;
export type PollingMeta = polling_.PollingMeta;
