import React from 'react';
import { Formik } from 'formik';
import {
  DoctorPreferencesInput,
  useMeQuery,
  MeQuery,
} from 'generated/legacy/graphql';
import ScanPreferences from 'pages/AccountPreferencesPage/Scan';
import TreatmentPreferences from 'pages/AccountPreferencesPage/Treatment';
import ProMonitoring from 'pages/AccountPreferencesPage/ProMonitoring';
import Notation from 'pages/AccountPreferencesPage/Notation';
import CBCTScanPreferences from 'pages/AccountPreferencesPage/CBCTScanPreferences';
import ScanIntervalDays from 'pages/AccountPreferencesPage/ScanIntervalDays';
import PreferencesTabbedMenu from 'pages/AccountPreferencesPage/PreferencesTabbedMenu';
import {
  Page,
  Container,
  Sidebar,
  Header,
  FormSections,
  SidebarLink,
  SidebarTitle,
} from 'pages/AccountPreferencesPage/AccountPreferencesView.css';
import DocumentTitle from 'components/DocumentTitle';
import {
  CANDID_BRAND_NAME,
  convertToBrand,
  getBrandSupportedFeatures,
} from 'utils/brands';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AutoSaveForm from 'pages/AccountPreferencesPage/AutoSaveForm';

enum ANCHOR_TAGS {
  TREATMENT = 'treatment',
  SCAN = 'scan',
  SCAN_INTERVAL = 'scan-interval',
  PRO_MONITORING = 'pro-monitoring',
  DENTAL_NOTATION = 'dental-notation',
  CBCT_SCAN = 'cbct-scan',
}

type UserInfo = MeQuery['me'];

type PreferencesProps = {
  userId: string | undefined;
  userInfo: UserInfo;
  supportPvsScan: boolean;
  initialValues: DoctorPreferencesInput;
};

const LegacyPreferencesSidebar = () => {
  const {
    'enable-dental-notation': enableDentalNotation,
    'enable-cbct-scan': enableCbctScanFlag,
  } = useFlags();

  return (
    <Sidebar>
      <Header>Preferences</Header>
      <SidebarTitle>CASE SUBMISSION</SidebarTitle>
      <SidebarLink href={`#${ANCHOR_TAGS.TREATMENT}`}>
        Treatment preferences
      </SidebarLink>
      <SidebarLink href={`#${ANCHOR_TAGS.SCAN}`}>
        Scan upload preferences
      </SidebarLink>
      <SidebarTitle>MONITORING</SidebarTitle>
      <SidebarLink href={`#${ANCHOR_TAGS.SCAN_INTERVAL}`}>
        Aligner wear schedule
      </SidebarLink>
      <SidebarLink href={`#${ANCHOR_TAGS.PRO_MONITORING}`}>
        ProMonitoring patient app activation
      </SidebarLink>
      <SidebarTitle>CLINICAL</SidebarTitle>
      {enableDentalNotation && (
        <SidebarLink href={`#${ANCHOR_TAGS.DENTAL_NOTATION}`}>
          Notation system
        </SidebarLink>
      )}
      {enableCbctScanFlag && (
        <SidebarLink href={`#${ANCHOR_TAGS.CBCT_SCAN}`}>
          CBCT scan preferences
        </SidebarLink>
      )}
    </Sidebar>
  );
};

const LegacyPreferences = ({
  userId,
  userInfo,
  supportPvsScan,
  initialValues,
}: PreferencesProps) => (
  <>
    <Header>Preferences</Header>
    <TreatmentPreferences id={ANCHOR_TAGS.TREATMENT} />
    <ScanPreferences
      isLoading={!userInfo}
      supportPvsScan={supportPvsScan}
      id={ANCHOR_TAGS.SCAN}
    />
    <ScanIntervalDays isLoading={!userInfo} id={ANCHOR_TAGS.SCAN_INTERVAL} />
    <ProMonitoring isLoading={!userInfo} id={ANCHOR_TAGS.PRO_MONITORING} />
    <Notation isLoading={!userInfo} id={ANCHOR_TAGS.DENTAL_NOTATION} />
    <CBCTScanPreferences isLoading={!userInfo} id={ANCHOR_TAGS.CBCT_SCAN} />
    <AutoSaveForm userId={Number(userId)} initialValues={initialValues} />
  </>
);

const AccountPreferencesView = () => {
  const { data: meData } = useMeQuery();
  const userInfo = meData?.me;

  const { accountPreferences, id: userId } = userInfo || {};
  const initialValues = accountPreferences?.doctor || {};
  const brand = convertToBrand(userInfo?.brandInfo?.name, CANDID_BRAND_NAME);
  const { PvsScanFlow: supportPvsScan } = getBrandSupportedFeatures(brand);
  const { enableOrthoTreatmentGoals } = useFlags();

  const preferenceTabs = [
    {
      id: 'clinical',
      name: 'Clinical',
      Component: () => (
        <>
          <Notation isLoading={!userInfo} id={ANCHOR_TAGS.DENTAL_NOTATION} />
        </>
      ),
    },

    {
      id: 'caseTemplates',
      name: 'Case templates',
      Component: () => <div> Placeholder </div>,
    },
    {
      id: 'diagosticMaterials',
      name: 'Diagnostic Materials',
      Component: () => (
        <>
          <ScanPreferences
            isLoading={!userInfo}
            supportPvsScan={supportPvsScan}
            id={ANCHOR_TAGS.SCAN}
          />
          <TreatmentPreferences id={ANCHOR_TAGS.TREATMENT} />
          <CBCTScanPreferences
            isLoading={!userInfo}
            id={ANCHOR_TAGS.CBCT_SCAN}
          />
        </>
      ),
    },
    {
      id: 'monitoring',
      name: 'Monitoring',
      Component: () => (
        <>
          <ProMonitoring
            isLoading={!userInfo}
            id={ANCHOR_TAGS.PRO_MONITORING}
          />
          <ScanIntervalDays
            isLoading={!userInfo}
            id={ANCHOR_TAGS.SCAN_INTERVAL}
          />
        </>
      ),
    },
  ];

  return (
    <DocumentTitle title="Preferences">
      <Page>
        {enableOrthoTreatmentGoals ? (
          <PreferencesTabbedMenu
            preferenceTabs={preferenceTabs}
            initialValues={initialValues}
            userId={userId}
          />
        ) : (
          <Container>
            <LegacyPreferencesSidebar />
            <Formik
              enableReinitialize
              initialValues={initialValues}
              // onSubmit isRequired by Formik but since we are auto-saving we don't need it
              onSubmit={() => {}}
            >
              <FormSections>
                <LegacyPreferences
                  userId={userId}
                  userInfo={userInfo}
                  supportPvsScan={supportPvsScan}
                  initialValues={initialValues}
                />
              </FormSections>
            </Formik>
          </Container>
        )}
      </Page>
    </DocumentTitle>
  );
};

export default AccountPreferencesView;
