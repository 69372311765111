import React from 'react';
import Select, { Props as SelectProps } from 'react-select';

import { Label, ErrorText } from 'core/shared/forms.css';
import {
  Wrap,
  StyledSelectInput,
} from 'core/components/SelectInput/SelectInput.css';

export interface OptionType {
  value: string;
  label: string;
}

export interface SelectInputProps extends SelectProps<OptionType> {
  errorText?: string;
  isError?: boolean;
  label?: string;
  testId?: string;
}

const SelectInput = ({
  className,
  classNamePrefix = 'select',
  closeMenuOnScroll = true,
  errorText,
  isError,
  label,
  name,
  onChange,
  options,
  placeholder,
  isClearable,
  testId,
  value,
  ...props
}: SelectInputProps) => {
  const showError = isError || !!errorText;

  return (
    <Wrap className={className}>
      {label && <Label>{label}</Label>}
      <Select
        classNamePrefix={classNamePrefix}
        closeMenuOnScroll={closeMenuOnScroll}
        data-testid={testId}
        isClearable={isClearable}
        name={name}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        showError={showError}
        style={StyledSelectInput}
        type="text"
        value={value}
        {...props}
      />
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </Wrap>
  );
};

export default SelectInput;
