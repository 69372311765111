import styled from 'styled-components/macro';

export const OrdersPortalPage = styled.div`
  margin: 1rem auto;
  max-width: 800px;
`;

export const TabContent = styled.div`
  margin: 1rem 0;
`;

export const Actions = styled.div`
  input[type='checkbox'] {
    appearance: checkbox;
    margin-right: 0.5rem;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const ProductSelectContainer = styled.div`
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;
