import styled from 'styled-components/macro';
import { colors, Box } from 'core/components';

export const Flex = styled(Box)`
  display: flex;
`;

export const ButtonContainer = styled(Box)`
  button:first-child {
    margin-bottom: 0.5rem;
  }
`;

export const Container = styled.section<{
  empty?: boolean;
}>`
  position: relative;
  display: flex;
  flex: ${({ empty }) => (empty ? '0' : '1 1 0')};
  flex-direction: column;
  border-radius: 8px;
  height: fit-content;
  border: ${({ empty }) => (empty ? 'none' : `1px solid ${colors.black20}`)};
  transition: all 0.5s ease-in-out;
`;

export const InnerContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`;

export const Title = styled.div<{
  padding?: boolean;
}>`
  color: ${colors.text90};
  font-family: Modern Era;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  padding: ${({ padding }) => (padding ? '24px' : '0')};
  width: 100%;
`;
export const Border = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.black20};
`;
