import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetPracticeDocument,
  GetPracticeQueryVariables,
  GetThinPracticesDocument,
  GetThinPracticesQueryVariables,
  GetUserDocument,
  GetUserQuery,
  GetUserQueryVariables,
  Practice,
} from 'generated/legacy/graphql';
import {
  GetCurrentPracticeLoyaltyProgramDocument,
  GetCurrentPracticeLoyaltyProgramQuery,
  GetCurrentPracticeLoyaltyProgramQueryVariables,
  GetPricingDocument,
  GetPricingQuery,
  GetPricingQueryVariables,
  GetLoyaltyProgramsDocument,
  GetLoyaltyProgramsQueryVariables,
} from 'generated/core/graphql';

import store, { RootState } from 'state/store';
import { autoQuery } from 'state/system';
import { transformLoyaltyInfo } from 'pages/PracticeManagement/shared/utils';
import { coreClient } from 'gql/GraphQLProvider';

export type LoyaltyProgramTier = {
  name: string;
  sortOrder: number;
  id: string;
  entryRule: {
    args: { minNumber: number };
    name: 'min_products';
  };
  loyaltyTierProducts: {
    sku: string;
    discount: number;
  }[];
};

export type EnrolledLoyaltyProgram = {
  practiceLoyaltyStatusId: string;
  caseCount: number;
  evaluationPeriodEnd: string;
  programStart: string;
  programEnd: string;
  loyaltyProgram: {
    name: string;
    interval: number;
    currentTier: {
      id: string;
      sortOrder: number;
      name: string;
    };
    tiersInProgram: LoyaltyProgramTier[];
  };
};

type EnrichedPractice = Practice & {
  enrolledLoyaltyProgram?: EnrolledLoyaltyProgram | undefined;
};

type PracticeManagementState = {
  selectedPractice: EnrichedPractice | null;
  practices: EnrichedPractice[];
  contractRates: GetPricingQuery['getContractRates'];
  userEmails: {
    [key: string]: string | null | undefined;
  };
  loyaltyPrograms: LoyaltyProgramTier[];
};

const initialState: PracticeManagementState = {
  selectedPractice: null,
  practices: [],
  contractRates: [],
  userEmails: {},
  loyaltyPrograms: [],
};

export const fetchThinPractices =
  autoQuery.createQueryAction<GetThinPracticesQueryVariables>(
    'practiceManagement/getPractices',
    GetThinPracticesDocument,
    'practiceManagement/setPractices'
  );

export const fetchPractice =
  autoQuery.createQueryAction<GetPracticeQueryVariables>(
    'practiceManagement/getPractice',
    GetPracticeDocument,
    'practiceManagement/setSelectedPractice'
  );

//switching from autoQuery to createAsyncThunk to fetch the loyalty status.
//this is a fix for a bug related to autoQuery hanging in pending
//state when the query errors out.
//(which indicates the practice has no loyalty program/tier)
export const fetchPracticeLoyaltyStatus = createAsyncThunk(
  'practiceManagement/getPracticeLoyaltyStatus',
  async (variables: { practiceId: string }) => {
    try {
      const { data } = await coreClient.query<
        GetCurrentPracticeLoyaltyProgramQuery,
        GetCurrentPracticeLoyaltyProgramQueryVariables
      >({
        query: GetCurrentPracticeLoyaltyProgramDocument,
        variables,
      });
      store.dispatch(setSelectedPracticeLoyaltyStatus(data));
    } catch (error) {
      console.error(error);
      store.dispatch(setSelectedPracticeLoyaltyStatus(undefined));
    }
  }
);

export const fetchContractRates =
  autoQuery.createQueryAction<GetPricingQueryVariables>(
    'practiceManagement/getContractRates',
    GetPricingDocument,
    'practiceManagement/setContractRates',
    coreClient
  );

export const fetchUserEmails =
  autoQuery.createQueryAction<GetUserQueryVariables>(
    'practiceManagement/getUserEmail',
    GetUserDocument,
    'practiceManagement/setUserEmail'
  );

export const fetchLoyaltyPrograms =
  autoQuery.createQueryAction<GetLoyaltyProgramsQueryVariables>(
    'practiceManagement/getLoyaltyPrograms',
    GetLoyaltyProgramsDocument,
    'practiceManagement/setLoyaltyPrograms',
    coreClient
  );

const practiceManagementSlice = createSlice({
  name: 'practiceManagement',
  initialState,
  reducers: {
    reset: () => initialState,
    setPractices: (state, action) => {
      const { practices } = action.payload;
      state.practices = practices;
    },
    setSelectedPractice: (
      state,
      action: PayloadAction<{ practice: Practice }>
    ) => {
      if (
        state.selectedPractice === null ||
        state.selectedPractice.id !== action.payload.practice.id
        // don't merge if the id is different
      ) {
        state.selectedPractice = action.payload.practice;
      } else {
        state.selectedPractice = {
          ...state.selectedPractice,
          ...action.payload.practice,
        };
      }
    },
    setSelectedPracticeLoyaltyStatus: (
      state,
      action: PayloadAction<GetCurrentPracticeLoyaltyProgramQuery | undefined>
    ) => {
      if (state.selectedPractice) {
        state.selectedPractice.enrolledLoyaltyProgram = transformLoyaltyInfo(
          action.payload?.getCurrentPracticeLoyaltyProgram
        );
      }
    },
    setContractRates: (state, action) => {
      state.contractRates = action.payload.getContractRates;
    },
    setUserEmail: (state, action: PayloadAction<GetUserQuery>) => {
      if (action.payload.user) {
        state.userEmails[action.payload.user.id] = action.payload.user?.email;
      }
    },
    setLoyaltyPrograms: (
      state,
      action: PayloadAction<GetLoyaltyProgramsQueryVariables>
    ) => {
      state.loyaltyPrograms = action.payload.getLoyaltyPrograms;
    },
  },
});

export const {
  setSelectedPractice,
  setSelectedPracticeLoyaltyStatus,
  setContractRates,
  setUserEmail,
  setLoyaltyPrograms,
} = practiceManagementSlice.actions;

// selectors
export const getSelectedPractice = (state: RootState) =>
  state.practiceManagement.selectedPractice;
export const getSelectedPracticeLoyaltyStatus = (state: RootState) =>
  state.practiceManagement.selectedPractice?.enrolledLoyaltyProgram;
export const selectAllPractices = (state: RootState) =>
  state.practiceManagement.practices;
export const getContractRates = (state: RootState) =>
  state.practiceManagement.contractRates;
export const getUserEmails = (state: RootState) =>
  state.practiceManagement.userEmails;
export const getLoyaltyPrograms = (state: RootState) =>
  state.practiceManagement.loyaltyPrograms;

export default practiceManagementSlice.reducer;
