import React, { Fragment, useState } from 'react';

import theme from 'core/styles/theme';

import { Tab, TabContainer, TabContent } from 'core/components/Tabs/Tabs.css';

export type TabItem = {
  content: React.ReactNode;
  id: string;
  isHidden?: boolean;
  name?: string;
};

export type TabsProps = {
  contentWrapper?: React.ComponentType<NonNullable<unknown>>;
  defaultTabId?: string;
  onTabChange?: (tabId: string) => void;
  disabledBreakpoint?: keyof typeof theme.mediaQueries;
  hasBottomMargin?: boolean;
  items: TabItem[];
};

const Tabs = ({
  contentWrapper,
  defaultTabId,
  onTabChange,
  disabledBreakpoint,
  hasBottomMargin = false,
  items = [],
}: TabsProps) => {
  const [firstTab = {} as TabItem] = items;
  const [activeTabId, setActiveTabId] = useState(defaultTabId || firstTab.id);
  const Content = contentWrapper;

  const tabContents = items.map(({ content, id }) => (
    <TabContent
      key={id}
      disabledBreakpoint={disabledBreakpoint}
      isVisible={id === activeTabId}
    >
      {content}
    </TabContent>
  ));

  return (
    <Fragment>
      <TabContainer
        disabledBreakpoint={disabledBreakpoint}
        hasBottomMargin={hasBottomMargin}
      >
        {items.map(({ id, name, isHidden }) => (
          <Tab
            key={id}
            isActive={id === activeTabId}
            isHidden={isHidden}
            onClick={() => {
              setActiveTabId(id);
              onTabChange?.(id);
            }}
            type="button"
            // default type is 'submit' which causes form submission
          >
            {name}
          </Tab>
        ))}
      </TabContainer>
      {Content ? <Content>{tabContents}</Content> : tabContents}
    </Fragment>
  );
};

export default Tabs;
