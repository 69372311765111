import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { mediaQueries } from 'core/components';
import ToothSvg from 'assets/icons/small/tooth.svg?react';

export const ProtocolRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  > button {
    width: 50%;
  }
`;

export const Spacer = styled.div`
  margin-bottom: 36px;
`;

export const CardContainer = styled.div<{
  isSelected: boolean;
  anySelected: boolean;
  disabled: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 24px;
  border-radius: 8px;
  border: 2px solid
    ${({ isSelected, theme }) =>
      isSelected ? theme.colors.blue50 : theme.colors.text40};
  cursor: pointer;
  opacity: ${({ anySelected, isSelected }) =>
    isSelected || !anySelected ? 1 : 0.5};
  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.colors.black10};
    `}

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
        position:relative;
        box-shadow: 0px 2px 8px 0px rgba(0,0,0,.1);

        :after {
            content:'';
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 0;
            height: 0;
            border-top: 10px solid ${theme.colors.blue50};
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
        }
}`}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  @media ${mediaQueries.tabletAndAbove} {
    flex-direction: row;
  }
`;

export const Header = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const Body = styled.div`
  display: none;
  font-size: ${({ theme }) => theme.fontSizes.small};

  @media ${mediaQueries.tabletAndAbove} {
    display: block;
  }
`;

export const OuterCircle = styled.div`
  background: #0452c74d;
  border-radius: 40px;
  width: 36px;
  height: 36px;
`;

export const Circle = styled.div`
  background: ${({ theme }) => theme.colors.blue50};
  width: 32px;
  height: 32px;
  border-radius: 40px;
  text-align: center;
  margin-top: 2px;
  margin-left: 2px;

  > svg {
    min-width: 20px;
    min-height: 20px;
    margin-top: 6px;
  }
`;

export const FormWrapper = styled.div`
  margin-bottom: 16px;
`;

export const WhiteToothIcon = styled(ToothSvg)`
  path {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;
