import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { DoctorPreferencesInput } from 'generated/legacy/graphql';
import api from 'state/api';

import { StyledSnackbar } from 'pages/AccountPreferencesPage/AccountPreferencesView.css';

const AutoSaveForm = ({
  userId,
  initialValues,
}: {
  userId: number;
  initialValues: DoctorPreferencesInput;
}) => {
  const { values, dirty, resetForm } =
    useFormikContext<DoctorPreferencesInput>();

  const [setAccountPreferences, { data: accountPreferenceData }] =
    api.useSetAccountPreferencesMutation();

  useEffect(() => {
    if (accountPreferenceData?.user?.accountPreferences?.doctor) {
      resetForm({
        values: accountPreferenceData.user.accountPreferences.doctor,
      });
      setIsSnackbarOpen(true);
    }
  }, [accountPreferenceData]);

  useEffect(() => {
    if (dirty) {
      setAccountPreferences({
        input: {
          userId,
          preferences: {
            doctor: {
              ...initialValues,
              ...values,
            },
          },
        },
      });
    }
  }, [values]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  return (
    <StyledSnackbar
      open={isSnackbarOpen}
      message="Preferences updated"
      autoHideDuration={2000}
      onClose={() => setIsSnackbarOpen(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    />
  );
};

export default AutoSaveForm;
