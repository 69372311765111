import styled from 'styled-components/macro';
import { type } from 'core/components';
import RejectedIconRaw from 'assets/danger-icon.svg?react';
import ActionNeededIconRaw from 'assets/warning-icon.svg?react';

export const AlertTitle = styled(type.BodySmall)`
  padding-top: 5px;
  margin-bottom: 2px;
  font-family: 'Modern Era';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
`;

export const RejectedIcon = styled(RejectedIconRaw)`
  margin-right: 18px;
`;

export const ActionNeededIcon = styled(ActionNeededIconRaw)`
  margin-right: 18px;
`;

export const AlertContent = styled(type.BodySmall)`
  margin-bottom: 8px;
  font-family: 'Modern Era';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  max-width: 500px;
  color: rgba(0, 0, 0, 0.87);
`;

export const ActionLink = styled.div`
  cursor: pointer;
  font-family: 'Modern Era';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  :hover {
    text-decoration-line: underline;
    color: rgba(0, 0, 0, 0.87);
  }
`;

export const Container = styled.div`
  padding-top: 4px;
`;
