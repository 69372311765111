import styled, { css } from 'styled-components';
import colors from 'core/styles/colors';
import mediaQueries from 'core/styles/mediaQueries';

const family = {
  modernEra: `'Modern Era'`,
  modernEraMono: `'Modern Era Mono'`,
  sansSerifStack: `'Helvetica Neue', Helvetica, Arial, sans-serif`,
};

const size = {
  t0: '0.625rem',
  t1: '0.875rem',
  t2: '1rem',
  t3: '1.25rem',
  t4: '1.5rem',
  t5: '2rem',
  t6: '3rem',
  t7: '4rem',
  t8: '6rem',
  t9: '8rem',

  // @TODO deprecate
  default: '1rem',
  micro: '0.625rem',
  tiny: '0.75rem',
  small: '0.875rem',
  medium: '1.25rem',
  large: '1.5rem',
  larger: '2rem',
  largest: '2.5rem',
};

const weight = {
  default: '400',
  medium: '500',
  bold: '700',
};

const lineHeight = {
  default: '1.5',
  body: '1.6',
  bodySmall: '1.7',
  heading: '1.2',
  button: '1.3125',
};

const H1 = styled.h1`
  ${({ theme }) => theme.text.headings.h1};
`;

const H2 = styled.h2`
  ${({ theme }) => theme.text.headings.h2};
`;

const H3 = styled.h3`
  ${({ theme }) => theme.text.headings.h3};
`;

const H4 = styled.h4`
  ${({ theme }) => theme.text.headings.h4};
`;

const H5 = styled.h5`
  ${({ theme }) => theme.text.headings.h5};
`;

const H6 = styled.h6`
  ${({ theme }) => theme.text.headings.h6};
`;

const Overline = styled.h6`
  ${({ theme }) => theme.text.overline};
`;

const MH1 = styled.h1`
  font-size: ${size.t9};
  font-weight: ${weight.bold};
  line-height: ${lineHeight.heading};
`;

const MH2 = styled.h2`
  font-size: ${size.t8};
  font-weight: ${weight.bold};
  line-height: ${lineHeight.heading};
`;

const MH3 = styled.h3`
  font-size: ${size.t7};
  font-weight: ${weight.bold};
  line-height: ${lineHeight.heading};
`;

const Body = styled.p`
  ${({ theme }) => theme.text.body.default};
`;

const BodyLarge = styled.p`
  ${({ theme }) => theme.text.body.large};
`;

const BodySmall = styled.p`
  ${({ theme }) => theme.text.body.small};
`;

const BodyMicro = styled.p`
  ${({ theme }) => theme.text.body.micro};
`;

const Link = styled.a`
  ${({ theme }) => theme.text.links.default};
`;

const SecondaryLink = styled.a`
  ${({ theme }) => theme.text.links.secondary};
`;

// @TODO deprecate
const heading = {
  h2: css`
    font-size: ${size.large};
    font-weight: ${weight.bold};
    line-height: ${lineHeight.heading};

    @media ${mediaQueries.tabletAndAbove} {
      font-size: ${size.larger};
    }
  `,
  h3: css`
    font-size: ${size.medium};
    font-weight: ${weight.bold};
    line-height: ${lineHeight.default};
  `,
  h4: css`
    font-size: ${size.default};
    font-weight: ${weight.bold};
    line-height: ${lineHeight.default};
  `,
};

const links = {
  default: css`
    font-weight: ${weight.bold};
    color: ${colors.blue50};
    text-decoration: none;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: ${colors.blue70};
    }
  `,
  secondary: css`
    font-weight: ${weight.medium};
    color: ${colors.black90};
    text-decoration: underline;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: ${colors.black90};
      text-decoration: none;
    }
  `,
};

export default {
  family,
  MH1,
  MH2,
  MH3,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Overline,
  Body,
  BodyLarge,
  BodySmall,
  BodyMicro,
  Link,
  SecondaryLink,
  heading,
  lineHeight,
  links,
  size,
  weight,
};
