import { css } from 'styled-components';
import { colors } from 'core/components';
import plusCirclePath from 'assets/dashed-circle-plus.svg';

export const evaluationIndicator = css`
  position: relative;

  &::after {
    position: absolute;
    bottom: -0.25rem;
    left: -0.25rem;
    display: none;
    width: 0.75rem;
    height: 0.75rem;
    content: '';
    border-radius: 50%;
    box-shadow: 0 1px 0.125rem 0 rgba(0, 0, 0, 0.1);

    ${({ isApproved }) =>
      isApproved &&
      css`
        display: block;
        background: #7dc2a4;
        border: 1px solid #67a389;
      `};

    ${({ isRejected }) =>
      isRejected &&
      css`
        display: block;
        background: #e68099;
        border: 1px solid #d07188;
      `};
  }
`;

export const baseimgThumbStyles = css`
  border-radius: 0.125rem;
  box-shadow: ${({ isSelected }) =>
    isSelected ? `0 0 0 0.125rem ${colors.blue}` : '0 0 0 1px #dbdbdb'};

  ${({ photo }) =>
    photo &&
    css`
      background-image: url(${photo});
      background-size: cover;
    `};
`;

export const imageThumbStyles = css`
  ${evaluationIndicator};
  background: ${colors.black10} url(${plusCirclePath}) no-repeat center;
  ${baseimgThumbStyles};
`;
