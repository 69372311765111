import React from 'react';
import { AlertCard, Button } from 'core/components';
import moment from 'moment';
import {
  MaterialBannerWrapper,
  MaterialBannerSubmitedDate,
} from 'pages/Patient/styles.css';

type EditBannerProps = {
  handleEdit: () => void;
  materialTypeLabel: string;
  submitedDateTime: string;
  submitedBy?: string | null;
  allowEdit?: boolean;
};

/**
 * Used when materials have been submitted and another material set requires clinician action
 */
export const MaterialEditBanner = ({
  handleEdit,
  materialTypeLabel,
  submitedDateTime,
  submitedBy,
  allowEdit = false,
}: EditBannerProps) => {
  const submitedMoment = moment(submitedDateTime).local();

  const subText = submitedBy
    ? `At ${submitedMoment.format('LT')}, by ${submitedBy}`
    : `At ${submitedMoment.format('LT')}`;

  return (
    <MaterialBannerWrapper>
      <AlertCard
        header={`${materialTypeLabel} submitted on ${submitedMoment.format(
          'LL'
        )}`}
        type="action"
        actionContent={
          allowEdit ? (
            <Button buttonType="black-outline" onClick={handleEdit}>
              Edit
            </Button>
          ) : null
        }
      >
        <MaterialBannerSubmitedDate>{subText}</MaterialBannerSubmitedDate>
      </AlertCard>
    </MaterialBannerWrapper>
  );
};
