import { Problem } from 'components/QualityTicketModal/types';

export const QualityTicketMappings: Problem[] = [
  {
    header: 'Product',
    subCategories: [
      {
        options: [
          {
            label: 'Aligner Fit, Step 1-2',
            sfValue: 'Aligner Fit, Step 1-2',
          },
          {
            label: 'Aligner Fit, Step 3+',
            sfValue: 'Aligner Fit, Step 3+',
          },
          {
            label: 'Incorrect serial number',
            sfValue: 'Incorrect serial number',
          },

          {
            label: 'Retainer does not fit',
            sfValue: 'Retainer does not fit',
          },
          {
            label: 'Visible issue with product',
            sfValue: 'Visible issue with product',
          },
        ],
      },
    ],
  },
  {
    header: 'Clinical',
    subCategories: [
      {
        options: [
          {
            label: 'Allergic Reaction',
            sfValue: 'Allergic Reaction',
          },
          {
            label: 'Change in dentition',
            sfValue: 'Change in dentition',
          },
          {
            label: 'Soft tissue concerns',
            sfValue: 'Soft tissue concerns',
          },
          {
            label: 'Other clinical issue',
            sfValue: 'Other clinical issue',
          },
        ],
      },
    ],
  },
  {
    header: 'Kit and Accessories',
    subCategories: [
      {
        options: [
          {
            label: 'Damaged or expired accessory',
            sfValue: 'Damaged or expired accessory',
          },
          {
            label: 'Missing or extra items',
            sfValue: 'Missing or extra items',
          },
          {
            label: 'Label mismatch',
            sfValue: 'Label mismatch',
          },
          {
            label: 'Other aligner kit issue',
            sfValue: 'Other aligner kit issue',
          },
        ],
      },
    ],
  },
  {
    header: 'Shipping',
    subCategories: [
      {
        options: [
          {
            label: 'Kit damaged upon arrival',
            sfValue: 'Kit damaged upon arrival',
          },
          {
            label: 'Issue with shipment',
            sfValue: 'Issue with shipment',
          },
        ],
      },
    ],
  },
];
