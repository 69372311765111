import React, { Fragment, useState, useEffect } from 'react';
import { FieldArray, useFormikContext } from 'formik';

import {
  FormikCheckboxWrapper,
  FormikInputWrapper,
  FormikRichTextEditorWrapper,
  FormikSelectWrapper,
} from 'components/FormikForms';

import CloseSVG from 'assets/close.svg?react';

import { AnswerType, QuestionFormikProps } from 'pages/IntakeForms/types';

import {
  FormContainer,
  ActionButtons,
  ChoiceRow,
  ChoiceHeader,
  ConditionsWrap,
  PrimaryButtons,
  QuestionTypeSelect,
  SaveButton,
  TextArea,
  TextButton,
  DeleteButton,
  AddAnotherButton,
  PlusIcon,
} from 'pages/IntakeForms/QuestionModal/QuestionForm.css';
import {
  ConsentTypes,
  QuestionTypes,
  FormMetaChoices,
} from 'generated/core/graphql';
import {
  convertEnumToFormikOptions,
  alphaNumericUnderscoreValidator,
} from 'components/FormikForms/utils';
import { colors, type } from 'core/components';

type QuestionFormProps = {
  handleCloseModal: () => void;
  handleDelete: (resetForm: () => void, callback: () => void) => void;
  isEditing: boolean;
  position: number;
  questionKeyExist: boolean;
};

const questionTypeOptions = [
  {
    value: QuestionTypes.Boolean,
    displayValue: 'Yes/No question',
  },
  {
    value: QuestionTypes.Text,
    displayValue: 'Open response',
  },
  {
    value: QuestionTypes.Date,
    displayValue: 'Date response',
  },
  {
    value: QuestionTypes.Choice,
    displayValue: 'Multiple choice',
  },
  { value: QuestionTypes.ToothChart, displayValue: 'Tooth chart' },
];

const answerTypeOptions = [
  {
    value: AnswerType.YES,
    displayValue: 'Yes',
  },
  {
    value: AnswerType.NO,
    displayValue: 'No',
  },
];

const ConsentTypesOptions = [
  {
    value: ConsentTypes.NonStandard,
    displayValue: 'Non-standard',
  },
  {
    value: ConsentTypes.CouldNotTreat,
    displayValue: 'CNT',
  },
];

const consentOptions = convertEnumToFormikOptions(ConsentTypes);

const defaultChoices: FormMetaChoices[] = [
  {
    choice: '',
    consentType: ConsentTypes.Na,
  },
  {
    choice: '',
    consentType: ConsentTypes.Na,
  },
];

const QuestionForm = ({
  handleCloseModal,
  handleDelete,
  isEditing,
  position,
  questionKeyExist,
}: QuestionFormProps) => {
  const { dirty, handleSubmit, resetForm, values, setValues } =
    useFormikContext<QuestionFormikProps>();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const isBoolean = values['question-type'] === QuestionTypes.Boolean;
  const isDateType = values['question-type'] === QuestionTypes.Date;
  const isMultipleChoice = values['question-type'] === QuestionTypes.Choice;

  useEffect(() => {
    if (!isMultipleChoice) {
      setValues({ ...values, choices: undefined });
    } else if (isMultipleChoice && !values?.choices) {
      setValues({ ...values, choices: defaultChoices });
    }
  }, [isMultipleChoice]);

  const hasCondition =
    !!values['answer-type-condition'] || !!values['consent-type-condition'];
  const hasCNTCondition =
    values['consent-type-condition'] === ConsentTypes.CouldNotTreat;
  const isExplanationRequired = values['require-explanation'];

  const closeModal = () => {
    handleCloseModal();
    setShowConfirmDelete(false);
    resetForm();
  };

  const handleClickCancel = () => {
    if (dirty) {
      if (window.confirm('You will lose any unsaved changes')) {
        closeModal();
      }
    } else {
      closeModal();
    }
  };

  if (showConfirmDelete) {
    return (
      <FormContainer isConfirmView>
        <type.H5>Are you sure?</type.H5>
        <p>Confirm if you want to delete this question.</p>
        <ActionButtons>
          <PrimaryButtons>
            <TextButton
              onClick={() => setShowConfirmDelete(false)}
              type="button"
            >
              Cancel
            </TextButton>
            <SaveButton
              buttonType="secondary"
              isShort
              onClick={() =>
                handleDelete(resetForm, () => setShowConfirmDelete(false))
              }
            >
              Confirm
            </SaveButton>
          </PrimaryButtons>
        </ActionButtons>
      </FormContainer>
    );
  }

  return (
    <FormContainer onSubmit={handleSubmit}>
      <type.H5>{isEditing ? 'Editing a question' : 'New question'}</type.H5>
      <QuestionTypeSelect
        label="Question type"
        name="question-type"
        options={questionTypeOptions}
        type="select"
      />
      <FormikInputWrapper
        label="Question key"
        name="question-key"
        placeholder="key"
        type="text"
        disabled={questionKeyExist}
        validate={(value) => alphaNumericUnderscoreValidator(value, true)}
      />
      <FormikRichTextEditorWrapper
        label="Question*"
        name="question-text"
        placeholder="Text"
        type="textArea"
      />
      <FormikInputWrapper
        label="Tooltip"
        name="question-tooltip"
        type="text"
        placeholder="A tip for the patient"
      />
      {isDateType && (
        <FormikInputWrapper
          label="Date Format*"
          name="date-format"
          placeholder="ex. MM/YYYY or DD-MM-YYYY"
          type="text"
        />
      )}
      {isMultipleChoice && (
        <div>
          <FieldArray name="choices">
            {({ remove, push }) => (
              <>
                <ChoiceHeader>
                  <div>Answer choices</div>
                  <div>Consent type</div>
                </ChoiceHeader>
                {values?.choices?.map((_choice, idx) => {
                  //There must always be at least two options, so ensure the first 2 rows can't be deleted
                  const showDelete = idx > 1;
                  return (
                    <ChoiceRow>
                      <FormikInputWrapper
                        name={`choices[${idx}].choice`}
                        placeholder={`option ${idx + 1}`}
                        type="text"
                      />
                      <FormikSelectWrapper
                        type="select"
                        options={consentOptions}
                        name={`choices[${idx}].consentType`}
                      />
                      {showDelete && (
                        <DeleteButton type="button" onClick={() => remove(idx)}>
                          <CloseSVG fill={colors.black70} />
                        </DeleteButton>
                      )}
                    </ChoiceRow>
                  );
                })}
                <AddAnotherButton
                  type="button"
                  onClick={() =>
                    push({ choice: '', consentType: ConsentTypes.Na })
                  }
                >
                  <PlusIcon />
                  <div>Add another</div>
                </AddAnotherButton>
              </>
            )}
          </FieldArray>
        </div>
      )}
      {isBoolean && (
        <Fragment>
          <ConditionsWrap>
            <FormikSelectWrapper
              isClearable
              placeholder="–"
              label="If this answer"
              name="answer-type-condition"
              options={answerTypeOptions}
              type="select"
            />
            <FormikSelectWrapper
              isClearable
              placeholder="–"
              label="Then consent is"
              name="consent-type-condition"
              options={ConsentTypesOptions}
              type="select"
            />
          </ConditionsWrap>
          {hasCondition && (
            <Fragment>
              <FormikCheckboxWrapper
                label="Require explanation"
                name="require-explanation"
                type="checkbox"
              />
              {isExplanationRequired && (
                <FormikInputWrapper
                  label="Explanation label*"
                  name="explanation-label"
                  type="text"
                />
              )}
            </Fragment>
          )}
          {hasCNTCondition && (
            <TextArea
              label="Reason for CNT*"
              name="cnt-reason-text"
              placeholder="Text"
              type="textArea"
            />
          )}
        </Fragment>
      )}
      <FormikCheckboxWrapper
        label="This question is required"
        name="is-required"
        type="checkbox"
      />
      {isMultipleChoice && (
        <>
          <FormikCheckboxWrapper
            label="Add question to Account Preferences"
            name="has-default-preference-option"
            type="checkbox"
          />
          * To finish adding a question to Account Preferences, please have eng
          add the question key to DoctorPreferences
        </>
      )}
      {position > 1 && (
        <FormikCheckboxWrapper
          label="Indent this question (present as a follow-up to the previous question)"
          name="is-follow-up-question"
          type="checkbox"
        />
      )}
      <ActionButtons>
        {isEditing && (
          <TextButton
            isDelete
            onClick={() => setShowConfirmDelete(true)}
            type="button"
          >
            Delete
          </TextButton>
        )}
        <PrimaryButtons>
          <TextButton onClick={handleClickCancel} type="button">
            Cancel
          </TextButton>
          <SaveButton buttonType="secondary" isShort type="submit">
            Save
          </SaveButton>
        </PrimaryButtons>
      </ActionButtons>
    </FormContainer>
  );
};

export default QuestionForm;
