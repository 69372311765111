import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import moment from 'moment';

import ArrowLeftSVG from 'assets/arrow-left-small.svg?react';
import ArrowRightSVG from 'assets/arrow-right-small.svg?react';
import FullScreenOpenSVG from 'assets/full-screen-open.svg?react';
import FullScreenCloseSVG from 'assets/full-screen-close.svg?react';
import { HelpTooltip } from 'components/HelpTooltip';
import { isUpToDate } from 'utils/materials';

import {
  ActionBar,
  ActionButton,
  FullScreenContainer,
  FullScreenContent,
  Image,
  ImageContainer,
  ImageContainerWrapper,
  Overline,
  Thumbnail,
  Thumbnails,
  TipContainer,
  XrayInfo,
} from 'components/XrayGallery/XrayGallery.css';

class XrayGallery extends Component {
  static propTypes = {
    photos: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
      })
    ).isRequired,
  };

  state = {
    currentPhotoIndex: 0,
    isFullScreen: false,
    isZooming: false,
    zoomHeight: null,
    zoomWidth: null,
  };

  componentDidMount() {
    this.imageEl.addEventListener('click', this.toggleZoom);
    this.onResize = debounce(this.handleResize, 200);
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    this.containerEl.removeEventListener('mousemove', this.panImage);
    this.imageEl.removeEventListener('click', this.toggleZoom);
    window.removeEventListener('resize', this.onResize);
  }

  panImage = (e) => {
    const { width, height, top, left } = this.containerRect;
    const { offsetWidth: imgWidth, offsetHeight: imgHeight } = this.imageEl;
    let x = (width - imgWidth) / 2;
    let y = (height - imgHeight) / 2;
    // Only pan horizontally if zoomed image is wider than container
    if (imgWidth > width) {
      const cursorX = e.pageX - left - window.pageXOffset;
      x = -1 * (cursorX / width) * (imgWidth - width);
    }
    // Only pan vertically if zoomed image is taller than container
    if (imgHeight > height) {
      const cursorY = e.pageY - top - window.pageYOffset;
      y = -1 * (cursorY / height) * (imgHeight - height);
    }
    this.imageEl.style.transform = `translate(${x}px,${y}px)`;
  };

  toggleZoom = (e) => {
    if (!this.state.isZooming) {
      const zoomWidth = Math.round(this.imageEl.offsetWidth * 2);
      const zoomHeight = Math.round(this.imageEl.offsetHeight * 2);
      this.containerRect = this.containerEl.getBoundingClientRect();
      this.containerEl.addEventListener('mousemove', this.panImage);
      this.setState(
        {
          isZooming: true,
          zoomWidth,
          zoomHeight,
        },
        () => this.panImage(e)
      );
    } else {
      this.endZoom();
    }
  };

  updatePhoto = (index) => {
    if (this.state.isZooming) {
      this.endZoom();
    }

    this.setState({ currentPhotoIndex: index });
  };

  handleClickPrevPhoto = () => {
    const { photos } = this.props;
    const { currentPhotoIndex } = this.state;
    const lastPhotoIndex = photos.length - 1;
    const prevPhotoIndex =
      currentPhotoIndex === 0 ? lastPhotoIndex : currentPhotoIndex - 1;
    this.updatePhoto(prevPhotoIndex);
  };

  handleClickNextPhoto = () => {
    const { photos } = this.props;
    const { currentPhotoIndex } = this.state;
    const lastPhotoIndex = photos.length - 1;
    const nextPhotoIndex =
      currentPhotoIndex === lastPhotoIndex ? 0 : currentPhotoIndex + 1;
    this.updatePhoto(nextPhotoIndex);
  };

  handleToggleFullScreen = () => {
    if (this.state.isZooming) {
      this.endZoom();
    }

    this.setState((prevState) => ({ isFullScreen: !prevState.isFullScreen }));
  };

  handleKeyDown = (e) => {
    // Close when ESC key is pressed
    if (this.state.isFullScreen && e.keyCode === 27) {
      this.handleToggleFullScreen();
    }
  };

  handleClickBackdrop = (e) => {
    if (this.state.isFullScreen && e.target === this.backdropEl) {
      e.stopPropagation();
      this.handleToggleFullScreen();
    }
  };

  handleResize = () => {
    if (!this.containerEl || !this.imageEl) {
      return;
    }

    if (this.state.isZooming) {
      this.endZoom();
    }
  };

  endZoom() {
    this.imageEl.style.transform = '';
    this.containerRect = null;
    this.containerEl.removeEventListener('mousemove', this.panImage);
    this.setState({
      isZooming: false,
      zoomWidth: null,
      zoomHeight: null,
    });
  }

  render() {
    const { photos } = this.props;
    const {
      currentPhotoIndex,
      isFullScreen,
      isZooming,
      zoomHeight,
      zoomWidth,
    } = this.state;
    const hasPhotos = photos && photos.length;
    const hasMultiplePhotos = photos && photos.length > 1;
    const currentPhoto = hasPhotos ? photos[currentPhotoIndex] : null;

    if (!currentPhoto) {
      return null;
    }

    const {
      capture_date: xrayTaken,
      created: xrayUploaded,
      captured_within_year_of_submission: xrayUpToDate,
      material_source: source,
    } = currentPhoto;

    return (
      <Fragment>
        <FullScreenContainer
          ref={(el) => {
            this.backdropEl = el;
          }}
          isFullScreen={isFullScreen}
          onClick={this.handleClickBackdrop}
        >
          <FullScreenContent
            isFullScreen={isFullScreen}
            onKeyDown={this.handleKeyDown}
            tabIndex="-1"
          >
            <ActionBar isFullScreen={isFullScreen}>
              {hasMultiplePhotos && (
                <Fragment>
                  <ActionButton
                    onClick={this.handleClickPrevPhoto}
                    title="Previous photo"
                    type="button"
                  >
                    <ArrowLeftSVG role="img" />
                  </ActionButton>
                  <ActionButton
                    onClick={this.handleClickNextPhoto}
                    title="Next photo"
                    type="button"
                  >
                    <ArrowRightSVG role="img" />
                  </ActionButton>
                </Fragment>
              )}
              <ActionButton
                hideOnMobile
                onClick={this.handleToggleFullScreen}
                title={isFullScreen ? 'Close full screen' : 'Open full screen'}
                type="button"
              >
                {isFullScreen ? (
                  <FullScreenCloseSVG role="img" />
                ) : (
                  <FullScreenOpenSVG role="img" />
                )}
              </ActionButton>
            </ActionBar>
            <ImageContainerWrapper isFullScreen={isFullScreen}>
              <ImageContainer
                ref={(el) => {
                  this.containerEl = el;
                }}
              >
                {currentPhoto && (
                  <Image
                    data-private="true"
                    ref={(el) => {
                      this.imageEl = el;
                    }}
                    isZooming={isZooming}
                    url={currentPhoto.url}
                    zoomHeight={zoomHeight}
                    zoomWidth={zoomWidth}
                  />
                )}
              </ImageContainer>
            </ImageContainerWrapper>
            {hasPhotos && (
              <Thumbnails>
                {photos.map((photo, i) => (
                  <Thumbnail
                    key={photo.id}
                    isSelected={photo.id === currentPhoto.id}
                    onClick={() => this.updatePhoto(i)}
                    photo={photo.url}
                  />
                ))}
              </Thumbnails>
            )}
          </FullScreenContent>
        </FullScreenContainer>
        <XrayInfo>
          <li>
            <Overline data-testid="overline-heading">SOURCE</Overline>
            <div>{source.type.label}</div>
          </li>
          <li>
            <Overline data-testid="overline-heading">
              UP TO DATE{' '}
              <TipContainer>
                <HelpTooltip content="Taken within a year of submission and after any dental work" />
              </TipContainer>
            </Overline>
            <div>
              {xrayUpToDate || isUpToDate(xrayUploaded, xrayTaken)
                ? 'Yes'
                : 'No'}
            </div>
          </li>
          <li>
            <Overline data-testid="overline-heading">UPLOADED ON</Overline>
            <div>{moment(xrayUploaded).format('M/D/YY')}</div>
          </li>
        </XrayInfo>
      </Fragment>
    );
  }
}

export default XrayGallery;
