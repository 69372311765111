import styled from 'styled-components/macro';

import VersionPills, {
  useVersionPills as vphook,
} from 'components/VersionPills';

const StyledVersionPills = styled(VersionPills)`
  position: absolute;
  top: -3.5rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    position: relative;
    top: 0;

    button {
      font-size: ${({ theme }) => theme.fontSizes.tiny};
    }
  }
`;

export const useVersionPills = vphook;
export default StyledVersionPills;
