import styled from 'styled-components/macro';
import { type, colors } from 'core/components';

export const Overline = styled(type.Overline)`
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: ${colors.black60};
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  vertical-align: middle;
  line-height: 1rem;
`;
