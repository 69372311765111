import React from 'react';
import { MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables';
import useTheme from 'core/hooks/useTheme';
import { StyledMUIDataTable } from 'core/components/Table/Table.css';

export type TableProps = Omit<MUIDataTableProps, 'title'> & {
  card?: boolean;
  title?: MUIDataTableProps['title'];
};

const Table = (props: TableProps) => {
  const theme = useTheme();
  const { card, options, title, ...rest } = props;

  const elevation = card
    ? theme.elevation.elevation
    : theme.elevation.no_elevation;

  const defaultOptions: MUIDataTableOptions = {
    download: false,
    elevation,
    filter: false,
    filterType: 'checkbox',
    pagination: true,
    print: false,
    search: false,
    viewColumns: false,
  };

  return (
    <StyledMUIDataTable
      title={title}
      card={card ?? false}
      options={{
        ...defaultOptions,
        ...options,
      }}
      {...rest}
    />
  );
};

export default Table;
