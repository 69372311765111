import styled from 'styled-components/macro';
import { mediaQueries, SelectInput, TextInput, type } from 'core/components';
import { Switch } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  align-items: center;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 0;
  }
`;

export const RetainerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
`;

export const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  max-width: 680px;
  width: 100%;
  padding: 0 10px;
`;

export const StyledSelectInput = styled(SelectInput)<{
  $displayPrices?: boolean;
}>`
  width: ${(props) => (props.$displayPrices ? '16rem' : '4rem')};
`;

export const StyleToggle = styled(Switch)`
  & .MuiIconButton-root {
    color: ${({ theme }) => theme.colors.black50};
    opacity: 1;
    &:hover {
      background-color: transparent;
    }
  }
  & .MuiSwitch-thumb {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  & .MuiSwitch-track {
    background-color: ${({ theme }) => theme.colors.text20};
  }

  & .MuiSwitch-switchBase.Mui-checked {
    color: ${({ theme }) => theme.colors.blue50};

    &:hover {
      background-color: transparent;
    }
  }

  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.blue30};
  }
`;

export const Message = styled.span`
  display: none;
  @media ${mediaQueries.tabletAndAbove} {
    display: block;
    color: ${({ theme }) => theme.colors.black90};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;

export const PlaceOrder = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

export const QuestionHeading = styled(type.H4)`
  margin-bottom: 4px;
`;
export const QuestionBody = styled.p`
  color: ${({ theme }) => theme.colors.black70};
  margin-bottom: 16px;
`;

export const CouponTextInput = styled(TextInput)`
  padding-right: 0.5rem;
`;

export const CouponContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const CouponError = styled.div`
  color: ${({ theme }) => theme.colors.red50};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;
