import { AlertCard } from 'core/components';
import React, { useContext } from 'react';
import { ReopenLink, AlertContainer } from 'pages/OrthoPrism/OrthoPrism.css';
import { AuthContext } from 'components/AuthProvider';
import { ACCESS_GROUPS } from 'constants/index';

import {
  RemoveMaterialEvaluationDocument,
  RemoveMaterialEvaluationMutation,
  RemoveMaterialEvaluationMutationVariables,
  MaterialStates,
  CaseSource,
} from 'generated/core/graphql';
import { useGQLMutation } from 'hooks/useGQL';
import { CoreCase } from 'pages/OrthoPrism/orthoSlice';
import { useTransitionJourneyMutation } from 'generated/legacy/graphql';
import { InternalCaseStates } from 'constants/Case';
import { JourneyTransition } from 'pages/Case/types';

type Props = {
  materialName: string;
  evaluationSetId: string;
  onReverse: () => void;
  selectedCase: CoreCase;
};

const MaterialApprovedAlertCard = ({
  materialName,
  evaluationSetId,
  onReverse,
  selectedCase,
}: Props) => {
  const [submitReverseApproval] = useGQLMutation<
    RemoveMaterialEvaluationMutation,
    RemoveMaterialEvaluationMutationVariables
  >(RemoveMaterialEvaluationDocument);

  const [transitionJourney] = useTransitionJourneyMutation();

  const { checkHasAccess } = useContext(AuthContext);
  const inMaterialReviewGroup = checkHasAccess(ACCESS_GROUPS.MATERIAL_REVIEW);
  const allowReversal =
    inMaterialReviewGroup &&
    selectedCase?.isActive &&
    selectedCase?.caseState?.treatmentPlanStaging?.state !==
      MaterialStates.Completed;

  const reverseApproval = async () => {
    //If all materials are approved
    await submitReverseApproval({
      evaluationSetId,
    });

    // instead of moving this transition to the backend, we're doing it here
    // because case service is deprecated in favor of core
    // NOTE: if we migrate cases to core and decide to keep 'source' we should
    // make sure to check the feature flag here as well
    if (selectedCase?.source === CaseSource.CaseService) {
      const [alignerLeg, transition] =
        materialName === 'scan'
          ? ['scans_aligner_leg', 'force_scans_uploaded']
          : ['xrays_aligner_leg', 'force_x-rays_uploaded'];

      await transitionJourney({
        variables: {
          caseRef: selectedCase?.caseRef!,
          component: alignerLeg,
          transition,
          transitionReason: 'unapprove',
        },
      });

      if (
        selectedCase?.caseState?.internal ===
        InternalCaseStates.TREATMENT_PLANNING_IN_PROGRESS
      ) {
        await transitionJourney({
          variables: {
            caseRef: selectedCase?.caseRef!,
            component: 'production_aligner_leg',
            transition: JourneyTransition.ForceReadyForTreatmentPlanning,
            transitionReason: 'materials need re review',
          },
        });
      }
    }
    onReverse();
  };

  return (
    <AlertContainer>
      <AlertCard type="success" header="Submission Approved" displayIcon={true}>
        {allowReversal && (
          <ReopenLink onClick={reverseApproval}>
            Re-open {materialName} review process
          </ReopenLink>
        )}
      </AlertCard>
    </AlertContainer>
  );
};

export default MaterialApprovedAlertCard;
