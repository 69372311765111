import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Loading } from 'core/components';

import CandidTable from 'components/CandidTable';
import DocumentTitle from 'components/DocumentTitle';
import { PageSection } from 'styles/layout.css';

import { IntakeFormsContext } from 'pages/IntakeForms/IntakeFormsContext';
import {
  FormsTable,
  HeaderRow,
  Heading,
  TitleLink,
} from 'pages/IntakeForms/IntakeForms.css';

const IntakeFormsOverview = ({ match }: RouteComponentProps) => {
  const { formsData } = useContext(IntakeFormsContext);

  return (
    <DocumentTitle title="Intake forms">
      {!formsData ? (
        <Loading isCentered />
      ) : (
        <PageSection isNarrow>
          <Heading>Intake forms</Heading>
          <FormsTable>
            <CandidTable.Header>
              <HeaderRow onClick={null}>
                <CandidTable.HeaderCell>Form title</CandidTable.HeaderCell>
                <CandidTable.HeaderCell>Material type</CandidTable.HeaderCell>
                <CandidTable.HeaderCell>Form Type</CandidTable.HeaderCell>
              </HeaderRow>
            </CandidTable.Header>
            <CandidTable.Body>
              {formsData.map((form, formIndex) => {
                const formType = form.beta ? 'beta' : 'standard';
                return form?.formSchema?.map((section, sectionIndex) => (
                  <CandidTable.Row key={`${formIndex}_${sectionIndex}`}>
                    <CandidTable.BodyCell>
                      <TitleLink
                        to={`${match.path}/${form.materialType.name}/${sectionIndex}/${formType}`}
                      >
                        {section?.label}
                      </TitleLink>
                    </CandidTable.BodyCell>
                    <CandidTable.BodyCell>
                      {form.materialType.name}
                    </CandidTable.BodyCell>
                    <CandidTable.BodyCell>
                      {form.beta ? 'Beta' : 'Standard'}
                    </CandidTable.BodyCell>
                  </CandidTable.Row>
                ));
              })}
            </CandidTable.Body>
          </FormsTable>
        </PageSection>
      )}
    </DocumentTitle>
  );
};

export default IntakeFormsOverview;
