import { Material, Submission } from 'generated/core/graphql';

import { Sort, sortByCreated } from 'utils/prism';

/**
 * NOTE: This is kind of hacky since we have to copy and paste the exact method from orthoSlice
 * instead of importing. This is due to some complications with the types which makes this a lot
 * easier. TODO: tdror refactor this when we update the types for core materials
 */
type SubmissionMap = {
  [key: string]: Submission;
};
export const getSubmissionsFromMaterials = (materials: Material[]) => {
  const submissionMap: SubmissionMap = {};
  materials.forEach((material) => {
    material.submissions.forEach((submission) => {
      if (!submissionMap[submission.id]) {
        const submissionCopy = JSON.parse(JSON.stringify(submission));
        if (!submission.materials) {
          submissionCopy.materials = [];
        }
        submissionCopy.materials.push(material);
        submissionMap[submission.id] = submissionCopy;
      }
    });
  });
  const submissions = Object.values(submissionMap);
  submissions.sort(sortByCreated(Sort.Desc));
  return submissions;
};
