import styled from 'styled-components/macro';

export const StyledDiv = styled.div`
  display: flex;
  gap: 12px;
  > svg > path {
    stroke: #0452c7;
  }
`;

export const StyledAlertContainer = styled.div`
  margin-bottom: 1.5rem;
`;

export const AlertBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
