import React, { useContext, useEffect, useState } from 'react';
import { Divider, Loading, colors } from 'core/components';
import moment from 'moment';
import LearnMoreIcon from 'assets/ic_information-circle.svg?react';
import ErrorCircleIcon from 'assets/red-exclamation-circle.svg?react';
import { displayLoyaltyPricing } from 'pages/LoyaltyProviderView/utilities';
import {
  Container,
  CurrentTierContainer,
  CurrentTierLeft,
  CurrentTierTitle,
  Header,
  NextCycleSubText,
  NextCycleText,
  PracticeSelect,
  Price,
  HeaderContainer,
  LearnMoreLink,
  LearnMoreContainer,
  CurrentTierHeader,
  CurrentTierUntil,
} from 'pages/LoyaltyProviderView/LoyaltyProviderView.css';
import { AuthContext } from 'components/AuthProvider';
import { useGQLQuery } from 'hooks/useGQL';
import {
  GetCurrentPracticeLoyaltyProgramDocument,
  GetCurrentPracticeLoyaltyProgramQuery,
  GetCurrentPracticeLoyaltyProgramQueryVariables,
  GetPracticeLoyaltyQuotesDocument,
  GetPracticeLoyaltyQuotesQuery,
  GetPracticeLoyaltyQuotesQueryVariables,
} from 'generated/core/graphql';
import { useHistory } from 'react-router-dom';
import { usePartnerOrgs } from 'hooks/usePartnerOrgs';

import TierProgress from 'pages/LoyaltyProviderView/TierProgress';
import { LoyaltyTierInfo, ALIGNER_SKU } from 'constants/loyalty';
import { Practice } from 'generated/legacy/graphql';
import { REFACTOR_ANY } from '@Types/refactor';

type PracticeSelectOption = {
  label: string;
  value: Practice;
};

const LoyaltyProviderView = () => {
  const { userInfo } = useContext(AuthContext);
  const { push } = useHistory();
  const { partnerOrgs } = usePartnerOrgs();
  const hasPartnerOrg = partnerOrgs.length > 0;
  if (hasPartnerOrg) {
    push('/action-items');
  }
  const [selectedPractice, setSelectedPractice] =
    useState<PracticeSelectOption>();
  const [practiceOptions, setPracticeOptions] = useState<
    Array<PracticeSelectOption>
  >([]);

  const [
    getCurrentPracticeLoyaltyProgram,
    {
      data: getCurrentPracticeLoyaltyProgramResult,
      errors: getCurrentPracticeLoyaltyProgramErrors,
      loading: getLoyaltyTierLoading,
    },
  ] = useGQLQuery<
    GetCurrentPracticeLoyaltyProgramQuery,
    GetCurrentPracticeLoyaltyProgramQueryVariables
  >(GetCurrentPracticeLoyaltyProgramDocument);

  const [
    getLoyaltyQuotes,
    {
      data: getLoyaltyQuotesResult,
      errors: getLoyaltyQuotesErrors,
      loading: getLoyaltyQuotesLoading,
    },
  ] = useGQLQuery<
    GetPracticeLoyaltyQuotesQuery,
    GetPracticeLoyaltyQuotesQueryVariables
  >(GetPracticeLoyaltyQuotesDocument);

  useEffect(() => {
    const practice =
      userInfo?.doctor?.practices?.length === 1
        ? userInfo?.doctor?.practices[0]
        : null;

    if (practice) {
      setSelectedPractice({
        value: practice as Practice,
        label: practice.name,
      });
    } else if (userInfo?.doctor?.practices) {
      setPracticeOptions(
        [...(userInfo?.doctor?.practices || {})] //need to copy to sort
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((p) => {
            return { value: p as Practice, label: p.name };
          })
      );
    }
  }, [userInfo]);

  useEffect(() => {
    if (selectedPractice) {
      getCurrentPracticeLoyaltyProgram({
        practiceId: selectedPractice?.value.id,
      });
      // here
      if (!selectedPractice.value.parentOrganization) {
        getLoyaltyQuotes({ practiceId: selectedPractice?.value.id });
      }
    }
  }, [selectedPractice]);

  const onChange = (option: PracticeSelectOption) => {
    setSelectedPractice(option);
  };

  const getValue = () => {
    if (practiceOptions && selectedPractice) {
      return practiceOptions.find((p) => p.value === selectedPractice.value);
    } else {
      return (practiceOptions && practiceOptions[0]?.value) || ('' as string);
    }
  };

  const getSelectWidth = () => {
    // Autosize the select to the practice name width. Else use the minimum of 200
    if (selectedPractice) {
      return 8 * selectedPractice.label.length + 75;
    }
    return 200;
  };

  if (!selectedPractice && practiceOptions[0]) {
    setSelectedPractice(practiceOptions[0]);
  }
  const displayPrice = displayLoyaltyPricing(
    getLoyaltyQuotesResult?.getPracticeLoyaltyQuotes,
    !!selectedPractice?.value
      .parentOrganization as REFACTOR_ANY /* TODO: Casting is a quickfix */
  );

  const caseCount =
    getCurrentPracticeLoyaltyProgramResult?.getCurrentPracticeLoyaltyProgram
      .caseCount ?? 0;
  const endDate = moment(
    getCurrentPracticeLoyaltyProgramResult?.getCurrentPracticeLoyaltyProgram
      .evaluationPeriodEnd
  );
  const formattedEndDate = endDate.format('MMM DD, YYYY');

  const interval =
    getCurrentPracticeLoyaltyProgramResult?.getCurrentPracticeLoyaltyProgram
      .loyaltyProgramTier?.loyaltyProgram?.interval;

  const tiers =
    getCurrentPracticeLoyaltyProgramResult?.getCurrentPracticeLoyaltyProgram
      .loyaltyProgramTier?.loyaltyProgram?.loyaltyProgramTiers;

  const currentTier =
    getCurrentPracticeLoyaltyProgramResult?.getCurrentPracticeLoyaltyProgram
      .loyaltyProgramTier;

  const maxTier = Math.max(
    ...(tiers?.filter((t) => t).map((t) => t.sortOrder) ?? [])
  );

  const minNum = currentTier?.entryRule?.args?.minNumber;
  //True if the patient has reached the current tier
  const hasMetCurrentTier = minNum && caseCount >= minNum;

  const untilDate = hasMetCurrentTier
    ? endDate.add(interval, 'M').format('MMM DD, YYYY')
    : formattedEndDate;

  // the tier based only on the case count
  const nextCycleEarnTier = tiers?.find(
    (t) => caseCount < (t.entryRule?.args?.minNumber ?? 0)
  );

  const currentPrice = getLoyaltyQuotesResult?.getPracticeLoyaltyQuotes
    ?.find((q) => q.tierName === currentTier?.name)
    ?.products?.find((p) => p?.sku === ALIGNER_SKU)?.quote;

  const isDso = !!selectedPractice?.value.parentOrganization;

  // don't know if we have a nullish error, one error or multiple errors, being defensive.
  const errors = [
    getLoyaltyQuotesErrors,
    getCurrentPracticeLoyaltyProgramErrors,
  ]
    .flat()
    .filter((e) => e);

  const isLoading =
    getLoyaltyTierLoading ||
    !getCurrentPracticeLoyaltyProgramResult ||
    (!isDso && (getLoyaltyQuotesLoading || !getLoyaltyQuotesResult));

  const learnMoreLink =
    'https://candidpro.zendesk.com/hc/en-us/articles/10070980195479-Loyalty-Pricing-Tiers';

  return (
    <Container>
      <HeaderContainer>
        <Header>Loyalty program</Header>
        <LearnMoreContainer>
          <LearnMoreLink href={learnMoreLink}>Learn more</LearnMoreLink>
          <LearnMoreIcon stroke={colors.blue50} role="img" />
        </LearnMoreContainer>
      </HeaderContainer>

      {practiceOptions && practiceOptions.length > 0 && (
        <PracticeSelect
          options={practiceOptions}
          onChange={onChange}
          value={getValue()}
          width={getSelectWidth()}
        />
      )}

      {errors.length ? (
        <div
          style={{
            color: '#E00',
            display: 'flex',
          }}
        >
          <ErrorCircleIcon
            style={{ marginTop: '-0.125rem', marginRight: '0.25rem' }}
          />
          <div>
            {`An error occurred: "${errors[0].message}".`}
            <br />
            {`Please try again later or contact support.`}
          </div>
        </div>
      ) : isLoading ? (
        <Loading isCentered></Loading>
      ) : (
        <>
          {!!currentTier && (
            <div>
              <CurrentTierHeader>
                <CurrentTierTitle>Current tier</CurrentTierTitle>
                {currentTier.sortOrder !== 0 && (
                  <CurrentTierUntil>
                    <span>{currentTier.name} until</span>
                    <span> {untilDate}</span>
                  </CurrentTierUntil>
                )}
              </CurrentTierHeader>
              <CurrentTierContainer>
                <CurrentTierLeft>
                  <img
                    src={LoyaltyTierInfo[currentTier.sortOrder].icon}
                    alt=""
                  />
                  <div>{currentTier?.name}</div>
                </CurrentTierLeft>
                {displayPrice && !!currentPrice && (
                  <Price>
                    <div>
                      {(currentPrice / 100).toLocaleString('en-us', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </div>
                    <div>per case</div>
                  </Price>
                )}
              </CurrentTierContainer>
            </div>
          )}

          <Divider />

          <div>
            <NextCycleText>Next cycle progress</NextCycleText>
            <NextCycleSubText>
              <span>You have until </span>
              <span>{formattedEndDate} </span>
              <span>
                to {currentTier?.sortOrder === maxTier ? 'adjust' : 'upgrade'}{' '}
                your current tier and lock in for the next {interval} month
                {interval === 1 ? '' : 's'}.
              </span>
            </NextCycleSubText>
          </div>

          {!!tiers &&
            tiers
              .filter((t) => t?.entryRule?.args?.minNumber !== 0) //Filter out the base case
              .map((t) => {
                const quoteType =
                  getLoyaltyQuotesResult?.getPracticeLoyaltyQuotes?.find(
                    (q) => q.tierName === t.name
                  );

                const price = displayPrice
                  ? quoteType?.products?.find((p) => p?.sku === ALIGNER_SKU)
                      ?.quote ?? null
                  : null;
                return (
                  <TierProgress
                    name={t.name!}
                    imgPath={LoyaltyTierInfo[t.sortOrder].icon}
                    price={price ?? null}
                    isNextTier={nextCycleEarnTier?.sortOrder === t.sortOrder}
                    caseCount={caseCount}
                    requiredCases={t.entryRule?.args?.minNumber!}
                    earningTowardsMaintainingCurrentTier={
                      nextCycleEarnTier?.name === currentTier?.name
                    }
                  />
                );
              })}
        </>
      )}
    </Container>
  );
};

export default LoyaltyProviderView;
