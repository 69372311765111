import { colors, type, Text, Table } from 'core/components';
import styled from 'styled-components/macro';

export const SaveButton = styled.button`
  color: ${colors.blue50};
  text-decoration: underline;
`;

export const OrderDetailWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 1rem;
  overflow: hidden;
  border: 1px solid ${colors.black20};
  border-radius: 0.5rem;
`;

export const OrderDetailHeader = styled(type.H5)`
  padding: 1rem 1rem;
  background-color: ${colors.blue10};
  border-bottom: 1px solid ${colors.black20};
`;

export const OrderDetailContents = styled.div`
  padding: 1.5rem;
`;

export const OrderDetailFieldTitle = styled(type.Overline)`
  color: ${colors.black60};
`;

export const EditableFieldWrapper = styled.div`
  position: relative;
  cursor: pointer;

  svg {
    position: absolute;
    right: 1rem;
  }

  input {
    width: 100%;

    &:focus {
      border-bottom: 2px solid ${colors.blue50};

      & + svg {
        display: none;
      }
    }
  }
`;

export const OrderDetailFieldContainer = styled.div`
  min-height: 4rem;
`;

export const ShipTo = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const ShippingText = styled(Text)`
  margin: 0rem;
  padding: 0rem;
`;

export const StyledTable = styled(Table)`
  &.MuiTableRow-root.Mui-selected,
  .MuiTableRow-root.Mui-selected {
    background-color: ${({ theme }) => theme.colors.blue10};
    border-left: 3px solid ${({ theme }) => theme.colors.blue50};
  }
  & .MuiTableRow-root.Mui-selected,
  .MuiTableRow-root.Mui-selected:hover {
    background-color: ${({ theme }) => theme.colors.blue10};
  }
  & .MUIDataTable-responsiveBase-9 {
    // TODO: find way to target all .MUIDataTable-responsiveBase
    overflow: visible;
  }
  & .MUIDataTable-responsiveBase-5 {
    overflow: visible;
  }
`;

export const Note = styled(Text)`
  margin-left: 1rem;
`;

export const ContainerBody = styled.div`
  max-width: 90rem;
  min-height: 60rem;
  padding: 2rem 1rem 1rem 1rem;
  > div:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black20};
  width: 100%;
  height: 50px;
}
`;

export const MainText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
export const SubText = styled.span`
  color: ${({ theme }) => theme.colors.black70};
  margin-left: 0.5rem;
`;
