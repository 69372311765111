import styled from 'styled-components';
import { Grid, Button } from 'core/components';
import { FormikSelectWrapper } from 'components/FormikForms';

export const FormContainer = styled.form`
  padding: 1rem 0rem;
`;

export const StyledGrid = styled(Grid)<{ xs?: number }>`
  padding: ${({ xs }) => (xs !== 12 ? '1rem 2rem' : '0rem')};
  overflow: visible;
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 1rem;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)<{ buttonType?: string }>`
  margin: 1rem 2rem;
  ${({ buttonType }) =>
    buttonType === 'tertiary' &&
    'background-color: transparent; opacity: 0.75;'}
`;

export const StyledFormikSelectWrapper = styled(FormikSelectWrapper)`
  overflow: visible;
`;
