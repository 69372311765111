import styled from 'styled-components/macro';
import { css } from 'styled-components';

export const Container = styled.section<{
  hasTools?: boolean;
  isResponsive?: boolean;
}>`
  position: relative;
  min-width: 39rem;
  height: 32rem;
  padding-bottom: 2rem;
  margin-bottom: ${({ hasTools }) => (hasTools ? '4.25rem' : '1.25rem')};

  ${({ isResponsive }) =>
    isResponsive &&
    css`
      min-width: auto;
      height: 0;
      padding-top: 75%;
    `};
`;

export const ViewDefault = styled.div`
  width: 50%;
  svg {
    height: 100%;
    width: 100%;
  }
  #circle {
    display: none;
  }
`;

export const ImageContainer = styled.div<{
  isResponsive?: boolean;
  isZoomable?: boolean;
  isZooming?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.black10};

  ${({ isResponsive }) =>
    isResponsive &&
    css`
      position: absolute;
      top: 0;
      bottom: 2rem;
      left: 0;
      height: auto;
    `};

  .cropper-canvas {
    ${({ isZoomable, isZooming }) =>
      isZoomable &&
      css`
        z-index: 1;
        cursor: ${isZooming ? 'zoom-out' : 'zoom-in'};
      `};
  }
`;

export const Image = styled.img`
  max-height: 100%;
`;
