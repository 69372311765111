import { fetchApi } from 'utils/api';

export const fetchCustomerData = async (id) => {
  try {
    const customerData = await fetchApi(`/api/v1/customers/${id}/`, 'GET');

    return customerData;
  } catch (err) {
    throw new Error('Could not fetch customer data');
  }
};
