import React, { PureComponent } from 'react';
import CloseSVG from 'core/assets/icons/close.svg?react';

import {
  CloseButton,
  Container,
  Content,
  transitionDuration,
} from 'core/components/Modal/Modal.css';

export type ModalProps = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  closeButtonColor?: string;
  closeOnOverlayClick?: boolean;
  hideCloseButton?: boolean;
  hideOverlay?: boolean;
  id?: string;
  isFullScreenOnMobile?: boolean;
  isOpen?: boolean;
  onClose: () => void;
  overlayColor?: string;
  testId?: string;
  zIndex?: number;
};

type ModalState = {
  isVisible: boolean;
};

class Modal extends PureComponent<ModalProps, ModalState> {
  static getDerivedStateFromProps(
    nextProps: ModalProps,
    prevState: ModalState
  ) {
    // If the modal is hidden and we are told to open it, make it visible
    if (!prevState.isVisible && nextProps.isOpen) {
      return { isVisible: true };
    }

    return null;
  }

  state = {
    isVisible: this.props.isOpen || false,
  };

  // Ref defaults
  backdrop: HTMLDivElement | null = null;

  modal: HTMLDivElement | null = null;

  closeButton: HTMLButtonElement | null = null;

  componentDidUpdate(prevProps: ModalProps) {
    // If we are told to open the modal, set focus to it
    if (!prevProps.isOpen && this.props.isOpen && this.modal) {
      this.modal.focus();
    }

    // If we are told to close the modal, wait for the fade-out transition to
    // complete before hiding it
    if (prevProps.isOpen && !this.props.isOpen) {
      window.setTimeout(
        () => this.setState({ isVisible: false }),
        transitionDuration
      );
    }
  }

  handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // Close when ESC key is pressed
    if (e.keyCode === 27) {
      this.props.onClose();
    }
  };

  handleCloseModal = (
    e: React.MouseEvent<HTMLDivElement & HTMLButtonElement>
  ) => {
    if (
      ![this.backdrop, this.closeButton].includes(
        e.target as HTMLDivElement | HTMLButtonElement
      )
    ) {
      return;
    }

    e.stopPropagation();
    this.props.onClose();
  };

  render() {
    const {
      children,
      className,
      closeButtonColor,
      hideCloseButton,
      hideOverlay,
      id,
      overlayColor,
      isFullScreenOnMobile,
      isOpen,
      testId,
      zIndex,
    } = this.props;
    const { isVisible } = this.state;

    return (
      <Container
        ref={(el: HTMLDivElement) => {
          if (this.props.closeOnOverlayClick ?? true) {
            this.backdrop = el;
          }
        }}
        aria-hidden={!isVisible}
        className={className}
        id={id}
        isFullScreenOnMobile={isFullScreenOnMobile}
        isHidden={!isVisible}
        isOpen={isOpen}
        isOverlayHidden={hideOverlay}
        onClick={this.handleCloseModal}
        overlayColor={overlayColor}
        zIndex={zIndex}
      >
        <Content
          ref={(el: HTMLDivElement) => {
            this.modal = el;
          }}
          aria-modal
          isFullScreenOnMobile={isFullScreenOnMobile}
          onKeyDown={this.handleKeyDown}
          role="dialog"
          tabIndex={-1}
        >
          {!hideCloseButton && (
            <CloseButton
              ref={(el: HTMLButtonElement) => {
                this.closeButton = el;
              }}
              color={closeButtonColor}
              data-testid={testId ? `${testId}-close-icon` : null}
              onClick={this.handleCloseModal}
            >
              <CloseSVG aria-hidden />
              <span>Close</span>
            </CloseButton>
          )}
          {children}
        </Content>
      </Container>
    );
  }
}

export default Modal;
