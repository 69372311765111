import styled from 'styled-components';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Tooltip as MuiTooltip } from '@material-ui/core';

export const HelpToolTipIcon = styled(HelpOutlineIcon)`
  color: ${({ theme }) => theme.palette.secondary.main};
  max-width: 1rem;
  position: relative;
  bottom: 1px;
  left: 2px;
`;

export const StyledMuiTooltip = styled(MuiTooltip)`
  &.MuiTooltip-tooltip {
    background-color: ${({ theme }) => theme.colors.black10};
    color: ${({ theme }) => theme.palette.black};
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: ${({ theme }) => theme.fontWeights.default};
    border: 1px solid ${({ theme }) => theme.colors.black30};
    border-radius: 3px;
    box-shadow: ${({ theme }) => `0 0 10px ${theme.colors.black30}`};
  }

  & .MuiTooltip-arrow {
    color: ${({ theme }) => theme.colors.white};
    &::before {
      border-style: ${({ theme }) => theme.palette.border.style};
      border-width: ${({ theme }) => theme.palette.border.width};
    }
  }
`;
