import React from 'react';

import {
  Banner,
  BannerText,
  AlertContainer,
  NavLink,
} from 'components/D2CWarningBanner/D2CWarningBanner.css';

const D2CWarningBanner = () => (
  <AlertContainer>
    <Banner>
      <BannerText>🚨</BannerText>
      <BannerText>
        It looks like you&apos;re trying at access a D2C customer. Tooth Tools
        no longer supports accessing D2C customers or their data.{' '}
        <strong>Please use the D2C Vault site to access D2C customers.</strong>
      </BannerText>
    </Banner>
    <NavLink href={`https://d2cvault.candidco.com/`} target="_blank">
      Go to the D2C Vault site ➡
    </NavLink>
  </AlertContainer>
);

export default D2CWarningBanner;
