import React, { useState, useContext } from 'react';
import { Grid, Loading, NotificationContext } from 'core/components';
import { CaseContext } from 'pages/Case/CaseProvider';
import moment from 'moment';

import PencilSVG from 'assets/pencil.svg?react';
import { PRODUCT_SKUS } from 'constants/index';
import CopyableText from 'components/CopyableText';
import {
  SaveButton,
  OrderDetailWrapper,
  OrderDetailHeader,
  OrderDetailContents,
  OrderDetailFieldTitle,
  EditableFieldWrapper,
  OrderDetailFieldContainer,
  ShipTo,
  ShippingText,
} from 'components/Modals/OrderShippingModal/OrderShipping.css';

import {
  EditableFieldProps,
  OrderDetailFieldProps,
  OrderDetailContainerProps,
} from 'pages/OrthoPrism/types';
import { useSelector } from 'react-redux';
import { selectCustomer } from 'pages/OrthoPrism/orthoSlice';
import { useGQLMutation } from 'hooks/useGQL';
import {
  UpdateShipment,
  UpdateShipmentDocument,
  UpdateShipmentMutationVariables,
} from 'generated/core/graphql';

const EditableField = ({ onChange, value }: EditableFieldProps) => {
  const textInput = React.createRef<HTMLInputElement>();
  const focusInput = () => {
    textInput.current?.focus();
  };

  return (
    <EditableFieldWrapper onClick={focusInput}>
      <input
        ref={textInput}
        onChange={onChange}
        placeholder="--"
        type="text"
        value={value ?? ''}
      />
      <PencilSVG />
    </EditableFieldWrapper>
  );
};

const OrderDetailField = ({
  title,
  value,
  editable,
  copyable = false,
  onChange = () => null,
}: OrderDetailFieldProps) => {
  const displayValue = value || '–';

  return (
    <OrderDetailFieldContainer>
      <OrderDetailFieldTitle>{title}</OrderDetailFieldTitle>
      <div>
        {editable ? (
          <EditableField onChange={onChange} value={displayValue} />
        ) : (
          displayValue
        )}
        {copyable && (
          <CopyableText label="Tracking Number" text={displayValue} />
        )}
      </div>
    </OrderDetailFieldContainer>
  );
};

const getShipToText = (keyword: string) => {
  switch (keyword) {
    case 'patient':
      return 'to patient';
    case 'practice':
      return 'to my practice';
    default:
      return null;
  }
};

const OrderDetailForm = ({
  isInbound,
  shipmentDetail,
  selectedOrderItem,
  internal,
}: any) => {
  const {
    shipped_at,
    delivered_at,
    status,
    carrier,
    tracking_id,
    shipment_ref,
    shipmentRef,
  } = shipmentDetail;
  const { showNotification } = useContext(NotificationContext);
  const [updateShipment] = useGQLMutation<
    UpdateShipment,
    UpdateShipmentMutationVariables
  >(UpdateShipmentDocument);

  const { customerData } = useContext(CaseContext);
  const customerInfo = useSelector(selectCustomer);
  const [tracking_id_value, setTrackingIdValue] = useState(tracking_id);
  const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);
  const customerAddresses = internal
    ? customerData?.addresses
    : customerInfo?.addresses;
  const customerShippingAddress = customerAddresses?.find(
    (a) => a.addressType === 'Shipping Address'
  );

  const shipmentItemData = isInbound ? shipmentDetail : selectedOrderItem;

  const {
    shipping_address: shippingAddress,
    shipping_address_type: shippingAddressType,
  } = shipmentItemData;

  const renderAddressField = () => {
    const name = shippingAddress?.name;
    const addressLines = getAddressLines();
    const shipToText = getShipToText(shippingAddressType);
    return (
      <>
        {!!shippingAddress && (
          <>
            {shipToText && <ShipTo>{`Ship ${shipToText}`}</ShipTo>}
            {name && <ShippingText>{name}</ShippingText>}
          </>
        )}
        <ShippingText>{addressLines['addressLine']}</ShippingText>
        <ShippingText>{addressLines['cityLine']}</ShippingText>
      </>
    );
  };

  /**
   * Updates the tracking number of a shipment
   * @param {string} shipmentRef
   * @param {string} trackingNumber
   */
  const updateShipmentTracking = async (
    shipmentRef: string,
    trackingNumber: string
  ) => {
    try {
      setIsUpdatingOrder(true);
      await updateShipment({
        shipmentId: shipmentRef,
        updates: {
          trackingId: trackingNumber,
        },
      });

      showNotification(
        'Successfully updated the shipment tracking number',
        'success'
      );
      setIsUpdatingOrder(false);
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      setIsUpdatingOrder(false);
      showNotification(err.message, 'error');
    }
  };

  const getAddressLines = () => {
    const orderShippingAddress = shipmentItemData.shipping_address;
    if (orderShippingAddress) {
      return {
        addressLine:
          orderShippingAddress?.address_line_1 +
          (orderShippingAddress?.address_line_2
            ? `, ${orderShippingAddress?.address_line_2}`
            : ''),
        cityLine: `${orderShippingAddress?.city}, ${orderShippingAddress?.state_code} ${orderShippingAddress?.zip}`,
      };
    } else {
      // TODO: This is a temporary fix for the case where the shipping address is not available on the order item
      return {
        addressLine:
          customerShippingAddress?.addressLine1 +
          (customerShippingAddress?.addressLine2
            ? `, ${customerShippingAddress?.addressLine2}`
            : ''),
        cityLine: `${customerShippingAddress?.city}, ${customerShippingAddress?.stateCode} ${customerShippingAddress?.zip}`,
      };
    }
  };

  return (
    <Grid container spacing={1}>
      {!isInbound && (
        <Grid item xs={12}>
          <OrderDetailField title="SHIP ADDRESS" value={renderAddressField()} />
        </Grid>
      )}
      <Grid item xs={6}>
        <OrderDetailField
          title="SHIP DATE"
          value={shipped_at ? moment(shipped_at).format('M/D/YY, h:mma') : '–'}
        />
      </Grid>
      <Grid item xs={6}>
        <OrderDetailField
          title="DELIVERY DATE"
          value={
            delivered_at ? moment(delivered_at).format('M/D/YY, h:mma') : '–'
          }
        />
      </Grid>
      <Grid item xs={6}>
        <OrderDetailField title="SHIPPING STATUS" value={status} />
      </Grid>
      <Grid item xs={6}>
        <OrderDetailField title="CARRIER" value={carrier} />
      </Grid>
      <Grid item xs={12}>
        <OrderDetailField
          title="TRACKING NUMBER"
          value={tracking_id_value ? tracking_id_value : '–'}
          copyable={!internal && !!tracking_id}
          editable={internal}
          onChange={(e: any) => setTrackingIdValue(e.target.value)}
        />
      </Grid>
      {!!shippingAddress && (
        <Grid item xs={12}>
          <OrderDetailField
            title="SEND TRACKING UPDATES TO PATIENT"
            value={
              shipmentItemData?.sent_patient_shipping_update ? 'Yes' : 'No'
            }
          />
        </Grid>
      )}
      {!isUpdatingOrder && tracking_id_value !== tracking_id && (
        <SaveButton
          onClick={() =>
            updateShipmentTracking(
              shipment_ref || shipmentRef,
              tracking_id_value
            )
          }
        >
          Save
        </SaveButton>
      )}
      {isUpdatingOrder && <Loading />}
    </Grid>
  );
};

const OrderDetailContainer = ({
  internal = false,
  isInbound = false,
  selectedOrderItem,
  shipmentDetail,
  orderSummary = null,
  title = 'Shipment details',
}: OrderDetailContainerProps) => {
  /*
    Replacements metadata format:
    {"AKREPL0001": {"aligner_stages": ["U01", "L01"]}}
  */

  const replacements =
    orderSummary?.metadata &&
    Object.entries(orderSummary.metadata)
      .filter(([sku]) => sku === PRODUCT_SKUS.replacements)
      .map(([, entry]: any) => entry.aligner_stages?.join(', '));

  return (
    <OrderDetailWrapper>
      <OrderDetailHeader>{`${title} - Order Ref ${selectedOrderItem?.short_order_ref}`}</OrderDetailHeader>
      <OrderDetailContents>
        <OrderDetailForm
          internal={internal}
          isInbound={isInbound}
          shipmentDetail={shipmentDetail}
          selectedOrderItem={selectedOrderItem}
          orderSummary={orderSummary}
        />
        {replacements?.length > 0 && (
          <div style={{ marginTop: '1rem' }}>
            <OrderDetailField
              title="REPLACEMENTS"
              value={replacements.toString()}
            />
          </div>
        )}
      </OrderDetailContents>
    </OrderDetailWrapper>
  );
};

export default OrderDetailContainer;
