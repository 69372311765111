import React, { useState, useEffect, useMemo, ComponentType } from 'react';
import styled from 'styled-components/macro';
import produce from 'immer';
import { Loading } from 'core/components';
import moment from 'moment';
import {
  useCustomerSearchQuery,
  CustomerSearchQuery,
  DoctorSelectorType,
} from 'generated/legacy/graphql';
import { useGQLQuery } from 'hooks/useGQL';
import {
  GetCasesForSearchQuery as useGetCoreCasesQuery,
  GetCasesForSearchDocument,
  GetCasesForSearchQueryVariables,
} from 'generated/core/graphql';
import DocumentTitle from 'components/DocumentTitle';
import { CustomerSearchTable } from 'components/SearchTablePage/CustomerSearchTable';
import { PatientSearchTable } from 'components/SearchTablePage/PatientSearchTable';
import {
  FilterType,
  SearchSidebar,
} from 'components/SearchTablePage/SearchSidebar';
import { OrthoPrismSearchTable } from 'components/SearchTablePage/OrthoPrismSearchTable';
import { SidebarLayout } from 'components/SidebarLayout';
import {
  CustomerSearchRow,
  CustomerSearchProps,
  Props,
  CaseQuery,
  TableProps,
} from 'components/SearchTablePage/types';
import {
  PAGINATION_LIMIT,
  initialCustomerSearchValues,
} from 'components/SearchTablePage/constants';
import { PageSection } from 'styles/layout.css';
import { convertJourneyFormInputToBeCustomerSearchCompatible } from 'components/FormikForms/utils';
import { SearchPageLocation } from 'components/SearchTablePage/SearchSidebar';
import { PROVIDER_FACING_STATUSES } from 'constants/caseStatus';
import { FilterStatus } from 'components/SearchTablePage/SearchSidebar';
import { SearchableInternalCaseStates } from 'constants/Case';
import { TableSkeleton } from 'components/SearchTablePage/Skeleton';
import { getCaseTypeLabel } from 'utils/case';

const SIDEBAR_WIDTH = '300px';

const Container = styled.div`
  position: relative;
  width: calc(100vw - ${SIDEBAR_WIDTH} - 40px);
  margin-left: 2.5rem;
  padding-bottom: 2.5rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100vw;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow-x: scroll;
  }
`;

type SearchPageComponentMap = {
  // managed tables get their props (rows, sort, filter, etc.) from this component
  managed: {
    [key: string]: {
      TableComponent: ComponentType<TableProps>;
      filterType: FilterType;
      instantSearch: boolean;
      filterComponent: FilterStatus;
      searchPage: SearchPageLocation;
      validFilterOptions: string[];
    };
  };
  // standalone tables are rendered without this component providing any props (rows, sort, filter, etc.)
  // and are responsible for fetching their own data
  standalone: {
    [key: string]: {
      TableComponent: React.ComponentType<Record<string, never>>;
      filterComponent: FilterStatus;
      validFilterOptions: string[];
    };
  };
};

const SearchPageToComponentMap: SearchPageComponentMap = {
  managed: {
    'Case overview': {
      TableComponent: CustomerSearchTable,
      filterType: 'journey',
      instantSearch: false,
      filterComponent: FilterStatus.ProviderFacingStatus,
      searchPage: SearchPageLocation.Case,
      validFilterOptions: Object.values(PROVIDER_FACING_STATUSES),
    },
  },
  standalone: {
    'Patient search': {
      TableComponent: PatientSearchTable,
      filterComponent: FilterStatus.ProviderFacingStatus,
      validFilterOptions: Object.values(PROVIDER_FACING_STATUSES),
    },
    'Ortho Prism': {
      TableComponent: OrthoPrismSearchTable,
      filterComponent: FilterStatus.InternalCaseState,
      validFilterOptions: Object.values(SearchableInternalCaseStates),
    },
  },
};

/*
  combineCasesWithCustomers - takes 2 datasets (from customer_search and get_cases), joins them by customer_ref and
  converts the result into a CustomerSearchRow
*/
const combineCasesWithCustomers = (
  data: CustomerSearchQuery,
  cases: CaseQuery[]
): CustomerSearchRow[] => {
  if (!data.customerSearch) {
    return [];
  }

  const searchResult = data?.customerSearch?.edges.map((currentCustomer) => {
    const customerCases = cases
      ?.filter(
        (currentCase) =>
          (currentCase?.customerRef ||
            currentCase?.patientId?.toString() ||
            '') === currentCustomer?.node?.id?.toString()
      )
      .sort((a, b) => {
        const aDate = moment(a!.createdAt);
        const bDate = moment(b!.createdAt);
        // sort by descending to find the latest case
        return aDate.isAfter(bDate) ? -1 : 1;
      });
    let journeyState: string | undefined | null = '-';
    let providerFacingStatus: string | undefined | null = '-';
    let internal: string | undefined | null = '-';
    let caseType: string | undefined | null = '-';
    if (customerCases !== undefined && customerCases?.length > 0) {
      journeyState = customerCases[0]?.journey?.humanReadableState;
      caseType = getCaseTypeLabel(customerCases[0]?.caseType?.label ?? '-');
      providerFacingStatus =
        customerCases[0]?.journey?.providerFacingStatus ||
        customerCases[0]?.caseState?.providerFacing ||
        '-';
      internal = customerCases[0]?.caseState?.internal || '-';
    }

    return {
      id: currentCustomer?.node?.id ?? '-',
      firstName: currentCustomer?.node?.firstName ?? '-',
      fullName: currentCustomer?.node?.fullName ?? '-',
      lastName: currentCustomer?.node?.lastName ?? '-',
      caseType: caseType,
      journeyState: journeyState ?? '-',
      providerFacingStatus: providerFacingStatus ?? '-',
      internal: internal ?? '-',
      treatingProvider:
        currentCustomer?.node?.referringDentist?.fullName ?? '-',
      email: currentCustomer?.node?.user?.email ?? '-',
    };
  });
  return searchResult;
};

const SideBarBody = ({
  rows,
  loaded,
  handleLoadMore,
  TableComponent,
  loading,
}: {
  rows: CustomerSearchRow[];
  loaded: boolean;
  handleLoadMore: () => Promise<CustomerSearchQuery>;
  loading: boolean;
  TableComponent: ComponentType<TableProps>;
}) => {
  if (!loaded) {
    return (
      <PageSection>
        <TableSkeleton numberOfRow={20} numberOfColumn={5} />
      </PageSection>
    );
  }

  return (
    <Container>
      {TableComponent && (
        <TableComponent rows={rows} onLoadMore={handleLoadMore} />
      )}
      {loading && <Loading isCentered />}
    </Container>
  );
};

export const SearchTablePage = ({ pageTitle }: Props) => {
  if (SearchPageToComponentMap.standalone[pageTitle]) {
    const TableComponent =
      SearchPageToComponentMap.standalone[pageTitle].TableComponent;
    return (
      <DocumentTitle title={pageTitle}>
        <TableComponent />
      </DocumentTitle>
    );
  } else {
    return <SearchTablePageImpl pageTitle={pageTitle} />;
  }
};

const SearchTablePageImpl = ({ pageTitle }: Props) => {
  const [rows, setRows] = useState<CustomerSearchRow[]>([]);
  const searchParams = new URLSearchParams(window.location.search);
  const {
    TableComponent,
    filterComponent,
    filterType,
    instantSearch,
    validFilterOptions,
  } = SearchPageToComponentMap.managed[pageTitle];
  const paramFilterValue = useMemo(() => {
    return Object.values(validFilterOptions).find(
      (value) =>
        value.toLowerCase() === searchParams.get('status')?.toLowerCase()
    );
  }, [searchParams.get('status') || '']);
  const [searchState, setSearchState] = useState<CustomerSearchProps>({
    ...initialCustomerSearchValues,
    component: paramFilterValue ? filterComponent : '',
    state: paramFilterValue ? paramFilterValue : '',
  });
  const [runningCases, setRunningCases] = useState<CaseQuery[]>([]);

  const {
    data,
    loading: searchQueryLoading,
    fetchMore,
  } = useCustomerSearchQuery({
    fetchPolicy: 'network-only',
    variables: {
      first: PAGINATION_LIMIT,
      ...(searchState.searchInput && {
        searchTerm: searchState.searchInput.trim(),
      }),
      isCoreCase: true,
      ...(searchState.state && {
        state: searchState.state as string,
      }),
      ...(searchState.component && {
        component: searchState.component as string,
      }),
      ...(searchState.doctors?.length && {
        doctors: {
          type: DoctorSelectorType.Include,
          ids: searchState.doctors,
        },
      }),
    },
  });

  const [getCoreCases, { loading: coreCaseLoading, data: coreCases }] =
    useGQLQuery<useGetCoreCasesQuery, GetCasesForSearchQueryVariables>(
      GetCasesForSearchDocument
    );

  const [cases, caseLoading] = useMemo(() => {
    return [coreCases?.getCases || [], coreCaseLoading];
  }, [coreCases, coreCaseLoading]);

  const filterResultsOnSearchState = (
    rows: CustomerSearchRow[],
    searchState: CustomerSearchProps
  ) => {
    return rows.filter((row) => {
      if (searchState.component && searchState.state) {
        return (
          (searchState.component === FilterStatus.ProviderFacingStatus &&
            row.providerFacingStatus === searchState.state) ||
          (searchState.component === FilterStatus.InternalCaseState &&
            row.internal === searchState.state) ||
          row.internal === '-'
        );
      } else {
        return true;
      }
    });
  };

  const handleSearch = (values: CustomerSearchProps) => {
    setRunningCases([]);
    setRows([]);
    if (values.journeyFormInput) {
      const { journeyFormInput, ...otherValues } = values;
      const componentAndState =
        convertJourneyFormInputToBeCustomerSearchCompatible(journeyFormInput);
      const customerSearchValues = { ...otherValues, ...componentAndState };
      setSearchState(customerSearchValues);
    } else if (values.filterComponent) {
      const customerSearchValues = {
        ...values,
        component: values.filterComponent,
        state: values.filterValue,
      };
      setSearchState(customerSearchValues);
    } else {
      setSearchState(values);
    }
  };

  const handleLoadMore = (): Promise<CustomerSearchQuery> =>
    new Promise((resolve) => {
      if (data?.customerSearch?.pageInfo.hasNextPage) {
        fetchMore({
          variables: {
            after: data?.customerSearch?.pageInfo.endCursor,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const updatedQuery = produce(previousResult, (draft) => {
              if (draft.customerSearch) {
                const combinedEdges = [
                  ...(draft.customerSearch?.edges ?? []),
                  ...(fetchMoreResult?.customerSearch?.edges ?? []),
                ];
                draft.customerSearch.edges = combinedEdges;
                draft.customerSearch.pageInfo.hasNextPage =
                  fetchMoreResult?.customerSearch?.pageInfo.hasNextPage ??
                  false;
                draft.customerSearch.pageInfo.endCursor =
                  fetchMoreResult?.customerSearch?.pageInfo.endCursor ?? '';
              }
            });
            resolve(updatedQuery);

            return updatedQuery;
          },
        });
      }
    });

  useEffect(() => {
    if (!searchQueryLoading && data) {
      const combinedRows = combineCasesWithCustomers(data, runningCases);
      setRows(filterResultsOnSearchState(combinedRows, searchState));
      const customerRefs = data?.customerSearch?.edges?.map(
        (customer) => customer?.node?.id
      ) as string[];
      // Only get the next PAGINATION_LIMIT patients
      const nextPaginationRefs =
        customerRefs.splice(-PAGINATION_LIMIT) || undefined;
      getCoreCases({
        patientIds: nextPaginationRefs.map(Number) ?? [],
      });
    }
  }, [searchQueryLoading, data]);
  useEffect(() => {
    if (!caseLoading && !searchQueryLoading && cases && data) {
      const allCases = [...runningCases, ...(cases as CaseQuery[])];
      const rawRows = combineCasesWithCustomers(data, allCases);
      setRows(filterResultsOnSearchState(rawRows, searchState));
      setRunningCases(allCases);
    }
  }, [cases, caseLoading]);

  return (
    <DocumentTitle title={pageTitle}>
      <SidebarLayout isOpen>
        {{
          sidebar: (
            <SearchSidebar
              onSubmit={handleSearch}
              filterType={filterType}
              search={instantSearch ? 'instant' : 'delayed'}
              showClearFilter={true}
            />
          ),
          body: (
            <SideBarBody
              rows={rows}
              loaded={
                rows.length > 0 ||
                (!caseLoading &&
                  !searchQueryLoading &&
                  data?.customerSearch?.edges?.length === 0)
              }
              TableComponent={TableComponent}
              handleLoadMore={handleLoadMore}
              loading={caseLoading || searchQueryLoading}
            />
          ),
        }}
      </SidebarLayout>
    </DocumentTitle>
  );
};
