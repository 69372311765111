import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { LoyaltyProgram, LoyaltyProgramTier } from 'generated/core/graphql';

import { Grid, Table, Heading, Button, Text, colors } from 'core/components';

import { PageContainer, StyledBackLink } from 'pages/LoyaltyProgram/shared';

const StyledTable = styled(Table)`
  td,
  th {
    padding-left: 0;
  }
`;

const columns = [
  'Tier name',
  {
    name: 'id',
    label: 'Discount',
    options: {
      customBodyRender: (value: string) => {
        return <Text color={colors.red50}>{value}</Text>;
      },
    },
  },

  'TPAs required',
];
type RouteParams = {
  id: string;
};

const LoyaltyProgramViewer = ({
  loyaltyPrograms = [],
}: {
  loyaltyPrograms: LoyaltyProgram[];
}) => {
  const history = useHistory();

  const { id: programId } = useParams<RouteParams>();
  const program = loyaltyPrograms.find(
    (program: LoyaltyProgram) => program?.id === programId
  );

  if (!program) {
    return null;
  }

  const mappedData = program.loyaltyProgramTiers
    .sort(
      (a: LoyaltyProgramTier, b: LoyaltyProgramTier) =>
        a.sortOrder - b.sortOrder
    )
    .map((tier: LoyaltyProgramTier) => {
      return [
        tier.name,
        `$${tier.loyaltyTierProducts[0].discount}`,
        tier?.entryRule?.args?.minNumber,
      ];
    });

  const handleDuplicateClick = () => {
    history.push('/loyalty-program/create', { program });
  };

  return (
    <PageContainer container justifyContent="center">
      <Grid container xs={8} direction="column">
        <Grid item style={{ marginBottom: '2rem' }}>
          <StyledBackLink />
        </Grid>
        <Grid item>
          <Heading variant={'h4'}>{program?.name || ''}</Heading>
        </Grid>
        <Grid item style={{ marginBottom: '3.5rem' }}>
          <StyledTable
            data={mappedData}
            columns={columns}
            options={{
              selectableRows: 'none',
              fixedHeader: false,
              pagination: false,
            }}
          />
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item>
              <Button buttonType="secondary" onClick={handleDuplicateClick}>
                Duplicate
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default LoyaltyProgramViewer;
