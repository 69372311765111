import { Chip, type } from 'core/components';
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion';
import styled from 'styled-components/macro';
import ArrowSVG from 'assets/arrow-up-down.svg?react';

export const StyledAccordion = styled(AccordionItem)`
  border: 1px solid #c9c9c9;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

export const Button = styled(AccordionButton)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const Title = styled(type.H5)`
  margin-bottom: 0;
`;

export const SubCategoryHeader = styled.div`
  font-weight: 700;
`;

export const Panel = styled(AccordionPanel)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ArrowIcon = styled(ArrowSVG)<{ $isExpanded: boolean }>`
  width: 12px;
  height: 7px;
  fill: currentColor;
  transform: ${({ $isExpanded }) => $isExpanded && 'rotateX(180deg)'};
`;

export const Checkbox = styled.input`
  margin-top: 0.125rem;
  margin-right: 0.75rem;
  vertical-align: text-top;
  cursor: pointer;
  appearance: checkbox;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 0.25rem;
  }
`;

export const Label = styled.label`
  display: block;
  cursor: pointer;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: flex;
    align-items: flex-start;
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export const SubCategory = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const StyledChip = styled(Chip)`
  height: 21px;
  width: 21px;
  .MuiChip-label {
    color: white;
    font-size: 12px;
  }
`;
