import React, { FunctionComponent } from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import JourneyProvider from 'components/JourneyProvider';

import CustomerCasesProvider from 'pages/Journey/CustomerCasesProvider';
import JourneySearch from 'pages/Journey/JourneySearch';
import JourneyView from 'pages/Journey/JourneyView';

const Journey: FunctionComponent<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route
        component={JourneySearch}
        exact
        path={`${match.path}/:customerId?`}
      />
      <CustomerCasesProvider>
        <JourneyProvider>
          <Route
            component={JourneyView}
            path={`${match.path}/:customerId/:caseRef`}
          />
        </JourneyProvider>
      </CustomerCasesProvider>
    </Switch>
  );
};

export default Journey;
