import { CustomerSearchProps } from 'components/SearchTablePage/types';
import { FilterStatus } from 'components/SearchTablePage/SearchSidebar';

export const PAGINATION_LIMIT = 50;

export const initialCustomerSearchValues: CustomerSearchProps = {
  searchInput: '',
  state: '',
  component: '',
  journeyFormInput: '',
  filterComponent: '' as FilterStatus,
  filterValue: '',
};
