import styled from 'styled-components/macro';
import Drawer from '@material-ui/core/Drawer';

const drawerWidth = 400;

export default styled(Drawer)`
  ${({ theme }) => `
  & .MuiDrawer-root {
      position: relative;
  }
  & .MuiDrawer-paper {
    width: ${drawerWidth}px;
    position: inherit;
    top: 0.75rem;
    right: 0.75rem;
    border: 1px solid #C9C9C9;
    border-radius: 0.75rem;
    padding: 0.75rem;
    overflow: visible;
    }
  & .MuiDrawer-paperAnchorDockedLeft {
    border-right: 1px solid ${theme.colors.black20};
`}
`;
