import styled from 'styled-components/macro';

export const FormContainer = styled.form<{ isVisible?: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  padding: 2rem;
  margin: 2.5rem 0 0 0;
  background: white;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 0.1875rem;
  box-shadow: 0 1px 0.375rem 0 rgba(0, 0, 0, 0.05);
`;

export const FormRow = styled.div<{
  isSingleCol?: boolean;
  isStacked?: boolean;
}>`
  display: flex;
  flex-direction: ${({ isStacked }) => (isStacked ? 'column' : 'row')};
  align-items: flex-start;
  justify-content: space-between;
  width: ${({ isSingleCol }) => (isSingleCol ? 'calc(75% - 1.5rem)' : '100%')};
  margin-bottom: 1.5rem;

  p {
    margin-bottom: 0;
  }
`;

export const SubmitButtonContainer = styled.div`
  margin-top: 2.5rem;
`;

export const RadioGroup = styled.div`
  display: flex;
  width: 25%;
  margin-left: 1.5rem;
`;

export const Label = styled.label`
  display: block;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 2rem;
  }
`;

export const Radio = styled.input`
  margin-right: 0.75rem;
  vertical-align: text-top;
  cursor: pointer;
  appearance: radio;
`;

export const SmallHeading = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const ReasonOptions = styled.div`
  margin-top: 2rem;

  ${Label} {
    margin: 0.375rem 0;
  }
`;

export const MainReasons = styled.ul`
  column-count: 2;

  > li {
    break-inside: avoid;
    margin-bottom: 2rem;
  }
`;

export const NoteText = styled.p`
  margin-bottom: 1rem !important;
  color: ${({ theme }) => theme.colors.black70};
`;

export const ReviewLink = styled.button`
  display: block;
  margin-bottom: 1rem;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.blue50};
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 6.5rem;
  padding: 0.625rem;
  line-height: 1.4;
  border: ${({ theme }) => theme.borders.default};
  border-radius: 0.125rem;
  transition: border-color 0.2s ease-in-out;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.black40};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.black60};
  }
`;
