import React from 'react';

import { RejectionDetailProblem } from 'pages/OrthoPrism/Plan/utils';

import {
  Label,
  StyledReactSelect,
  CustomerReactSelectStyles,
} from 'styles/inputs.css';
import {
  RejectionDetailsContainer,
  ProblemsContainer,
  ProblemItemContainer,
  ActionButtonContainer,
  Image,
  ItemPhotosContainer,
  FileUploadContainer,
  PhotoSectionContainer,
  NotesContainer,
  DeleteButton,
  ImageWrapper,
  ActionButton,
  TopicSpan,
} from 'pages/OrthoPrism/Plan/TreatmentReviewForm/RejectionDetails.css';
import {
  QuestionHeading,
  TextArea,
  RejectionItemsButtonRow,
} from 'pages/OrthoPrism/Plan/TreatmentReviewForm/TreatmentReviewForm.css';
import FileUpload from 'components/FileUpload';
import RejectionProblemItem from 'pages/OrthoPrism/Plan/TreatmentReviewForm/RejectionProblemItem';
import { Button } from 'core/components';
import { ProblemCategory } from 'utils/types';

const PROBLEM_CATEGORIES: ProblemCategory[] = [
  'Alignment - Asymmetric arch form',
  'Alignment - Bridge moved separately',
  'Alignment - Candid alignment protocol not followed',
  'Alignment - Deciduous tooth/teeth moved',
  'Alignment - Implant moved',
  'Alignment - Inappropriate Anterior Leveling',
  'Alignment - Midline worsen and not required',
  'Alignment - Movement not properly done',
  'Alignment - Movement was not performed',
  'Alignment - Non requested tooth/teeth movement',
  'Alignment - Overbite not corrected',
  'Alignment - Overbite worse',
  'Alignment - Overjet not corrected',
  'Alignment - Overjet worse',
  'Alignment - Posterior marginal ridge unlevel',
  'Alignment - Unnecessary movement',
  'Assemblies - Anomalous Geometry',
  'Assemblies - Assemblies not exported',
  'Assemblies - Laser Mark Wrong Position',
  'Assemblies - Tag place in wrong position',
  'Base - Base too short / long',
  'Base - Holes in the base',
  'Base - Missing data',
  'Comments - Confusing',
  'Comments - Inaccurate',
  'Comments - Treatment Comment required no placed',
  'Gingiva - Asymmetric Base',
  'Gingiva - Gingiva overlaps the tooth surface',
  'Gingiva - Inappropriate Final Gingiva',
  'Gingiva - Visible Root',
  'Impression Issues - Impression(s) should have been rejected due to defects',
  'Interproximal Contacts - IPR present',
  'Manual Operation - Orientation model not properly done',
  'Manual Operation - Ortho Internal comments not reviewed/considered',
  'Manual Operation - Tooth numbering wrongly placed',
  'Manual Operation - Tooth/teeth initial position changed',
  'Manual Operation - Wrong Qty of Aligners',
  'Occlusion - Crossbite not corrected',
  'Occlusion - Crossbite not maintained',
  'Occlusion - Hard occlusal contacts',
  'Occlusion - Incorrect initial Bite set',
  'Occlusion - Occlusion not corrected',
  'Occlusion - Occlusion worse',
  'Occlusion - Sagital position worse',
  'Patient Records - Mismatch',
  'Patient Records - Patient records not properly examined',
  'Staging - Spaces distribution was not performed according with Candid protocol',
  'Staging - Spaces not closed according with Candid protocol',
  'Staging - Unnecessary spaces',
  'Staging - Exceeded maximum aligners allowed',
  'Staging - Inadequate pattern',
  'Staging - Intra-arch collisions were not avoided',
  'Staging - Separation of movements not applied',
  'Staging - Velocities outside protocol',
  'Staging - Inappropriate movements during the avoiding collisions process',
  'Teeth Shape - Axis badly placed',
  'Teeth Shape - Entire arch not properly detailed',
  'Teeth Shape - Inappropriate Paint',
  'Teeth Shape - Large Paint',
  'Teeth Shape - Missing teeth',
  'Teeth Shape - Overdetailing - Add more material',
  'Teeth Shape - Oversmoothing - Remove more material',
  'Teeth Shape - Red contact area was not eliminated',
  'Teeth Shape - Short Paint',
  'Trim Line - Irregular trim line',
  'Trim line - Not fully erupted tooth not properly cover',
  'Trim Line - Too close from the tooth',
  'Trim Line - Too far from the tooth',
  'Alignment - Inappropriate BOJ',
  'Alignment - Preference not applied',
  'Attachments - Attachment badly placed',
  'Attachments - Incorrect attachment',
  'Base - Asymetric Base',
  'Base - Incorrect gingiva height',
  'Bite Ramps - Bite Ramp badly placed',
  'Bite Ramps - Incorrect bite ramp',
  'Bolton Analysis - T-Mark badly placed',
  'Clinical Analysis - Clinical analysis not properly done',
  'Gingiva - Unstable gingiva',
  'IPR - IPR not properly done',
  'Pontic - Hard occlusal contacts',
  'Pontic - Inadequate pontic size',
  'Segmentation - Incorrect Anatomy',
  'Undercuts - Undercut not properly done',
  'Alignment - Bridge movement not properly done',
  'Alignment - Overlapping tooth',
  'Attachment - Attachment not placed',
  'Attachments - Change position during treatment',
  'Bite Ramps - Bite Ramps not placed',
  'Bite Ramps - Change position during treatment',
  'Comments - Semi-erupted molar excluded without comment',
  'Cut outs - Not placed/ Wrong Teeth',
  'Cut outs - Wrong Position',
  'IPR - IPR not done',
  'Manual Operation - Save not properly done',
  'Mesh Sculpting - Bridge anatomy not properly done',
  'Mesh Sculpting - Retentive areas not covered',
  'Segmentation - Bulky root',
  'Segmentation - Semi-erupted molar not excluded',
  'Segmentation - Semi-erupted molar excluded w/o clinical consent',
  'Staging - Movement not properly done',
].map((value) => ({ label: value, name: value }));

const RejectionDetails: React.FC<{
  problems: Array<RejectionDetailProblem>;
  handleAddRemoveProblems: (
    type: 'add' | 'remove',
    problemId?: string | undefined
  ) => void;
  handleEditProblem: <F extends keyof RejectionDetailProblem>(
    problemId: string,
    field: F,
    value: RejectionDetailProblem[F]
  ) => void;
  handleProblemDone: (type: 'save' | 'cancel') => void;
}> = ({
  problems,
  handleAddRemoveProblems,
  handleEditProblem,
  handleProblemDone,
}) => {
  const isAnyEditing = problems.every((p) => !p.isEditing);
  return (
    <RejectionDetailsContainer>
      <QuestionHeading>Rejection details</QuestionHeading>
      <ProblemsContainer>
        {problems.map((p, idx) => {
          const selectId = `topic-select-for-problem-${p.id}`;
          return (
            <ProblemItemContainer key={idx}>
              <QuestionHeading>
                Defect #{idx + 1}{' '}
                <TopicSpan>{!p.isEditing && p?.topic?.label}</TopicSpan>
              </QuestionHeading>
              {p.isEditing ? (
                <>
                  <Label htmlFor={selectId}>Topic*</Label>
                  <StyledReactSelect
                    placeholder="Select topic..."
                    styles={CustomerReactSelectStyles}
                    options={PROBLEM_CATEGORIES.map((pc) => ({
                      label: pc?.label,
                      name: pc?.name,
                    }))}
                    id={selectId}
                    onChange={(o: ProblemCategory[]) => {
                      // Hack to prevent dropdown from highlighting all items (bug in ReactSelect)
                      handleEditProblem(p.id, 'topic', o ? o[0] : null);
                    }}
                    value={p.topic}
                    isMulti={true} // Hack to prevent dropdown from highlighting all items (bug in ReactSelect)
                  />
                  <PhotoSectionContainer>
                    <Label>Photos(s)*</Label>
                    {p.photos.length > 0 && (
                      <ItemPhotosContainer>
                        {p.photos.map((photo, photoIdx) => {
                          const photoUrl = URL.createObjectURL(photo);
                          return (
                            <ImageWrapper key={photoIdx}>
                              <Image src={photoUrl} />
                              <DeleteButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  // Create new reference without the photo selected for deletion
                                  const newPhotos = [
                                    ...p.photos.slice(0, photoIdx),
                                    ...p.photos.slice(photoIdx + 1),
                                  ];
                                  handleEditProblem(p.id, 'photos', newPhotos);
                                }}
                              />
                            </ImageWrapper>
                          );
                        })}
                      </ItemPhotosContainer>
                    )}
                    <FileUploadContainer>
                      <FileUpload
                        allowMultipleFiles
                        onSelectFile={(f) =>
                          !!f &&
                          handleEditProblem(p.id, 'photos', [
                            ...p.photos,
                            ...Array.from(f),
                          ])
                        }
                      />
                    </FileUploadContainer>
                  </PhotoSectionContainer>
                  <NotesContainer>
                    <Label htmlFor={selectId}>Notes*</Label>
                    <TextArea
                      onChange={({ target: { value } }) =>
                        handleEditProblem(p.id, 'notes', value)
                      }
                      placeholder="Notes or instructions"
                      value={p.notes}
                    />
                  </NotesContainer>
                  <RejectionItemsButtonRow>
                    <Button
                      isShort
                      buttonType="secondary-outline"
                      onClick={() => handleProblemDone('cancel')}
                      disabled={problems.length === 1}
                    >
                      Cancel
                    </Button>
                    <Button
                      isShort
                      buttonType="secondary"
                      onClick={() => handleProblemDone('save')}
                      disabled={
                        p.notes === '' ||
                        p.topic === null ||
                        p.photos.length === 0
                      }
                    >
                      Save
                    </Button>
                  </RejectionItemsButtonRow>
                </>
              ) : (
                <>
                  <ActionButtonContainer>
                    <ActionButton
                      onClick={() => handleAddRemoveProblems('remove', p.id)}
                    >
                      Delete
                    </ActionButton>

                    {isAnyEditing && (
                      //if any other items are currently being edited, hide the edit button
                      <ActionButton
                        onClick={() =>
                          handleEditProblem(p.id, 'isEditing', true)
                        }
                      >
                        Edit
                      </ActionButton>
                    )}
                  </ActionButtonContainer>
                  <RejectionProblemItem
                    photoUrls={p.photos.map((p) => URL.createObjectURL(p))}
                    notes={p.notes}
                  />
                </>
              )}
            </ProblemItemContainer>
          );
        })}
      </ProblemsContainer>
    </RejectionDetailsContainer>
  );
};

export default RejectionDetails;
