import styled from 'styled-components/macro';
import ToothUpIcon from 'assets/ToothUp.svg?react';

export const ToothWrapper = styled.div<{
  isSelected: boolean;
  readOnly: boolean;
}>`
  position: relative;
  width: 24px;
  height: 24px;

  cursor: ${({ readOnly }) => (readOnly ? 'default' : 'pointer')};
  svg {
    ${({ theme, isSelected }) => `
    display: block;
    width: 100%;
    height: 100%;
  
      ${
        isSelected &&
        `
          path {
              fill: ${theme.colors.blue10};
              stroke: ${theme.colors.blue50};
          }
      `
      }
  `}
`;

export const CenterToothIcon = styled(ToothUpIcon)`
  position: absolute;
  transform: scale(0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;
