import styled from 'styled-components/macro';
import { Button, Grid, colors, Text } from 'core/components';
import {
  FormikInputWrapper,
  FormikSelectWrapper,
} from 'components/FormikForms';

export const StyledFormikInputWrapper = styled(FormikInputWrapper)`
  width: 100%;
`;
export const StyledFormikSelectWrapper = styled(FormikSelectWrapper)`
  width: 100%;
`;

export const BoldedErrorHeader = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const StyledAlertText = styled(Text)`
  white-space: pre-line;
`;

export const CardContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 768px;
  padding: 1rem;
  gap: 2rem;
  border-radius: 5px;
  text-align: center;
  width: 100%;
`;

export const Card = styled(Grid)`
  display: flex;
  padding: 1rem;
  background-color: ${colors.blue05};
  border-radius: 20px;
  font-size: ${({ theme }) => theme.fontSizes.default};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  border: 2px solid ${colors.blue30};
  justify-content: space-between;
  width: 45%;
`;

export const CopyIconWrapper = styled.div`
  cursor: pointer;
  padding: 0.5rem;
  background-color: ${colors.blue};
  color: ${colors.white};
  border-radius: 5px;
`;

export const ButtonGroupContainer = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.default};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  display: flex;
  width: 100%;
  grid-column-gap: 8px;
  justify-content: flex-start;
`;

export const PresetValuesGroup = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const PresetGroup = styled.div`
  padding: 0.5rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: flex-start;
  align-items: flex-start;
  width: 33%;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const Border = styled.div`
  width: 100%;
  border: 1px dashed ${colors.black20};
`;

export const SectionInputLabelGroup = styled(Grid)`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const SectionInputLabel = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeights.default};
  font-size: ${({ theme }) => theme.fontSizes.small};
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const InputGroup = styled(Grid)`
  display: flex;
  width: 100%;
  min-height: 2rem;
  align-items: center;
  justify-content: space-between;
  gap: 0.2rem;
`;

export const Checkbox = styled.input`
  margin-top: 0.125rem;
  margin-right: 0.75rem;
  vertical-align: text-top;
  cursor: pointer;
  appearance: checkbox;
`;

export const DashboardContainer = styled(Grid)`
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem;
  flex-direction: column;
`;

export const SectionTitle = styled.h4`
  ${({ theme }) => theme.text.headings.h4};
`;

export const StyledButton = styled(Button)`
  height: 3rem;
  width: 100%;
  max-width: 300px;
  margin: auto;
`;

export const ButtonCounter = styled.div<{ isPositive: boolean }>`
  border: 1px solid ${colors.black20};
  cursor: pointer;
  width: 25px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  user-select: none;
  transition: 0.2s;
  &:hover {
    background-color: ${({ isPositive = true }) =>
      isPositive ? colors.green50 : colors.red30};
    color: ${colors.white};
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  grid-column-gap: 2px;
`;
export const Subheader = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.default};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  display: flex;
  grid-column-gap: 2px;
  width: 100%;
  justify-content: space-between;
`;

export const Section = styled(Grid)`
  align-items: center
  gap: .15rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0.5rem 0;
`;

export const AddressContainer = styled.div`
  width: 100%;
  border-radius: 20px;
  background-color: ${colors.black05};
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
`;

export const Subsection = styled(Grid)`
  width: 100%;
  border-radius: 20px;
  background-color: ${colors.black05};
`;

export const FormContainer = styled(Grid)`
  width: 100%;
  max-width: 768px;
  padding: 1rem;
  .w-100 {
    width: 100%;
  }
`;
