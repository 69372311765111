import { useCallback, useState } from 'react';
import { DocumentNode, OperationVariables } from '@apollo/client';
import { client, coreClient } from 'gql/GraphQLProvider';

export const useGQLQuery = <Data, Variables extends OperationVariables>(
  query: DocumentNode,
  raiseError: boolean = false,
  useCoreClient: boolean = true
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Data>();
  const [errors, setError] = useState<any>();

  const callback = useCallback(
    async (variables: Variables) => {
      try {
        const gqlClient = useCoreClient ? coreClient : client;
        setLoading(true);
        const { data: results } = await gqlClient.query<Data, Variables>({
          query: query,
          variables: variables,
        });
        setData(results);
        return results;
      } catch (exc) {
        setError(exc);
        if (raiseError) {
          throw exc;
        }
      } finally {
        setLoading(false);
      }
    },
    [query]
  );

  type Result = {
    loading: boolean;
    data: Data | undefined;
    errors: any;
  };
  type Callback = (variables: Variables) => Promise<Data | undefined>;
  return [callback, { loading, data, errors }] as [Callback, Result];
};

export const useGQLMutation = <Data, Variables extends OperationVariables>(
  mutation: DocumentNode,
  raiseError: boolean = false
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Data | null>();
  const [errors, setError] = useState<any>();

  const callback = useCallback(
    async (variables: Variables) => {
      try {
        setLoading(true);
        const { data: results } = await coreClient.mutate<Data, Variables>({
          mutation: mutation,
          variables: variables,
          fetchPolicy: 'no-cache',
        });
        setData(results);
        return results;
      } catch (exc) {
        setError(exc);
        if (raiseError) {
          throw exc;
        }
      } finally {
        setLoading(false);
      }
    },
    [mutation]
  );

  type Result = {
    loading: boolean;
    data: Data | undefined;
    errors: any;
  };
  type Callback = (variables: Variables) => Promise<Data | undefined>;
  return [callback, { loading, data, errors }] as [Callback, Result];
};
