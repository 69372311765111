import { ProductVariantType, FormType } from 'pages/OrdersPortal/types';

import { ProductBrand } from 'utils/brands';

export const includeProductVariantByBrand = (
  productVariant: ProductVariantType,
  brandName: string,
  includeGenericProducts: boolean
) => {
  /*
    This method filters out a specific brand's products from the list of products
    leaving the brand agnostic 
    */
  const brand =
    ProductBrand[brandName as keyof typeof ProductBrand]?.toUpperCase();

  const brandMatches = productVariant.brand === brand;
  const isGenericProduct = productVariant.brand === ProductBrand.generic;
  return brandMatches || (isGenericProduct && includeGenericProducts);
};

export const includeProductVariantByFormType = (
  productVariant: ProductVariantType,
  formType?: FormType
) => {
  /*
    This method filters out products based on the form type
    */
  switch (formType) {
    case FormType.practice:
      return !productVariant.krakenFgs;
    case FormType.patient:
      return !!productVariant.krakenFgs;
    default:
      return true;
  }
};
