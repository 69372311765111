import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { colors, mediaQueries, type } from 'core/components';
import { darken } from 'polished';

export const headerHeight = '4.25rem';
export const siteNarrowWidth = '44.5rem';
const siteMaxWidth = '100rem';

interface CenteredLayout {
  isNarrow?: boolean;
}

interface PageSectionProps extends CenteredLayout {}

interface ColumnProps {
  numColumns: number;
  columnGap?: string;
}

interface GridContainerProps extends ColumnProps {
  alternatingGrayBg?: boolean; // can only be used if full-rows are not used
  padding?: string;
  clearBackground?: boolean; // clears white background color
}

interface SpacerProps {
  isVertical?: boolean;
  spacing?: string;
  mobileSpacing?: string;
}

export const centeredLayout = css<CenteredLayout>`
  width: 100%;
  max-width: ${({ isNarrow }) => (isNarrow ? siteNarrowWidth : siteMaxWidth)};
  margin-right: auto;
  margin-left: auto;
`;

/**
 * Creates an alternating grey background color between rows in the grid
 */
const createBackgroundColor = (numColumns: number) => {
  let styles = '';
  for (let i = 1; i <= numColumns; i++) {
    styles += `> div:nth-child(${numColumns * 2}n + ${numColumns + i}) {
      background-color: ${colors.black05};
    }`;
  }

  return css`
    ${styles}
  `;
};

export const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: ${({ numColumns }) => `repeat(${numColumns}, 1fr)`};
  grid-gap: ${({ columnGap = '1rem' }) => columnGap};
  background-color: ${({ clearBackground }) =>
    clearBackground ? 'none' : colors.white};

  > * {
    padding-right: ${({ padding = '1rem' }) => padding};
    padding-bottom: ${({ padding = '1rem' }) => padding};
    padding-left: ${({ padding = '1rem' }) => padding};

    ${({ numColumns }) =>
      numColumns % 2 === 0 &&
      `
      &:nth-last-child(1):nth-child(odd) {
        grid-column-end: span ${numColumns};
        grid-row-end: span ${numColumns};
      }
    `}

    ${({ numColumns }) =>
      numColumns % 2 === 1 &&
      `
      &:nth-last-child(1):nth-child(even) {
        grid-column-end: span ${numColumns};
        grid-row-end: span ${numColumns};
      }
    `}
  }
  ${({ numColumns, alternatingGrayBg }) =>
    alternatingGrayBg && createBackgroundColor(numColumns)}

  .full-row {
    grid-row-end: ${({ numColumns }) => `span ${numColumns}`};
    grid-column-end: ${({ numColumns }) => `span ${numColumns}`};
  }

  .graybg {
    background-color: ${colors.black05};
  }

  @media ${mediaQueries.tabletAndBelow} {
    grid-template-columns: auto;
    row-gap: 1rem;
  }
`;

export const PageSection = styled.section<PageSectionProps>`
  ${centeredLayout};
  padding: 2.5rem 1.25rem;

  @media ${mediaQueries.tabletAndAbove} {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.black20};
`;

export const PageHeading = styled(type.H2)`
  margin-bottom: 1.5rem;
`;

export const PageHeader = styled.header`
  background: ${colors.black05};
`;

export const PageHeaderContent = styled.div`
  ${centeredLayout};
  padding: 1.5rem 1.25rem;

  @media ${mediaQueries.tabletAndAbove} {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  ${PageHeading} {
    margin-bottom: 0;
  }
`;

export const Spacer = styled.div<SpacerProps>`
  display: flex;
  flex-direction: ${({ isVertical = false }) =>
    isVertical ? 'column' : 'row'};

  > *:not(:first-child) {
    margin-top: ${({ spacing = '1rem', isVertical = false }) =>
      isVertical ? spacing : '0'};
    margin-left: ${({ spacing = '1rem', isVertical = false }) =>
      !isVertical ? spacing : '0'};

    ${({ mobileSpacing = false, theme }) =>
      mobileSpacing &&
      `
    @media ${theme.mediaQueries.mobile} {
      margin-top: ${mobileSpacing};
    }`}
  }
`;

export const IndentedSpacer = styled.div<SpacerProps>`
  display: flex;
  padding-left: 40px;
  flex-direction: ${({ isVertical = false }) =>
    isVertical ? 'column' : 'row'};

  > *:not(:first-child) {
    margin-top: ${({ spacing = '1rem', isVertical = false }) =>
      isVertical ? spacing : '0'};
    margin-left: ${({ spacing = '1rem', isVertical = false }) =>
      !isVertical ? spacing : '0'};
  }
`;

interface HRDividerProps {
  spacing?: '0.5rem' | '1rem' | '1.5rem' | '2rem' | '3rem';
  mobileSpacing?: '0.25rem' | '0.5rem' | '0.75rem' | '1rem';
}

export const HRDividerLine = styled.hr<HRDividerProps>`
  width: 100%;
  margin-top: ${({ spacing = '1.5rem' }) => spacing};
  margin-bottom: ${({ spacing = '1.5rem' }) => spacing};
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: ${({ mobileSpacing = '1.5rem' }) => mobileSpacing};
    margin-bottom: ${({ mobileSpacing = '1.5rem' }) => mobileSpacing};
  }
`;

export const PhotoNav = styled.nav`
  position: relative;
  margin-bottom: 2.5rem;
`;

export const BackButton = styled.button`
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: ${type.weight.bold};
  color: ${colors.blue};
  fill: currentColor;

  svg {
    margin: 0.0625rem 0.5rem 0 0;
    vertical-align: text-top;
  }
`;

export const NavButtons = styled.div`
  position: absolute;
  top: 0.375rem;
  right: 0;
  display: flex;
`;

export const NavButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  color: ${colors.white};
  background: ${colors.blue};
  border-radius: 50%;
  fill: currentColor;
  transition:
    background 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:first-child {
    margin-right: 1.25rem;

    svg {
      margin-right: 0.1875rem;
    }
  }

  &:last-child svg {
    margin-left: 0.1875rem;
  }

  &:hover {
    color: ${colors.white};
    background: ${darken(0.1, colors.blue)};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Question = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 0.2rem;
  gap: 2px;

  @media ${mediaQueries.mobile} {
    font-size: ${type.size.t1};
  }
`;
