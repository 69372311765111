import { IntakeSection } from 'generated/core/graphql';

// move treatment plan to the top
const sortIntakeFormSections = (a: IntakeSection, b: IntakeSection) => {
  const aLabel = a.label.toLowerCase();
  const bLabel = b.label.toLowerCase();
  if (aLabel.includes('treatment') || aLabel < bLabel) {
    return 1;
  }
  if (bLabel.includes('treatment') || aLabel > bLabel) {
    return -1;
  }
  return 0;
};

// remove consent since it's not needed for pro but exists in older forms
export const formatIntakeSections = (sections: IntakeSection[]) =>
  sections.filter((s) => s.label !== 'Consent').sort(sortIntakeFormSections);

export const determineIntakeName = (isCaseGen2: boolean | null | undefined) => {
  if (isCaseGen2) {
    return 'Treatment goals';
  }
  return 'Treatment details';
};
