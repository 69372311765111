import axios from 'axios';
import JSZip from 'jszip';
import moment from 'moment';
import { saveAs } from 'file-saver';

export type FileInfo = {
  file: File;
  fileSrc: string;
};

export const downloadFiles = async (
  files: { url: string; filename: string }[],
  prefix: string
) => {
  const zip = new JSZip();
  const date = moment().format('MM-DD-YYYY');

  await Promise.all(
    files.map(async ({ url, filename }) => {
      const ext = filename.split('.').pop() || 'jpg';
      const { data } = await axios({
        url: `${url}&not-from-cache`,
        responseType: 'blob',
      });
      zip.file(`${filename}.${ext}`, data);
    })
  );

  const content = await zip.generateAsync({ type: 'blob' });
  saveAs(content, `${prefix}_${date}.zip`);
};
