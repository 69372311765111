import React from 'react';
import { ThemeProvider as Provider } from 'styled-components';
import {
  createTheme,
  ThemeProvider as MaterialThemeProvider,
} from '@material-ui/core/styles';

import theme from 'core/styles/theme';

export type Theme = typeof theme;

const ThemeProvider: React.FC = ({ children }) => {
  const materialTheme = createTheme({
    palette: {
      primary: {
        main: theme.palette.primary.main,
      },
    },
    typography: {
      fontFamily: theme.fonts.modernEra,
    },
    overrides: {
      MuiTooltip: {
        popper: {
          textAlign: 'center',
        },
      },
      MuiOutlinedInput: {
        input: {
          padding: '0.75rem',
        },
      },
      MuiLinearProgress: {
        colorPrimary: {
          backgroundColor: theme.colors.black20,
        },
        colorSecondary: {
          backgroundColor: theme.colors.black20,
        },
        barColorPrimary: {
          backgroundColor: theme.colors.green70,
        },
        barColorSecondary: {
          backgroundColor: theme.colors.blue50,
        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: theme.palette.secondary.main,
          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
          },
        },
        current: {
          color: theme.palette.secondary.main,
        },
      },
    },
  });

  return (
    <MaterialThemeProvider theme={materialTheme}>
      <Provider theme={theme}>{children}</Provider>
    </MaterialThemeProvider>
  );
};

export default ThemeProvider;
