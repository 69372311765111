import styled from 'styled-components/macro';
import { colors, mediaQueries, type } from 'core/components';
import { PermissionedInput } from 'components/PermissionedInput';

export const FormRow = styled.div`
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 2.5rem;
  }

  p {
    margin-bottom: 0;
  }
`;

export const RadioGroup = styled.div`
  margin-top: 1.25rem;

  @media ${mediaQueries.tabletAndAbove} {
    width: 50%;
  }
`;

export const Label = styled.label`
  display: block;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

export const Radio = styled(PermissionedInput)`
  margin-right: 0.75rem;
  vertical-align: text-top;
  cursor: pointer;
  appearance: radio;
`;

export const Checkbox = styled.input`
  margin-top: 0.125rem;
  margin-right: 0.75rem;
  vertical-align: text-top;
  cursor: pointer;
  appearance: checkbox;
`;

export const QuestionHeading = styled(type.H4)`
  margin-bottom: 1rem;
`;

export const SmallHeading = styled.h4`
  font-size: ${type.size.small};
  font-weight: ${type.weight.bold};
`;

export const NoteHeading = styled.div`
  font-size: ${type.size.small};
  font-weight: ${type.weight.bold};
`;

export const SmallSubHeading = styled.div`
  font-size: ${type.size.small};
  color: ${colors.black70};
  padding-bottom: 8px;
`;

export const ReasonOptions = styled.div`
  margin-top: 2rem;

  ${Label} {
    margin: 0.375rem 0;
  }
`;

export const MainReasons = styled.ul`
  column-count: 2;

  > li {
    break-inside: avoid;
    margin-bottom: 2rem;
  }
`;

export const OtherReasons = styled.div`
  padding-top: 1.25rem;
  margin-top: -1rem;
  margin-bottom: 2rem;
  border-top: 1px solid ${colors.black20};
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 6.5rem;
  padding: 0.625rem;
  line-height: 1.4;
  border: 1px solid ${colors.black20};
  border-radius: 0.125rem;
  transition: border-color 0.2s ease-in-out;

  &:hover,
  &:focus {
    border-color: ${colors.black40};
  }

  &::placeholder {
    color: ${colors.black60};
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  button :first-child {
    margin-right: 25px;
  }
`;

export const RejectionItemsButtonRow = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: flex-end;
  align-items: center;
  button :first-child {
    margin-right: 25px;
  }
`;
