import {
  CoreCase,
  Customer,
} from 'components/SearchTablePage/customerQuerier/type';
import { CustomerSearchRow } from 'components/SearchTablePage/types';
import moment from 'moment';

export const combineData = (
  customers: readonly Customer[],
  cases: readonly CoreCase[]
): readonly CustomerSearchRow[] => {
  const customersMap: Map<string, Customer> = new Map();
  customers.forEach((customer) => customersMap.set(customer.id, customer));

  const casesMap: Map<number, (typeof cases)[number]> = new Map();
  cases.forEach((c) => {
    const currentCase = casesMap.get(c.patientId);

    if (currentCase?.isActive) {
      return;
    }

    if (
      !currentCase ||
      moment(c.createdAt).isAfter(moment(currentCase.createdAt))
    ) {
      casesMap.set(c.patientId, c);
    }
  });

  return customers.map((customer): CustomerSearchRow => {
    const currentCase = casesMap.get(+customer.id);

    return {
      id: customer.id,
      firstName: customer.firstName,
      fullName: customer.fullName,
      lastName: customer.lastName,
      caseType: currentCase?.caseType.label ?? '-',
      journeyState: '-',
      providerFacingStatus: currentCase?.caseState?.providerFacing ?? '-',
      internal: currentCase?.caseState?.internal ?? '-',
      treatingProvider: customer.referringDentist?.fullName ?? '-',
      email: customer.user.email ?? '-',
    };
  });
};
