import { Button, colors } from 'core/components';
import styled from 'styled-components/macro';
import { Title } from 'pages/Patient/PatientDetail/PatientDetail.css';

export const Container = styled.div`
  border-top: 1px solid ${colors.black20};
  border-bottom: 1px solid ${colors.black20};

  ${({ theme }) => `
    padding: 32px;
    @media ${theme.mediaQueries.mobile} {
      padding: 16px 20px;
    }
  `}
`;

export const OptionsContainer = styled.div`
  display: flex;
  gap: 16px;

  div {
    max-width: 328px;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    width: 100%;
    gap: 8px;

    div {
      max-width: 100%;
    }
  }
`;

export const OptionsTitle = styled(Title)`
  margin-bottom: 16px;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 480px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 10px;
`;
export const Header = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
export const CancelButton = styled(Button)`
  color: ${colors.black70};

  &:hover {
    background: ${colors.white};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 32px;
  box-shadow: 0px 1px 0px 0px #dedede inset;
`;
