import styled, { keyframes } from 'styled-components';
import { hideVisually } from 'polished';

const bounce = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Container = styled.span`
  display: inline-flex;
  justify-content: space-between;
  width: 1.75rem;
  color: ${({ color, theme }) => color || theme.palette.black};
  vertical-align: middle;
`;

export const Dot = styled.span<{ delay: number }>`
  width: 0.5rem;
  height: 0.5rem;
  background: currentColor;
  border-radius: 50%;
  animation: ${bounce} 1s ease-in-out infinite both;
  animation-delay: ${({ delay }) => delay}s;
`;

export const Text = styled.span`
  ${hideVisually()};
`;
