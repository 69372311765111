import { ValuesOf } from 'utils/types';

import {
  SelectedAddress,
  SelectedAddressType,
} from 'components/AddressForm/types';
import { RejectionReason } from 'pages/OrthoPrism/types';

export const ReviewOption = {
  Accept: 'accept',
  ProAccept: 'pro_accept',
  Reject: 'reject',
  Approve: 'approve',
  ProReject: 'pro_clinician_reject',
  MissingInformation: 'missing_information',
  QcAccept: 'approve_by_qc',
  QcReject: 'reject_by_qc',
} as const;
export type ReviewOption = ValuesOf<typeof ReviewOption>;

/// WARNING: This is a big hack
///
/// - Rejections require sub reasons to be present on the submission arguments.
/// - EXCEPT in the case of `revise_setup` which has no sub reasons.
///
/// This constant is checked in for the `isRejectTreatmentPlan` typeguard.
/// BUT it should be checked at a higher level (inspecting the reasons
/// from the server to see if they have any sub-reasons)
///
/// Please fix ASAP.
const UglyHackReviseTreatmentPlan = 'revise_setup';

const tpOptions = Object.values(ReviewOption);
export const isReviewOption = (o: any): o is ReviewOption =>
  tpOptions.indexOf(o) > -1;

export const isRejectionOption = (o?: ReviewOption): boolean => {
  switch (o) {
    case ReviewOption.Reject:
    case ReviewOption.ProReject:
    case ReviewOption.QcReject:
      return true;
    default:
      return false;
  }
};

export const isAcceptOption = (o?: ReviewOption): boolean => {
  switch (o) {
    case ReviewOption.Accept:
    case ReviewOption.ProAccept:
    case ReviewOption.QcAccept:
      return true;
    default:
      return false;
  }
};

const otherReasonNames = new Set(['cnt_other', 'nmi_other']);

export const mainRejectionCategories = (
  rs?: RejectionReason[]
): RejectionReason[] | undefined =>
  rs?.filter((r) => !otherReasonNames.has(r.name));

export const otherRejectionCategory = (
  rs?: RejectionReason[]
): RejectionReason | undefined => rs?.find((r) => otherReasonNames.has(r.name));

interface AcceptTreatmentPlan {
  transition: typeof ReviewOption.Accept;
  notes?: string;
  shippingAddress?: SelectedAddress;
  shippingAddressType?: SelectedAddressType;
  sendPatientUpdate?: boolean;
}

interface ProAcceptTreatmentPlan {
  transition: typeof ReviewOption.ProAccept;
  notes: string;
  shippingAddress: SelectedAddress;
  shippingAddressType: SelectedAddressType;
  sendPatientUpdate: boolean;
}

interface ApproveTreatmentPlan {
  transition: typeof ReviewOption.Approve;
  notes?: string;
  shippingAddress?: SelectedAddress;
  shippingAddressType?: SelectedAddressType;
  sendPatientUpdate?: boolean;
  clientCouponCode?: string;
}

interface QcAcceptTreatmentPlan {
  transition: typeof ReviewOption.QcAccept;
  notes?: string;
  shippingAddress?: SelectedAddress;
  shippingAddressType?: SelectedAddressType;
  sendPatientUpdate?: boolean;
}

interface RejectTreatmentPlan {
  transition:
    | typeof ReviewOption.Reject
    | typeof ReviewOption.MissingInformation;
  reason: string;
  subReasons: string[];
  notes: string;
  shippingAddress?: SelectedAddress;
  shippingAddressType?: SelectedAddressType;
  sendPatientUpdate?: boolean;
}

interface ProRejectTreatmentPlan {
  transition: typeof ReviewOption.ProReject;
  notes: string;
  shippingAddress?: SelectedAddress;
  shippingAddressType?: SelectedAddressType;
  sendPatientUpdate?: boolean;
  reason?: string;
}

interface QcRejectTreatmentPlan {
  transition: typeof ReviewOption.QcReject;
  notes?: string;
  shippingAddress?: SelectedAddress;
  shippingAddressType?: SelectedAddressType;
  sendPatientUpdate?: boolean;
}

type OrthoSubmissionArgs =
  | AcceptTreatmentPlan
  | ProAcceptTreatmentPlan
  | RejectTreatmentPlan
  | ProRejectTreatmentPlan
  | QcAcceptTreatmentPlan
  | QcRejectTreatmentPlan;

type ProClinicianSubmissionArgs = ApproveTreatmentPlan | ProRejectTreatmentPlan;

const isAcceptTreatmentPlan = (args: any): args is AcceptTreatmentPlan =>
  args.transition === ReviewOption.Accept;

export const isProAcceptTreatmentPlan = (
  args: any
): args is ProAcceptTreatmentPlan =>
  args.transition === ReviewOption.ProAccept && !!args.notes;

export const isRejectTreatmentPlan = (
  args: any
): args is RejectTreatmentPlan => {
  const isRejectOrMissing =
    args.transition === ReviewOption.Reject ||
    args.transition === ReviewOption.ProReject ||
    args.transition === ReviewOption.MissingInformation;
  const hasReasonAndNotes = !!args.reason && !!args.notes;

  // this is an ugly hack and should be taken out of here.
  const hasSubreasonsIfNeeded =
    args.reason === UglyHackReviseTreatmentPlan || !!args.subReasons?.length;

  return isRejectOrMissing && hasReasonAndNotes && hasSubreasonsIfNeeded;
};
export const isProRejectTreatmentPlan = (
  args: any
): args is ProRejectTreatmentPlan =>
  args.transition === ReviewOption.ProReject && !!args.notes;

const isApproveTreatmentPlan = (args: any): args is ApproveTreatmentPlan =>
  args.transition === ReviewOption.Approve;

export const isQcAcceptTreatmentPlan = (
  args: any
): args is QcAcceptTreatmentPlan => args.transition === ReviewOption.QcAccept;

export const isQcRejectTreatmentPlan = (
  args: any
): args is QcRejectTreatmentPlan => args.transition === ReviewOption.QcReject;

export const isOrthoSubmissionArgs = (args: any): args is OrthoSubmissionArgs =>
  isAcceptTreatmentPlan(args) ||
  isProAcceptTreatmentPlan(args) ||
  isRejectTreatmentPlan(args) ||
  isProRejectTreatmentPlan(args) ||
  isQcAcceptTreatmentPlan(args) ||
  isQcRejectTreatmentPlan(args);

export const isProClinicianSubmissionArgs = (
  args: any
): args is ProClinicianSubmissionArgs =>
  isApproveTreatmentPlan(args) || isProRejectTreatmentPlan(args);
